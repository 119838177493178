import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import QuestionEdit         from "./QuestionEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchQuestions, deleteQuestion,
} from "Actions/Admin/Coach/QuestionActions";



/**
 * The Coach Question List
 */
class QuestionList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        this.props.fetchQuestions(params || this.props.data.sort);
    }

    /**
     * Fetch the tab content
     * @param {Number=} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        this.fetch({ ...this.props.data.sort, filter : Number(filter), page : 0 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.fetchTab(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteQuestion(elemID);
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "coachQuestionID", elemID, "question");

        return <Main>
            <Header message="COACH_QUESTIONS_NAME" icon="question" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="CONVERSATIONS_NAME" value="1" />
                    <TabItem message="MEETINGS_NAME"      value="2" />
                </TabList>
                <Table fetch={this.fetch} sort={sort} none="QUESTIONS_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="question"    message="QUESTIONS_SINGULAR" grow="3" />
                        <TableHeader field="type"        message="GENERAL_TYPE"       />
                        <TableHeader field="position"    message="GENERAL_POSITION"   maxWidth="80" align="center" hideMobile />
                        <TableHeader field="createdTime" message="GENERAL_CREATED"    maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.coachQuestionID} elemID={elem.coachQuestionID}>
                            <TableCell message={elem.question}    circle={elem.statusColor} />
                            <TableCell message={elem.typeName}    />
                            <TableCell message={elem.position}    />
                            <TableCell message={elem.createdDate} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="QUESTIONS_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="QUESTIONS_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <QuestionEdit
                open={action.isVCE}
                elemID={elemID}
                section={sort.filter}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="QUESTIONS_DELETE_TITLE"
                message="QUESTIONS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuestions : PropTypes.func.isRequired,
        deleteQuestion : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        route          : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.coachQuestion,
        };
    }
}

export default connect(QuestionList.mapStateToProps, {
    fetchQuestions, deleteQuestion,
})(QuestionList);
