import React                                from "react";
import ReactDOM                             from "react-dom";
import * as ServiceWorker                   from "serviceWorker";
import { BrowserRouter }                    from "react-router-dom";
import { pdfjs }                            from "react-pdf";

// Redux
import { createStore, applyMiddleware }     from "redux";
import thunk                                from "redux-thunk";
import { composeWithDevTools }              from "redux-devtools-extension";
import RootReducer                          from "Reducers/RootReducer";

// NLS
import actions                              from "Utils/Actions";
import params                               from "Utils/Params";
import access                               from "Utils/Access";
import status                               from "Utils/Status";
import esStrings                            from "NLS/Strings/es";
import enStrings                            from "NLS/Strings/en";
import esUrls                               from "NLS/Urls/es";
import enUrls                               from "NLS/Urls/en";
import esActions                            from "NLS/Actions/es";
import enActions                            from "NLS/Actions/en";

// Dashboard
import Dashboard                            from "dashboard/dist/Dashboard";
import NLS                                  from "dashboard/dist/Core/NLS";

// Mysc
import Main                                 from "Components/Core/Main";



// Creates a Redux Store
const store = createStore(
    RootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

// Set the NLS Languages
NLS.initLang("es", esStrings, esUrls, esActions);
NLS.initLang("en", enStrings, enUrls, enActions);
NLS.setLang("es");

// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// VH Hack
const fullHeight = window.innerHeight;
document.documentElement.style.setProperty("--full-height", `${fullHeight}px`);
window.addEventListener("resize", () => {
    const fullHeight = window.innerHeight;
    document.documentElement.style.setProperty("--full-height", `${fullHeight}px`);
});



// Render the App
ReactDOM.render(
    <BrowserRouter basename={process.env.REACT_APP_BASEURL}>
        <Dashboard
            store={store}
            actions={actions}
            params={params}
            access={access}
            status={status}
        >
            <Main />
        </Dashboard>
    </BrowserRouter>,
    document.getElementById("root")
);

ServiceWorker.register({
    onUpdate(registration) {
        console.log("New version available! Ready to update?");
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
        window.location.reload();
    }
});
