const data = {

    // ID to Section
    SECTIONS  : {
    },



    // ID to Action
    ACTIONS   : {
    },
};

export default data;
