import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import GuideView            from "./GuideView";
import GuideEdit            from "./GuideEdit";
import GuideStudents        from "./GuideStudents";
import GuideAnswers         from "./GuideAnswers";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchGuides, deleteGuide, importGuides,
} from "Actions/Admin/Strech/GuideActions";



/**
 * The Strech Guide List
 */
class GuideList extends React.Component {
    // The Current State
    state = {
        action    : Action.get(),
        elemID    : 0,
        studentID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();

        const params = this.props.params;
        if (params.guideID && params.studentID) {
            this.startAction(Action.get("GUIDE"), params.guideID, params.studentID);
        }
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchGuides(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Number}  elemID
     * @param {Number=} studentID
     * @returns {Void}
     */
    startAction = (action, elemID, studentID = 0) => {
        this.setState({ action, elemID, studentID });
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch();
        }
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
        this.props.fetchStrech(this.props.elemID);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteGuide(this.state.elemID);
        this.editElem();
    }

    /**
     * Handles the Import Submit
     * @returns {Promise}
     */
    importElem = async () => {
        const strechID = this.props.params.strechID;
        await this.props.importGuides(strechID);
        this.editElem();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, studentID                                  } = this.state;
        const { data, strech, params, route, withDetails                   } = this.props;
        const { canEdit, canReply, canFeedback, list, total, sort, loading } = data;

        const elem     = Utils.getValue(list, "strechGuideID", elemID);
        const elemName = elem.guideName || "";

        return <Main withDetails={withDetails}>
            <Header message="GUIDES_NAME" icon="guide" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <StrechBar step={StrechStep.GUIDES} />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="GUIDES_NONE_AVAILABLE"
                    hasAlert={StrechStep.hasHelp(strech, StrechStep.GUIDES)}
                >
                    <TableHead>
                        <TableHeader field="guideName" message="GENERAL_NAME"     grow="2" hideCircle={!canEdit} bigMobile />
                        <TableHeader field="className" message="CLASSES_SINGULAR" grow="2" bigMobile />
                        <TableHeader field="position"  message="GENERAL_POSITION" maxWidth="80" align="center" isHidden={!canEdit} hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechGuideID} elemID={elem.strechGuideID}>
                            <TableCell message={elem.guideName}   circle={elem.statusColor} />
                            <TableCell message={elem.classString} />
                            <TableCell message={elem.position}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="GUIDES_VIEW_TITLE"   />
                        <TableAction action="EDIT"   message="GUIDES_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="GUIDES_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <GuideView
                open={Boolean(canReply && action.isView)}
                elemID={elemID}
                onClose={this.endAction}
            />
            <GuideStudents
                open={Boolean(canFeedback && elem.isActive && action.isView)}
                elemID={elemID}
                onClose={this.endAction}
            />
            <GuideAnswers
                open={action.isGuide}
                elemID={elemID}
                credentialID={studentID}
                onClose={this.endAction}
            />

            <GuideEdit
                open={canEdit && ((!elem.isActive && action.isView) || action.isCE)}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="GUIDES_DELETE_TITLE"
                message="GUIDES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isImport}
                icon="import"
                title="GUIDES_IMPORT_TITLE"
                message="GUIDES_IMPORT_TEXT"
                onSubmit={this.importElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech  : PropTypes.func.isRequired,
        fetchGuides  : PropTypes.func.isRequired,
        deleteGuide  : PropTypes.func.isRequired,
        importGuides : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        strech       : PropTypes.object.isRequired,
        type         : PropTypes.string.isRequired,
        params       : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
        withDetails  : PropTypes.bool.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.strechGuide,
            strech : state.strech.elem,
        };
    }
}

export default connect(GuideList.mapStateToProps, {
    fetchStrech, fetchGuides, deleteGuide, importGuides,
})(GuideList);
