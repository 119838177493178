import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachQuestion }    from "Utils/API";

// Types
import {
    COACH_QUESTION, COACH_QUESTION_LOADING,
    COACH_QUESTION_LIST, COACH_QUESTION_ELEM, COACH_QUESTION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Question List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestions(params = {}) {
    return async (dispatch) => {
        dispatch({ type : COACH_QUESTION_LOADING });
        Params.unset(params);
        const data = await CoachQuestion.getAll(params);
        data.sort = params;
        dispatch({ type : COACH_QUESTION_LIST, data });
        dispatch(setSection(COACH_QUESTION));
    };
}

/**
 * Fetches a single Question
 * @param {Number} coachQuestionID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestion(coachQuestionID) {
    return async (dispatch) => {
        const data = await CoachQuestion.getOne({ coachQuestionID });
        dispatch({ type : COACH_QUESTION_ELEM, data });
    };
}

/**
 * Fetches the Question Create data
 * @param {Number} section
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestionCreate(section) {
    return async (dispatch) => {
        const data = await CoachQuestion.getCreateData({ section });
        dispatch({ type : COACH_QUESTION_CREATE, data });
    };
}



/**
 * Edits a Question
 * @param {Object} data
 * @returns {() => Void}
 */
export function editQuestion(data) {
    return () => CoachQuestion.edit(data);
}

/**
 * Deletes a Question
 * @param {Number} coachQuestionID
 * @returns {() => Void}
 */
export function deleteQuestion(coachQuestionID) {
    return () => CoachQuestion.delete({ coachQuestionID });
}
