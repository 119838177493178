import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchMeeting }     from "Actions/Admin/Strech/MeetingActions";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Meeting View Dialog
 */
class MeetingView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchMeeting } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchMeeting(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading             } = this.state;

        return <ViewDialog
            open={open}
            icon="meeting"
            title="MEETINGS_VIEW_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField
                label="COACHES_SINGULAR"
                value={elem.coachString}
            />
            <ViewField label="GENERAL_MODE" value={elem.modeName} />
            {elem.isPresential && <ViewField
                label="LOCATIONS_SINGULAR"
                value={elem.locationString}
            />}
            {elem.hasStream && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={elem.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={elem.streamPassword} />
            </>}
            <ViewField
                label="GENERAL_DATE"
                value={elem.timeString}
            />
            <ViewField
                isHidden={!elem.isCancelled}
                label="GENERAL_STATUS"
                value={elem.cancelledString}
            />
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeeting : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        elem         : PropTypes.object.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.meeting.edition,
            elem    : state.meeting.elem,
        };
    }
}

export default connect(MeetingView.mapStateToProps, {
    fetchMeeting,
})(MeetingView);
