import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramGuide }     from "Utils/API";

// Types
import {
    PROGRAM_GUIDE, PROGRAM_GUIDE_LOADING,
    PROGRAM_GUIDE_LIST, PROGRAM_GUIDE_ELEM, PROGRAM_GUIDE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program Guide List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuides(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_GUIDE_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramGuide.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_GUIDE_LIST, data });
        dispatch(setSection(PROGRAM_GUIDE));
    };
}

/**
 * Fetches a single Program Guide
 * @param {Number} programGuideID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuide(programGuideID) {
    return async (dispatch) => {
        const data = await ProgramGuide.getOne({ programGuideID });
        dispatch({ type : PROGRAM_GUIDE_ELEM, data });
    };
}

/**
 * Fetches the Program Guide Create data
 * @param {Number} programID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuideCreate(programID) {
    return async (dispatch) => {
        const data = await ProgramGuide.getCreateData({ programID });
        dispatch({ type : PROGRAM_GUIDE_CREATE, data });
    };
}



/**
 * Edits/Creates a Program Guide
 * @param {Object} data
 * @returns {() => Void}
 */
export function editGuide(data) {
    return () => ProgramGuide.edit(data);
}

/**
 * Deletes a Program Guide
 * @param {Number} programGuideID
 * @returns {() => Void}
 */
export function deleteGuide(programGuideID) {
    return () => ProgramGuide.delete({ programGuideID });
}
