import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchQuestion, fetchQuestionCreate, editQuestion,
} from "Actions/Admin/Strech/QuestionActions";



/**
 * The Strech Question Edit Dialog
 */
class QuestionEdit extends React.Component {
    // The Initial Data
    initialData = {
        strechQuestionID : 0,
        strechID         : 0,
        questionID       : 0,
        question         : "",
        type             : "",
        options          : "",
        withOther        : 0,
        isRequired       : 0,
        position         : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, strechID, position, fetchQuestion, fetchQuestionCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchQuestion(elemID);
                loading = true;
            // Load edit data
            } else if (strechID) {
                fetchQuestionCreate(strechID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, strechID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const { questData } = this.props;
        let fields = { [name] : value };
        let errors = { [name] : ""    };

        if (name === "questionID") {
            const elem        = Utils.getValue(questData, "questionID", value);
            fields.question   = elem.question   || "";
            fields.type       = elem.type       || "";
            fields.options    = elem.options    || "";
            fields.withOther  = elem.isRequired || 0;
            fields.isRequired = elem.isRequired || 0;
            errors.question   = "";
            errors.options    = "";
            errors.type       = "";
            errors.withOther  = "";
            errors.isRequired = "";
        }

        this.setState({
            data   : { ...this.state.data,   ...fields },
            errors : { ...this.state.errors, ...errors },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editQuestion(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, questions } = this.props;
        const { data, loading, errors            } = this.state;

        return <EditDialog
            open={open}
            icon="question"
            title={!!elemID ? "QUESTIONS_EDIT_TITLE" : "QUESTIONS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="questionID"
                label="QUESTIONS_SINGULAR"
                helperText="QUESTIONS_NEW_HELP"
                value={data.questionID}
                error={errors.questionID}
                onChange={this.handleChange}
                options={questions}
                noneText="QUESTIONS_NEW_QUESTION"
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                name="question"
                label="QUESTIONS_SINGULAR"
                value={data.question}
                error={errors.question}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="type"
                label="QUESTIONS_TYPE"
                value={data.type}
                error={errors.type}
                onChange={this.handleChange}
                options="QUESTIONS_TYPES"
                withNone
                isRequired
            />
            <InputField
                isHidden={data.type !== "radio"}
                type="fields"
                name="options"
                label="QUESTIONS_OPTIONS"
                value={data.options}
                error={errors.options}
                onChange={this.handleChange}
                isRequired
                isSmall
            />
            <InputField
                isHidden={data.type !== "radio"}
                type="toggle"
                name="withOther"
                label="QUESTIONS_WITH_OTHER"
                value={data.withOther}
                error={errors.withOther}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="isRequired"
                label="QUESTIONS_IS_REQUIRED"
                value={!!data.isRequired}
                onChange={this.handleChange}
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={this.handleChange}
                shrinkLabel
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuestion       : PropTypes.func.isRequired,
        fetchQuestionCreate : PropTypes.func.isRequired,
        editQuestion        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        position            : PropTypes.number.isRequired,
        questions           : PropTypes.array.isRequired,
        questData           : PropTypes.array.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
        strechID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.strechQuestion.edition,
            position  : state.strechQuestion.position,
            questions : state.strechQuestion.questions,
            questData : state.strechQuestion.questData,
            elem      : state.strechQuestion.elem,
        };
    }
}

export default connect(QuestionEdit.mapStateToProps, {
    fetchQuestion, fetchQuestionCreate, editQuestion,
})(QuestionEdit);
