import { setSection }        from "Actions/Core/CoreActions";
import { ConversationReport }from "Utils/API";

// Types
import {
    CONVERSATION_REPORT, CONVERSATION_REPORT_LOADING,
    CONVERSATION_REPORT_DATA, CONVERSATION_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Conversation Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchConversationData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CONVERSATION_REPORT_LOADING });
        }
        const data = await ConversationReport.getData(params);
        dispatch({ type : CONVERSATION_REPORT_DATA, data });
        dispatch(setSection(CONVERSATION_REPORT));
    };
}

/**
 * Fetches the Conversation Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchConversationGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CONVERSATION_REPORT_LOADING });
        }
        const data = await ConversationReport.getGraph(params);
        dispatch({ type : CONVERSATION_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Conversation Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchConversationEmails(params = {}) {
    return async () => {
        return await ConversationReport.getEmails(params);
    };
}
