import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Containers
import CoachContainer       from "Components/Coach/CoachContainer";
import ProfileContainer     from "Components/Profile/ProfileContainer";

// Sections
import StrechPage           from "Components/Coach/StrechPage";
import StrechMinePage       from "Components/Student/Strech/StrechPage";
import ClassPage            from "Components/Admin/Strech/Class/ClassPage";
import GroupPage            from "Components/Admin/Strech/Group/GroupPage";

// Components
import CoachRoute           from "Components/Utils/Route/CoachRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Coach Router
 */
class CoachRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isCoach) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <CoachRoute url="PROFILE"           component={ProfileContainer} />
            <CoachRoute url="STRECH_CLASS"      component={ClassPage}        />
            <CoachRoute url="STRECH_GROUP"      component={GroupPage}        />
            <CoachRoute url="STRECH"            component={StrechPage}       />
            <CoachRoute url="GROUP"             component={GroupPage}        />
            <CoachRoute url="COURSE_MINE_CLASS" component={ClassPage}        />
            <CoachRoute url="COURSE_MINE"       component={StrechMinePage}   />
            <CoachRoute url="NONE"              component={CoachContainer}   />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isCoach : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isCoach : state.auth.isCoach,
        };
    }
}

export default connect(CoachRouter.mapStateToProps)(CoachRouter);
