import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import HyperLink            from "dashboard/dist/Components/HyperLink";



/**
 * The Strech Url Dialog
 */
class StrechUrl extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;

        const url = NLS.fullUrl("INSC_STRECH_ONLY", elemID);

        return <ViewDialog
            open={open}
            icon="link"
            title="COURSES_VIEW_URL_TITLE"
            secondary="GENERAL_COPY_URL"
            onSecondary={() => navigator.clipboard.writeText(url)}
            onClose={onClose}
            withSpacing
        >
            <h3 className="align-center">
                <HyperLink
                    message={url}
                    url={url}
                    target="_blank"
                />
            </h3>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elemID  : PropTypes.number,
    }
}

export default StrechUrl;
