import React                from "react";
import PropTypes            from "prop-types";

// Components
import HelpContent          from "./HelpContent";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";



/**
 * The Help Page
 */
class HelpPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, withDetails } = this.props;

        return <Main withDetails={withDetails}>
            <Header message="HELP_NAME" icon="help" route={route} />
            <Content>
                <HelpContent withButton />
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
    }
}

export default HelpPage;
