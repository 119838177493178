import { setSection }       from "Actions/Core/CoreActions";
import { Home }             from "Utils/API";

// Types
import {
    HOME, HOME_LOADING,
    HOME_LIST, HOME_WELCOME,
} from "Utils/Types";



/**
 * Fetches the Home
 * @returns {(dispatch: Function) => Void}
 */
export function fetchHome() {
    return async (dispatch) => {
        dispatch({ type : HOME_LOADING });
        const data = await Home.getHome();
        dispatch({ type : HOME_LIST, data });
        dispatch(setSection(HOME));
    };
}

/**
 * Fetches the Welcome
 * @returns {(dispatch: Function) => Void}
 */
export function fetchWelcome() {
    return async (dispatch) => {
        dispatch({ type : HOME_LOADING });
        const data = await Home.getWelcome();
        dispatch({ type : HOME_WELCOME, data });
        dispatch(setSection(HOME));
    };
}
