import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    CAMPAIGN_LOADING, CAMPAIGN_CHARGING,
    CAMPAIGN_LIST, CAMPAIGN_ELEM, CAMPAIGN_SEND, CAMPAIGN_CONTENT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading    : false,
    charging   : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    templates  : [],
    templateID : 0,
    users      : [],
    content    : "",
    sort       : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.sendTimeDate    = DateTime.formatDate(elem.sendTime, "dashesReverse");
    elem.sendTimeHour    = DateTime.formatDate(elem.sendTime, "time");
    elem.sentDateTime    = DateTime.formatIf(elem.sentTime, "dashesTime");
    elem.sendToText      = NLS.pluralize("CAMPAIGNS_SEND_TO", elem.totalUsers);
    elem.stateName       = NLS.get("CAMPAIGNS_STATE_NAMES", elem.state);
    elem.stateClass      = Utils.getTextClass("CAMPAIGNS_STATE_COLORS", elem.state);
    return elem;
}

/**
 * Parses a single Element
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseTemplates(list, templateID) {
    list.sort((a, b) => {
        if (a.key === templateID) {
            return -1;
        }
        if (b.key === templateID) {
            return 1;
        }
        return String(a.value).toLocaleLowerCase().localeCompare(b.value.toLocaleLowerCase());
    });
    return list;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, CAMPAIGN_LIST, CAMPAIGN_ELEM, CAMPAIGN_SEND, CAMPAIGN_CONTENT)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case CAMPAIGN_LOADING:
        return {
            ...state,
            loading    : true,
        };
    case CAMPAIGN_CHARGING:
        return {
            ...state,
            charging   : true,
        };

    case CAMPAIGN_LIST:
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : parseList(action.data.list),
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case CAMPAIGN_ELEM:
        return {
            ...state,
            charging   : false,
            error      : false,
            edition    : state.edition + 1,
            canEdit    : action.data.canEdit,
            elem       : parseElem(action.data.elem),
            users      : action.data.users,
            templates  : parseTemplates(action.data.templates, action.data.templateID),
            templateID : action.data.templateID,
        };

    case CAMPAIGN_SEND:
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            templates  : parseTemplates(action.data.templates, action.data.templateID),
            templateID : action.data.templateID,
        };

    case CAMPAIGN_CONTENT:
        return {
            ...state,
            error      : false,
            charging   : false,
            content    : action.data.content,
        };

    default:
        return state;
    }
};

export default reducer;
