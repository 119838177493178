import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchSpeciality, fetchSpecialityCreate, editSpeciality,
} from "Actions/Admin/Program/SpecialityActions";



/**
 * The Program Speciality Edit Dialog
 */
class SpecialityEdit extends React.Component {
    // The Initial Data
    initialData = {
        programSpecialityID : 0,
        programID           : 0,
        specialityID        : 0,
        maxAmount           : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, programID, fetchSpeciality, fetchSpecialityCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchSpeciality(elemID);
                loading = true;
            // Load edit data
            } else if (programID) {
                fetchSpecialityCreate(programID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, programID };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editSpeciality(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, specialities } = this.props;
        const { data, loading, errors               } = this.state;

        return <EditDialog
            open={open}
            icon="speciality"
            title={!!elemID ? "SPECIALITIES_EDIT_TITLE" : "SPECIALITIES_ASSIGN_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="specialityID"
                label="SPECIALITIES_SINGULAR"
                value={data.specialityID}
                error={errors.specialityID}
                onChange={this.handleChange}
                options={specialities}
                withNone
                isRequired
            />
            <InputField
                type="number"
                name="maxAmount"
                label="SPECIALITIES_MAX_AMOUNT"
                value={data.maxAmount}
                onChange={this.handleChange}
                error={errors.maxAmount}
                withNone
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchSpeciality       : PropTypes.func.isRequired,
        fetchSpecialityCreate : PropTypes.func.isRequired,
        editSpeciality        : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        onSubmit              : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        specialities          : PropTypes.array.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
        programID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.programSpeciality.edition,
            specialities : state.programSpeciality.specialities,
            elem         : state.programSpeciality.elem,
        };
    }
}

export default connect(SpecialityEdit.mapStateToProps, {
    fetchSpeciality, fetchSpecialityCreate, editSpeciality,
})(SpecialityEdit);
