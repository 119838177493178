import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import CoachSelect          from "./CoachSelect";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";
import ViewField            from "dashboard/dist/Components/ViewField";
import Columns              from "dashboard/dist/Components/Columns";

// Actions
import {
    fetchAssignment, editAssignment,
} from "Actions/Admin/Strech/AssignmentActions";



/**
 * The Assignment Edit Dialog
 */
class AssignmentEdit extends React.Component {
    // The Initial Data
    initialData = {
        inscriptionID : 0,
        assignments   : {},
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        action  : Action.get(),
        elemID  : 0,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const {
            open, edition, elemID, strechID, credentialID,
            inscriptionID, specialities, assignments, fetchAssignment,
        } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchAssignment(elemID);
                loading = true;
            } else if (strechID && credentialID) {
                fetchAssignment(0, strechID, credentialID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && inscriptionID) {
            const newAssignments = {};
            for (const elem of specialities) {
                newAssignments[elem.specialityID] = { coachID : 0, additionals : "" };
            }
            for (const elem of assignments) {
                if (newAssignments[elem.specialityID] && newAssignments[elem.specialityID].coachID === 0) {
                    newAssignments[elem.specialityID].coachID     = elem.coachID;
                    newAssignments[elem.specialityID].additionals = elem.additionals;
                }
            }
            data = { inscriptionID, assignments : newAssignments };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {Number}          specialityID
     * @param {String}          field
     * @param {(Number|String)} value
     * @returns {Void}
     */
    handleChange = (specialityID, field, value) => {
        const assignments = this.state.data.assignments;
        assignments[specialityID][field] = value;
        this.setState({ data : { ...this.state.data, assignments }, errors : {} });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editAssignment({
                    inscriptionID : data.inscriptionID,
                    assignments   : JSON.stringify(data.assignments),
                });
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Starts an Action
     * @returns {Void}
     */
    startAction = (elemID) => {
        this.setState({ action : Action.get("SELECT"), elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get(), elemID : 0 });
    }

    /**
     * Selects a Coach
     * @param {Number} credentialID
     * @returns {Void}
     */
    selectCoach = (credentialID) => {
        this.handleChange(this.state.elemID, "coachID", credentialID);
        this.endAction();
    }

    /**
     * Returns the Coach Name
     * @param {Number} specialityID
     * @returns {String}
     */
    getCoachName(specialityID) {
        const coaches     = this.props.coaches;
        const assignments = this.state.data.assignments;
        let   coachName   = "";
        if (assignments[specialityID] && coaches[specialityID]) {
            coachName = Utils.getValue(coaches[specialityID], "credentialID", assignments[specialityID].coachID, "credentialName");
        }
        return coachName || NLS.get("INSC_ASSIGNS_SELECT");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, specialities, coaches, name } = this.props;
        const { data, loading, errors, action, elemID      } = this.state;

        return <>
            <EditDialog
                open={open}
                icon="assignment"
                title="INSC_ASSIGNS_EDIT_TITLE"
                error={errors.form}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                isLoading={loading}
            >
                <DialogMessage html={NLS.format("INSC_ASSIGNS_EDIT_HELP", name)} />
                <Columns amount="3">
                    {specialities.map((elem) => <React.Fragment key={elem.specialityID}>
                        <ViewField value={elem.value} />
                        <ViewField
                            label="COACHES_SINGULAR"
                            value={this.getCoachName(elem.specialityID)}
                            error={errors[`coach-${elem.specialityID}`]}
                            onClick={() => this.startAction(elem.specialityID)}
                            showEmpty
                        />
                        <InputField
                            type="number"
                            name={`additionals-${elem.specialityID}`}
                            label="INSC_ASSIGNS_ADDITIONALS"
                            value={data.assignments[elem.specialityID] ? data.assignments[elem.specialityID].additionals : ""}
                            error={errors[`additionals-${elem.specialityID}`]}
                            onChange={(name, value) => this.handleChange(elem.specialityID, "additionals", value)}
                        />
                    </React.Fragment>)}
                </Columns>
            </EditDialog>

            <CoachSelect
                open={action.isSelect}
                coaches={coaches[elemID] ? coaches[elemID] : []}
                selected={data.assignments[elemID] ? data.assignments[elemID].coachID : 0}
                onSubmit={this.selectCoach}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAssignment : PropTypes.func.isRequired,
        editAssignment  : PropTypes.func.isRequired,
        open            : PropTypes.bool.isRequired,
        onSubmit        : PropTypes.func.isRequired,
        onClose         : PropTypes.func.isRequired,
        edition         : PropTypes.number.isRequired,
        inscriptionID   : PropTypes.number.isRequired,
        name            : PropTypes.string.isRequired,
        assignments     : PropTypes.array.isRequired,
        specialities    : PropTypes.array.isRequired,
        coaches         : PropTypes.object.isRequired,
        elemID          : PropTypes.number,
        strechID        : PropTypes.number,
        credentialID    : PropTypes.number,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        elemID       : 0,
        strechID     : 0,
        credentialID : 0,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition       : state.inscriptionAssign.edition,
            name          : state.inscriptionAssign.name,
            inscriptionID : state.inscriptionAssign.inscriptionID,
            assignments   : state.inscriptionAssign.assignments,
            specialities  : state.inscriptionAssign.specialities,
            coaches       : state.inscriptionAssign.coaches,
        };
    }
}

export default connect(AssignmentEdit.mapStateToProps, {
    fetchAssignment, editAssignment,
})(AssignmentEdit);
