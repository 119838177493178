// The Ticket Type Uses
const NONE          = 0;
const CHANGE_COACH  = 1;
const CHANGE_GROUP  = 2;
const CHANGE_PRICES = 3;



// The public API
const types = {
    NONE,
    CHANGE_COACH,
    CHANGE_GROUP,
    CHANGE_PRICES,
};

export default types;