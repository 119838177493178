import React                from "react";
import PropTypes            from "prop-types";
import Action               from "dashboard/dist/Core/Action";

// Components
import MediaContent         from "Components/Utils/Media/MediaContent";
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogSearch         from "dashboard/dist/Components/DialogSearch";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";



/**
 * The Media Dialog
 */
class MediaDialog extends React.Component {
    // The Current State
    state = {
        file   : "",
        search : "",
    }

    // The Refs
    mediaRef = React.createRef();

    /**
     * Handles the Media Select
     * @param {String} file
     * @returns {Void}
     */
    handleSelect = (file) => {
        this.setState({ file });
    }

    /**
     * Handles the Media Select
     * @returns {Void}
     */
    handleCreate = () => {
        this.mediaRef.current.startAction(Action.get("CREATE"));
    }

    /**
     * Handles the Submit
     * @returns {Void}
     */
    handleSubmit = () => {
        if (this.state.file) {
            this.props.onSubmit(this.state.file);
        } else {
            this.props.onClose();
        }
    }

    /**
     * Handles the Search Change
     * @param {String} search
     * @returns {Void}
     */
    handleSearch = (search) => {
        this.setState({ search });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.mediaRef.current.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, mediaType, path, selected, onUpdate } = this.props;
        const { file, search                                       } = this.state;

        return <Dialog open={open} onClose={onClose} isWide noTab>
            <DialogHeader message="MEDIA_SELECT_TITLE" icon="media">
                <DialogSearch value={search} onChange={this.handleSearch} />
            </DialogHeader>
            <DialogBody>
                <MediaContent
                    passedRef={this.mediaRef}
                    mediaType={mediaType}
                    path={path}
                    search={search}
                    selected={selected}
                    onSelect={this.handleSelect}
                    onUpdate={onUpdate}
                    canSelect
                    withSpacing
                />
            </DialogBody>
            <DialogFooter
                primary="GENERAL_SELECT"
                secondary="MEDIA_CREATE_TITLE"
                onSubmit={this.handleSubmit}
                onSecondary={this.handleCreate}
                isDisabled={!file}
            />
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open      : PropTypes.bool.isRequired,
        onClose   : PropTypes.func.isRequired,
        onUpdate  : PropTypes.func.isRequired,
        onSubmit  : PropTypes.func.isRequired,
        mediaType : PropTypes.string,
        path      : PropTypes.string,
        selected  : PropTypes.string,
    }
}

export default MediaDialog;
