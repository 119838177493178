import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProgram }     from "Actions/Admin/Program/ProgramActions";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ProgramEdit          from "./ProgramEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";


/**
 * The Program Details
 */
class ProgramDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchProgram(this.props.programID, true);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, programID } = this.props;
        const { action                                    } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="PROGRAMS_ERROR_EXISTS"
            >
                <DetailList message="PROGRAMS_DETAILS_TITLE">
                    <DetailItem
                        icon="name"
                        message={elem.name}
                        tooltip="PROGRAMS_ITS_NAME"
                    />
                    <DetailItem
                        icon="name"
                        message={elem.title}
                        tooltip="GENERAL_STUDENT_TITLE"
                    />
                    <DetailItem
                        icon="course"
                        message={elem.courseName}
                        tooltip="PROGRAMS_COURSE"
                        href={NLS.baseUrl("COURSES", elem.courseID)}
                    />
                    <DetailItem
                        icon="type"
                        message={elem.typeName}
                        tooltip="PROGRAMS_TYPE"
                    />
                    <DetailItem
                        icon="status"
                        message={elem.statusName}
                        tooltip="GENERAL_STATUS"
                        className={elem.statusClass}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.createdDateTime}
                        tooltip="GENERAL_CREATED"
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT" message="PROGRAMS_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <ProgramEdit
                open={action.isEdit}
                elemID={programID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProgram : PropTypes.func.isRequired,
        charging     : PropTypes.bool.isRequired,
        error        : PropTypes.bool.isRequired,
        canEdit      : PropTypes.bool.isRequired,
        elem         : PropTypes.object.isRequired,
        programID    : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.program.charging,
            error    : state.program.error,
            canEdit  : state.program.canEdit,
            elem     : state.program.elem,
        };
    }
}

export default connect(ProgramDetails.mapStateToProps, {
    fetchProgram,
})(ProgramDetails);
