import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    INSCRIPTION_CHAT_LOADING,
    INSCRIPTION_CHAT_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canEdit   : false,
    elem      : {},
    students  : [],
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.date         = DateTime.formatIf(elem.time,          "dashes");
    elem.scheduleTime = [ elem.fromHour, elem.toHour ].join(" - ");
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, INSCRIPTION_CHAT_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case INSCRIPTION_CHAT_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case INSCRIPTION_CHAT_LIST:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            students  : action.data.students,
        };

    default:
        return state;
    }
};

export default reducer;
