import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import DateTime             from "dashboard/dist/Utils/DateTime";

// Dialogs
import StrechView           from "./Views/StrechView";
import ClassView            from "./Views/ClassView";
import ChatView             from "./Views/ChatView";
import MeetingView          from "./Views/MeetingView";
import ConversationView     from "./Views/ConversationView";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import Content              from "dashboard/dist/Components/Content";
import MenuLink             from "dashboard/dist/Components/MenuLink";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";

// Actions
import {
    fetchCalendar, setCalendarDay,
} from "Actions/Student/CalendarActions";

// Styles
import "Styles/Components/App/Calendar.css";



/**
 * The Calendar Page
 */
class CalendarPage extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        dialog : "",
        elem   : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch(this.props.currentDay);
    }

    /**
     * Fetch the content
     * @param {Object} currentDay
     * @returns {Void}
     */
    fetch(currentDay) {
        const fromTime = currentDay.toMonthStart().toWeekStart().time;
        const toTime   = currentDay.toMonthEnd().toWeekEnd().time;
        this.props.fetchCalendar(fromTime, toTime);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        const oldDay     = this.props.currentDay;
        let   currentDay = oldDay;

        switch (action.name) {
        case "PREV":
            currentDay = currentDay.moveMonth(-1, 1);
            break;
        case "NEXT":
            currentDay = currentDay.moveMonth(1, 1);
            break;
        case "TODAY":
            currentDay = DateTime.create();
            break;
        default:
        }

        this.setState({ action });
        if (!currentDay.isEqualTimeTo(oldDay)) {
            this.props.setCalendarDay(currentDay);
            this.fetch(currentDay);
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get() });
    }

    /**
     * Goes the the given Date
     * @param {String} newDate
     * @returns {Void}
     */
    handleGoto = (newDate) => {
        const newDay = DateTime.fromString(newDate);
        // @ts-ignore
        if (!newDay.isEqualMonthTo(this.props.currentDay)) {
            this.props.setCalendarDay(newDay);
            this.fetch(newDay);
        }
        this.endAction();
    }



    /**
     * Opens a Dialog
     * @param {Object} elem
     * @returns {Void}
     */
    openDialog = (elem) => {
        this.setState({ dialog : elem.type, elem });
    }

    /**
     * Closes a Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ dialog : "", elem : {} });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, currentDay, events, route } = this.props;
        const { action, dialog, elem               } = this.state;

        const monthData = currentDay.getMonthData(0, false, undefined, undefined, events);
        const title     = currentDay.toString("monthYear");

        return <Main>
            <Header message={title} icon="calendar" route={route}>
                <ActionList onAction={this.startAction}>
                    <ActionItem action="PREV"  />
                    <ActionItem action="NEXT"  />
                    <ActionItem action="TODAY" />
                    <ActionItem action="GOTO"  />
                </ActionList>
            </Header>

            <Content isLoading={loading}>
                <table className="calendar calendar-month">
                    <thead>
                        <tr>
                            {monthData.names.map((elem, index) => (
                                <th key={index}>{elem}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {monthData.weeks.map((week) => <tr key={week.key}>
                            {week.days.map((day) => <td
                                key={day.key}
                                className={`
                                    ${day.isDay ? "calendar-day" : "calendar-none"}
                                    ${day.isToday ? "calendar-today" : ""}
                                    ${day.isWeekend ? "calendar-weekend" : ""}
                                `}
                            >
                                <span className="calendar-number">{day.name}</span>
                                {day.events.map((elem, index) => <MenuLink
                                    key={index}
                                    className="calendar-event"
                                    variant="light"
                                    html={elem.text}
                                    onClick={() => this.openDialog(elem)}
                                />)}
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </Content>

            <StrechView
                open={dialog === "strech"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ClassView
                open={dialog === "class"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ChatView
                open={dialog === "chat"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <MeetingView
                open={dialog === "meeting"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ConversationView
                open={dialog === "conversation"}
                elem={elem}
                onClose={this.closeDialog}
            />

            <PromptDialog
                open={action.isGoto}
                icon="goto"
                title="CALENDAR_GOTO"
                message="CALENDAR_GOTO_TEXT"
                inputType="date"
                inputLabel="GENERAL_DATE"
                onSubmit={this.handleGoto}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCalendar  : PropTypes.func.isRequired,
        setCalendarDay : PropTypes.func.isRequired,
        loading        : PropTypes.bool.isRequired,
        currentDay     : PropTypes.object.isRequired,
        events         : PropTypes.object.isRequired,
        route          : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading    : state.calendar.loading,
            currentDay : state.calendar.currentDay,
            events     : state.calendar.events,
        };
    }
}

export default connect(CalendarPage.mapStateToProps, {
    fetchCalendar, setCalendarDay,
})(CalendarPage);
