import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCourse }      from "Actions/Admin/Course/CourseActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import HyperLink            from "dashboard/dist/Components/HyperLink";



/**
 * The Course Url Dialog
 */
class CourseUrl extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchCourse } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchCourse(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }

    /**
     * Handles the Secondary Button
     * @returns {Void}
     */
    handleSecondary = () => {
        if (this.props.elem.link) {
            navigator.clipboard.writeText(this.props.elem.link);
        } else {
            this.props.onClose(true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading             } = this.state;

        const hasUrl = Boolean(elem.url);

        return <ViewDialog
            open={open}
            icon="link"
            title="COURSES_VIEW_URL_TITLE"
            secondary={hasUrl ? "GENERAL_COPY_URL" : "COURSES_EDIT_TITLE"}
            onSecondary={this.handleSecondary}
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            {!hasUrl ? <DialogMessage
                variant="h3"
                html={NLS.format("COURSES_NO_URL", elem.name)}
                noSpace
            /> : <h3 className="align-center">
                <HyperLink
                    message={elem.link}
                    url={elem.link}
                    target="_blank"
                />
            </h3>}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCourse : PropTypes.func.isRequired,
        open        : PropTypes.bool.isRequired,
        onClose     : PropTypes.func.isRequired,
        edition     : PropTypes.number.isRequired,
        elem        : PropTypes.object.isRequired,
        elemID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.course.edition,
            elem    : state.course.elem,
        };
    }
}

export default connect(CourseUrl.mapStateToProps, {
    fetchCourse,
})(CourseUrl);
