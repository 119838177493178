import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Href                 from "dashboard/dist/Core/Href";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import StrechCreating       from "Components/Admin/Strech/Strech/StrechCreating";
import PriceList            from "Components/Admin/Strech/Price/PriceList";
import ScheduleList         from "Components/Admin/Strech/Schedule/ScheduleList";
import CoachList            from "Components/Admin/Strech/Coach/CoachList";
import ChatList             from "Components/Admin/Strech/Chat/ChatList";
import ClassList            from "Components/Admin/Strech/Class/ClassList";
import MaterialList         from "Components/Admin/Strech/Material/MaterialList";
import GuideList            from "Components/Admin/Strech/Guide/GuideList";
import SpecialityList       from "Components/Admin/Strech/Speciality/SpecialityList";
import InscriptionList      from "Components/Admin/Strech/Inscription/InscriptionList";
import AssignmentList       from "Components/Admin/Strech/Assignment/AssignmentList";
import GroupList            from "Components/Admin/Strech/Group/GroupList";
import PresentismList       from "Components/Admin/Strech/Presentism/PresentismList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";
import PollPage             from "Components/Admin/Strech/Poll/PollPage";
import InvoiceList          from "Components/Admin/Coach/Invoice/InvoiceList";

// Components
import StrechDetails        from "./StrechDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Strech Page
 */
class StrechPage extends React.Component {
    // The Current State
    state = {
        redirect : false,
        route    : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { params, match, route, fetchStrech } = this.props;
        fetchStrech(params.strechID, true);
        this.setState({ redirect : match.url === route, route });
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { params, edition, elem, fetchStrech } = this.props;
        if (params.strechID !== prevProps.params.strechID) {
            fetchStrech(params.strechID, true);
        }
        if (this.state.redirect && edition !== prevProps.edition && elem.isCreating) {
            this.setState({ redirect : false });
            Href.goto(this.state.route, "CREATING");
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        const hasInvoices = elem.hasEnded && (elem.hasConversations || elem.hasGroups);

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="STRECHES_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem
                    message="STRECHES_CREATING"
                    url="CREATING"
                    icon="strech"
                    isHidden={!elem.isCreating}
                />
                <NavigationItem
                    message="COACHES_NAME"
                    url="COACHES"
                    icon="coach"
                />
                <NavigationItem
                    message="PRICES_NAME"
                    url="PRICES"
                    icon="price"
                    isHidden={elem.isFree}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.PRICES)}
                />
                <NavigationItem
                    message="SCHEDULES_NAME"
                    url="SCHEDULES"
                    icon="schedule"
                    isHidden={!elem.hasClasses}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.SCHEDULES)}
                />
                <NavigationItem
                    message="CHATS_NAME"
                    url="CHATS"
                    icon="chat"
                    isHidden={!elem.hasChats}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.CHATS)}
                />
                <NavigationItem
                    message="CLASSES_NAME"
                    url="CLASSES"
                    icon="class"
                    isHidden={!elem.hasClasses}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.CLASSES)}
                />
                <NavigationItem
                    message="MATERIALS_NAME"
                    url="MATERIALS"
                    icon="material"
                    isHidden={!elem.hasMaterials}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.MATERIALS)}
                />
                <NavigationItem
                    message="GUIDES_NAME"
                    url="GUIDES"
                    icon="guide"
                    isHidden={!elem.hasGuides}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.GUIDES)}
                    usePrefix
                />
                <NavigationItem
                    message="SPECIALITIES_TITLE"
                    url="SPECIALITIES"
                    icon="speciality"
                    isHidden={!elem.hasConversations}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.SPECIALITIES)}
                />

                <NavigationItem
                    message="INSCRIPTIONS_NAME"
                    url="INSCRIPTIONS"
                    icon="inscription"
                    isHidden={elem.isCreating}
                    usePrefix
                />
                <NavigationItem
                    message="INSC_ASSIGNS_NAME"
                    url="ASSIGNMENTS"
                    icon="assignment"
                    isHidden={!elem.hasConversations || elem.isCreating}
                />
                <NavigationItem
                    message="GROUPS_NAME"
                    url="GROUPS"
                    icon="group"
                    isHidden={!elem.hasGroups || elem.isCreating}
                />
                <NavigationItem
                    message="PRESENTISM_NAME"
                    url="PRESENTISM"
                    icon="presentism"
                    isHidden={!elem.hasClasses || elem.isCreating}
                />
                <NavigationItem
                    message="CONVERSATIONS_NAME"
                    url="CONVERSATIONS"
                    icon="conversation"
                    isHidden={!elem.hasConversations || elem.isCreating}
                />
                <NavigationItem
                    message="POLL_NAME"
                    url="POLL"
                    icon="question"
                    isHidden={!elem.hasPoll}
                    isDisabled={StrechStep.isDisabled(elem, StrechStep.QUESTIONS)}
                />
                <NavigationItem
                    message="COACH_INVOICES_NAME"
                    url="INVOICES"
                    icon="invoice"
                    isHidden={!hasInvoices || elem.isCreating}
                />
            </SecondaryNav>

            <SwitchRoute type="STRECH" path={path} baseUrl={url} initialUrl="COACHES" withDetails>
                <AdminRoute url="CREATING"      component={StrechCreating}   />
                <AdminRoute url="COACHES"       component={CoachList}        />
                <AdminRoute url="PRICES"        component={PriceList}        />
                <AdminRoute url="SCHEDULES"     component={ScheduleList}     />
                <AdminRoute url="CHATS"         component={ChatList}         />
                <AdminRoute url="CLASSES"       component={ClassList}        />
                <AdminRoute url="MATERIALS"     component={MaterialList}     />
                <AdminRoute url="GUIDE_STUDENT" component={GuideList}        />
                <AdminRoute url="GUIDES"        component={GuideList}        />
                <AdminRoute url="SPECIALITIES"  component={SpecialityList}   />
                <AdminRoute url="INSCRIPTIONS"  component={InscriptionList}  />
                <AdminRoute url="ASSIGNMENTS"   component={AssignmentList}   />
                <AdminRoute url="GROUPS"        component={GroupList}        />
                <AdminRoute url="PRESENTISM"    component={PresentismList}   />
                <AdminRoute url="CONVERSATIONS" component={ConversationList} />
                <AdminRoute url="POLL"          component={PollPage}         />
                <AdminRoute url="INVOICES"      component={InvoiceList}      />
            </SwitchRoute>

            <StrechDetails strechID={params.strechID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        edition     : PropTypes.number.isRequired,
        elem        : PropTypes.object.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.strech.edition,
            elem    : state.strech.elem,
        };
    }
}

export default connect(StrechPage.mapStateToProps, {
    fetchStrech,
})(StrechPage);
