import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Common               from "Utils/Common";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";
import InscriptionEdit      from "Components/Admin/Strech/Inscription/InscriptionEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";

// Actions
import {
    fetchInscriptions, fetchInscriptionEmails, cancelInscription,
} from "Actions/Admin/Strech/InscriptionActions";



/**
 * The Inscription List
 */
class InscriptionList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.route.includes(NLS.url("REQUESTS"))) {
            this.fetchTab(Status.getID("REQUESTED"));
        } else {
            this.fetch();
        }
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchInscriptions(type, elemID, params || data.sort, withLoader);
    }

    /**
     * Fetch the tab content
     * @param {Number} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        this.fetch({ ...this.props.data.sort, filter, page : 0 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { type, elemID : typeID, data, fetchInscriptionEmails } = this.props;
        if (action.isTab) {
            this.fetchTab(elemID);
        } else if (action.isStudent) {
            const inscription = Utils.getValue(data.list, "inscriptionID", elemID);
            Href.goto(Common.getAccessBaseUrl(inscription), inscription.credentialID);
        } else {
            let users = [];
            if (action.isCampaign) {
                users = await fetchInscriptionEmails(type, typeID, data.sort.filter);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Cancel Submit
     * @param {Number} reasonID
     * @returns {Promise}
     */
    cancelElem = async (reasonID) => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.cancelInscription(elemID, reasonID);
        this.fetch(null, false);
    }



    /**
     * Returns true if it can Edit the Inscription
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEdit(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "inscriptionID", elemID).canEdit;
    }

    /**
     * Returns true if it can Cancel the Inscription
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canCancel(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "inscriptionID", elemID).canCancel;
    }

    /**
     * Returns true if it can Activate the Inscription
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canActivate(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "inscriptionID", elemID).canActivate;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users            } = this.state;
        const { data, params, route, withDetails } = this.props;
        const {
            canEdit, list, total, sort, loading,
            reasons, isFree, hasChats, hasConversations,
        } = data;

        const isCancelled = Status.is("CANCELLED", sort.filter);
        const elemName    = Utils.getValue(list, "inscriptionID", elemID, "credentialName");

        return <Main withDetails={withDetails}>
            <Header message="INSCRIPTIONS_NAME" icon="inscription" route={route}>
                <ActionList data={data} onAction={this.startAction} withCampaign />
            </Header>
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="INSCRIPTIONS_TAB_ACTIVE"     status="ACTIVE"     />
                    <TabItem message="INSCRIPTIONS_TAB_REQUESTED"  status="REQUESTED"  isHidden={isFree} />
                    <TabItem message="INSCRIPTIONS_TAB_CANCELLED"  status="CANCELLED"  />
                    <TabItem message="INSCRIPTIONS_TAB_INTERESTED" status="INTERESTED" isHidden={!hasChats} />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="INSCRIPTIONS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="firstName"     message="STUDENTS_SINGULAR"        grow="1.5" />
                        <TableHeader field="pricePrice"    message="GENERAL_PRICE"            grow="1.5" isHidden={isFree} />
                        <TableHeader field="paymentID"     message="PAYMENT_METHODS_SINGULAR" align="center" isHidden={isFree} />
                        <TableHeader field="tipo"           message="Tipo"                    align="center" isHidden={!isFree} />
                        <TableHeader field="conversations" message="CONVERSATIONS_NAME"       maxWidth="120" align="center" isHidden={!hasConversations} />
                        <TableHeader field="reasonID"      message="GENERAL_REASON"           isHidden={!isCancelled} />
                        <TableHeader field="createdTime"   message="GENERAL_CREATED_FEM"      maxWidth="90"  hideMobile isHidden={isCancelled} />
                        <TableHeader field="cancelledTime" message="INSCRIPTIONS_CANCELLED"   maxWidth="90"  hideMobile isHidden={!isCancelled} />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.inscriptionID} elemID={elem.inscriptionID}>
                            <TableCell message={elem.credentialName}    />
                            <TableCell message={elem.priceText}         />
                            <TableCell message={elem.paymentName}       />
                            <TableCell message={elem.tipo} />
                            <TableCell message={elem.conversationsText} />
                            <TableCell message={elem.reasonName}        />
                            <TableCell message={elem.createdDate}       />
                            <TableCell message={elem.cancelledDate}     />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"     message="INSCRIPTIONS_EDIT_TITLE"     hide={(elemID) => !this.canEdit(elemID)}     />
                        <TableAction action="CANCEL"   message="INSCRIPTIONS_CANCEL_TITLE"   hide={(elemID) => !this.canCancel(elemID)}   />
                        <TableAction action="ACTIVATE" message="INSCRIPTIONS_ACTIVATE_TITLE" hide={(elemID) => !this.canActivate(elemID)} />
                        <TableAction action="STUDENT"  message="STUDENTS_VIEW_TITLE"         />
                    </TableActionList>
                </Table>
            </Content>

            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />
            <InscriptionEdit
                open={Boolean(action.isVCE || action.isActivate)}
                elemID={elemID}
                strechID={params.strechID}
                isActivate={action.isActivate}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <PromptDialog
                open={action.isCancel}
                icon="cancel"
                title="INSCRIPTIONS_CANCEL_TITLE"
                message="INSCRIPTIONS_CANCEL_TEXT"
                content={elemName}
                inputType="select"
                inputLabel="INSC_REASONS_SINGULAR"
                inputOptions={reasons}
                onSubmit={this.cancelElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInscriptions      : PropTypes.func.isRequired,
        fetchInscriptionEmails : PropTypes.func.isRequired,
        cancelInscription      : PropTypes.func.isRequired,
        data                   : PropTypes.object.isRequired,
        type                   : PropTypes.string.isRequired,
        params                 : PropTypes.object.isRequired,
        route                  : PropTypes.string.isRequired,
        withDetails            : PropTypes.bool.isRequired,
        elemID                 : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.inscription,
        };
    }
}

export default connect(InscriptionList.mapStateToProps, {
    fetchInscriptions, fetchInscriptionEmails, cancelInscription,
})(InscriptionList);
