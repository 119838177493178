import React                from "react";
import PropTypes            from "prop-types";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Columns              from "dashboard/dist/Components/Columns";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";

// Styles
import "Styles/Components/App/Setup.css";


/**
 * The Setup Page
 */
class SetupPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;

        return <Main>
            <Header message="SETTINGS_TITLE" icon="settings" route={route} />
            <Content>
                <Columns amount="3" className="setup-content spacing">
                    <DetailList message="SETTINGS_GENERAL" className="setup-details">
                        <DetailItem message="ADMINS_NAME"    url="ADMINS"    icon="admin"    />
                        <DetailItem message="GROUPINTERESTS_NAME" url="GROUPINTERESTS" icon="interest" />
                        <DetailItem message="INTERESTS_NAME" url="INTERESTS" icon="interest" />
                        <DetailItem message="STAFF_NAME"     url="STAFF"     icon="staff"    />
                        <DetailItem message="ADVICES_NAME"   url="ADVICES"   icon="advice"   />
                    </DetailList>

                    <DetailList message="STRECHES_NAME" className="setup-details">
                        <DetailItem message="PAYMENT_METHODS_NAME" url="PAYMENT_METHODS"     icon="billing"  />
                        <DetailItem message="MAT_CATEGORIES_NAME"  url="MATERIAL_CATEGORIES" icon="material" />
                        <DetailItem message="INSC_REASONS_NAME"    url="CANCEL_REASONS"      icon="cancel"   />
                    </DetailList>

                    <DetailList message="COACHES_NAME" className="setup-details">
                        <DetailItem message="SPECIALITIES_NAME"     url="COACHES_SPECIALITIES" icon="speciality" />
                        <DetailItem message="COACH_CATEGORIES_NAME" url="COACHES_CATEGORIES"   icon="coach"      />
                        <DetailItem message="COACH_QUESTIONS_NAME"  url="COACHES_QUESTIONS"    icon="question"   />
                        <DetailItem message="COACH_MATERIALS_NAME"  url="COACHES_MATERIALS"    icon="material"   />
                        <DetailItem message="COACH_PROFESION_NAME"  url="COACHES_PROFESION"    icon="material"   />
                    </DetailList>

                    <DetailList message="TICKETS_NAME" className="setup-details">
                        <DetailItem message="HELP_NAME"          url="TICKETS_HELP"  icon="help" />
                        <DetailItem message="TICKETS_TYPES_NAME" url="TICKETS_TYPES" icon="type" />
                    </DetailList>

                    <DetailList message="SUPPORT_NAME" className="setup-details">
                        <DetailItem message="HELP_NAME"          url="SUPPORT_HELP"  icon="help" />
                        <DetailItem message="SUPPORT_TYPES_NAME" url="SUPPORT_TYPES" icon="type" />
                    </DetailList>

                    <DetailList message="LEADS_NAME" className="setup-details">
                        <DetailItem message="ORIGINS_NAME"       url="LEADS_ORIGINS"     icon="origin"     />
                        <DetailItem message="ASSIGNMENTS_NAME"   url="LEADS_ASSIGNMENTS" icon="assignment" />
                        <DetailItem message="CLOSE_REASONS_NAME" url="LEADS_REASONS"     icon="cancel"     />
                    </DetailList>

                    <DetailList message="SETTINGS_LOCATION" className="setup-details">
                        <DetailItem message="LOCATIONS_NAME"  url="LOCATIONS"  icon="location" />
                        <DetailItem message="COUNTRIES_NAME"  url="COUNTRIES"  icon="country"  />
                        <DetailItem message="PROVINCES_NAME"  url="PROVINCES"  icon="province" />
                        <DetailItem message="LOCALITIES_NAME" url="LOCALITIES" icon="locality" />
                    </DetailList>

                    <DetailList message="SETTINGS_EMAILS" className="setup-details">
                        <DetailItem message="EMAIL_TEMPLATES_NAME"  url="EMAIL_TEMPLATES"  icon="email"      />
                        <DetailItem message="EMAIL_QUEUE_NAME"      url="EMAIL_QUEUE"      icon="queue"      />
                        <DetailItem message="EMAIL_WHITE_LIST_NAME" url="EMAIL_WHITE_LIST" icon="whiteemail" />
                    </DetailList>

                    <DetailList message="SETTINGS_EXTRAS" className="setup-details">
                        <DetailItem message="MEDIA_NAME"       url="MEDIA"   icon="media"   />
                        <DetailItem message="ACTIONS_LOG_NAME" url="ACTIONS" icon="actions" />
                        <DetailItem message="ERRORS_LOG_NAME"  url="ERRORS"  icon="error"   />
                    </DetailList>
                </Columns>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default SetupPage;
