// Types
import {
    CORE_LOADING, CORE_REDIRECT, CORE_SECTION, CORE_RESULT,
    CORE_MENU_OPEN, CORE_MENU_CLOSE,
    CORE_DETAILS_SET, CORE_DETAILS_OPEN, CORE_DETAILS_CLOSE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    redirect    : "/",
    section     : "",
    showMenu    : false,
    hasDetails  : false,
    showDetails : false,
    result      : {
        open    : false,
        variant : "",
        message : "",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case CORE_LOADING:
        return {
            ...state,
            loading     : action.loading,
        };
    case CORE_REDIRECT:
        return {
            ...state,
            redirect    : action.redirect,
        };
    case CORE_SECTION:
        return {
            ...state,
            section     : action.section,
        };
    case CORE_RESULT:
        return {
            ...state,
            result      : action.result,
        };


    // The Menu Actions
    case CORE_MENU_OPEN:
        return {
            ...state,
            showMenu : true,
        };
    case CORE_MENU_CLOSE:
        return {
            ...state,
            showMenu : false,
        };

    // The Details Actions
    case CORE_DETAILS_SET:
        return {
            ...state,
            hasDetails : action.hasDetails,
        };
    case CORE_DETAILS_OPEN:
        return {
            ...state,
            showDetails : true,
        };
    case CORE_DETAILS_CLOSE:
        return {
            ...state,
            showDetails : false,
        };

    default:
        return state;
    }
};

export default reducer;
