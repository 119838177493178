import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Sections
import QuestionList         from "./QuestionList";
import AnswerList           from "./AnswerList";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";



/**
 * The Strech Poll Page
 */
class PollPage extends React.Component {
    // The Current State
    state = {
        filter : "questions",
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.setState({ filter : elemID });
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { questions, answers, route, params, strech, elemID : strechID } = this.props;
        const { action, elemID, filter                                       } = this.state;

        const isQuestions = filter === "questions";
        const isAnswers   = filter === "answers";

        return <Main>
            <Header message="POLL_TITLE" icon="question" route={route}>
                {isQuestions && <ActionList
                    data={questions}
                    createText="QUESTIONS_CREATE_TITLE"
                    onAction={this.startAction}
                />}
                {isAnswers && <ActionList
                    data={answers}
                    onAction={this.startAction}
                />}
            </Header>
            <Content>
                <TabList
                    isHidden={strech.isCreating}
                    selected={filter}
                    onAction={this.startAction}
                >
                    <TabItem message="QUESTIONS_NAME" value="questions" />
                    <TabItem message="ANSWERS_NAME"   value="answers"   />
                </TabList>
                {isQuestions && <QuestionList
                    startAction={this.startAction}
                    endAction={this.endAction}
                    params={params}
                    strechID={strechID}
                    action={action}
                    elemID={elemID}
                />}
                {isAnswers && <AnswerList
                    startAction={this.startAction}
                    endAction={this.endAction}
                    params={params}
                    strechID={strechID}
                    action={action}
                    elemID={elemID}
                />}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        strech    : PropTypes.object.isRequired,
        questions : PropTypes.object.isRequired,
        answers   : PropTypes.object.isRequired,
        params    : PropTypes.object.isRequired,
        route     : PropTypes.string.isRequired,
        elemID    : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            strech    : state.strech.elem,
            questions : state.strechQuestion,
            answers   : state.strechAnswer,
        };
    }
}

export default connect(PollPage.mapStateToProps)(PollPage);
