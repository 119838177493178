import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    STRECH_SPECIALITY_LOADING,
    STRECH_SPECIALITY_LIST, STRECH_SPECIALITY_ELEM, STRECH_SPECIALITY_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : {},
    specialities : [],
    maxAmount    : 0,
    sort         : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_SPECIALITY_LIST, STRECH_SPECIALITY_ELEM, STRECH_SPECIALITY_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_SPECIALITY_LOADING:
        return {
            ...state,
            loading      : true,
        };

    case STRECH_SPECIALITY_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : parseList(action.data.list),
            total        : action.data.total,
            sort         : action.data.sort,
        };

    case STRECH_SPECIALITY_ELEM:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            specialities : action.data.specialities,
        };

    case STRECH_SPECIALITY_CREATE:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            specialities : action.data.specialities,
            maxAmount    : action.data.maxAmount,
        };

    default:
        return state;
    }
};

export default reducer;
