import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Strech }           from "Utils/API";
import NLS                  from "dashboard/dist/Core/NLS";
import Action               from "dashboard/dist/Core/Action";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Html                 from "dashboard/dist/Components/Html";
import Button               from "dashboard/dist/Components/Button";
import Downloader           from "dashboard/dist/Components/Downloader";

// Styles
import "Styles/Components/App/Strech.css";



/**
 * The Strech Certificate Page
 */
class CertificatePage extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts the Download
     * @returns {Void}
     */
    startDownload = () => {
        this.setState({ action : Action.get("DOWNLOAD") });
    }

    /**
     * Ends the Download
     * @returns {Void}
     */
    endDownload = () => {
        this.setState({ action : Action.get() });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { params, route, charging, strech } = this.props;
        const { action                          } = this.state;

        const title = strech.typeName ? NLS.format("CERTIFICATE_TITLE", strech.typeName) : "CERTIFICATE_NAME";

        return <Main>
            <Header message={title} icon="certificate" route={route} />
            <Content isLoading={charging}>
                <Html variant="h3" className="certificate-title">
                    {strech.title ? NLS.format("CERTIFICATE_TEXT", strech.title) : ""}
                </Html>
                <div className="certificate-image">
                    <img
                        src={Strech.getCertificate({ strechID : params.strechID })}
                        alt={strech.title}
                    />
                </div>
                <div>
                    <Button
                        className="certificate-button"
                        message="GENERAL_DOWNLOAD"
                        variant="primary"
                        icon="download"
                        onClick={this.startDownload}
                    />
                </div>
            </Content>

            <Downloader
                download={action.isDownload}
                source={Strech.getCertificate({ strechID : params.strechID, download : 1 })}
                onLoad={this.endDownload}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        charging : PropTypes.bool.isRequired,
        strech   : PropTypes.object.isRequired,
        params   : PropTypes.object.isRequired,
        route    : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            charging : state.strech.charging,
            strech   : state.strech.elem,
        };
    }
}

export default connect(CertificatePage.mapStateToProps)(CertificatePage);
