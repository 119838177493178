import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EmailWhiteListEdit   from "./EmailWhiteListEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchWhiteList, removeEmail,
} from "Actions/Admin/Setup/EmailWhiteListActions";



/**
 * The Email White List
 */
class EmailWhiteList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        this.props.fetchWhiteList(params || this.props.data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.removeEmail(elemID);
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "emailID", elemID, "email");

        return <Main>
            <Header message="EMAIL_WHITE_LIST_NAME" icon="whiteemail" route={route}>
                <ActionList data={data} onAction={this.startAction} useAdd />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="EMAIL_WHITE_LIST_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="email"       message="GENERAL_EMAIL"       isTitle />
                        <TableHeader field="description" message="GENERAL_DESCRIPTION" grow="1.5" />
                        <TableHeader field="createdTime" message="GENERAL_CREATED"     maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.emailID} elemID={elem.emailID}>
                            <TableCell message={elem.email}       />
                            <TableCell message={elem.description} />
                            <TableCell message={elem.createdDate} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="EMAIL_WHITE_LIST_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="EMAIL_WHITE_LIST_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <EmailWhiteListEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="EMAIL_WHITE_LIST_DELETE_TITLE"
                message="EMAIL_WHITE_LIST_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchWhiteList : PropTypes.func.isRequired,
        removeEmail    : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.emailWhiteList,
        };
    }
}

export default connect(EmailWhiteList.mapStateToProps, {
    fetchWhiteList, removeEmail,
})(EmailWhiteList);
