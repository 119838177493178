import { setSection }       from "Actions/Core/CoreActions";
import { StrechAnswer }     from "Utils/API";

// Types
import {
    STRECH_ANSWER, STRECH_ANSWER_LOADING,
    STRECH_ANSWER_STUDENTS, STRECH_ANSWER_ANSWERS, STRECH_ANSWER_QUESTIONS,
} from "Utils/Types";



/**
 * Fetches the Answer Students
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudents(strechID) {
    return async (dispatch) => {
        dispatch({ type : STRECH_ANSWER_LOADING });
        const data = await StrechAnswer.getStudents({ strechID });
        dispatch({ type : STRECH_ANSWER_STUDENTS, data });
        dispatch(setSection(STRECH_ANSWER));
    };
}

/**
 * Fetches the Answer List
 * @param {Number} strechID
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAnswers(strechID, credentialID) {
    return async (dispatch) => {
        const data = await StrechAnswer.getAnswers({ strechID, credentialID });
        dispatch({ type : STRECH_ANSWER_ANSWERS, data });
    };
}

/**
 * Fetches the Question List
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestions(strechID) {
    return async (dispatch) => {
        dispatch({ type : STRECH_ANSWER_LOADING });
        const data = await StrechAnswer.getQuestions({ strechID });
        dispatch({ type : STRECH_ANSWER_QUESTIONS, data });
        dispatch(setSection(STRECH_ANSWER));
    };
}

/**
 * Generates the export url from the given data
 * @param {Number} strechID
 * @returns {String}
 */
export function exportAnswers(strechID) {
    return StrechAnswer.export({ strechID });
}



/**
 * Sends the Answers to the Questions
 * @param {Number} strechID
 * @param {Object} answers
 * @returns {() => Void}
 */
export function answerQuestions(strechID, answers) {
    return () => StrechAnswer.answer({ strechID, answers });
}
