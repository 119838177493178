import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import ClassEdit            from "./ClassEdit";
import ClassDate            from "./ClassDate";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";

// Actions
import {
    fetchClasses, editClassFeedback, deleteClass, importClasses,
} from "Actions/Admin/Strech/ClassActions";



/**
 * The Strech Class List
 */
class ClassList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchClasses(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
        this.props.fetchStrech(this.props.elemID);
    }

    /**
     * Handles the Feedback Edit Submit
     * @param {String} feedback
     * @returns {Promise}
     */
    editFeedback = async (feedback) => {
        await this.props.editClassFeedback(this.state.elemID, feedback);
        this.endAction();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteClass(this.state.elemID);
        this.editElem();
    }

    /**
     * Handles the Import Submit
     * @param {String} date
     * @returns {Promise}
     */
    importElem = async (date) => {
        const strechID = this.props.params.strechID;
        await this.props.importClasses(strechID, date);
        this.editElem();
    }

    /**
     * Returns true if it can't view the Class
     * @param {Number} elemID
     * @returns {Boolean}
     */
    cantView = (elemID) => {
        const { data, isStudent } = this.props;
        if (isStudent) {
            const hasStarted = Utils.getValue(data.list, "strechClassID", elemID, "hasStarted");
            return !hasStarted;
        }
        return false;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                                                       } = this.state;
        const { data, strech, params, route, withDetails                             } = this.props;
        const { canEdit, canEditDate, canFeedback, list, total, sort, dates, loading } = data;

        const elemName     = Utils.getValue(list, "strechClassID", elemID, "name");
        const elemFeedback = Utils.getValue(list, "strechClassID", elemID, "feedback");

        return <Main withDetails={withDetails}>
            <Header message="CLASSES_NAME" icon="class" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <StrechBar step={StrechStep.CLASSES} />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CLASSES_NONE_AVAILABLE"
                    hasAlert={StrechStep.hasHelp(strech, StrechStep.CLASSES)}
                >
                    <TableHead>
                        <TableHeader field="name"       message="GENERAL_NAME"       grow="2" hideCircle={!canEdit} bigMobile />
                        <TableHeader field="firstName"  message="COACHES_SINGULAR"   bigMobile />
                        <TableHeader field="time"       message="GENERAL_DAY"        maxWidth="100" />
                        <TableHeader field="scheduleID" message="SCHEDULES_SINGULAR" maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechClassID} elemID={elem.strechClassID}>
                            <TableCell message={elem.name}           circle={elem.statusColor} />
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.date}           />
                            <TableCell message={elem.scheduleTime}   />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"     message="CLASSES_VIEW_TITLE"     route={route} hide={this.cantView} />
                        <TableAction action="EDIT"     message="CLASSES_EDIT_TITLE"     />
                        <TableAction action="DATE"     message="CLASSES_DATE_TITLE"     isHidden={!canEditDate} />
                        <TableAction action="FEEDBACK" message="CLASSES_FEEDBACK_TITLE" isHidden={!canFeedback} />
                        <TableAction action="DELETE"   message="CLASSES_DELETE_TITLE"   />
                    </TableActionList>
                </Table>
            </Content>

            <ClassEdit
                open={action.isVCE}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ClassDate
                open={action.isDate}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <PromptDialog
                open={action.isFeedback}
                icon="edit"
                title="CLASSES_FEEDBACK_TITLE"
                message="CLASSES_FEEDBACK_TEXT"
                inputType="textarea"
                initialValue={elemFeedback}
                onSubmit={this.editFeedback}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="CLASSES_DELETE_TITLE"
                message="CLASSES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <PromptDialog
                open={action.isImport}
                icon="import"
                title="CLASSES_IMPORT_TITLE"
                message="CLASSES_IMPORT_TEXT"
                inputType="select"
                inputOptions={dates}
                onSubmit={this.importElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech       : PropTypes.func.isRequired,
        fetchClasses      : PropTypes.func.isRequired,
        editClassFeedback : PropTypes.func.isRequired,
        deleteClass       : PropTypes.func.isRequired,
        data              : PropTypes.object.isRequired,
        strech            : PropTypes.object.isRequired,
        type              : PropTypes.string.isRequired,
        params            : PropTypes.object.isRequired,
        route             : PropTypes.string.isRequired,
        withDetails       : PropTypes.bool.isRequired,
        isStudent         : PropTypes.bool.isRequired,
        elemID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data      : state.strechClass,
            strech    : state.strech.elem,
            isStudent : state.auth.isStudent,
        };
    }
}

export default connect(ClassList.mapStateToProps, {
    fetchStrech, fetchClasses, editClassFeedback, deleteClass, importClasses,
})(ClassList);
