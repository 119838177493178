import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import StrechStep           from "Utils/Entity/StrechStep";

// Components
import Alert                from "dashboard/dist/Components/Alert";
import Icon                 from "dashboard/dist/Components/Icon";



/**
 * The Strech Info Bar
 */
class StrechBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, step } = this.props;

        const help        = StrechStep.getHelp(elem, step);
        const isCompleted = StrechStep.isCompleted(elem, step);

        return <Alert
            isHidden={!help}
            variant={isCompleted ? "success" : "error"}
            smallSpace
            noClose
        >
            <Icon icon={isCompleted ? "check" : "close"} />
            {help}
        </Alert>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        elem : PropTypes.object.isRequired,
        step : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.strech.elem,
        };
    }
}

export default connect(StrechBar.mapStateToProps)(StrechBar);
