import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchPrice, editPrice,
} from "Actions/Admin/Program/PriceActions";



/**
 * The Program Price Edit Dialog
 */
class PriceEdit extends React.Component {
    // The Initial Data
    initialData = {
        priceID      : 0,
        programID    : 0,
        dues         : "",
        price        : "",
        showPrice    : 1,
        isPromotion  : 0,
        status       : Status.getID("ACTIVE"),
        observations : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, programID, fetchPrice } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchPrice(elemID);
                loading = true;
            // Set edit data
            } else if (programID) {
                data.programID = programID;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editPrice(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        const showPromotion = Boolean(data.showPrice && Number(data.dues) === 1);

        return <EditDialog
            open={open}
            icon="price"
            title={!!elemID ? "PRICES_EDIT_TITLE" : "PRICES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="number"
                name="dues"
                label="PRICES_DUES"
                value={data.dues}
                error={errors.dues}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="number"
                name="price"
                icon="money"
                label="PRICES_PRICE"
                value={data.price}
                error={errors.price}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="showPrice"
                label="PRICES_SHOW"
                value={data.showPrice}
                onChange={this.handleChange}
            />
            <InputField
                isHidden={!showPromotion}
                type="toggle"
                name="isPromotion"
                label="PRICES_IS_PROMOTION"
                value={data.isPromotion}
                error={errors.isPromotion}
                onChange={this.handleChange}
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPrice : PropTypes.func.isRequired,
        editPrice  : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        onSubmit   : PropTypes.func.isRequired,
        edition    : PropTypes.number.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
        programID  : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.programPrice.edition,
            elem    : state.programPrice.elem,
        };
    }
}

export default connect(PriceEdit.mapStateToProps, {
    fetchPrice, editPrice,
})(PriceEdit);
