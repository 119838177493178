import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechMaterial }   from "Utils/API";

// Types
import {
    STRECH_MATERIAL, STRECH_MATERIAL_LOADING,
    STRECH_MATERIAL_LIST, STRECH_MATERIAL_ELEM,
    STRECH_MATERIAL_EDIT, STRECH_MATERIAL_CREATE,
} from "Utils/Types";



/**
 * Fetches the Material List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterials(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_MATERIAL_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechMaterial.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_MATERIAL_LIST, data });
        dispatch(setSection(STRECH_MATERIAL));
    };
}

/**
 * Fetches a single Material
 * @param {Number} strechMaterialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterial(strechMaterialID) {
    return async (dispatch) => {
        const data = await StrechMaterial.getOne({ strechMaterialID });
        dispatch({ type : STRECH_MATERIAL_ELEM, data });
    };
}

/**
 * Fetches the Material Edit data
 * @param {Number} strechMaterialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterialEdit(strechMaterialID) {
    return async (dispatch) => {
        const data = await StrechMaterial.getEditData({ strechMaterialID });
        dispatch({ type : STRECH_MATERIAL_EDIT, data });
    };
}

/**
 * Fetches the Material Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterialCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechMaterial.getCreateData({ strechID });
        dispatch({ type : STRECH_MATERIAL_CREATE, data });
    };
}



/**
 * Edits/Creates a Material
 * @param {Object} data
 * @returns {() => Void}
 */
export function editMaterial(data) {
    return () => StrechMaterial.edit(data);
}

/**
 * Deletes a Material
 * @param {Number} strechMaterialID
 * @returns {() => Void}
 */
export function deleteMaterial(strechMaterialID) {
    return () => StrechMaterial.delete({ strechMaterialID });
}

/**
 * Imports Materials
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function importMaterials(strechID) {
    return () => StrechMaterial.import({ strechID });
}
