import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    DASHBOARD_LOADING,
    DASHBOARD_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,

    advice       : null,
    students     : [],
    inscriptions : [],
    tickets      : [],
    leads        : [],
};



/**
 * Parses the Students
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseStudents(list) {
    const result = [];
    Utils.parseList(list, (elem) => {
        result.push({
            type : "student",
            key  : elem.credentialID,
            id   : elem.credentialID,
            date : DateTime.formatShort(elem.createdTime),
            text : elem.credentialName,
        });
    });
    return result;
}

/**
 * Parses the Inscriptions
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseInscriptions(list) {
    const result = [];
    Utils.parseList(list, (elem) => {
        result.push({
            type : "inscription",
            key  : elem.inscriptionID,
            id   : elem.strechID,
            date : DateTime.formatShort(elem.createdTime),
            text : NLS.format("DASHBOARD_INSCRIPTION", elem.credentialName, elem.strechName),
        });
    });
    return result;
}

/**
 * Parses the Tickets
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseTickets(list) {
    const result = [];
    Utils.parseList(list, (elem) => {
        result.push({
            type : "ticket",
            key  : elem.ticketID,
            id   : elem.ticketID,
            date : DateTime.formatShort(elem.createdTime),
            text : NLS.format("DASHBOARD_TICKET", elem.ticketTypeName, elem.userName),
        });
    });
    return result;
}

/**
 * Parses the Leads
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseLeads(list) {
    const result = [];
    Utils.parseList(list, (elem) => {
        result.push({
            type : "lead",
            key  : elem.leadID,
            id   : elem.leadID,
            date : DateTime.formatShort(elem.createdTime),
            text : NLS.format("DASHBOARD_LEAD", elem.credentialName, elem.originName),
        });
    });
    return result;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, DASHBOARD_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case DASHBOARD_LOADING:
        return {
            ...state,
            loading : true,
        };

    case DASHBOARD_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            advice       : action.data.advice,
            students     : parseStudents(action.data.students),
            inscriptions : parseInscriptions(action.data.inscriptions),
            tickets      : parseTickets(action.data.tickets),
            leads        : parseLeads(action.data.leads),
        };

    default:
        return state;
    }
};

export default reducer;
