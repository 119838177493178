import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Components
import PresentismEdit       from "./PresentismEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchPresentisms,
} from "Actions/Admin/Strech/PresentismActions";



/**
 * The Presentism List
 */
class PresentismList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchPresentisms(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, params, route, withDetails    } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        return <Main withDetails={withDetails}>
            <Header message="PRESENTISM_NAME" icon="presentism" route={route} />
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="CLASSES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="name"      message="CLASSES_SINGULAR" grow="1.5" />
                        <TableHeader field="time"      message="GENERAL_DAY"      maxWidth="90" />
                        <TableHeader field="firstName" message="COACHES_SINGULAR" />
                        <TableHeader field="amount"    message="GENERAL_AMOUNT"   maxWidth="80" noSorting />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechClassID} elemID={elem.strechClassID}>
                            <TableCell message={elem.name}           />
                            <TableCell message={elem.date}           />
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.amount}         />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT" message="PRESENTISM_EDIT_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <PresentismEdit
                open={action.isVCE}
                strechID={params.strechID}
                strechClassID={elemID}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPresentisms : PropTypes.func.isRequired,
        data             : PropTypes.object.isRequired,
        type             : PropTypes.string.isRequired,
        params           : PropTypes.object.isRequired,
        route            : PropTypes.string.isRequired,
        withDetails      : PropTypes.bool.isRequired,
        elemID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.presentism,
        };
    }
}

export default connect(PresentismList.mapStateToProps, {
    fetchPresentisms,
})(PresentismList);
