import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import GroupEdit            from "./GroupEdit";
import GroupAssign          from "./GroupAssign";
import MeetingEdit          from "../Meeting/MeetingEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchGroups, deleteGroup,
} from "Actions/Admin/Strech/GroupActions";



/**
 * The Group List
 */
class GroupList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchGroups(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isCoach) {
            const credentialID = Utils.getValue(this.props.data.list, "groupID", elemID, "credentialID");
            Href.goto("COACHES", credentialID, "GROUPS");
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteGroup(elemID);
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                         } = this.state;
        const { data, type, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading    } = data;

        const elem       = Utils.getValue(list, "groupID", elemID);
        const showCoach  = type !== "COACH" && type !== "USER";
        const showStrech = type !== "STRECH";
        const canAssign  = canEdit && showStrech;

        return <Main withDetails={withDetails}>
            <Header message="GROUPS_NAME" icon="group" route={route}>
                <ActionList data={data} onAction={this.startAction}>
                    {canAssign && <ActionItem action="ASSIGN" icon="add" />}
                </ActionList>
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="GROUPS_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="name"                message="GENERAL_NAME"      hideCircle={!canEdit} bigMobile />
                        <TableHeader field="credentialFirstName" message="COACHES_SINGULAR"  isHidden={!showCoach}  />
                        <TableHeader field="strechName"          message="STRECHES_SINGULAR" isHidden={!showStrech} />
                        <TableHeader field="totalMembers"        message="STUDENTS_NAME"     maxWidth="80" align="center" hideMobile />
                        <TableHeader field="totalMeetings"       message="MEETINGS_SHORT"    maxWidth="90" align="center" hideMobile />
                        <TableHeader field="createdTime"         message="GENERAL_CREATED"   maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.groupID} elemID={elem.groupID}>
                            <TableCell message={elem.name}           circle={elem.statusColor} />
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.strechName}     />
                            <TableCell message={elem.totalMembers}   />
                            <TableCell message={elem.meetingsText}   />
                            <TableCell message={elem.createdDate}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"    message="GROUPS_VIEW_TITLE"      route={route} />
                        <TableAction action="EDIT"    message="GROUPS_EDIT_TITLE"      />
                        <TableAction action="DELETE"  message="GROUPS_DELETE_TITLE"    />
                        <TableAction action="PROGRAM" message="MEETINGS_PROGRAM_TITLE" />
                        <TableAction action="COACH"   message="COACHES_VIEW_TITLE"     isHidden={!showCoach} />
                    </TableActionList>
                </Table>
            </Content>

            <GroupEdit
                open={action.isVCE}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <GroupAssign
                open={action.isAssign}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="GROUPS_DELETE_TITLE"
                message="GROUPS_DELETE_TEXT"
                content={elem.name}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <MeetingEdit
                open={action.isProgram}
                groupID={elem.groupID}
                coachID={elem.credentialID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroups : PropTypes.func.isRequired,
        deleteGroup : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        type        : PropTypes.string.isRequired,
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
        elemID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.strechGroup,
        };
    }
}

export default connect(GroupList.mapStateToProps, {
    fetchGroups, deleteGroup,
})(GroupList);
