import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";

// Sections
import WelcomePage          from "Components/Student/Dashboard/WelcomePage";
import HomePage             from "Components/Student/Dashboard/HomePage";
import CalendarPage         from "Components/Student/Dashboard/CalendarPage";
import EnrollPage           from "Components/Student/Enroll/EnrollPage";
import StrechList           from "Components/Student/Strech/StrechList";
import ConversationList     from "Components/Student/Conversation/ConversationList";
import HelpPage             from "Components/Admin/Ticket/Help/HelpPage";
import TicketList           from "Components/Admin/Ticket/Ticket/TicketList";

// Components
import PrimaryNav           from "Components/Utils/Navigation/PrimaryNav";
import StudentRoute         from "Components/Utils/Route/StudentRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Student Container
 */
class StudentContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route, credential } = this.props;

        const isPotential = Status.is("POTENTIAL", credential.status);

        return <>
            <PrimaryNav path={route}>
                <NavigationItem message="WELCOME_NAME"       url="WELCOME"       icon="home"         isHidden={!isPotential} />
                <NavigationItem message="HOME_NAME"          url="HOME"          icon="home"         isHidden={isPotential}  />
                <NavigationItem message="ENROLL_NAME"        url="ENROLL"        icon="inscription"  />
                <NavigationItem message="CALENDAR_NAME"      url="CALENDAR"      icon="calendar"     isHidden={isPotential}  />
                <NavigationItem message="COURSES_MINE"       url="COURSES_MINE"  icon="strech"       isHidden={isPotential}  />
                <NavigationItem message="CONVERSATIONS_MINE" url="CONVERSATIONS" icon="conversation" isHidden={isPotential}  />
                <NavigationItem message="HELP_NAME"          url="HELP"          icon="help"         />
                <NavigationItem message="TICKETS_NAME"       url="TICKETS"       icon="ticket"       usePrefix />
            </PrimaryNav>

            <SwitchRoute type="USER">
                <StudentRoute url="WELCOME"       component={WelcomePage}      exact isHidden={!isPotential} />
                <StudentRoute url="HOME"          component={HomePage}         exact isHidden={isPotential}  />
                <StudentRoute url="ENROLL"        component={EnrollPage}       exact />
                <StudentRoute url="CALENDAR"      component={CalendarPage}     exact isHidden={isPotential}  />
                <StudentRoute url="COURSES_MINE"  component={StrechList}       exact isHidden={isPotential}  />
                <StudentRoute url="CONVERSATIONS" component={ConversationList} exact isHidden={isPotential}  />
                <StudentRoute url="HELP"          component={HelpPage}         exact />
                <StudentRoute url="TICKETS"       component={TicketList}       />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        credential : PropTypes.object.isRequired,
        route      : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            credential : state.auth.credential,
        };
    }
}

export default connect(StudentContainer.mapStateToProps)(StudentContainer);
