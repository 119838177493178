import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Admin }            from "Utils/API";

// Types
import {
    ADMIN, ADMIN_LOADING,
    ADMIN_LIST, ADMIN_ELEM, ADMIN_CREATE,
} from "Utils/Types";



/**
 * Fetches the Admin List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAdmins(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : ADMIN_LOADING });
        }
        Params.unset(params);
        const data = await Admin.getAll(params);
        data.sort = params;
        dispatch({ type : ADMIN_LIST, data });
        dispatch(setSection(ADMIN));
    };
}

/**
 * Fetches a single Admin
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAdmin(credentialID) {
    return async (dispatch) => {
        const data = await Admin.getOne({ credentialID });
        dispatch({ type : ADMIN_ELEM, data });
    };
}

/**
 * Fetches the Admin Create data
 * @returns {(dispatch) => Void}
 */
export function fetchAdminCreate() {
    return async (dispatch) => {
        const data = await Admin.getCreateData();
        dispatch({ type : ADMIN_CREATE, data });
    };
}



/**
 * Edits/Creates an Admin
 * @param {Object} data
 * @returns {() => Void}
 */
export function editAdmin(data) {
    return () => Admin.edit(data);
}

/**
 * Edits an Admin Level
 * @param {Number} credentialID
 * @param {Number} level
 * @returns {() => Void}
 */
export function editAdminLevel(credentialID, level) {
    return () => Admin.editLevel({ credentialID, level });
}

/**
 * Deletes an Admin
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function deleteAdmin(credentialID) {
    return () => Admin.delete({ credentialID });
}
