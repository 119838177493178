import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { saveCourses }      from "Actions/Core/AuthActions";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Auth Course Step
 */
class CourseStep extends React.Component {
    // The Initial Data
    initialData = {
        credentialID : 0,
        courses      : {},
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }

    /**
     * Set the User data
     * @returns {Void}
     */
    componentDidMount() {
        const { credentialID, courses, completed } = this.props;
        const newCourses = {};
        for (const elem of courses) {
            newCourses[elem.key] = 0;
        }
        for (const elem of completed) {
            if (newCourses[elem.courseID] === 0) {
                newCourses[elem.courseID] = 1;
            }
        }
        this.setState({
            data    : { credentialID, courses : newCourses },
            loading : false,
            errors  : {}
        });
    }

    /**
     * Handles the Input Change
     * @param {Number} courseID
     * @returns {Void}
     */
     handleToggle = (courseID) => {
        const courses = this.state.data.courses;
        courses[courseID] = courses[courseID] ? 0 : 1;
        this.setState({ data : { ...this.state.data, courses }, errors : {} });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { saveCourses, onSubmit } = this.props;
        const { data, loading         } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const completed = [];
                for (const [ courseID, valid ] of Object.entries(data.courses)) {
                    if (valid) {
                        completed.push(courseID);
                    }
                }
                const result = await saveCourses({
                    credentialID : data.credentialID,
                    completed    : JSON.stringify(completed),
                });
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, courses        } = this.props;
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title={title}
            help="AUTH_COURSE_HELP"
            error={errors.form}
            isLoading={loading}
        >
            <ul className="spaced-list no-list">
                {courses.map((elem) => <li key={elem.key}>
                    <InputField
                        className="auth-check"
                        type="toggle"
                        name={`course-${elem.key}`}
                        label={elem.value}
                        value={!!data.courses[elem.key]}
                        onChange={() => this.handleToggle(elem.key)}
                    />
                </li>)}
            </ul>
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        saveCourses  : PropTypes.func.isRequired,
        credentialID : PropTypes.number.isRequired,
        courses      : PropTypes.array.isRequired,
        completed    : PropTypes.array.isRequired,
        onSubmit     : PropTypes.func.isRequired,
        title        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            credentialID : state.auth.credentialID,
            courses      : state.auth.courses,
            completed    : state.auth.completed,
        };
    }
}

export default connect(CourseStep.mapStateToProps, {
    saveCourses,
})(CourseStep);
