// The App Params
const params = {
    "COURSE"   : "courseID",
    "PROGRAM"  : "programID",
    "STRECH"   : "strechID",
    "CLASS"    : "classID",
    "GUIDE"    : "guideID",
    "GROUP"    : "groupID",
    "LOCATION" : "locationID",
    "STUDENT"  : "studentID",
    "COACH"    : "coachID",
    "PROFILE"  : "profileID",
    "SETUP"    : "setupID",
    "USER"     : "userID",
    "ADMIN"    : "adminID",
    "INVOICE"  : "invoiceID",
    "CAMPAIGN" : "campaignID",
    "TICKET"   : "ticketID",
    "LEAD"     : "leadID",
};

export default params;
