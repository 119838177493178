import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchUsers }       from "Actions/Admin/Campaign/UserActions";
import Common               from "Utils/Common";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import StatList             from "dashboard/dist/Components/StatList";
import StatItem             from "dashboard/dist/Components/StatItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";



/**
 * The Campaign User List
 */
class UserList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchUsers(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isUser) {
            const user = Utils.getValue(this.props.data.list, "credentialID", elemID);
            Href.goto(Common.getAccessBaseUrl(user), user.credentialID);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, route                                } = this.props;
        const { canEdit, list, total, sort, stats, loading } = data;

        return <Main>
            <Header message="USERS_NAME" icon="user" route={route} />
            <Content isLoading={loading}>
                <StatList isHidden={!stats.hasReport}>
                    <StatItem message="CAMPAIGNS_STATS_SENT"   value={stats.emailsSent}   />
                    <StatItem message="CAMPAIGNS_STATS_OPENS"  value={stats.opensUnique}  percent={stats.opensRate} />
                    <StatItem message="CAMPAIGNS_STATS_CLICKS" value={stats.clicksUnique} percent={stats.clicksRate} />
                </StatList>
                <Table fetch={this.fetch} sort={sort} none="USERS_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="firstName"    message="GENERAL_NAME"         />
                        <TableHeader field="level"        message="GENERAL_LEVEL"        maxWidth="100" />
                        <TableHeader field="email"        message="GENERAL_EMAIL"        />
                        <TableHeader field="subscription" message="GENERAL_SUBSCRIPTION" maxWidth="110" />
                        <TableHeader field="sent"         message="GENERAL_SENT"         maxWidth="60" align="center" noSorting isHidden={!stats.hasReport} />
                        <TableHeader field="open"         message="CAMPAIGNS_OPEN"       maxWidth="50" align="center" noSorting isHidden={!stats.hasReport} />
                        <TableHeader field="click"        message="CAMPAIGNS_CLICK"      maxWidth="50" align="center" noSorting isHidden={!stats.hasReport} />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                            <TableCell message={elem.credentialName}   />
                            <TableCell message={elem.levelName}        />
                            <TableCell message={elem.email}            />
                            <TableCell message={elem.subscriptionName} className={elem.subscriptionClass} />
                            <TableCell message={elem.sentString}       />
                            <TableCell message={elem.openString}       />
                            <TableCell message={elem.clickString}      />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="USER" message="USERS_VIEW_TITLE" />
                    </TableActionList>
                </Table>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchUsers : PropTypes.func.isRequired,
        data       : PropTypes.object.isRequired,
        route      : PropTypes.string.isRequired,
        type       : PropTypes.string.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.campaignUser,
        };
    }
}

export default connect(UserList.mapStateToProps, {
    fetchUsers,
})(UserList);
