import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import QuestionEdit         from "./QuestionEdit";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchQuestions, deleteQuestion,
} from "Actions/Admin/Ticket/QuestionActions";



/**
 * The Help Question List
 */
class QuestionList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { data, forSupport, fetchQuestions } = this.props;
        fetchQuestions(params || data.sort, forSupport);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { elemID, endAction, deleteQuestion } = this.props;
        endAction();
        await deleteQuestion(elemID);
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, action, elemID, startAction, endAction, forSupport } = this.props;
        const { canEdit, list, total, sort, loading                      } = data;

        const elemName = Utils.getValue(list, "questionID", elemID, "question");

        return <>
            <Table
                fetch={this.fetch}
                sort={sort}
                none="QUESTIONS_NONE_AVAILABLE"
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="question"    message="QUESTIONS_SINGULAR"  bigMobile />
                    <TableHeader field="sectionName" message="SECTIONS_SINGULAR"   />
                    <TableHeader field="accessType"  message="GENERAL_ACCESS"      maxWidth="80" isHidden={forSupport} />
                    <TableHeader field="position"    message="GENERAL_POSITION"    maxWidth="80" align="center" hideMobile />
                    <TableHeader field="createdTime" message="GENERAL_CREATED_FEM" maxWidth="90" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.questionID} elemID={elem.questionID}>
                        <TableCell message={elem.question}       circle={elem.statusColor} />
                        <TableCell message={elem.sectionName}    />
                        <TableCell message={elem.accessTypeName} />
                        <TableCell message={elem.position}       />
                        <TableCell message={elem.createdDate}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="QUESTIONS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="QUESTIONS_DELETE_TITLE" />
                </TableActionList>
            </Table>

            <QuestionEdit
                open={action.isVCE}
                forSupport={forSupport}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="QUESTIONS_DELETE_TITLE"
                message="QUESTIONS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuestions : PropTypes.func.isRequired,
        deleteQuestion : PropTypes.func.isRequired,
        startAction    : PropTypes.func.isRequired,
        endAction      : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        action         : PropTypes.object,
        elemID         : PropTypes.number,
        forSupport     : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        forSupport : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.helpQuestion,
        };
    }
}

export default connect(QuestionList.mapStateToProps, {
    fetchQuestions, deleteQuestion,
})(QuestionList);
