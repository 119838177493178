import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";

// Actions
import {
    validatePassword, requestReset,
} from "Actions/Core/AuthActions";



/**
 * The Auth Password Step
 */
class PasswordStep extends React.Component {
    // The Current State
    state = {
        data    : { password : "" },
        loading : false,
        errors  : {},
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { email, validatePassword, onSubmit } = this.props;
        const { data, loading                     } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await validatePassword({ ...data, email });
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Link
     * @returns {Promise}
     */
    handleLink = async () => {
        const { sendData, email, requestReset, onSubmit } = this.props;

        if (!this.state.loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await requestReset({ email, ...sendData });
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Email
     * @returns {Void}
     */
    handleEmail = () => {
        const { email, onSubmit } = this.props;
        onSubmit({ step : "email", email });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, email          } = this.props;
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title={title}
            email={email}
            help="AUTH_PASSWORD_HELP"
            error={errors.form}
            link="AUTH_PASSWORD_RECOVER"
            onClick={this.handleLink}
            onEmail={this.handleEmail}
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="password"
                name="password"
                icon="password"
                label="USERS_PASSWORD"
                autoComplete="password"
                value={data.password}
                error={errors.password}
                onChange={this.handleChange}
                autoFocus
                isRequired
                smallMargin
            />
            <input
                className="auth-hidden"
                type="email"
                name="hiddenEmail"
                autoComplete="username"
                tabIndex={-1}
                aria-hidden="true"
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        validatePassword : PropTypes.func.isRequired,
        requestReset     : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        title            : PropTypes.string.isRequired,
        email            : PropTypes.string.isRequired,
        sendData         : PropTypes.object,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        sendData : {},
    }
}

export default connect(null, {
    validatePassword, requestReset,
})(PasswordStep);
