import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCourse }      from "Actions/Admin/Course/CourseActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import CourseEdit           from "./CourseEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Course Details
 */
class CourseDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchCourse(this.props.courseID, true);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, courseID } = this.props;
        const { action                                    } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="COURSES_ERROR_EXISTS"
            >
                <DetailList message="COURSES_DETAILS_TITLE">
                    <DetailItem
                        icon="name"
                        message={elem.name}
                        tooltip="COURSES_ITS_NAME"
                    />
                    <DetailItem
                        icon="link"
                        message={elem.link}
                        url={elem.link}
                        tooltip="COURSES_ITS_URL"
                    />
                    <DetailItem
                        icon="course"
                        message={elem.correlativeCourse}
                        tooltip="COURSES_CORRELATIVE"
                        prefix="COURSES_CORRELATIVE"
                    />
                    <DetailItem
                        icon="status"
                        message={elem.statusName}
                        tooltip="GENERAL_STATUS"
                        className={elem.statusClass}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.createdDateTime}
                        tooltip="GENERAL_CREATED"
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT" message="COURSES_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <CourseEdit
                open={action.isEdit}
                elemID={courseID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCourse : PropTypes.func.isRequired,
        charging    : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        canEdit     : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        courseID    : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.course.charging,
            error    : state.course.error,
            canEdit  : state.course.canEdit,
            elem     : state.course.elem,
        };
    }
}

export default connect(CourseDetails.mapStateToProps, {
    fetchCourse,
})(CourseDetails);
