import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";

// Dialogs
import MeetingEdit          from "./MeetingEdit";
import MeetingReport        from "./MeetingReport";
import MeetingCancel        from "./MeetingCancel";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import DialogAction         from "dashboard/dist/Components/DialogAction";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import ListContent          from "dashboard/dist/Components/ListContent";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";

// Actions
import {
    fetchMeeting,
} from "Actions/Admin/Strech/MeetingActions";



/**
 * The Meeting View Dialog
 */
class MeetingView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        loading : false,
        update  : false,
        filter  : "",
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchMeeting } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchMeeting(elemID);
            this.setState({ loading : true, update : false, filter : "" });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false, update : false, filter : "" });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @param {String=}  filter
     * @returns {Promise}
     */
    endAction = async (update, filter) => {
        if (update !== undefined) {
            await this.props.fetchMeeting(this.props.elemID);
            this.setState({ action : Action.get(), filter, update });
        } else {
            this.setState({ action : Action.get() });
        }
    }

    /**
     * Edits the Meeting
     * @param {String} filter
     * @returns {Void}
     */
    editElem = (filter) => {
        this.endAction(true, filter);
    }

    /**
     * Handles de Dialog Closing
     * @returns {Void}
     */
    handleClose = () => {
        const { update, filter } = this.state;
        this.props.onClose(update, filter);
    }



    /**
     * Does the Item Render
     * @returns {Object[]}
     */
    renderItems() {
        const { elem, questions, members } = this.props;

        const presentism = elem.reportPresentism;
        const result     = [];
        let   index      = 1;

        result.push(<AccordionItem
            key={index}
            number={index}
            icon="checkedbox"
            message="MEETINGS_PRESENTISM"
        >
            <List>
                {members.map((elem) => <ListItem key={elem.credentialID}>
                    <ListContent>
                        {elem.credentialName}
                        {!elem.isActive && <span className="text-red left-space">
                            {NLS.get("GENERAL_INACTIVE")}
                        </span>}
                    </ListContent>
                    <ListContent>
                        {presentism[elem.credentialID] ?
                            <b>{NLS.get("PRESENTISM_ASSISTED")}</b> :
                            NLS.get("PRESENTISM_DIDNT_ASSISTED")
                        }
                    </ListContent>
                </ListItem>)}
            </List>
        </AccordionItem>);
        index += 1;

        for (const elem of questions) {
            result.push(<AccordionItem
                key={index}
                number={index}
                icon="checkedbox"
                message={elem.question}
            >
                <ViewField label="ANSWERS_SINGULAR" value={elem.answer} />
            </AccordionItem>);
            index += 1;
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, groupID, coachID, locationID } = this.props;
        const { action, loading                          } = this.state;

        const showStrech   = Boolean(!groupID);
        const showCoach    = Boolean(!coachID);
        const showLocation = Boolean(elem.isPresential && !locationID);

        return <>
            <Dialog open={open} onClose={this.handleClose} isLoading={loading}>
                <DialogHeader message="MEETINGS_VIEW_TITLE" icon="meeting" />
                <DialogBody withSpacing>
                    <ViewField
                        isHidden={!showStrech}
                        label="MEETINGS_STRECH"
                        value={elem.strechName}
                    />
                    <ViewField
                        isHidden={!showStrech}
                        label="MEETINGS_GROUP"
                        value={elem.groupName}
                    />
                    <Columns amount={showCoach ? 2 : 1}>
                        <ViewField
                            isHidden={!showCoach}
                            label="COACHES_SINGULAR"
                            value={elem.coachString}
                        />
                        <ViewField
                            label="GENERAL_MODE"
                            value={elem.modeName}
                        />
                    </Columns>

                    {showLocation && <ViewField
                        className="columns-double"
                        label="LOCATIONS_SINGULAR"
                        value={elem.locationString}
                    />}
                    {elem.hasStream && <>
                        <ViewField
                            label="GENERAL_STREAM_LINK"
                            value={elem.streamLink}
                        />
                        <ViewField
                            label="GENERAL_STREAM_PASSWORD"
                            value={elem.streamPassword}
                        />
                    </>}

                    <ViewField
                        label="GENERAL_DATE"
                        value={elem.timeString}
                    />
                    <ViewField
                        isHidden={!elem.observations || !elem.canView}
                        label="GENERAL_OBSERVATIONS"
                        value={elem.observations}
                    />
                    <ViewField
                        isHidden={!elem.isCancelled}
                        label="GENERAL_STATUS"
                        value={elem.cancelledString}
                    />
                    <ViewField
                        isHidden={!elem.canViewReport}
                        label="GENERAL_STATUS"
                        value={elem.reportString}
                    />

                    {elem.canViewReport && <AccordionList>
                        {this.renderItems()}
                    </AccordionList>}
                </DialogBody>
                <DialogFooter onAction={this.startAction} cancel="GENERAL_CLOSE">
                    <DialogAction action="EDIT"   isHidden={!elem.canEdit}   />
                    <DialogAction action="REPORT" isHidden={!elem.canReport} />
                    <DialogAction action="CANCEL" isHidden={!elem.canCancel} />
                </DialogFooter>
            </Dialog>

            <MeetingEdit
                open={action.isCE}
                elemID={elem.meetingID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <MeetingReport
                open={action.isReport}
                elemID={elem.meetingID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <MeetingCancel
                open={action.isCancel}
                elemID={elem.meetingID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeeting : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        elem         : PropTypes.object.isRequired,
        questions    : PropTypes.array.isRequired,
        members      : PropTypes.array.isRequired,
        elemID       : PropTypes.number,
        groupID      : PropTypes.number,
        coachID      : PropTypes.number,
        locationID   : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.meeting.edition,
            elem      : state.meeting.elem,
            questions : state.meeting.questions,
            members   : state.meeting.members,
        };
    }
}

export default connect(MeetingView.mapStateToProps, {
    fetchMeeting,
})(MeetingView);
