import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Dialogs
import ChatEdit             from "./ChatEdit";
import ChatEnroll           from "./ChatEnroll";
import InscriptionEdit      from "Components/Admin/Strech/Inscription/InscriptionEdit";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import InfoAction           from "dashboard/dist/Components/InfoAction";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import ListContent          from "dashboard/dist/Components/ListContent";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import InputField           from "dashboard/dist/Components/InputField";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchChatPresentism, togglePresentism,
} from "Actions/Admin/Strech/InscriptionChatActions";



/**
 * The Strech Chat Presentism Dialog
 */
class ChatPresentism extends React.Component {
    // The Current State
    state = {
        loading : false,
        sending : false,
        update  : false,
        action  : Action.get(),
        student : null,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchChatPresentism } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchChatPresentism(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }

    /**
     * Handles the Toggle
     * @param {Object} elem
     * @returns {Promise}
     */
    handleToggle = async (elem) => {
        const { elemID, togglePresentism } = this.props;
        const { sending                  } = this.state;

        if (!sending) {
            this.setState({ sending : true });
            try {
                elem.attended = !elem.attended;
                await togglePresentism(elemID, elem.credentialID, elem.attended ? 1 : 0);
                this.setState({ sending : false, update : true });
            } catch {
                this.setState({ sending : false });
            }
        }
    }

    /**
     * Handles the Close
     * @param {Object} elem
     * @returns {Void}
     */
    handleInscription = (elem) => {
        this.startAction(Action.get("INSCRIPTION"), elem);
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
        this.setState({ update : false });
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Object=} student
     * @returns {Void}
     */
    startAction = (action, student) => {
        this.setState({ action, student });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get(), student : null });
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { elemID, fetchChatPresentism } = this.props;
        this.endAction();
        this.setState({ update : true });
        fetchChatPresentism(elemID);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, canEdit, elem, students } = this.props;
        const { loading, action, student      } = this.state;

        const hasStudents = Boolean(students.length);

        return <>
            <ViewDialog
                open={open}
                icon="chat"
                title={elem.name || "CHATS_VIEW_TITLE"}
                onClose={this.handleClose}
                isLoading={loading}
            >
                <InfoList onAction={this.startAction}>
                    <InfoItem label="COACHES_SINGULAR"   message={elem.credentialName} />
                    <InfoItem label="GENERAL_DAY"        message={elem.date}           />
                    <InfoItem label="SCHEDULES_SINGULAR" message={elem.scheduleTime}   />
                    <InfoAction action="EDIT" isHidden={!canEdit} />
                    <InfoAction action="ADD"  isHidden={!canEdit} />
                </InfoList>

                {!hasStudents ? <NoneAvailable
                    variant="dialog"
                    message="INSCRIPTIONS_NONE_AVAILABLE"
                /> : <List columns="3" withSpacing>
                    {students.map((elem) => <ListItem key={elem.credentialID}>
                        <ListContent>{elem.credentialName}</ListContent>
                        <ListContent>
                            <InputField
                                type="toggle"
                                name="attended"
                                label={elem.attended ? "PRESENTISM_ASSISTED" : "PRESENTISM_DIDNT_ASSISTED"}
                                value={!!elem.attended}
                                onChange={() => this.handleToggle(elem)}
                                noMargin
                            />
                        </ListContent>
                        <Button
                            variant="primary"
                            message="INSCRIPTIONS_SINGULAR"
                            onClick={() => this.handleInscription(elem)}
                        />
                    </ListItem>)}
                </List>}
            </ViewDialog>

            <ChatEdit
                open={action.isEdit}
                elemID={elem.strechChatID}
                strechID={elem.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ChatEnroll
                open={action.isAdd}
                elemID={elem.strechChatID}
                strechID={elem.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <InscriptionEdit
                open={action.isInscription}
                elemID={student ? student.inscriptionID : 0}
                strechID={elem.strechID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchChatPresentism : PropTypes.func.isRequired,
        togglePresentism    : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        canEdit             : PropTypes.bool.isRequired,
        elem                : PropTypes.object.isRequired,
        students            : PropTypes.array.isRequired,
        elemID              : PropTypes.number,
        strechID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.inscriptionChat.edition,
            canEdit  : state.inscriptionChat.canEdit,
            elem     : state.inscriptionChat.elem,
            students : state.inscriptionChat.students,
        };
    }
}

export default connect(ChatPresentism.mapStateToProps, {
    fetchChatPresentism, togglePresentism,
})(ChatPresentism);
