import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    ASSIGNMENT_LOADING,
    ASSIGNMENT_LIST, ASSIGNMENT_ELEM, ASSIGNMENT_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    interests   : [],
    credentials : [],
    sort        : {
        orderBy  : "interestName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.name            = `${elem.interestName} - ${elem.credentialName}`;
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.statusName      = Status.getFemName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, ASSIGNMENT_LIST, ASSIGNMENT_ELEM, ASSIGNMENT_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case ASSIGNMENT_LOADING:
        return {
            ...state,
            loading     : true,
        };

    case ASSIGNMENT_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : parseList(action.data.list),
            total       : action.data.total,
            sort        : action.data.sort,
        };

    case ASSIGNMENT_ELEM:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            interests   : action.data.interests,
            credentials : action.data.credentials,
            elem        : parseElem(action.data.elem),
        };

    case ASSIGNMENT_CREATE:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            interests   : action.data.interests,
            credentials : action.data.credentials,
        };

    default:
        return state;
    }
};

export default reducer;
