import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGuide }       from "Actions/Admin/Guide/GuideActions";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import PDFViewer            from "dashboard/dist/Components/PDFViewer";
import PDFNavigation        from "dashboard/dist/Components/PDFNavigation";
import Button               from "dashboard/dist/Components/Button";



/**
 * The Strech Guide View Dialog
 */
class GuideView extends React.Component {
    // The Current State
    state = {
        loading     : false,
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchGuide } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchGuide(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false, currentPage : 1 });
        }
    }



    /**
     * Handles the PDF Load
     * @param {Number} totalPages
     * @returns {Void}
     */
    handleLoad = (totalPages) => {
        this.setState({ totalPages });
    }

    /**
     * Handles the PDF Page Change
     * @returns {Void}
     */
    handlePage = (currentPage) => {
        this.setState({ currentPage });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose              } = this.props;
        const { loading, currentPage, totalPages } = this.state;

        return <Dialog open={open} onClose={onClose} isLoading={loading} width={800}>
            <DialogHeader message={elem.name || "GENERAL_LOADING"} icon="guide" />
            <DialogBody>
                <PDFViewer
                    source={elem.fileUrl}
                    currentPage={currentPage}
                    onLoad={this.handleLoad}
                />
            </DialogBody>
            <DialogFooter cancel="GENERAL_CLOSE">
                <PDFNavigation
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={this.handlePage}
                />
                <Button
                    variant="primary"
                    href={elem.fileUrl}
                    target="_blank"
                    message="GENERAL_DOWNLOAD"
                />
            </DialogFooter>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuide : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        edition    : PropTypes.number.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.guide.edition,
            elem    : state.guide.elem,
        };
    }
}

export default connect(GuideView.mapStateToProps, {
    fetchGuide,
})(GuideView);
