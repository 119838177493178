import React                from "react";
import PropTypes            from "prop-types";
import Common               from "Utils/Common";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";
import Columns              from "dashboard/dist/Components/Columns";
import Video                from "dashboard/dist/Components/Video";



/**
 * The Chat View Dialog
 */
class ChatView extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { type, data          } = elem;

        const values   = data || {};
        const typeName = Common.getTypeName(values.programType);

        return <ViewDialog
            open={open}
            icon={type}
            title={values.name || "CHATS_VIEW_TITLE"}
            onClose={onClose}
            withSpacing
        >
            {!!values.videoLink && <Video
                className="bottom-space-big"
                title={values.name}
                source={values.videoLink}
            />}
            <ViewField label="CHATS_DESCRIPTION" value={values.description} />
            <Columns amount="2">
                <ViewField label={typeName}    value={values.strechTitle}    />
                <ViewField label="CHATS_COACH" value={values.credentialName} />
            </Columns>
            <ViewField label="CHATS_DATE" value={values.timeString} />
            {values.isVirtual && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={values.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={values.streamPassword} />
            </>}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elem    : PropTypes.object.isRequired,
    }
}

export default ChatView;
