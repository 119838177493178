import { CourseCompleted }  from "Utils/API";

// Types
import {
    COURSE_COMPLETED_ELEM,
} from "Utils/Types";



/**
 * Fetches a single Course Completed
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCompleted(credentialID) {
    return async (dispatch) => {
        const data = await CourseCompleted.getAllForCredential({ credentialID });
        dispatch({ type : COURSE_COMPLETED_ELEM, data });
    };
}



/**
 * Edits a Course Completed
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCompleted(data) {
    return () => CourseCompleted.edit(data);
}
