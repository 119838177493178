import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Common               from "Utils/Common";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import AssignmentEdit       from "./AssignmentEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchAssignments,
} from "Actions/Admin/Strech/AssignmentActions";



/**
 * The Assignment List
 */
class AssignmentList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader = true) => {
        const { type, elemID, data } = this.props;
        this.props.fetchAssignments(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isStudent) {
            const inscription = Utils.getValue(this.props.data.list, "inscriptionID", elemID);
            Href.goto(Common.getAccessBaseUrl(inscription), inscription.credentialID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Returns true if it can Assign a Coach
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canAssign(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "inscriptionID", elemID).canAssign;
    }



    /**
     * Returns a Row Render
     * @param {Object} elem
     * @returns {Object}
     */
    renderRow(elem) {
        const result = [
            <TableCell key={1} message={elem.credentialName}    />,
            <TableCell key={2} message={elem.conversationsText} />,
        ];
        for (const assign of elem.assignments) {
            result.push(<TableCell key={assign.specialityID + 2} message={assign.coachName} />)
        }
        return <TableRow key={elem.inscriptionID} elemID={elem.inscriptionID}>
            {result}
        </TableRow>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                                    } = this.state;
        const { data, route, withDetails                          } = this.props;
        const { canEdit, list, total, specialities, sort, loading } = data;

        const header = [
            <TableHeader key={1} field="student"       message="STUDENTS_SINGULAR"  grow="1.5" />,
            <TableHeader key={2} field="conversations" message="CONVERSATIONS_NAME" maxWidth="120" align="center" />,
        ];
        for (const elem of specialities) {
            header.push(<TableHeader
                key={elem.key + 2}
                field={`speciality-${elem.key}`}
                message={elem.value}
            />);
        }

        return <Main withDetails={withDetails}>
            <Header message="INSC_ASSIGNS_NAME" icon="assignment" route={route} />
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="INSCRIPTIONS_NONE_AVAILABLE">
                    <TableHead>
                        {header}
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => this.renderRow(elem))}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"    message="INSC_ASSIGNS_EDIT_TITLE" hide={(elemID) => !this.canAssign(elemID)} />
                        <TableAction action="STUDENT" message="STUDENTS_VIEW_TITLE"    />
                    </TableActionList>
                </Table>
            </Content>

            <AssignmentEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAssignments : PropTypes.func.isRequired,
        data             : PropTypes.object.isRequired,
        type             : PropTypes.string.isRequired,
        params           : PropTypes.object.isRequired,
        route            : PropTypes.string.isRequired,
        withDetails      : PropTypes.bool.isRequired,
        elemID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.inscriptionAssign,
        };
    }
}

export default connect(AssignmentList.mapStateToProps, {
    fetchAssignments,
})(AssignmentList);
