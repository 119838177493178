import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchQueue }       from "Actions/Admin/Setup/EmailQueueActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import EmailQueueView       from "./EmailQueueView";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Filter               from "dashboard/dist/Components/Filter";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";



/**
 * The Email Queue List
 */
class EmailQueueList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { data, fetchQueue } = this.props;
        fetchQueue(data.filter, params || data.sort);
    }

    /**
     * Filters the content
     * @param {Object} params
     * @returns {Promise}
     */
    filter = async (params) => {
        try {
            const { data, fetchQueue } = this.props;
            await fetchQueue(params, { ...data.sort, page : 0 });
        } catch (errors) {
            throw errors;
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                     } = this.state;
        const { data, route                        } = this.props;
        const { list, total, sort, filter, loading } = data;

        return <Main>
            <Header message="EMAIL_QUEUE_NAME" icon="queue" route={route} />
            <Content>
                <Filter onFilter={this.filter} values={filter} hasSearch labelInside />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="EMAIL_QUEUE_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="createdTime" message="GENERAL_CREATED"       maxWidth="90" />
                        <TableHeader field="subject"     message="GENERAL_SUBJECT"       />
                        <TableHeader field="sendTo"      message="GENERAL_EMAIL"         />
                        <TableHeader field="sentTime"    message="EMAIL_QUEUE_SEND_DATE" maxWidth="90" />
                        <TableHeader field="sentSuccess" message="GENERAL_SENT"          maxWidth="60" align="center" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.emailID} elemID={elem.emailID}>
                            <TableCell message={elem.createdDate}       />
                            <TableCell message={elem.subject}           />
                            <TableCell message={elem.sendToString}      />
                            <TableCell message={elem.sentDate}          />
                            <TableCell message={elem.sentSuccessString} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction}>
                        <TableAction action="VIEW" message="EMAIL_QUEUE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <EmailQueueView
                open={action.isView}
                elemID={elemID}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQueue : PropTypes.func.isRequired,
        data       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.emailQueue,
        };
    }
}

export default connect(EmailQueueList.mapStateToProps, {
    fetchQueue,
})(EmailQueueList);
