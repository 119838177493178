import React                from "react";
import PropTypes            from "prop-types";

// Components
import ClassContent         from "Components/Admin/Strech/Class/ClassContent";



/**
 * The Strech Class Page
 */
class ClassPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { params, route } = this.props;

        return <ClassContent
            elemID={params.classID}
            guideID={params.guideID}
            route={route}
        />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        params : PropTypes.object.isRequired,
        route  : PropTypes.string.isRequired,
    }
}

export default ClassPage;
