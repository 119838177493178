import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchLeadClose, closeLead,
} from "Actions/Admin/Lead/LeadActions";



/**
 * The Lead Close Dialog
 */
class LeadClose extends React.Component {
    // The Initial Data
    initialData = {
        leadID        : 0,
        reasonID      : 0,
        inscriptionID : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchLeadClose } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchLeadClose(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = Utils.extend(this.initialData, { leadID : elemID });
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { closeLead, onSubmit } = this.props;
        const { data, loading       } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await closeLead(data);
                await this.setState({ loading : false });
                onSubmit(true);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, reasons, inscriptions } = this.props;
        const { data, loading, errors                } = this.state;

        const showInscription = Utils.getValue(reasons, "key", data.reasonID, "forInscription");

        return <EditDialog
            open={open}
            icon="status"
            title="LEADS_CLOSE_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isNarrow
        >
            <InputField
                type="select"
                name="reasonID"
                label="LEADS_CLOSE_REASON"
                value={data.reasonID}
                error={errors.reasonID}
                options={reasons}
                onChange={this.handleChange}
                withNone
                isRequired
            />
            <InputField
                isHidden={!showInscription}
                type="select"
                name="inscriptionID"
                label="LEADS_CLOSE_INSCRIPTION"
                value={data.inscriptionID}
                error={errors.inscriptionID}
                options={inscriptions}
                onChange={this.handleChange}
                withNone
                isRequired
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLeadClose : PropTypes.func.isRequired,
        closeLead      : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        onClose        : PropTypes.func.isRequired,
        onSubmit       : PropTypes.func.isRequired,
        edition        : PropTypes.number.isRequired,
        reasons        : PropTypes.array.isRequired,
        inscriptions   : PropTypes.array.isRequired,
        elemID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            edition      : state.lead.edition,
            reasons      : state.lead.reasons,
            inscriptions : state.lead.inscriptions,
        };
    }
}

export default connect(LeadClose.mapStateToProps, {
    fetchLeadClose, closeLead,
})(LeadClose);
