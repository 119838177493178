import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STRECH_ANSWER_LOADING,
    STRECH_ANSWER_STUDENTS, STRECH_ANSWER_ANSWERS, STRECH_ANSWER_QUESTIONS,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    total     : 0,
    students  : [],
    questions : [],
    answers   : {},
    coachName : "",
};



/**
 * Parses the Students
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseStudents(list) {
    return Utils.parseList(list, (elem) => {
        elem.answeredDate = elem.answeredTime ? DateTime.formatDate(elem.answeredTime, "dashes") : "-";
    });
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_ANSWER_STUDENTS, STRECH_ANSWER_ANSWERS, STRECH_ANSWER_QUESTIONS)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_ANSWER_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case STRECH_ANSWER_STUDENTS:
        return {
            ...state,
            loading   : false,
            error     : false,
            canExport : action.data.canExport,
            students  : parseStudents(action.data.students),
            total     : action.data.students.length,
        };

    case STRECH_ANSWER_ANSWERS:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            questions : Common.parseQuestions(action.data.questions),
            answers   : action.data.answers,
            coachName : action.data.coachName,
        };

    case STRECH_ANSWER_QUESTIONS:
        return {
            ...state,
            loading   : false,
            error     : false,
            questions : Common.parseQuestions(action.data.questions),
            answers   : action.data.answers,
            coachName : action.data.coachName,
        };

    default:
        return state;
    }
};

export default reducer;
