import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { GroupInterest }         from "Utils/API";

// Types
import {
    GROUPINTEREST, GROUPINTEREST_LIST, GROUPINTEREST_ELEM, GROUPINTEREST_CREATE
} from "Utils/Types";



/**
 * Fetches the Interests List
 * @param {Object=}  params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGroupInterests(params = {}) {
    return async (dispatch) => {
        Params.unset(params);
        const data = await GroupInterest.getAll(params);
        data.sort = params;
        dispatch({ type : GROUPINTEREST_LIST, data });
        dispatch(setSection(GROUPINTEREST));
    };
}

/**
 * Fetches a single Interest
 * @param {Number} interestID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGroupInterest(groupinterestID) {
    return async (dispatch) => {
        const data = await GroupInterest.getOne({ groupinterestID });
        dispatch({ type : GROUPINTEREST_ELEM, data });
    };
}

/**
 * Fetches the Interest Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGroupInterestCreate() {
    return async (dispatch) => {
        const data = await GroupInterest.getCreateData();
        dispatch({ type : GROUPINTEREST_CREATE, data });
    };
}


/**
 * Edits a Interest
 * @param {Object} data
 * @returns {() => Void}
 */
export function editGroupInterest(data) {
    return () => GroupInterest.edit(data);
}

/**
 * Deletes a Interest
 * @param {Number} interestID
 * @returns {() => Void}
 */
export function deleteGroupInterest(groupinterestID) {
    return () => GroupInterest.delete({ groupinterestID });
}
