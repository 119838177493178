import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramQuestion }  from "Utils/API";

// Types
import {
    PROGRAM_QUESTION, PROGRAM_QUESTION_LOADING,
    PROGRAM_QUESTION_LIST, PROGRAM_QUESTION_ELEM, PROGRAM_QUESTION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program Question List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestions(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_QUESTION_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramQuestion.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_QUESTION_LIST, data });
        dispatch(setSection(PROGRAM_QUESTION));
    };
}

/**
 * Fetches a single Program Question
 * @param {Number} questionID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestion(questionID) {
    return async (dispatch) => {
        const data = await ProgramQuestion.getOne({ questionID });
        dispatch({ type : PROGRAM_QUESTION_ELEM, data });
    };
}

/**
 * Fetches the Program Question Create data
 * @param {Number} programID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestionCreate(programID) {
    return async (dispatch) => {
        const data = await ProgramQuestion.getCreateData({ programID });
        dispatch({ type : PROGRAM_QUESTION_CREATE, data });
    };
}



/**
 * Edits a Program Question
 * @param {Object} data
 * @returns {() => Void}
 */
export function editQuestion(data) {
    return () => ProgramQuestion.edit(data);
}

/**
 * Deletes a Program Question
 * @param {Number} questionID
 * @returns {() => Void}
 */
export function deleteQuestion(questionID) {
    return () => ProgramQuestion.delete({ questionID });
}
