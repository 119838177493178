import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    STRECH_PRICE_LOADING,
    STRECH_PRICE_LIST, STRECH_PRICE_ELEM, STRECH_PRICE_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    prices    : [],
    priceData : [],
    sort      : {
        orderBy  : "dues",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.name            = NLS.pluralize("PRICES_NAME", elem.dues, elem.price);
    elem.duesName        = NLS.pluralize("PRICES_DUES", elem.dues);
    elem.showPriceString = Utils.toYesNo(elem.showPrice);
    elem.promotionText   = Utils.toYesNo(elem.isPromotion);
    elem.promotionString = elem.isPromotion && elem.promotionEnd ? NLS.format("PRICES_PROMOTION_DATE", DateTime.formatIf(elem.promotionEnd, "dashes")) : elem.promotionText;
    elem.promotionEnd    = DateTime.formatIf(elem.promotionEnd, "dashesReverse");
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}

/**
 * Parses the Prices
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parsePrices(list) {
    for (const elem of list) {
        const [ dues, price ] = elem.value.split(" - ");
        elem.value = NLS.pluralize("PRICES_NAME", dues, price);
    }
    return list;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_PRICE_LIST, STRECH_PRICE_ELEM, STRECH_PRICE_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_PRICE_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case STRECH_PRICE_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case STRECH_PRICE_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            prices    : parsePrices(action.data.prices),
            priceData : action.data.priceData,
        };

    case STRECH_PRICE_CREATE:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            prices    : parsePrices(action.data.prices),
            priceData : action.data.priceData,
        };

    default:
        return state;
    }
};

export default reducer;
