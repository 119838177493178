import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import PDFViewer            from "dashboard/dist/Components/PDFViewer";
import PDFNavigation        from "dashboard/dist/Components/PDFNavigation";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import InputField           from "dashboard/dist/Components/InputField";
import ViewField            from "dashboard/dist/Components/ViewField";
import Button               from "dashboard/dist/Components/Button";
import MultiLine            from "dashboard/dist/Components/MultiLine";

// Actions
import {
    fetchGuide, saveGuideAnswer, sendGuideAnswers,
} from "Actions/Admin/Strech/GuideActions";

// Styles
import "Styles/Components/App/Guide.css";



/**
 * The Strech Guide View Dialog
 */
class GuideView extends React.Component {
    // The Initial Data
    initialData = {
        elemID  : 0,
        answers : {},
    }

    // The Current State
    state = {
        data        : { ...this.initialData },
        error       : false,
        loading     : false,
        sending     : false,
        update      : false,
        pdfloaded   : false,
        changed     : false,
        errors      : {},
        pages       : [],
        accordion   : {},
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, error, edition, elemID, credentialID, answers, fetchGuide } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchGuide(elemID, credentialID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (!this.state.changed) {
                data = { elemID, answers : Utils.clone(answers) };
            } else {
                data = { elemID, answers : this.state.data.answers };
            }

        // There is an Error
        } else if (!prevProps.error && error) {
            this.setState({ loading : false, error : true });
        }

        // Set the State
        if (data) {
            if (this.state.pdfloaded && elemID === this.state.data.elemID) {
                this.setState({ data, errors : {} });
            } else {
                this.setState({
                    data, loading,
                    error       : false,
                    errors      : {},
                    accordion   : {},
                    currentPage : 1,
                    update      : false,
                    pdfloaded   : false,
                    changed     : false,
                });
            }
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        let questionID = name.split("-")[1];
        let answers    = this.state.data.answers;
        answers[questionID] = value;

        this.setState({
            data    : { ...this.state.data, answers },
            changed : true,
        });
    }

    /**
     * Handles the Submit
     * @param {Number} questionID
     * @returns {Promise}
     */
    handleSave = async (questionID) => {
        const { elem, saveGuideAnswer, fetchGuide } = this.props;
        const { data, sending                     } = this.state;

        if (!sending && data.answers[questionID]) {
            this.setState({ sending : true, errors : {} });
            try {
                await saveGuideAnswer({
                    strechGuideID : elem.strechGuideID,
                    questionID    : questionID,
                    answer        : data.answers[questionID],
                });
                await fetchGuide(elem.strechGuideID);
                this.setState({ sending : false });
            } catch (errors) {
                this.setState({ sending : false, errors });
            }
        }
    }

    /**
     * Handles the Send
     * @returns {Promise}
     */
    handleSend = async () => {
        const { elem, sendGuideAnswers, fetchGuide } = this.props;
        const { sending, currentPage               } = this.state;

        if (!sending) {
            this.setState({ sending : true, errors : {} });
            try {
                await sendGuideAnswers(elem.strechGuideID);
                await fetchGuide(elem.strechGuideID);
                this.setState({ sending : false, update : true, currentPage });
            } catch (errors) {
                this.setState({ sending : false, errors });
            }
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Sets the total pages of the pdf
     * @param {Number} numPages
     * @returns {Void}
     */
    handleLoad = (numPages) => {
        const { questions } = this.props;

        const pages      = [];
        let   addPage    = true;
        let   totalPages = numPages;

        for (let page = 1; page <= numPages; page++) {
            addPage = true;
            for (const section of Object.values(questions)) {
                if (section.page === page) {
                    addPage = false;
                    if (section.replace) {
                        pages.push({ isPDF : false, ...section });
                    } else if (section.after) {
                        pages.push({ isPDF : true, page });
                        pages.push({ isPDF : false, ...section });
                        totalPages += 1;
                    }
                    break;
                }
            }
            if (addPage) {
                pages.push({ isPDF : true, page });
            }
        }
        this.setState({ pages, totalPages, pdfloaded : true });
    }

    /**
     * Handles the Accordion change
     * @param {Number} value
     * @returns {Void}
     */
    handleAccordion = (value) => {
        const { accordion, currentPage } = this.state;
        accordion[currentPage] = value;
        this.setState({ accordion });
    }

    /**
     * Handles the PDF Page Change
     * @param {Number} currentPage
     * @returns {Void}
     */
    handlePage = (currentPage) => {
        this.setState({ currentPage });
    }

    /**
     * Returns true if all the Questions are answered
     * @param {Object[]} questions
     * @param {Object}   answers
     * @returns {Boolean}
     */
    allAnswered(questions, answers) {
        if (Utils.isEmpty(questions) || this.state.loading) {
            return false;
        }
        for (const section of Object.values(questions)) {
            for (const elem of section.questions) {
                if (!answers[elem.key]) {
                    return false;
                }
            }
        }
        return true;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, questions, answers, answersSent, feedback, feedbackSent, canReply, open } = this.props;
        const { data, error, loading, sending, pages, currentPage, totalPages, accordion      } = this.state;

        const page          = pages[currentPage - 1];
        const showPDF       = Boolean(!error && ((open && !pages.length) || (page && page.isPDF)));
        const showQuestions = Boolean(!error && page && !page.isPDF);
        const canSave       = canReply && !answersSent;
        const canSend       = canReply && !answersSent && this.allAnswered(questions, answers);
        const accordionInit = accordion[currentPage] || 0;

        return <Dialog open={open} onClose={this.handleClose} isLoading={loading} width={800}>
            <DialogHeader message={elem.fullName || "GUIDES_VIEW_TITLE"} icon="guide" />
            <DialogBody className="guide-dialog" fullHeight={elem.isPDF}>
                {error && <NoneAvailable variant="dialog" message="GUIDES_ERROR_EXISTS" />}
                {showPDF && <PDFViewer
                    source={elem.guideFileUrl}
                    currentPage={page ? page.page : currentPage}
                    onLoad={this.handleLoad}
                />}
                {showQuestions && <div className="guide-questions">
                    <MultiLine
                        variant="p"
                        className="guide-description"
                        content={elem.description}
                    />
                    <h3 className="guide-info">
                        {NLS.get(canSave ? "GUIDES_COMPLETE_HELP" : "GUIDES_COMPLETE_THANKS")}
                    </h3>
                    <AccordionList initial={accordionInit} onChange={this.handleAccordion}>
                        {page.questions.map((elem, index) => <AccordionItem
                            key={index}
                            number={elem.number}
                            message={elem.value}
                            icon={data.answers[elem.key] ? "checkedbox" : "checkbox"}
                        >
                            {!!elem.help && <p className="guide-help">{elem.help}</p>}
                            {canSave ? <div className="guide-answer">
                                <InputField
                                    type="textarea"
                                    name={`answer-${elem.key}`}
                                    label="ANSWERS_MINE"
                                    value={data.answers[elem.key] || ""}
                                    onChange={this.handleChange}
                                />
                                <Button
                                    variant="primary"
                                    message="GENERAL_SAVE"
                                    onClick={() => this.handleSave(elem.key)}
                                    isDisabled={sending}
                                />
                            </div> : <ViewField
                                label="ANSWERS_SINGULAR"
                                value={data.answers[elem.key] || NLS.get("GUIDES_NO_ANSWER")}
                                showEmpty
                            />}
                        </AccordionItem>)}
                    </AccordionList>
                    {feedbackSent && <div className="guide-feedback">
                        <h3>{NLS.get("GENERAL_FEEDBACK")}</h3>
                        <ViewField
                            label="ANSWERS_SINGULAR"
                            value={feedback[page.id] || ""}
                            showEmpty
                        />
                    </div>}
                </div>}
            </DialogBody>
            <DialogFooter
                primary={canSend ? "GENERAL_SEND" : ""}
                onSubmit={this.handleSend}
                cancel="GENERAL_CLOSE"
            >
                {!error && <>
                    <PDFNavigation
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChange={this.handlePage}
                    />
                    <Button
                        variant="primary"
                        href={elem.guideFileUrl}
                        target="_blank"
                        message="GENERAL_DOWNLOAD"
                    />
                </>}
            </DialogFooter>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuide       : PropTypes.func.isRequired,
        saveGuideAnswer  : PropTypes.func.isRequired,
        sendGuideAnswers : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        error            : PropTypes.bool.isRequired,
        edition          : PropTypes.number.isRequired,
        canReply         : PropTypes.bool.isRequired,
        elem             : PropTypes.object.isRequired,
        questions        : PropTypes.object.isRequired,
        answers          : PropTypes.object.isRequired,
        answersSent      : PropTypes.bool.isRequired,
        feedback         : PropTypes.object.isRequired,
        feedbackSent     : PropTypes.bool.isRequired,
        elemID           : PropTypes.number,
        credentialID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            error        : state.strechGuide.error,
            edition      : state.strechGuide.edition,
            canReply     : state.strechGuide.canReply,
            elem         : state.strechGuide.elem,
            questions    : state.strechGuide.questions,
            answers      : state.strechGuide.answers,
            answersSent  : state.strechGuide.answersSent,
            feedback     : state.strechGuide.feedback,
            feedbackSent : state.strechGuide.feedbackSent,
        };
    }
}

export default connect(GuideView.mapStateToProps, {
    fetchGuide, saveGuideAnswer, sendGuideAnswers,
})(GuideView);
