import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Student, Coach }   from "Utils/API";
import Access               from "dashboard/dist/Core/Access";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchTicket, fetchTicketCreate, createTicket, editTicket,
} from "Actions/Admin/Ticket/TicketActions";



/**
 * The Ticket Edit Dialog
 */
class TicketEdit extends React.Component {
    // The Initial Data
    initialData = {
        ticketID     : 0,
        ticketTypeID : 0,
        access       : Access.getID("STUDENT"),
        userID       : 0,
        userName     : "",
        adminID      : 0,
        strechID     : 0,
        message      : "",
        image        : "",
        forSupport   : false,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const {
            forSupport, open, edition, elemID, elem, studentID, coachID, strechID, use,
            ticketTypeID, fetchTicket, fetchTicketCreate,
        } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchTicket(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchTicketCreate(forSupport, use);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData };
                if (studentID) {
                    data.access = Access.getID("STUDENT");
                    data.userID = studentID;
                } else if (coachID) {
                    data.access = Access.getID("COACH");
                    data.userID = coachID;
                }
                if (forSupport) {
                    data.message = NLS.get("SUPPORT_MESSAGE_PLACEHOLDER");
                }
                if (ticketTypeID) {
                    data.ticketTypeID = ticketTypeID;
                }
                if (strechID) {
                    data.strechID = strechID;
                }
            }
            data.forSupport = forSupport;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                if (!this.props.elemID) {
                    await this.props.createTicket(data);
                } else {
                    await this.props.editTicket(data);
                }
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            elemID, forSupport, title, open, onClose, ticketTypeID, ticketTypes, streches,
            credentials, canAssign, studentID, coachID, strechID, isStudent, isCoach,
        } = this.props;
        const { data, loading, errors } = this.state;

        const isEdit       = !!elemID;
        const prefix       = forSupport ? "SUPPORT_" : "TICKETS_";
        const showType     = Boolean(!ticketTypeID);
        const showUser     = Boolean(!forSupport && !isEdit && !studentID && !coachID && !isStudent && !isCoach);
        const showStreches = Boolean(streches.length && !strechID);
        const message      = Utils.getValue(ticketTypes, "key", data.ticketTypeID, "message")

        return <EditDialog
            open={open}
            icon="ticket"
            title={title || (!!elemID ? `${prefix}EDIT_TITLE` : `${prefix}CREATE_TITLE`)}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <Columns isHidden={!showUser}>
                <InputField
                    type="select"
                    name="access"
                    label="GENERAL_TYPE"
                    value={data.access}
                    error={errors.access}
                    onChange={this.handleChange}
                    options={Access.getSelect("USER")}
                    isRequired
                />
                <InputField
                    isHidden={!Access.is("STUDENT", data.access)}
                    name="userName"
                    label="STUDENTS_SINGULAR"
                    suggestID="userID"
                    suggestFetch={Student.search}
                    suggestNone="STUDENTS_NONE_AVAILABLE"
                    value={data.userName}
                    error={errors.userID}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!Access.is("COACH", data.access)}
                    name="userName"
                    label="COACHES_SINGULAR"
                    suggestID="userID"
                    suggestFetch={Coach.search}
                    suggestNone="COACHES_NONE_AVAILABLE"
                    value={data.userName}
                    error={errors.userID}
                    onChange={this.handleChange}
                    isRequired
                />
            </Columns>
            <InputField
                isHidden={!showType}
                type="select"
                name="ticketTypeID"
                label={`${prefix}TYPES_SINGULAR`}
                value={data.ticketTypeID}
                error={errors.ticketTypeID}
                onChange={this.handleChange}
                options={ticketTypes}
                withNone
                isRequired
            />
            <InputField
                isHidden={!showStreches}
                type="select"
                name="strechID"
                label="COURSES_SINGULAR"
                value={data.strechID}
                error={errors.strechID}
                onChange={this.handleChange}
                options={streches}
                isRequired={!showType}
                withNone
            />
            <DialogMessage
                className="bottom-space"
                message={message}
                noSpace
            />
            <InputField
                isHidden={!!elemID}
                type="textarea"
                name="message"
                label={`${prefix}MESSAGE`}
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                isHidden={!forSupport}
                type="file"
                name="image"
                label={`${prefix}IMAGE`}
                // value={data.image}
                error={errors.image}
                onChange={this.handleChange}
                onlyImages
            />
            <InputField
                isHidden={!canAssign}
                type="select"
                name="adminID"
                label="TICKETS_ASSIGNED"
                value={data.adminID}
                error={errors.adminID}
                onChange={this.handleChange}
                options={credentials}
                withNone
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicket       : PropTypes.func.isRequired,
        fetchTicketCreate : PropTypes.func.isRequired,
        createTicket      : PropTypes.func.isRequired,
        editTicket        : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        ticketTypeID      : PropTypes.number.isRequired,
        ticketTypes       : PropTypes.array.isRequired,
        streches          : PropTypes.array.isRequired,
        credentials       : PropTypes.array.isRequired,
        canAssign         : PropTypes.bool.isRequired,
        isStudent         : PropTypes.bool.isRequired,
        isCoach           : PropTypes.bool.isRequired,
        elem              : PropTypes.object.isRequired,
        title             : PropTypes.string,
        elemID            : PropTypes.number,
        studentID         : PropTypes.number,
        coachID           : PropTypes.number,
        strechID          : PropTypes.number,
        use               : PropTypes.number,
        forSupport        : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        use        : 0,
        forSupport : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.ticket.edition,
            ticketTypeID : state.ticket.ticketTypeID,
            ticketTypes  : state.ticket.ticketTypes,
            streches     : state.ticket.streches,
            credentials  : state.ticket.credentials,
            canAssign    : state.ticket.canAssign,
            elem         : state.ticket.elem,
            isStudent    : state.auth.isStudent,
            isCoach      : state.auth.isCoach,
        };
    }
}

export default connect(TicketEdit.mapStateToProps, {
    fetchTicket, fetchTicketCreate, createTicket, editTicket,
})(TicketEdit);
