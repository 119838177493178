import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Strech }           from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import StrechEdit           from "./StrechEdit";
import StrechUrl            from "./StrechUrl";
import InscriptionEdit      from "Components/Admin/Strech/Inscription/InscriptionEdit";
import CoachEdit            from "Components/Admin/Strech/Coach/CoachEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchStreches, activateStrech, cancelStrech, deleteStrech,
} from "Actions/Admin/Strech/StrechActions";



/**
 * The Strech List
 */
class StrechList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchStreches(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.fetch({
                ...this.props.data.sort,
                filter   : elemID,
                page     : 0,
                orderAsc : elemID === "ended" ? 0 : 1,
            });
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch();
        }
    }



    /**
     * Handles the Edit Submit
     * @param {Number}  strechID
     * @param {Boolean} isEdit
     * @returns {Void}
     */
    editElem = (strechID, isEdit) => {
        if (!isEdit && strechID) {
            Href.goto(this.props.route, strechID);
        } else {
            this.endAction(true);
        }
    }

    /**
     * Handles the Activate Submit
     * @returns {Promise}
     */
    activateElem = async () => {
        await this.props.activateStrech(this.state.elemID);
        this.endAction(true);
    }

    /**
     * Handles the Cancel Submit
     * @returns {Promise}
     */
    cancelElem = async () => {
        await this.props.cancelStrech(this.state.elemID);
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteStrech(this.state.elemID);
        this.endAction(true);
    }



    /**
     * Returns true if the Strech has a Link
     * @param {Number} elemID
     * @returns {Boolean}
     */
    hasLink(elemID) {
        const { list } = this.props.data;
        return Boolean(Utils.getValue(list, "strechID", elemID, "link"));
    }

    /**
     * Returns true if it can Activate the Strech
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canActivate(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "strechID", elemID, "canActivate");
    }

    /**
     * Returns true if it can Cancel the Strech
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canCancel(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "strechID", elemID, "canCancel");
    }

    /**
     * Returns true if it can Delete the Strech
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canDelete(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "strechID", elemID, "canDelete");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                                  } = this.state;
        const { data, type, params, route, withDetails, isCoach } = this.props;
        const { canEdit, list, total, sort, loading             } = data;

        const elemName     = Utils.getValue(list, "strechID", elemID, "name");
        const forStudents  = type === "STUDENT";
        const forCoaches   = type === "COACH";
        const forStreches  = !forStudents && !forCoaches;
        const hideCreating = isCoach || forCoaches || forStudents;
        const hideProgram  = Boolean(params.programID);
        const createText   = forStudents ? "GENERAL_ENROLL" : (forCoaches ? "GENERAL_ASSIGN" : "GENERAL_CREATE");

        return <Main withDetails={withDetails}>
            <Header message="STRECHES_NAME" icon="strech" route={route}>
                <ActionList data={data} createText={createText} onAction={this.startAction} />
            </Header>
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="STRECHES_ACTIVE"   value="active"   />
                    <TabItem message="STRECHES_UPCOMING" value="upcoming" />
                    <TabItem message="STRECHES_ENDED"    value="ended"    />
                    <TabItem message="STRECHES_CREATING" value="creating" isHidden={hideCreating} />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="STRECHES_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="name"        message="GENERAL_NAME"      hideCircle={isCoach} grow="2" bigMobile />
                        <TableHeader field="programName" message="PROGRAMS_SINGULAR" isHidden={hideProgram} />
                        <TableHeader field="groupname"   message="GROUPS_SINGULAR"      maxWidth="80" />
                        <TableHeader field="mode"        message="GENERAL_MODE"      maxWidth="80" />
                        <TableHeader field="fromTime"    message="GENERAL_START"     maxWidth="90" />
                        <TableHeader field="toTime"      message="GENERAL_END"       maxWidth="90" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechID} elemID={elem.strechID}>
                            <TableCell message={elem.name}        circle={elem.statusColor} />
                            <TableCell message={elem.programName} />
                            <TableCell message={elem.groupName} />
                            <TableCell message={elem.modeName}    />
                            <TableCell message={elem.fromDate}    />
                            <TableCell message={elem.toDate}      />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"     message="STRECHES_VIEW_TITLE"     route={route} />
                        <TableAction action="URL"      message="STRECHES_VIEW_URL_TITLE" hide={(elemID) => !this.hasLink(elemID)} />
                        <TableAction action="EDIT"     message="STRECHES_EDIT_TITLE"     />
                        <TableAction action="ACTIVATE" message="STRECHES_ACTIVATE_TITLE" hide={(elemID) => !this.canActivate(elemID)} />
                        <TableAction action="CANCEL"   message="STRECHES_CANCEL_TITLE"   hide={(elemID) => !this.canCancel(elemID)} />
                        <TableAction action="DELETE"   message="STRECHES_DELETE_TITLE"   hide={(elemID) => !this.canDelete(elemID)} />
                    </TableActionList>
                </Table>
            </Content>

            <StrechEdit
                open={(forStreches && action.isCreate) || action.isEdit}
                elemID={elemID}
                courseID={params.courseID}
                programID={params.programID}
                locationID={params.locationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <InscriptionEdit
                open={forStudents && action.isCreate}
                elemID={elemID}
                credentialID={params.studentID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CoachEdit
                open={forCoaches && action.isCreate}
                elemID={elemID}
                credentialID={params.coachID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <StrechUrl
                open={action.isUrl}
                elemID={elemID}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isActivate}
                icon="check"
                title="STRECHES_ACTIVATE_TITLE"
                message="STRECHES_ACTIVATE_TEXT"
                content={elemName}
                onSubmit={this.activateElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isCancel}
                icon="cancel"
                title="STRECHES_CANCEL_TITLE"
                message="STRECHES_CANCEL_TEXT"
                content={elemName}
                onSubmit={this.cancelElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="STRECHES_DELETE_TITLE"
                message="STRECHES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <Downloader
                download={action.isExport}
                source={Strech.export()}
                onLoad={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStreches  : PropTypes.func.isRequired,
        activateStrech : PropTypes.func.isRequired,
        cancelStrech   : PropTypes.func.isRequired,
        deleteStrech   : PropTypes.func.isRequired,
        isCoach        : PropTypes.bool.isRequired,
        data           : PropTypes.object.isRequired,
        type           : PropTypes.string.isRequired,
        params         : PropTypes.object.isRequired,
        route          : PropTypes.string.isRequired,
        withDetails    : PropTypes.bool.isRequired,
        elemID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isCoach : state.auth.isCoach,
            data    : state.strech,
        };
    }
}

export default connect(StrechList.mapStateToProps, {
    fetchStreches, activateStrech, cancelStrech, deleteStrech,
})(StrechList);
