import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Dialogs
import ConversationView     from "./ConversationView";
import ConversationAssign   from "./ConversationAssign";
import ConversationCreate   from "./ConversationCreate";
import ConversationEdit     from "./ConversationEdit";
import ConversationProgram  from "./ConversationProgram";
import ConversationReport   from "./ConversationReport";
import ConversationCancel   from "./ConversationCancel";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchConversations, deleteConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation List
 */
class ConversationList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchConversations(type, elemID, params || data.sort);
    }

    /**
     * Fetch the tab content
     * @param {Number=} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        const orderBy  = Status.is("REQUESTED", filter) ? "createdTime" : "time";
        const orderAsc = Status.is("SCHEDULED", filter) ? 1 : 0;
        this.fetch({ ...this.props.data.sort, filter, page : 0, orderBy, orderAsc });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        const conversation = Utils.getValue(this.props.data.list, "conversationID", elemID);
        if (action.isStudent) {
            Href.goto(Common.getAccessBaseUrl(conversation, "user"), conversation.userID, "CONVERSATIONS");
        } else if (action.isCoach) {
            Href.goto("COACHES", conversation.coachID, "CONVERSATIONS");
        } else if (action.isStrech) {
            Href.goto("STRECHES", conversation.strechID, "CONVERSATIONS");
        } else if (action.isTab) {
            this.fetchTab(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @param {Number=} filter
     * @returns {Void}
     */
    endAction = (update, filter) => {
        this.startAction(Action.get(), 0);
        if (filter) {
            this.fetchTab(filter);
        } else if (update) {
            this.fetch();
        }
    }



    /**
     * Handles the Edit Submit
     * @param {Number=} filter
     * @returns {Void}
     */
    editElem = (filter) => {
        this.endAction(true, filter);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        await this.props.deleteConversation(elemID);
        this.endAction(true);
    }



    /**
     * Returns true if it can Edit the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEdit(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "conversationID", elemID).canEdit;
    }

    /**
     * Returns true if it can Program the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canProgram(elemID) {
        const { list, canProgram } = this.props.data;
        return canProgram && Utils.getValue(list, "conversationID", elemID).canProgram;
    }

    /**
     * Returns true if it can Report the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canReport(elemID) {
        const { list, canProgram } = this.props.data;
        return canProgram && Utils.getValue(list, "conversationID", elemID).canReport;
    }

    /**
     * Returns true if it can Cancel the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canCancel(elemID) {
        const { list, canProgram } = this.props.data;
        return canProgram && Utils.getValue(list, "conversationID", elemID).canCancel;
    }

    /**
     * Returns true if it can Delete the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canDelete(elemID) {
        const { list, canDelete } = this.props.data;
        return canDelete && Utils.getValue(list, "conversationID", elemID).canDelete;
    }

    /**
     * Returns true if it can Delete the Conversation
     * @param {Number}  elemID
     * @param {Boolean} showStrech
     * @returns {Boolean}
     */
    canShowStrech(elemID, showStrech) {
        const { list } = this.props.data;
        return showStrech && Utils.getValue(list, "conversationID", elemID, "strechID");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                                 } = this.state;
        const { data, type, params, route, withDetails         } = this.props;
        const { canAssign, canEdit, list, total, sort, loading } = data;

        const isRequested = Status.is("REQUESTED", sort.filter);
        const isAssigned  = Status.is("ASSIGNED", sort.filter);
        const useCreated  = isRequested || isAssigned;
        const showCoachee = type !== "STUDENT";
        const showCoach   = type !== "COACH" && type !== "USER";
        const showStrech  = type !== "STRECH";
        const showMode    = type !== "LOCATION" && !isRequested && !isAssigned;
        const elemName    = Utils.getValue(list, "conversationID", elemID, "description");

        return <Main withDetails={withDetails}>
            <Header message="CONVERSATIONS_NAME" icon="conversation" route={route}>
                <ActionList data={data} onAction={this.startAction}>
                    {canAssign && <ActionItem action="ASSIGN" icon="add" />}
                </ActionList>
            </Header>
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="CONVERSATIONS_REQUEST"   status="REQUESTED" />
                    <TabItem message="CONVERSATIONS_ASSIGNED"  status="ASSIGNED"  />
                    <TabItem message="CONVERSATIONS_PROGRAM"   status="SCHEDULED" />
                    <TabItem message="CONVERSATIONS_COMPLETED" status="COMPLETED" />
                    <TabItem message="CONVERSATIONS_REPORTED"  status="REPORTED"  />
                    <TabItem message="CONVERSATIONS_NREPORTED" status="NREPORTED" />
                    <TabItem message="CONVERSATIONS_CANCELLED" status="CANCELLED" />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CONVERSATIONS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="time"           message="GENERAL_DATE"          isHidden={useCreated}   maxWidth="140" />
                        <TableHeader field="createdTime"    message="GENERAL_DATE"          isHidden={!useCreated}  maxWidth="140" />
                        <TableHeader field="userFirstName"  message="CONVERSATIONS_COACHEE" isHidden={!showCoachee} />
                        <TableHeader field="coachFirstName" message="COACHES_SINGULAR"      isHidden={!showCoach}   />
                        <TableHeader field="strechName"     message="STRECHES_SINGULAR"     isHidden={!showStrech}  grow="2" />
                        <TableHeader field="specialityName" message="SPECIALITIES_SHORT"                            maxWidth="100" />
                        <TableHeader field="mode"           message="GENERAL_MODE"          isHidden={!showMode}    maxWidth="80" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.conversationID} elemID={elem.conversationID}>
                            <TableCell message={elem.theTime}        />
                            <TableCell message={elem.createdString}  />
                            <TableCell message={elem.userName}       />
                            <TableCell message={elem.coachString}    />
                            <TableCell message={elem.strechName}     />
                            <TableCell message={elem.specialityName} />
                            <TableCell message={elem.modeName}       />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"    message="CONVERSATIONS_VIEW_TITLE"    />
                        <TableAction action="STUDENT" message="CONVERSATIONS_VIEW_COACHEE"  isHidden={!showCoachee || !canEdit} />
                        <TableAction action="COACH"   message="COACHES_VIEW_TITLE"          isHidden={!showCoach || !canEdit} />
                        <TableAction action="STRECH"  message="STRECHES_VIEW_TITLE"         hide={(elemID) => !this.canShowStrech(elemID, showStrech)}  />
                        <TableAction action="PROGRAM" message="CONVERSATIONS_PROGRAM_TITLE" hide={(elemID) => !this.canProgram(elemID)} />
                        <TableAction action="EDIT"    message="CONVERSATIONS_EDIT_TITLE"    hide={(elemID) => !this.canEdit(elemID)}    />
                        <TableAction action="REPORT"  message="CONVERSATIONS_REPORT_TITLE"  hide={(elemID) => !this.canReport(elemID)}  />
                        <TableAction action="CANCEL"  message="CONVERSATIONS_CANCEL_TITLE"  hide={(elemID) => !this.canCancel(elemID)}  />
                        <TableAction action="DELETE"  message="CONVERSATIONS_DELETE_TITLE"  hide={(elemID) => !this.canDelete(elemID)}  />
                    </TableActionList>
                </Table>
            </Content>

            <ConversationView
                open={action.isView}
                elemID={elemID}
                strechID={params.strechID}
                locationID={params.locationID}
                onClose={this.endAction}
            />
            <ConversationAssign
                open={action.isAssign}
                userID={params.studentID}
                coachID={params.coachID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationCreate
                open={action.isCreate}
                userID={params.studentID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationEdit
                open={action.isEdit}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationProgram
                open={action.isProgram}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationReport
                open={action.isReport}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationCancel
                open={action.isCancel}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />

            <DeleteDialog
                open={action.isDelete}
                title="CONVERSATIONS_DELETE_TITLE"
                message="CONVERSATIONS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversations : PropTypes.func.isRequired,
        deleteConversation : PropTypes.func.isRequired,
        data               : PropTypes.object.isRequired,
        type               : PropTypes.string.isRequired,
        params             : PropTypes.object.isRequired,
        route              : PropTypes.string.isRequired,
        withDetails        : PropTypes.bool.isRequired,
        elemID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.conversation,
        };
    }
}

export default connect(ConversationList.mapStateToProps, {
    fetchConversations, deleteConversation,
})(ConversationList);
