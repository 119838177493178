import React                from "react";
import PropTypes            from "prop-types";
import Action               from "dashboard/dist/Core/Action";
import StatusData           from "Utils/Status";

// Sections
import HelpContent          from "Components/Admin/Ticket/Help/HelpContent";
import TicketContent        from "Components/Admin/Ticket/Ticket/TicketContent";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";



/**
 * The Support Page
 */
class SupportPage extends React.Component {
    // The Current State
    state = {
        filter     : "help",
        action     : Action.get(),
        selectedID : 0,
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} selectedID
     * @returns {Promise}
     */
    startAction = async (action, selectedID) => {
        if (action.isTab) {
            this.setState({ filter : selectedID });
        } else if (action.isCreate && this.state.filter === "help") {
            await this.setState({ filter : "open" });
            this.setState({ action, selectedID });
        } else {
            this.setState({ action, selectedID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { params, route              } = this.props;
        const { action, selectedID, filter } = this.state;

        const isHelp    = filter === "help";
        const isTickets = !isHelp;
        const status    = filter === "open" ? StatusData.OPEN : StatusData.CLOSED;

        return <Main>
            <Header message="SUPPORT_NAME" icon="support" route={route}>
                <ActionList onAction={this.startAction}>
                    <ActionItem action="CREATE" icon="create" />
                </ActionList>
            </Header>
            <Content>
                <TabList selected={filter} onAction={this.startAction}>
                    <TabItem message="HELP_NAME"         value="help"   />
                    <TabItem message="SUPPORT_OPEN_TAB"  value="open"   />
                    <TabItem message="SUPPORT_CLOSE_TAB" value="closed" />
                </TabList>
                {isHelp && <HelpContent
                    forSupport
                />}
                {isTickets && <TicketContent
                    startAction={this.startAction}
                    endAction={this.endAction}
                    type="ADMIN"
                    filter={status}
                    params={params}
                    action={action}
                    selectedID={selectedID}
                    forSupport
                />}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        params : PropTypes.object.isRequired,
        route  : PropTypes.string.isRequired,
        elemID : PropTypes.number,
    }
}

export default SupportPage;
