import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStudent }     from "Actions/Admin/User/StudentActions";

// Sections
import StrechList           from "Components/Admin/Strech/Strech/StrechList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";
import TicketList           from "Components/Admin/Ticket/Ticket/TicketList";
import LeadList             from "Components/Admin/Lead/Lead/LeadList";

// Components
import StudentDetails       from "./StudentDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Student Page
 */
class StudentPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchStudent(this.props.params.studentID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.studentID !== prevProps.params.studentID) {
            this.props.fetchStudent(this.props.params.studentID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.credentialName}
                fallback="STUDENTS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="STRECHES_NAME"      url="STRECHES"      icon="strech"       />
                <NavigationItem message="CONVERSATIONS_NAME" url="CONVERSATIONS" icon="conversation" />
                <NavigationItem message="TICKETS_NAME"       url="TICKETS"       icon="ticket"       />
                <NavigationItem message="LEADS_NAME"         url="LEADS"         icon="lead"       />
            </SecondaryNav>

            <SwitchRoute type="STUDENT" path={path} baseUrl={url} withDetails>
                <AdminRoute url="STRECHES"      component={StrechList}       />
                <AdminRoute url="CONVERSATIONS" component={ConversationList} />
                <AdminRoute url="TICKETS"       component={TicketList}       />
                <AdminRoute url="LEADS"         component={LeadList}       />
            </SwitchRoute>

            <StudentDetails credentialID={params.studentID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudent : PropTypes.func.isRequired,
        elem         : PropTypes.object.isRequired,
        from         : PropTypes.string.isRequired,
        params       : PropTypes.object.isRequired,
        match        : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.student.elem,
        };
    }
}

export default connect(StudentPage.mapStateToProps, {
    fetchStudent,
})(StudentPage);
