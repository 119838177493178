import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";

// Dialogs
import InvoiceSend          from "./InvoiceSend";
import InvoiceAttach        from "./InvoiceAttach";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import DialogAction         from "dashboard/dist/Components/DialogAction";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import ViewField            from "dashboard/dist/Components/ViewField";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";
import Html                 from "dashboard/dist/Components/Html";

// Actions
import {
    fetchInvoice, approveInvoice, rejectInvoice, paidInvoice,
} from "Actions/Admin/Coach/InvoiceActions";

// Styles
import "Styles/Components/App/Invoices.css";



/**
 * The Coach Invoice View Dialog
 */
class InvoiceView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        loading : false,
        update  : false,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchInvoice } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchInvoice(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        if (action.isDownload) {
            window.open(this.props.elem.fileUrl);
        } else {
            this.setState({ action });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Promise}
     */
    endAction = async (update) => {
        if (update !== undefined) {
            await this.props.fetchInvoice(this.props.elemID);
            this.setState({ action : Action.get(), update });
        } else {
            this.setState({ action : Action.get() });
        }
    }

    /**
     * Edits the Coach
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Approve Submit
     * @returns {Promise}
     */
    approveElem = async () => {
        await this.props.approveInvoice(this.props.elemID);
        this.endAction(true);
    }

    /**
     * Handles the Reject Submit
     * @returns {Promise}
     */
    rejectElem = async (reason) => {
        await this.props.rejectInvoice(this.props.elemID, reason);
        this.endAction(true);
    }

    /**
     * Handles the Paid Submit
     * @returns {Promise}
     */
    paidElem = async () => {
        await this.props.paidInvoice(this.props.elemID);
        this.endAction(true);
    }

    /**
     * Handles de Dialog Closing
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, elem, items } = this.props;
        const { action, loading   } = this.state;

        const buttonCount = (elem.file ? 1 : 0) + (elem.canSend ? 1 : 0) + (elem.canAttach ? 1 : 0) + (elem.canApprove ? 1 : 0) + (elem.canReject ? 1 : 0) + (elem.canPaid ? 1 : 0);
        const useMessage  = buttonCount < 3;

        return <>
            <Dialog open={open} onClose={this.handleClose} isLoading={loading}>
                <DialogHeader message="COACH_INVOICES_VIEW_TITLE" icon="invoice" />
                <DialogBody withSpacing>
                    <ViewField
                        label="STRECHES_SINGULAR"
                        value={elem.strechString}
                    />
                    <DialogMessage className="coach-invoices">
                        <h3>{NLS.get(elem.strechName ? "COACH_INVOICES_WITH" : "COACH_INVOICES_WITHOUT")}</h3>
                        <ul>
                            {items.map((elem) => <li key={elem.invoiceItemID}>
                                <Html>{elem.text}</Html>
                                <div><b>${elem.price}</b></div>
                            </li>)}
                        </ul>
                        <div>${elem.amount}</div>
                    </DialogMessage>
                    <ViewField
                        label="GENERAL_MESSAGE"
                        value={elem.message}
                    />
                    <ViewField
                        label="GENERAL_STATUS"
                        value={elem.statusString}
                        viewClass={elem.statusClass}
                    />
                </DialogBody>
                <DialogFooter onAction={this.startAction} cancel="GENERAL_CLOSE">
                    <DialogAction
                        isHidden={!elem.file}
                        action="DOWNLOAD"
                        message={useMessage ? "COACH_INVOICES_DOWNLOAD_TITLE" : ""}
                    />
                    <DialogAction
                        isHidden={!elem.canSend}
                        action="SEND"
                        message={useMessage ? "COACH_INVOICES_SEND_TITLE" : ""}
                    />
                    <DialogAction
                        isHidden={!elem.canAttach}
                        action="ATTACH"
                        message={useMessage ? "COACH_INVOICES_ATTACH_TITLE" : ""}
                    />
                    <DialogAction
                        isHidden={!elem.canApprove}
                        action="APPROVE"
                        message={useMessage ? "COACH_INVOICES_APPROVE_TITLE" : ""}
                    />
                    <DialogAction
                        isHidden={!elem.canReject}
                        action="REJECT"
                        message={useMessage ? "COACH_INVOICES_REJECT_TITLE" : ""}
                    />
                    <DialogAction
                        isHidden={!elem.canPaid}
                        action="PAID"
                        message={useMessage ? "COACH_INVOICES_PAID_TITLE" : ""}
                    />
                </DialogFooter>
            </Dialog>

            <InvoiceSend
                open={action.isSend}
                elemID={elem.invoiceID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <InvoiceAttach
                open={action.isAttach}
                elemID={elem.invoiceID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                icon="check"
                open={action.isApprove}
                title="COACH_INVOICES_APPROVE_TITLE"
                message="COACH_INVOICES_APPROVE_TEXT"
                onSubmit={this.approveElem}
                onClose={this.endAction}
            />
            <PromptDialog
                icon="cancel"
                open={action.isReject}
                title="COACH_INVOICES_REJECT_TITLE"
                message="COACH_INVOICES_REJECT_TEXT"
                inputLabel="COACH_INVOICES_REJECT_REASON"
                onSubmit={this.rejectElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                icon="check"
                open={action.isPaid}
                title="COACH_INVOICES_PAID_TITLE"
                message="COACH_INVOICES_PAID_TEXT"
                onSubmit={this.paidElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInvoice   : PropTypes.func.isRequired,
        approveInvoice : PropTypes.func.isRequired,
        rejectInvoice  : PropTypes.func.isRequired,
        paidInvoice    : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        onClose        : PropTypes.func.isRequired,
        edition        : PropTypes.number.isRequired,
        items          : PropTypes.array.isRequired,
        elem           : PropTypes.object.isRequired,
        elemID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.coachInvoice.edition,
            elem    : state.coachInvoice.elem,
            items   : state.coachInvoice.items,
        };
    }
}

export default connect(InvoiceView.mapStateToProps, {
    fetchInvoice, approveInvoice, rejectInvoice, paidInvoice,
})(InvoiceView);
