import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Campaign }         from "Utils/API";

// Types
import {
    CAMPAIGN, CAMPAIGN_LOADING, CAMPAIGN_CHARGING,
    CAMPAIGN_LIST, CAMPAIGN_ELEM, CAMPAIGN_SEND, CAMPAIGN_CONTENT,
} from "Utils/Types";



/**
 * Fetches the Campaign List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCampaigns(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CAMPAIGN_LOADING });
        }
        Params.unset(params);
        const data = await Campaign.getAll(params);
        data.sort = params;
        dispatch({ type : CAMPAIGN_LIST, data });
        dispatch(setSection(CAMPAIGN));
    };
}

/**
 * Fetches a single Campaign
 * @param {Number}   campaignID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCampaign(campaignID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CAMPAIGN_CHARGING });
        }
        const data = await Campaign.getOne({ campaignID });
        dispatch({ type : CAMPAIGN_ELEM, data });
    };
}

/**
 * Fetches the Campaign Send data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCampaignSend() {
    return async (dispatch) => {
        const data = await Campaign.getSendData();
        dispatch({ type : CAMPAIGN_SEND, data });
    };
}

/**
 * Fetches a single Campaign Content
 * @param {Number} campaignID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCampaignContent(campaignID) {
    return async (dispatch) => {
        dispatch({ type : CAMPAIGN_CHARGING });
        const data = await Campaign.getContent({ campaignID });
        dispatch({ type : CAMPAIGN_CONTENT, data });
    };
}



/**
 * Sends an Campaign
 * @param {Object} data
 * @returns {() => Void}
 */
export function sendCampaign(data) {
    return () => Campaign.send(data);
}

/**
 * Pauses an Campaign
 * @param {Number} campaignID
 * @returns {() => Void}
 */
export function pauseCampaign(campaignID) {
    return () => Campaign.pause({ campaignID });
}

/**
 * Deletes an Campaign
 * @param {Number} campaignID
 * @returns {() => Void}
 */
export function deleteCampaign(campaignID) {
    return () => Campaign.delete({ campaignID });
}
