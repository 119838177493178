import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Common               from "Utils/Common";

// Components
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Enroll Filters
 */
class EnrollFilters extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            streches, courses, countries, provinces, localities, isAuthenticated, isAdmin, isCoach,
            filters, course, onChange, className, labelInside,
        } = this.props;

        const isPresential = Common.isPresentialMode(filters.strechMode);
        const showFilter   = streches.length > 0;
        const showCourse   = !course;
        const showFor      = !course && (!isAuthenticated || (isAuthenticated && (isCoach || isAdmin)));
        const showAddress  = showFilter && isPresential;
        const columns      = 2 + (showCourse ? 1 : 0) + (showFor ? 1 : 0);

        return <header className={`enroll-filter ${className}`}>
            <div className={`enroll-filter-main enroll-filter-main-${columns}`}>
                <InputField
                    isHidden={!showCourse}
                    type="select"
                    name="courseID"
                    label="COURSES_SINGULAR"
                    value={filters.courseID}
                    options={courses}
                    noneText="GENERAL_ALL"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    shrinkLabel
                    noMargin
                />
                <InputField
                    type="select"
                    name="strechMode"
                    label="GENERAL_MODE"
                    value={filters.strechMode}
                    options="MODES"
                    noneText="GENERAL_ALL_FEM"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    shrinkLabel
                    noMargin
                />
                <InputField
                    type="select"
                    name="scheduleType"
                    label="ENROLL_SCHEDULE"
                    value={filters.scheduleType}
                    options="ENROLL_SCHEDULE_OPTIONS"
                    noneText="GENERAL_ALL"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    noMargin
                    shrinkLabel
                />
                <InputField
                    isHidden={!showFor}
                    type="select"
                    name="strechFor"
                    label="ENROLL_FOR"
                    value={filters.strechFor}
                    options="ENROLL_FOR_OPTIONS"
                    noneText="GENERAL_ALL"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    noMargin
                    shrinkLabel
                />
            </div>
            {showAddress && <div className="enroll-filter-sec">
                <InputField
                    type="select"
                    name="countryID"
                    label="COUNTRIES_SINGULAR"
                    value={filters.countryID}
                    options={countries}
                    noneText="GENERAL_ALL"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="provinceID"
                    label="PROVINCES_SINGULAR"
                    value={filters.provinceID}
                    options={filters.countryID ? provinces[filters.countryID] || [] : []}
                    noneText="GENERAL_ALL_FEM"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="localityID"
                    label="LOCALITIES_SINGULAR"
                    value={filters.localityID}
                    options={filters.countryID && filters.provinceID ? localities[filters.provinceID] || [] : []}
                    noneText="GENERAL_ALL_FEM"
                    labelInside={labelInside}
                    onChange={onChange}
                    withNone
                    noMargin
                    shrinkLabel
                />
            </div>}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        streches        : PropTypes.array.isRequired,
        courses         : PropTypes.array.isRequired,
        countries       : PropTypes.array.isRequired,
        provinces       : PropTypes.object.isRequired,
        localities      : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        isAdmin         : PropTypes.bool.isRequired,
        isCoach         : PropTypes.bool.isRequired,
        filters         : PropTypes.object.isRequired,
        course          : PropTypes.string,
        onChange        : PropTypes.func.isRequired,
        className       : PropTypes.string,
        labelInside     : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        labelInside : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            streches        : state.enroll.streches,
            courses         : state.enroll.courses,
            countries       : state.enroll.countries,
            provinces       : state.enroll.provinces,
            localities      : state.enroll.localities,
            isAuthenticated : state.auth.isAuthenticated,
            isAdmin         : state.auth.isAdmin,
            isCoach         : state.auth.isCoach,
        };
    }
}

export default connect(EnrollFilters.mapStateToProps)(EnrollFilters);
