import { setSection }        from "Actions/Core/CoreActions";
import { PollReport }        from "Utils/API";

// Types
import {
    POLL_REPORT, POLL_REPORT_LOADING,
    POLL_REPORT_DATA, POLL_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Poll Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchPollData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : POLL_REPORT_LOADING });
        }
        const data = await PollReport.getData(params);
        dispatch({ type : POLL_REPORT_DATA, data });
        dispatch(setSection(POLL_REPORT));
    };
}

/**
 * Fetches the Poll Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchPollGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : POLL_REPORT_LOADING });
        }
        const data = await PollReport.getGraph(params);
        dispatch({ type : POLL_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Poll Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchPollEmails(params = {}) {
    return async () => {
        return await PollReport.getEmails(params);
    };
}
