import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Components
import TicketTypeContent    from "Components/Admin/Ticket/TicketType/TicketTypeContent";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";



/**
 * The Support Type List
 */
class SupportTypeList extends React.Component {
     // The Current State
     state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, route    } = this.props;
        const { action, elemID } = this.state;

        return <Main>
            <Header message="SUPPORT_TYPES_NAME" icon="type" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <TicketTypeContent
                    startAction={this.startAction}
                    endAction={this.endAction}
                    action={action}
                    elemID={elemID}
                    forSupport
                />
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data  : PropTypes.object.isRequired,
        route : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.ticketType,
        };
    }
}

export default connect(SupportTypeList.mapStateToProps)(SupportTypeList);
