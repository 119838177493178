const data = {
    NONE                    : "",

    // Auth Urls
    AUTH                    : "home",
    AUTH_CODE               : "home/:code",
    INSCRIPTION             : "inscription",
    INSC_NONE_STRECH        : "inscription/strech/:strechID/:code",
    INSC_NONE_CHAT          : "inscription/chat/:chatID/:code",
    INSC_COURSE             : "inscription/:course",
    INSC_COURSE_STRECH      : "inscription/:course/strech/:strechID/:code",
    INSC_COURSE_CHAT        : "inscription/:course/chat/:chatID/:code",
    INSC_STRECH_ONLY        : "inscription/course",
    INSC_STRECH             : "inscription/course/:strechID",
    INSC_STRECH_ACCESS      : "inscription/course/:strechID/access",
    INSC_STRECH_STRECH      : "inscription/course/:strechID/:code",
    INSC_STRECH_CHAT        : "inscription/course/:strechID/chat/:chatID/:code",
    ASSIGNME                : "asignme",
    ASSIGNME_STRECH         : "asignme/:strechID",
    ASSIGNME_CODE           : "asignme/:strechID/:code",


    // User Urls
    WELCOME                 : "home",
    HOME                    : "home",
    ENROLL                  : "enroll",
    CALENDAR                : "calendar",

    COURSES_MINE            : "my-courses",
    COURSE_MINE             : "my-courses/:strechID",
    COURSE_MINE_CLASS       : "my-courses/:strechID/classes/:classID",
    CLASS_MINE              : "clases/:classID",
    CLASS_MINE_GUIDE        : "clases/:classID/guide/:guideID",


    // Admin Urls
    DASHBOARD               : "home",
    DASHBOARD_SIGNUPS       : "home/signups",
    DASHBOARD_INSCRIPTIONS  : "home/inscriptions",
    SUPPORT                 : "support",


    // Course Urls
    COURSES                 : "courses",
    COURSE                  : "courses/:courseID",
    COURSE_GUIDE            : "courses/:courseID/guides/:guideID",
    COURSE_PROGRAM          : "courses/:courseID/programs/:programID",
    COURSE_PROGRAM_STRECH   : "courses/:courseID/programs/:programID/streches/:strechID",
    COURSE_PROGRAM_CLASS    : "courses/:courseID/programs/:programID/streches/:strechID/classes/:classID",
    COURSE_PROGRAM_GROUP    : "courses/:courseID/programs/:programID/streches/:strechID/groups/:groupID",
    COURSE_STRECH           : "courses/:courseID/streches/:strechID",
    COURSE_STRECH_CLASS     : "courses/:courseID/streches/:strechID/classes/:classID",
    COURSE_STRECH_GROUP     : "courses/:courseID/streches/:strechID/groups/:groupID",


    // Program Urls
    PROGRAMS                : "programs",
    PROGRAM                 : "programs/:programID",
    PROGRAM_STRECH          : "programs/:programID/streches/:strechID",
    PROGRAM_STRECH_CLASS    : "programs/:programID/streches/:strechID/classes/:classID",
    PROGRAM_STRECH_GROUP    : "programs/:programID/streches/:strechID/groups/:groupID",

    PRICES                  : "prices",
    CLASSES                 : "classes",
    VIDEOS                  : "videos",
    POLL                    : "poll",
    POLLS                   : "polls",


    // Strech Urls
    STRECHES                : "streches",
    STRECH                  : "streches/:strechID",
    STRECH_CLASS            : "streches/:strechID/classes/:classID",
    STRECH_GROUP            : "streches/:strechID/groups/:groupID",

    SCHEDULES               : "schedules",
    CHATS                   : "chats",
    INSCRIPTIONS            : "inscriptions",
    REQUESTS                : "requests",
    SPECIALITIES            : "specialities",
    ASSIGNMENTS             : "assignments",
    PRESENTISM              : "presentism",
    CERTIFICATE             : "certificate",


    // Conversation Urls
    CONVERSATIONS           : "conversations",
    CONVERSATIONS_MINE      : "my-conversations",


    // Group Urls
    GROUPS                  : "groups",
    GROUP                   : "groups/:groupID",
    GROUP_MINE              : "group",

    MEETINGS                : "meetings",


    // Billing Urls
    INVOICES                : "invoices",
    INVOICES_INVOICE        : "invoices/:invoiceID",


    // Campaign Urls
    CAMPAIGNS               : "campaigns",
    CAMPAIGNS_CAMPAIGN      : "campaigns/:campaignID",

    USERS                   : "users",
    CONTENT                 : "content",


    // Report Urls
    REPORTS                 : "reports",


    // Tickets Urls
    TICKETS                 : "queries",
    TICKETS_TICKET          : "queries/:ticketID",
    TICKETS_CREATE          : "queries/create",

    HELP                    : "help",


    // Lead Urls
    LEADS                   : "leads",
    LEADS_LEAD              : "leads/:leadID",


    // Guide Urls
    GUIDES                  : "guides",
    GUIDE                   : "guides/:guideID",

    SECTIONS                : "sections",
    QUESTIONS               : "questions",


    // Material Urls
    MATERIALS               : "materials",
    CATEGORIES              : "categories",
    LIBRARY                 : "library",


    // Student Urls
    STUDENTS                : "students",
    STUDENT                 : "students/:studentID",
    STUDENT_STRECH          : "students/:studentID/streches/:strechID",
    STUDENT_STRECH_CLASS    : "students/:studentID/streches/:strechID/class/:classID",
    STUDENT_STRECH_GROUP    : "students/:studentID/streches/:strechID/groups/:groupID",


    // Coach Urls
    COACHES                 : "coaches",
    COACH                   : "coaches/:coachID",
    COACH_GROUP             : "coaches/:coachID/groups/:groupID",
    COACH_STRECH            : "coaches/:coachID/streches/:strechID",
    COACH_STRECH_CLASS      : "coaches/:coachID/streches/:strechID/class/:classID",
    COACH_STRECH_GROUP      : "coaches/:coachID/streches/:strechID/groups/:groupID",


    // Location Urls
    LOCATION_SINGLE         : "configuracion/location/locations",
    LOCATION                : "settings/location/locations/:locationID",
    LOCATION_STRECH         : "settings/location/locations/:locationID/streches/:strechID",
    LOCATION_STRECH_CLASS   : "settings/location/locations/:locationID/streches/:strechID/class/:classID",
    LOCATION_STRECH_GROUP   : "settings/location/locations/:locationID/streches/:strechID/groups/:groupID",

    ROOMS                   : "rooms",


    // Settings Urls
    SETTINGS                : "settings",
    SETTINGS_GENERAL        : "general",

    ADMINS                  : "general/admins",
    INTERESTS               : "general/interests",
    GROUPINTERESTS          : "general/groupinterests",
    STAFF                   : "general/staff",
    NEWS                    : "general/news",
    ADVICES                 : "general/advices",

    SETTINGS_STRECHES       : "streches",
    PAYMENT_METHODS         : "streches/payments",
    MATERIAL_CATEGORIES     : "streches/categories",
    CANCEL_REASONS          : "streches/reasons",

    SETTINGS_COACHES        : "coaches",
    COACHES_SPECIALITIES    : "coaches/specialities",
    COACHES_CATEGORIES      : "coaches/categories",
    COACHES_QUESTIONS       : "coaches/questions",
    COACHES_MATERIALS       : "coaches/materials",
    COACHES_PROFESION       : "coaches/profesions",

    SETTINGS_TICKETS        : "queries",
    TICKETS_HELP            : "queries/help",
    TICKETS_TYPES           : "queries/types",

    SETTINGS_SUPPORT        : "support",
    SUPPORT_HELP            : "support/help",
    SUPPORT_TYPES           : "support/types",

    SETTINGS_LEADS          : "leads",
    LEADS_ORIGINS           : "leads/origins",
    LEADS_ASSIGNMENTS       : "leads/assignments",
    LEADS_REASONS           : "leads/reasons",

    SETTINGS_LOCATIONS      : "location",
    LOCATIONS               : "location/locations",
    COUNTRIES               : "location/countries",
    PROVINCES               : "location/provinces",
    LOCALITIES              : "location/localities",

    SETTINGS_EMAILS         : "emails",
    EMAIL_TEMPLATES         : "emails/templates",
    EMAIL_QUEUE             : "emails/queue",
    EMAIL_WHITE_LIST        : "emails/emails",

    SETTINGS_EXTRAS         : "extras",
    MEDIA                   : "extras/files",
    ACTIONS                 : "extras/actions",
    ERRORS                  : "extras/errors",


    // Profile Urls
    PROFILE                 : "profile",
    PROFILE_SETTINGS        : "profile/settings",
};

export default data;
