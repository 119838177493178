import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Interest }         from "Utils/API";

// Types
import {
    INTEREST, INTEREST_LOADING,
    INTEREST_LIST, INTEREST_ELEM, INTEREST_CREATE,
} from "Utils/Types";



/**
 * Fetches the Interests List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInterests(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INTEREST_LOADING });
        }
        Params.unset(params);
        const data = await Interest.getAll(params);
        data.sort = params;
        dispatch({ type : INTEREST_LIST, data });
        dispatch(setSection(INTEREST));
    };
}

/**
 * Fetches a single Interest
 * @param {Number} interestID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInterest(interestID) {
    return async (dispatch) => {
        const data = await Interest.getOne({ interestID });
        dispatch({ type : INTEREST_ELEM, data });
    };
}

/**
 * Fetches the Interest Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInterestCreate() {
    return async (dispatch) => {
        const data = await Interest.getCreateData();
        dispatch({ type : INTEREST_CREATE, data });
    };
}



/**
 * Edits a Interest
 * @param {Object} data
 * @returns {() => Void}
 */
export function editInterest(data) {
    return () => Interest.edit(data);
}

/**
 * Deletes a Interest
 * @param {Number} interestID
 * @returns {() => Void}
 */
export function deleteInterest(interestID) {
    return () => Interest.delete({ interestID });
}
