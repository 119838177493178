import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachCategory }    from "Utils/API";

// Types
import {
    COACH_CATEGORY, COACH_CATEGORY_LOADING,
    COACH_CATEGORY_LIST, COACH_CATEGORY_ELEM,
} from "Utils/Types";



/**
 * Fetches the Coach Category List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategories(params = {}) {
    return async (dispatch) => {
        dispatch({ type : COACH_CATEGORY_LOADING });
        Params.unset(params);
        const data = await CoachCategory.getAll(params);
        data.sort = params;
        dispatch({ type : COACH_CATEGORY_LIST, data });
        dispatch(setSection(COACH_CATEGORY));
    };
}

/**
 * Fetches a single Coach Category
 * @param {Number} coachCategoryID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategory(coachCategoryID) {
    return async (dispatch) => {
        const data = await CoachCategory.getOne({ coachCategoryID });
        dispatch({ type : COACH_CATEGORY_ELEM, data });
    };
}



/**
 * Edits a Coach Category
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCategory(data) {
    return () => CoachCategory.edit(data);
}

/**
 * Deletes a Coach Category
 * @param {Number} coachCategoryID
 * @returns {() => Void}
 */
export function deleteCategory(coachCategoryID) {
    return () => CoachCategory.delete({ coachCategoryID });
}
