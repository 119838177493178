import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STRECH_ASSIGNMENT_LOADING,
    STRECH_ASSIGNMENT_LIST, STRECH_ASSIGNMENT_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading       : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    name          : "",
    inscriptionID : 0,
    assignments   : [],
    specialities  : [],
    coaches       : {},
    sort          : {
        orderBy  : "student",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.conversationsText = Common.parseConvLimit(elem.conversations);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_ASSIGNMENT_LIST, STRECH_ASSIGNMENT_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_ASSIGNMENT_LOADING:
        return {
            ...state,
            loading       : true,
        };

    case STRECH_ASSIGNMENT_LIST:
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : parseList(action.data.list),
            total         : action.data.total,
            specialities  : action.data.specialities,
            sort          : action.data.sort,
        };

    case STRECH_ASSIGNMENT_ELEM:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            name          : action.data.name,
            inscriptionID : action.data.inscriptionID,
            assignments   : action.data.assignments,
            specialities  : action.data.specialities,
            coaches       : action.data.coaches,
        };

    default:
        return state;
    }
};

export default reducer;
