import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Common               from "Utils/Common";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";

// Actions
import {
    fetchConversationCreate, fetchConversationStudents, createConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation Create Dialog
 */
class ConversationCreate extends React.Component {
    // The Initial Data
    initialData = {
        strechID       : 0,
        specialityID   : 0,
        userID         : 0,
        mode           : 0,
        streamLink     : "",
        streamPassword : "",
        locationID     : 0,
        roomID         : 0,
        customLocation : "",
        timeDate       : "",
        timeHour       : "",
        observations   : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {
            strechID     : "",
            specialityID : "",
            userID       : "",
        },
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, userID, strechID, specialityID, fetchConversationCreate } = this.props;

        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            fetchConversationCreate(strechID);
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData, userID, strechID, specialityID };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data   = { ...this.state.data,   [name] : value };
        const errors = { ...this.state.errors, [name] : ""    };

        if (name === "strechID") {
            data.specialityID   = 0;
            data.userID         = 0;
            errors.specialityID = "";
            errors.userID       = "";
        } else if (name === "specialityID") {
            data.userID   = 0;
            errors.userID = "";
            this.props.fetchConversationStudents(data.strechID, value);
        }
        this.setState({ data, errors });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { streches, createConversation, onSubmit } = this.props;
        const { data, loading                          } = this.state;

        if (!loading && streches.length > 0) {
            this.setState({ loading : true, errors : {} });
            try {
                await createConversation(data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            open, onClose, streches, specialities,
            students, locations, rooms, userID, strechID, specialityID,
        } = this.props;
        const { data, loading, errors } = this.state;

        const canCreate    = streches.length > 0;
        const isPresential = Common.isPresentialMode(data.mode);
        const isVirtual    = Common.isVirtualMode(data.mode);
        const isCustom     = Common.isCustomLocation(data.locationID);

        return <EditDialog
            open={open}
            icon="conversation"
            title="CONVERSATIONS_CREATE_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            hidePrimary={!canCreate}
            cancel={!canCreate ? "GENERAL_CLOSE" : "GENERAL_CANCEL"}
        >
            {!canCreate ? <DialogMessage
                message="CONVERSATIONS_CANT_CREATE"
                noSpace
            /> : <>
                <InputField
                    isHidden={!!strechID}
                    type="select"
                    name="strechID"
                    label="CONVERSATIONS_STRECH"
                    value={data.strechID}
                    error={errors.strechID}
                    onChange={this.handleChange}
                    options={streches}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={Boolean(specialityID || !data.strechID)}
                    type="select"
                    name="specialityID"
                    label="SPECIALITIES_SHORT"
                    value={data.specialityID}
                    error={errors.specialityID}
                    onChange={this.handleChange}
                    options={specialities[data.strechID]}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={!data.specialityID || !!userID}
                    type="select"
                    name="userID"
                    label="STUDENTS_SINGULAR"
                    value={data.userID}
                    error={errors.userID}
                    options={students}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />

                <InputField
                    type="select"
                    name="mode"
                    label="CONVERSATIONS_MODE"
                    value={data.mode}
                    error={errors.mode}
                    onChange={this.handleChange}
                    options="MODES"
                    withNone
                    isRequired
                />
                <Columns>
                    <InputField
                        isHidden={!isVirtual}
                        name="streamLink"
                        label="GENERAL_STREAM_LINK"
                        value={data.streamLink}
                        error={errors.streamLink}
                        onChange={this.handleChange}
                    />
                    <InputField
                        isHidden={!isVirtual}
                        name="streamPassword"
                        label="GENERAL_STREAM_PASSWORD"
                        value={data.streamPassword}
                        error={errors.streamPassword}
                        onChange={this.handleChange}
                    />

                    <InputField
                        isHidden={!isPresential}
                        type="select"
                        name="locationID"
                        label="CONVERSATIONS_LOCATION"
                        value={data.locationID}
                        error={errors.locationID}
                        onChange={this.handleChange}
                        options={locations}
                        withNone
                        withCustom
                        isRequired
                    />
                    <InputField
                        isHidden={!isPresential || isCustom}
                        type="select"
                        name="roomID"
                        label="CONVERSATIONS_ROOM"
                        value={data.roomID}
                        error={errors.roomID}
                        onChange={this.handleChange}
                        options={data.locationID > 0 ? rooms[data.locationID] || [] : []}
                        withNone
                        isRequired
                    />
                    <InputField
                        isHidden={!isPresential || !isCustom}
                        name="customLocation"
                        label="CONVERSATIONS_LOCATION_CUSTOM"
                        value={data.customLocation}
                        error={errors.customLocation}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <InputField
                        type="date"
                        name="timeDate"
                        label="CONVERSATIONS_DATE"
                        value={data.timeDate}
                        error={errors.timeDate}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <InputField
                        type="time"
                        name="timeHour"
                        label="CONVERSATIONS_HOUR"
                        value={data.timeHour}
                        error={errors.timeHour}
                        onChange={this.handleChange}
                        isRequired
                    />
                </Columns>
                <InputField
                    type="textarea"
                    name="observations"
                    label="GENERAL_OBSERVATIONS"
                    helperText="CONVERSATIONS_OBSERVATIONS"
                    value={data.observations}
                    onChange={this.handleChange}
                />
            </>}
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversationCreate   : PropTypes.func.isRequired,
        fetchConversationStudents : PropTypes.func.isRequired,
        createConversation        : PropTypes.func.isRequired,
        open                      : PropTypes.bool.isRequired,
        onClose                   : PropTypes.func.isRequired,
        onSubmit                  : PropTypes.func.isRequired,
        edition                   : PropTypes.number.isRequired,
        streches                  : PropTypes.array.isRequired,
        specialities              : PropTypes.object.isRequired,
        students                  : PropTypes.array.isRequired,
        locations                 : PropTypes.array.isRequired,
        rooms                     : PropTypes.object.isRequired,
        userID                    : PropTypes.number,
        strechID                  : PropTypes.number,
        specialityID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.conversation.edition,
            streches     : state.conversation.streches,
            specialities : state.conversation.specialities,
            students     : state.conversation.students,
            locations    : state.conversation.locations,
            rooms        : state.conversation.rooms,
        };
    }
}

export default connect(ConversationCreate.mapStateToProps, {
    fetchConversationCreate, fetchConversationStudents, createConversation,
})(ConversationCreate);
