import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGroup }       from "Actions/Admin/Strech/GroupActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";



/**
 * The Group View Dialog
 */
class GroupView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchGroup } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchGroup(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading             } = this.state;

        const members = elem && elem.members ? elem.members : [];

        return <ViewDialog
            open={open}
            icon="group"
            title={elem.name || "GROUPS_VIEW_TITLE"}
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField
                label="COACHES_SINGULAR"
                value={elem.credentialName}
            />
            <DialogMessage isHidden={!members.length}>
                <h3>{NLS.get("GROUPS_MEMBERS")}</h3>
                <ul>
                    {members.map((elem) => <li key={elem.credentialID}>
                        {elem.credentialName}
                    </li>)}
                </ul>
            </DialogMessage>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroup : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        edition    : PropTypes.number.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.strechGroup.edition,
            elem    : state.strechGroup.elem,
        };
    }
}

export default connect(GroupView.mapStateToProps, {
    fetchGroup,
})(GroupView);
