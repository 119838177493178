// The History Actions
const NONE    = 0;
const CREATE  = 1;
const EDIT    = 2;
const ASSIGN  = 3;
const CLOSE   = 4;
const OPEN    = 5;
const MESSAGE = 6;



// The public API
const actions = {
    NONE,
    CREATE,
    EDIT,
    ASSIGN,
    CLOSE,
    OPEN,
    MESSAGE,
};

export default actions;
