import React                from "react";
import PropTypes            from "prop-types";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Password Field
 */
class PasswordField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, isNew, isRequired, onChange } = this.props;

        return <Columns amount="2">
            <InputField
                type="password"
                name="newPassword"
                label={isNew ? "USERS_PASSWORD" : "USERS_NEW_PASSWORD"}
                value={data.newPassword}
                error={errors.newPassword}
                onChange={onChange}
                autoComplete="new-password"
                isRequired={isRequired && isNew}
            />
            <InputField
                type="password"
                name="confirmPassword"
                label="USERS_PASSWORD_CONFIRM"
                value={data.confirmPassword}
                error={errors.confirmPassword}
                onChange={onChange}
                autoComplete="new-password"
                isRequired={isRequired && isNew}
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.object.isRequired,
        errors     : PropTypes.object.isRequired,
        onChange   : PropTypes.func.isRequired,
        isNew      : PropTypes.bool,
        isRequired : PropTypes.bool,
    }
}

export default PasswordField;
