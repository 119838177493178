import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";
import StrechStep           from "Utils/Entity/StrechStep";

// Components
import StrechEdit           from "./StrechEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Button               from "dashboard/dist/Components/Button";
import Icon                 from "dashboard/dist/Components/Icon";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchStrech, activateStrech,
} from "Actions/Admin/Strech/StrechActions";

// Styles
import "Styles/Components/App/Creating.css";



/**
 * The Strech Creating Page
 */
class StrechCreating extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Step Click
     * @param {Object} item
     * @returns {Void}
     */
    handleClick = (item) => {
        if (item.key === StrechStep.STRECH) {
            this.startAction(Action.create("EDIT"));
        } else if (item.key === StrechStep.ACTIVATE) {
            this.startAction(Action.create("ACTIVATE"));
        }
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.props.fetchStrech(this.props.elem.strechID);
    }

    /**
     * Handles the Activate Submit
     * @returns {Promise}
     */
    activateElem = async () => {
        const { elem, from, activateStrech, fetchStrech } = this.props;
        await activateStrech(elem.strechID);
        await fetchStrech(elem.strechID);
        Href.goto(from, elem.strechID, "COACHES");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action                } = this.state;
        const { route, charging, elem } = this.props;

        const list = StrechStep.getList(elem);

        return <Main>
            <Header message="STRECHES_CREATING_TITLE" icon="strech" route={route} />
            <Content isLoading={charging}>
                <ol className="creating-list no-list">
                    {list.map((elem) => <li key={elem.key} className={elem.className}>
                        <div className="creating-step">
                            {elem.isCompleted ? <Icon icon="check" /> : elem.step}
                        </div>
                        <div className="creating-content">
                            <h3>{NLS.get(elem.title)}</h3>
                            {!!elem.help && <p>{elem.help}</p>}
                            {elem.isActive && <Button
                                variant="primary"
                                message={elem.title}
                                onClick={() => this.handleClick(elem)}
                                url={elem.url}
                            />}
                        </div>
                    </li>)}
                </ol>
            </Content>

            <StrechEdit
                open={action.isEdit}
                elemID={elem.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
                toActivate
            />
            <ConfirmDialog
                open={action.isActivate}
                icon="check"
                title="STRECHES_ACTIVATE_TITLE"
                message="STRECHES_ACTIVATE_TEXT"
                content={elem.name}
                onSubmit={this.activateElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech    : PropTypes.func.isRequired,
        activateStrech : PropTypes.func.isRequired,
        charging       : PropTypes.bool.isRequired,
        elem           : PropTypes.object.isRequired,
        route          : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.strech.charging,
            elem     : state.strech.elem,
        };
    }
}

export default connect(StrechCreating.mapStateToProps, {
    fetchStrech, activateStrech,
})(StrechCreating);
