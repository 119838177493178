import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "dashboard/dist/Core/Href";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EmailStep            from "./Steps/EmailStep";
import PasswordStep         from "./Steps/PasswordStep";
import ResetPassStep        from "./Steps/ResetPassStep";
import DataStep             from "./Steps/DataStep";
import CourseStep           from "./Steps/CourseStep";
import EnrollStep           from "./Enroll/EnrollStep";
import OptionsStep          from "./Enroll/OptionsStep";
import SuccessStep          from "./Enroll/SuccessStep";
import ErrorStep            from "./Enroll/ErrorStep";

// Actions
import {
    setData, setCourse,
} from "Actions/Core/AuthActions";
import {
    fetchEnroll, enrollToStrech, enrollToChat,
} from "Actions/Student/EnrollActions";



/**
 * The Enroll Page
 */
class EnrollPage extends React.Component {
    // The Current State
    state = {
        step     : "enroll",
        strech   : {},
        chats    : null,
        options  : {},
        email    : "",
        password : "",
        code     : "",
        error    : null,
        loading  : false,
        inCode   : false,
    }

    /**
     * Goes the Reset Step if required
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, streches, match } = this.props;
        const { strechID, chatID, code  } = match.params;

        const accessUrl = NLS.baseUrl("INSC_STRECH_ACCESS");
        const strech    = strechID ? Utils.getValue(streches, "strechID", strechID) : null;

        if (match.path === accessUrl && prevProps.loaded !== loaded) {
            this.handleEnroll(strech, null, {});
        } else if ((strechID || chatID) && code && prevProps.loaded !== loaded) {
            if (chatID) {
                const chatIDs = chatID.split("-").map((chatID) => Number(chatID));
                const chats   = {};
                let   found   = false;
                for (const strech of streches) {
                    for (const chat of strech.chats) {
                        if (chatIDs.includes(chat.strechChatID)) {
                            chats[chat.strechChatID] = 1;
                            found = true;
                        }
                    }
                    if (found) {
                        this.setState({ step : "reset", code, strech, chats, inCode : true });
                        break;
                    }
                }
            } else if (strech) {
                this.setState({ step : "reset", code, strech, inCode : true });
            }
        }
    }

    /**
     * Handles the Submit
     * @param {Object} data
     * @returns {Void}
     */
    handleSubmit = (data) => {
        const { setData                                       } = this.props;
        const { step, strech, chats, options, email, password } = data;

        let set = true;
        switch (step) {
        case "select":
            set = false;
            this.handleEnroll(strech, chats, {});
            break;
        case "options":
            set = false;
            this.handleEnroll(this.state.strech, this.state.chats, options);
            break;
        case "enter":
            set = false;
            this.handleEnroll(this.state.strech, this.state.chats, this.state.options);
            break;
        case "confirm":
            set = false;
            Href.goto("HOME");
            break;
        case "data":
            setData(data);
            break;
        case "course":
            setCourse(data);
            break;
        case "enroll":
            if (this.state.inCode) {
                set = false;
                Href.goto("INSCRIPTION");
            }
            break;
        default:
        }

        if (set) {
            this.setState({ step, email, password });
        }
    }

    /**
     * Handles the Enroll
     * @param {Object} strech
     * @param {Object} chats
     * @param {Object} options
     * @returns {Promise}
     */
    handleEnroll = async (strech, chats, options) => {
        const { isAuthenticated, isAdmin, enrollToStrech, enrollToChat } = this.props;
        let step  = "email";
        let error = null;

        if (Utils.isEmpty(options) && strech.canConverse) {
            step = "options";
        } else if (isAdmin) {
            step  = "error";
            error = "ENROLL_ERROR_ADMIN";
        } else if (isAuthenticated) {
            try {
                if (!chats) {
                    await enrollToStrech(strech.strechID, options);
                } else {
                    await enrollToChat(strech.strechID, chats);
                }
                step = "success";
            } catch (errors) {
                step  = "error";
                error = errors.form;
            }
        }
        this.setState({ step, strech, chats, options, error });
    }

    /**
     * Returns the Send Data to create the code url
     * @returns {Object}
     */
    getSendData() {
        const { course, strechID } = this.props.match.params;
        const { strech, chats    } = this.state;

        const result = { forEnroll : 1 };
        if (course) {
            result.course = course;
        }
        if (strechID) {
            result.strechID = Number(strechID);
        }
        if (!strechID && strech) {
            result.selectedStrech = strech.strechID;
        }
        if (chats) {
            result.selectedChats = JSON.stringify(Object.keys(chats));
        }
        return result;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { match                                             } = this.props;
        const { step, strech, chats, email, password, code, error } = this.state;

        const sendData = this.getSendData();
        const hasChat  = Boolean(sendData.selectedChats);

        switch (step) {
        case "enroll":
            return <EnrollStep
                course={sendData.course}
                strechID={sendData.strechID}
                onSubmit={this.handleSubmit}
            />;
        case "options":
            return <OptionsStep
                strech={strech}
                onSubmit={this.handleSubmit}
            />;

        case "email":
            return <EmailStep
                title="ENROLL_NAME"
                help={NLS.format(hasChat ? "ENROLL_CHAT_HELP" : "ENROLL_STRECH_HELP", strech.title)}
                link="ENROLL_BACK"
                prevStep="enroll"
                sendData={sendData}
                onSubmit={this.handleSubmit}
            />;
        case "password":
            return <PasswordStep
                title="ENROLL_NAME"
                email={email}
                sendData={sendData}
                onSubmit={this.handleSubmit}
            />;
        case "reset":
            return <ResetPassStep
                title="ENROLL_NAME"
                email={email}
                code={code}
                match={match}
                onSubmit={this.handleSubmit}
            />;
        case "data":
            return <DataStep
                title="ENROLL_NAME"
                email={email}
                password={password}
                onSubmit={this.handleSubmit}
            />;
        case "course":
            return <CourseStep
                title="ENROLL_NAME"
                onSubmit={this.handleSubmit}
            />;

        case "success":
            return <SuccessStep
                strechName={strech.title}
                isFree={strech.isFree}
                chats={chats}
                onSubmit={this.handleSubmit}
            />;
        case "error":
            return <ErrorStep
                strechName={strech.title}
                toChat={!!chats}
                error={error}
                onSubmit={this.handleSubmit}
            />;

        default:
        }
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setData         : PropTypes.func.isRequired,
        setCourse       : PropTypes.func.isRequired,
        fetchEnroll     : PropTypes.func.isRequired,
        enrollToStrech  : PropTypes.func.isRequired,
        enrollToChat    : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        isAdmin         : PropTypes.bool.isRequired,
        loaded          : PropTypes.bool.isRequired,
        streches        : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            isAdmin         : state.auth.isAdmin,
            loaded          : state.enroll.loaded,
            streches        : state.enroll.streches,
        };
    }
}

export default connect(EnrollPage.mapStateToProps, {
    setData, setCourse,
    fetchEnroll, enrollToStrech, enrollToChat,
})(EnrollPage);
