import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "dashboard/dist/Core/Href";
import Common               from "Utils/Common";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Meeting View Dialog
 */
class MeetingView extends React.Component {
    /**
     * Handles the View Link
     * @return {Void}
     */
    handleView = () => {
        const { data } = this.props.elem;
        let url;
        if (data.asCoach) {
            url = [ "STRECHES", data.strechID, "GROUPS", data.groupID ];
        } else {
            url = [ "COURSES_MINE", data.strechID ];
        }
        Href.goto(...url);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { type, data          } = elem;

        const values = data || {};
        const button = Common.getTypeButton(values.programType);

        return <ViewDialog
            open={open}
            icon={type}
            title="MEETINGS_VIEW_TITLE"
            onClose={onClose}
            secondary={values.asCoach ? "GROUPS_VIEW_TITLE" : button}
            onSecondary={this.handleView}
            withSpacing
        >
            <ViewField
                label="COACHES_SINGULAR"
                value={values.coachName}
                isHidden={values.asCoach}
            />
            <ViewField
                label="MEETINGS_COURSE"
                value={values.strechTitle}
                isHidden={values.asCoach}
            />
            <ViewField
                label="MEETINGS_STRECH"
                value={values.strechName}
                isHidden={!values.asCoach}
            />
            <ViewField
                label="GENERAL_MODE"
                value={values.modeName}
            />

            {values.isPresential && <ViewField
                label="LOCATIONS_SINGULAR"
                value={values.locationString}
            />}
            {values.hasStream && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={values.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={values.streamPassword} />
            </>}
            <ViewField label="GENERAL_DATE" value={values.timeString} />
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elem    : PropTypes.object.isRequired,
    }
}

export default MeetingView;
