import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Province }         from "Utils/API";

// Types
import {
    PROVINCE, PROVINCE_LOADING,
    PROVINCE_LIST, PROVINCE_ELEM, PROVINCE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Province List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProvinces(params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROVINCE_LOADING });
        Params.unset(params);
        const data = await Province.getAll(params);
        data.sort = params;
        dispatch({ type : PROVINCE_LIST, data });
        dispatch(setSection(PROVINCE));
    };
}

/**
 * Fetches a single Province
 * @param {Number} provinceID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProvince(provinceID) {
    return async (dispatch) => {
        const data = await Province.getOne({ provinceID });
        dispatch({ type : PROVINCE_ELEM, data });
    };
}

/**
 * Fetches the Province Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProvinceCreate() {
    return async (dispatch) => {
        const data = await Province.getCreateData();
        dispatch({ type : PROVINCE_CREATE, data });
    };
}



/**
 * Edits a Province
 * @param {Object} data
 * @returns {() => Void}
 */
export function editProvince(data) {
    return () => Province.edit(data);
}

/**
 * Deletes a Province
 * @param {Number} provinceID
 * @returns {() => Void}
 */
export function deleteProvince(provinceID) {
    return () => Province.delete({ provinceID });
}
