import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import MeetingView          from "./MeetingView";
import MeetingEdit          from "./MeetingEdit";
import MeetingReport        from "./MeetingReport";
import MeetingCancel        from "./MeetingCancel";
import GroupView            from "Components/Admin/Strech/Group/GroupView";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchMeetings, deleteMeeting,
} from "Actions/Admin/Strech/MeetingActions";



/**
 * The Meeting List
 */
class MeetingList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchMeetings(type, elemID, params || data.sort);
    }

    /**
     * Fetch the tab content
     * @param {Number=} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        this.fetch({ ...this.props.data.sort, filter, page : 0 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.fetchTab(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @param {Number=} filter
     * @returns {Void}
     */
    endAction = (update, filter) => {
        this.startAction(Action.get(), 0);
        if (filter) {
            this.fetchTab(filter);
        } else if (update) {
            this.fetch();
        }
    }



    /**
     * Handles the Edit Submit
     * @param {Number=} filter
     * @returns {Void}
     */
    editElem = (filter) => {
        this.endAction(true, filter);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        await this.props.deleteMeeting(elemID);
        this.endAction(true);
    }



    /**
     * Returns true if it can Edit the Meeting
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEdit(elemID) {
        const { list, canEdit } = this.props.data;
        return canEdit && Utils.getValue(list, "meetingID", elemID).canEdit;
    }

    /**
     * Returns true if it can Report the Meeting
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canReport(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "meetingID", elemID).canReport;
    }

    /**
     * Returns true if it can Cancel the Meeting
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canCancel(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "meetingID", elemID).canCancel;
    }

    /**
     * Returns true if it can Delete the Meeting
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canDelete(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "meetingID", elemID).canDelete;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                         } = this.state;
        const { data, type, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading    } = data;

        const showGroup = type !== "GROUP";
        const showCoach = type !== "COACH" && type !== "USER";
        const showMode  = type !== "LOCATION";
        const elemName  = Utils.getValue(list, "meetingID", elemID, "description");
        const groupID   = Utils.getValue(list, "meetingID", elemID, "groupID", 0);

        return <Main withDetails={withDetails}>
            <Header message="MEETINGS_NAME" icon="meeting" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="MEETINGS_PROGRAM"   status="SCHEDULED" />
                    <TabItem message="MEETINGS_COMPLETED" status="COMPLETED" />
                    <TabItem message="MEETINGS_REPORTED"  status="REPORTED"  />
                    <TabItem message="MEETINGS_NREPORTED" status="NREPORTED" />
                    <TabItem message="MEETINGS_CANCELLED" status="CANCELLED" />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="MEETINGS_NONE_AVAILABLE"
                    isLoading={loading}
                    hasTabs
                >
                    <TableHead>
                        <TableHeader field="time"           message="GENERAL_DATE"      maxWidth="180" />
                        <TableHeader field="groupName"      message="GROUPS_SINGULAR"   isHidden={!showGroup} />
                        <TableHeader field="strechName"     message="STRECHES_SINGULAR" isHidden={!showGroup} />
                        <TableHeader field="coachFirstName" message="COACHES_SINGULAR"  isHidden={!showCoach} />
                        <TableHeader field="mode"           message="GENERAL_MODE"      isHidden={!showMode}  maxWidth="80" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.meetingID} elemID={elem.meetingID}>
                            <TableCell message={elem.timeString}  />
                            <TableCell message={elem.groupName}   />
                            <TableCell message={elem.strechName}  />
                            <TableCell message={elem.coachString} />
                            <TableCell message={elem.modeName}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="MEETINGS_VIEW_TITLE"   />
                        <TableAction action="GROUP"  message="GROUPS_VIEW_TITLE"     />
                        <TableAction action="EDIT"   message="MEETINGS_EDIT_TITLE"   hide={(elemID) => !this.canEdit(elemID)}   />
                        <TableAction action="REPORT" message="MEETINGS_REPORT_TITLE" hide={(elemID) => !this.canReport(elemID)} />
                        <TableAction action="CANCEL" message="MEETINGS_CANCEL_TITLE" hide={(elemID) => !this.canCancel(elemID)} />
                        <TableAction action="DELETE" message="MEETINGS_DELETE_TITLE" hide={(elemID) => !this.canDelete(elemID)} />
                    </TableActionList>
                </Table>
            </Content>

            <MeetingView
                open={action.isView}
                elemID={elemID}
                groupID={params.groupID}
                coachID={params.coachID}
                locationID={params.locationID}
                onClose={this.endAction}
            />
            <MeetingEdit
                open={action.isCE}
                elemID={elemID}
                groupID={params.groupID}
                coachID={params.coachID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <MeetingReport
                open={action.isReport}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <MeetingCancel
                open={action.isCancel}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="MEETINGS_DELETE_TITLE"
                message="MEETINGS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <GroupView
                open={action.isGroup}
                elemID={groupID}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeetings : PropTypes.func.isRequired,
        deleteMeeting : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        type          : PropTypes.string.isRequired,
        params        : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
        withDetails   : PropTypes.bool.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.meeting,
        };
    }
}

export default connect(MeetingList.mapStateToProps, {
    fetchMeetings, deleteMeeting,
})(MeetingList);
