import React                from "react";
import PropTypes            from "prop-types";

// Sections
import HomePage             from "Components/Student/Dashboard/HomePage";
import CalendarPage         from "Components/Student/Dashboard/CalendarPage";
import StrechList           from "Components/Admin/Strech/Strech/StrechList";
import StudentList          from "Components/Coach/Student/StudentList";
import GroupList            from "Components/Admin/Strech/Group/GroupList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";
import MeetingList          from "Components/Admin/Strech/Meeting/MeetingList";
import MaterialList         from "Components/Admin/Coach/Material/MaterialList";
import InvoiceList          from "Components/Admin/Coach/Invoice/InvoiceList";
import EnrollPage           from "Components/Student/Enroll/EnrollPage";
import StrechMineList       from "Components/Student/Strech/StrechList";
import ConversationMineList from "Components/Student/Conversation/ConversationList";
import HelpPage             from "Components/Admin/Ticket/Help/HelpPage";
import TicketList           from "Components/Admin/Ticket/Ticket/TicketList";

// Components
import PrimaryNav           from "Components/Utils/Navigation/PrimaryNav";
import CoachRoute           from "Components/Utils/Route/CoachRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Coach Container
 */
class CoachContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;

        return <>
            <PrimaryNav path={route}>
                <NavigationList>
                    <NavigationItem message="HOME_NAME"               url="HOME"               icon="home"         />
                    <NavigationItem message="CALENDAR_NAME"           url="CALENDAR"           icon="calendar"     />
                    <NavigationItem message="HELP_NAME"               url="HELP"               icon="help"         />
                    <NavigationItem message="TICKETS_NAME"            url="TICKETS"            icon="ticket"       usePrefix />
                </NavigationList>
                <NavigationList message="COACHES_AS_TITLE">
                    <NavigationItem message="STRECHES_NAME"           url="STRECHES"           icon="strech"       />
                    <NavigationItem message="STUDENTS_NAME"           url="STUDENTS"           icon="student"      />
                    <NavigationItem message="GROUPS_NAME"             url="GROUPS"             icon="group"        />
                    <NavigationItem message="CONVERSATIONS_NAME"      url="CONVERSATIONS"      icon="conversation" />
                    <NavigationItem message="MEETINGS_NAME"           url="MEETINGS"           icon="meeting"      />
                    <NavigationItem message="COACH_MATERIALS_LIBRARY" url="LIBRARY"            icon="material"     />
                    <NavigationItem message="COACH_INVOICES_NAME"     url="INVOICES"           icon="invoice"      />
                </NavigationList>
                <NavigationList message="STUDENTS_AS_TITLE">
                    <NavigationItem message="ENROLL_NAME"             url="ENROLL"             icon="inscription"  />
                    <NavigationItem message="COURSES_MINE"            url="COURSES_MINE"       icon="strech"       />
                    <NavigationItem message="CONVERSATIONS_MINE"      url="CONVERSATIONS_MINE" icon="conversation" />
                </NavigationList>
                <NavigationList />
            </PrimaryNav>

            <SwitchRoute type="USER">
                <CoachRoute url="HOME"               component={HomePage}             exact />
                <CoachRoute url="CALENDAR"           component={CalendarPage}         exact />
                <CoachRoute url="HELP"               component={HelpPage}             exact />
                <CoachRoute url="TICKETS"            component={TicketList}           />
                <CoachRoute url="STRECHES"           component={StrechList}           exact />
                <CoachRoute url="STUDENTS"           component={StudentList}          exact />
                <CoachRoute url="GROUPS"             component={GroupList}            exact />
                <CoachRoute url="CONVERSATIONS"      component={ConversationList}     exact />
                <CoachRoute url="MEETINGS"           component={MeetingList}          exact />
                <CoachRoute url="LIBRARY"            component={MaterialList}         exact />
                <CoachRoute url="INVOICES"           component={InvoiceList}          exact />
                <CoachRoute url="ENROLL"             component={EnrollPage}           exact />
                <CoachRoute url="COURSES_MINE"       component={StrechMineList}       exact />
                <CoachRoute url="CONVERSATIONS_MINE" component={ConversationMineList} exact />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default CoachContainer;
