import React                from "react";
import PropTypes            from "prop-types";
import { Strech, Coach }    from "Utils/API";
import Status               from "dashboard/dist/Core/Status";
import Period               from "dashboard/dist/Utils/Period";

// Components
import FilterDialog         from "dashboard/dist/Components/FilterDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Coach Invoice Filter Dialog
 */
class InvoiceFilter extends React.Component {
    // The Initial Data
    initialData = {
        period     : 0,
        fromDate   : "",
        toDate     : "",
        strechID   : 0,
        strechName : "",
        coachID    : 0,
        coachName  : "",
        status     : 0,
    }

    // The Current State
    state = {
        data : { ...this.initialData },
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, filters } = this.props;
        let data = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData, ...filters };
        }

        // Set the State
        if (data) {
            this.setState({ data });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data = { ...this.state.data, [name] : value };
        if (name === "period") {
            data.fromDate = Period.getFromDate(value);
            data.toDate   = Period.getToDate(value);
        }
        this.setState({ data });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onSubmit, onClose, withStrech, withCoach } = this.props;
        const { data                                           } = this.state;

        return <FilterDialog
            open={open}
            title="COACH_INVOICES_FILTER_TITLE"
            initialData={this.initialData}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <Columns amount="3">
                <InputField
                    type="select"
                    name="period"
                    label="GENERAL_PERIOD"
                    value={data.period}
                    onChange={this.handleChange}
                    options={Period.getSelect()}
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM"
                    value={data.fromDate}
                    onChange={this.handleChange}
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="date"
                    name="toDate"
                    label="GENERAL_TO"
                    value={data.toDate}
                    onChange={this.handleChange}
                    noMargin
                    shrinkLabel
                />
            </Columns>
            <InputField
                isHidden={!withStrech}
                name="strechName"
                label="STRECHES_SINGULAR"
                suggestID="strechID"
                suggestFetch={Strech.search}
                suggestNone="STRECHES_NONE_AVAILABLE"
                value={data.strechName}
                onChange={this.handleChange}
            />
            <InputField
                isHidden={!withCoach}
                name="coachName"
                label="COACHES_SINGULAR"
                suggestID="coachID"
                suggestFetch={Coach.search}
                suggestNone="COACHES_NONE_AVAILABLE"
                value={data.coachName}
                onChange={this.handleChange}
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                onChange={this.handleChange}
                options={Status.getFemSelect("INVOICE")}
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
        </FilterDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        onSubmit   : PropTypes.func.isRequired,
        filters    : PropTypes.object,
        withStrech : PropTypes.bool,
        withCoach  : PropTypes.bool,
    }
}

export default InvoiceFilter;
