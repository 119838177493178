import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Sections
import SettingsPage         from "Components/Profile/Settings/SettingsPage";
import ActionLogList        from "Components/Admin/Setup/ActionLogList";

// Components
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import UserRoute            from "Components/Utils/Route/UserRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Profile Container
 */
class ProfileContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, match, route } = this.props;
        const { path, url          } = match;

        return <>
            <SecondaryNav
                message={data.name}
                fallback="ACCOUNT_MINE"
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="SETTINGS_NAME"    url="SETTINGS" icon="settings" />
                <NavigationItem message="ACTIONS_LOG_NAME" url="ACTIONS"  icon="actions"  />
            </SecondaryNav>

            <SwitchRoute type="PROFILE" path={path} baseUrl={url}>
                <UserRoute url="SETTINGS" component={SettingsPage}  />
                <UserRoute url="ACTIONS"  component={ActionLogList} />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data  : PropTypes.object.isRequired,
        match : PropTypes.object.isRequired,
        route : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.auth.credential,
        };
    }
}

export default connect(ProfileContainer.mapStateToProps)(ProfileContainer);
