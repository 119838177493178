import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { EmailWhiteList }   from "Utils/API";

// Types
import {
    EMAIL_WHITE, EMAIL_WHITE_LOADING,
    EMAIL_WHITE_LIST, EMAIL_WHITE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Email White List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchWhiteList(params = {}) {
    return async (dispatch) => {
        dispatch({ type : EMAIL_WHITE_LOADING });
        Params.unset(params);
        const data = await EmailWhiteList.getAll(params);
        data.sort = params;
        dispatch({ type : EMAIL_WHITE_LIST, data });
        dispatch(setSection(EMAIL_WHITE));
    };
}

/**
 * Fetches a single Email from the White List
 * @param {Number} emailID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchEmail(emailID) {
    return async (dispatch) => {
        const data = await EmailWhiteList.getOne({ emailID });
        dispatch({ type : EMAIL_WHITE_ELEM, data });
    };
}



/**
 * Edits an Email in the White List
 * @param {Object} data
 * @returns {() => Void}
 */
export function editEmail(data) {
    return () => EmailWhiteList.edit(data);
}

/**
 * Removes an Email from the White List
 * @param {Number} emailID
 * @returns {() => Void}
 */
export function removeEmail(emailID) {
    return () => EmailWhiteList.remove({ emailID });
}
