import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchEmail }       from "Actions/Admin/Setup/EmailQueueActions";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";
import Columns              from "dashboard/dist/Components/Columns";



/**
 * The Email Queue View Dialog
 */
class EmailQueueView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchEmail } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchEmail(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, elem } = this.props;
        const { loading             } = this.state;

        return <ViewDialog
            open={open}
            icon="queue"
            title="EMAIL_QUEUE_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField
                label="EMAIL_QUEUE_SEND_AS"
                value={elem.sendAs}
            />
            <ViewField
                label="EMAIL_QUEUE_SEND_NAME"
                value={elem.sendName}
            />
            <ViewField
                label="EMAIL_QUEUE_SEND_TO"
                value={elem.sendToString}
            />
            <ViewField
                label="GENERAL_SUBJECT"
                value={elem.subject}
            />
            <ViewField
                label="EMAIL_QUEUE_MESSAGE"
                value={elem.message}
            />
            <Columns amount={elem.sentDateTime ? "3" : "2"}>
                <ViewField
                    label="GENERAL_CREATED"
                    value={elem.createdDateTime}
                />
                <ViewField
                    label="EMAIL_QUEUE_SEND_DATE"
                    value={elem.sentDateTime}
                />
                <ViewField
                    label="GENERAL_SENT"
                    value={elem.sentSuccessString}
                />
            </Columns>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchEmail : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        edition    : PropTypes.number.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.emailQueue.edition,
            elem    : state.emailQueue.elem,
        };
    }
}

export default connect(EmailQueueView.mapStateToProps, {
    fetchEmail,
})(EmailQueueView);
