import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Student }          from "Utils/API";

// Types
import {
    STUDENT, STUDENT_LOADING, STUDENT_CHARGING,
    STUDENT_LIST, STUDENT_FILTER,
    STUDENT_ELEM, STUDENT_COACH, STUDENT_CREATE,
} from "Utils/Types";



/**
 * Fetches the Student List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Object=}  filters
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudents(type = "", elemID = 0, params = {}, filters = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STUDENT_LOADING });
        }
        Params.unset(params);
        const sendParams = { ...params, ...filters };
        let data = {};

        switch (type) {
        case "USER":
            data = await Student.getAllAsCoach(sendParams);
            break;
        case "STRECH":
            sendParams.strechID = elemID;
            data = await Student.getAllAsCoach(sendParams);
            break;
        case "COACH":
            sendParams.coachID = elemID;
            data = await Student.getAllForCoach(sendParams);
            break;
        case "SIGNUP":
            data = await Student.getAllSignups(sendParams);
            break;
        default:
            data = await Student.getAll(sendParams);
        }

        data.filters = filters;
        data.sort    = params;
        dispatch({ type : STUDENT_LIST, data });
        dispatch(setSection(STUDENT));
    };
}

/**
 * Fetches the Students Filter data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudentsFilter() {
    return async (dispatch) => {
        const data = await Student.getFilterData();
        dispatch({ type : STUDENT_FILTER, data });
    };
}

/**
 * Fetches the Students Emails
 * @param {String=} type
 * @param {Object=} filters
 * @returns {(dispatch) => Void}
 */
export function fetchStudentsEmails(type, filters = {}) {
    return async () => {
        const sendData = { forSignups : type === "SIGNUP" ? 1 : 0, ...filters };
        return await Student.getEmails(sendData);
    };
}

/**
 * Generates the export url from the given data
 * @param {Object} filters
 * @returns {String}
 */
export function exportStudents(type, filters) {
    const sendData = { forSignups : type === "SIGNUP" ? 1 : 0, ...filters };
    return Student.export(sendData);
}



/**
 * Fetches a single Student
 * @param {Number}   credentialID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudent(credentialID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STUDENT_CHARGING });
        }
        const data = await Student.getOne({ credentialID });
        dispatch({ type : STUDENT_ELEM, data });
    };
}

/**
 * Fetches a single Student as a Coach
 * @param {Number}   inscriptionID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudentAsCoach(inscriptionID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STUDENT_CHARGING });
        }
        const data = await Student.getOneAsCoach({ inscriptionID });
        dispatch({ type : STUDENT_COACH, data });
    };
}

/**
 * Fetches the Student Create data
 * @returns {(dispatch) => Void}
 */
export function fetchStudentCreate() {
    return async (dispatch) => {
        const data = await Student.getCreateData();
        dispatch({ type : STUDENT_CREATE, data });
    };
}



/**
 * Edits/Creates a Student
 * @param {Object} data
 * @returns {() => Void}
 */
export function editStudent(data) {
    return () => Student.edit(data);
}

/**
 * Edits a Student Level
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function editStudentLevel(credentialID) {
    return () => Student.editLevel({ credentialID });
}

/**
 * Deletes a Student
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function deleteStudent(credentialID) {
    return () => Student.delete({ credentialID });
}
