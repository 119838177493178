import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchConversation, fetchConversationRequest, requestConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation Request Dialog
 */
class ConversationRequest extends React.Component {
    // The Initial Data
    initialData = {
        strechID     : 0,
        specialityID : 0,
        message      : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, strechID, fetchConversation, fetchConversationRequest } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchConversation(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchConversationRequest();
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData };
                if (strechID) {
                    data.strechID = strechID;
                }
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { streches, requestConversation, onSubmit } = this.props;
        const { data, loading                           } = this.state;

        if (!loading && streches.length > 0) {
            this.setState({ loading : true, errors : {} });
            try {
                await requestConversation(data);
                this.setState({ loading : false });
                onSubmit(Status.getID("REQUSESTED"));
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, streches, specialities, strechID } = this.props;
        const { data, loading, errors                           } = this.state;

        const canRequest = streches.length > 0;

        return <EditDialog
            open={open}
            icon="conversation"
            title="CONVERSATIONS_REQUEST_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            hidePrimary={!canRequest}
            cancel={!canRequest ? "GENERAL_CLOSE" : "GENERAL_CANCEL"}
        >
            {!canRequest ? <DialogMessage
                message="CONVERSATIONS_CANT_REQUEST"
                noSpace
            /> : <>
                <DialogMessage message="CONVERSATIONS_REQUEST_HELP" />
                <InputField
                    isHidden={!!strechID}
                    type="select"
                    name="strechID"
                    label="CONVERSATIONS_COURSE"
                    value={data.strechID}
                    error={errors.strechID}
                    onChange={this.handleChange}
                    options={streches}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={!data.strechID}
                    type="select"
                    name="specialityID"
                    label="SPECIALITIES_SHORT"
                    value={data.specialityID}
                    error={errors.specialityID}
                    onChange={this.handleChange}
                    options={specialities[data.strechID]}
                    withNone
                    isRequired
                />
                <InputField
                    type="textarea"
                    name="message"
                    label="CONVERSATIONS_MESSAGE_REQUEST"
                    value={data.message}
                    error={errors.message}
                    onChange={this.handleChange}
                    isRequired
                />
            </>}
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversation        : PropTypes.func.isRequired,
        fetchConversationRequest : PropTypes.func.isRequired,
        requestConversation      : PropTypes.func.isRequired,
        open                     : PropTypes.bool.isRequired,
        onClose                  : PropTypes.func.isRequired,
        onSubmit                 : PropTypes.func.isRequired,
        edition                  : PropTypes.number.isRequired,
        streches                 : PropTypes.array.isRequired,
        specialities             : PropTypes.object.isRequired,
        elem                     : PropTypes.object.isRequired,
        elemID                   : PropTypes.number,
        studentID                : PropTypes.number,
        strechID                 : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.conversation.edition,
            canEdit      : state.conversation.canEdit,
            streches     : state.conversation.streches,
            specialities : state.conversation.specialities,
            elem         : state.conversation.elem,
        };
    }
}

export default connect(ConversationRequest.mapStateToProps, {
    fetchConversation, fetchConversationRequest, requestConversation,
})(ConversationRequest);
