import Utils            from "dashboard/dist/Utils/Utils";

// Types
import {
    MEDIA_LOADING, MEDIA_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    canCreate : false,
    canEdit   : false,
    list      : [],
    path      : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, MEDIA_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case MEDIA_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case MEDIA_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            list      : action.data.list,
            path      : action.data.path,
        };

    default:
        return state;
    }
};

export default reducer;
