import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Fiscal Field
 */
class FiscalField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, countries, onChange, isRequired } = this.props;

        const nationality = Utils.getValue(countries, "key", data.nationality, "code");
        const isArgentine = Boolean(nationality && nationality === "AR");
        const isCUIT      = Number(data.document) === 2;

        return <Columns amount="2">
            <InputField
                type="select"
                name="nationality"
                label="USERS_NATIONALITY"
                value={data.nationality}
                error={errors.nationality}
                onChange={onChange}
                options={countries}
                isRequired={isRequired}
                withNone
            />
            <InputField
                isHidden={!isArgentine}
                name={isCUIT ? "cuit" : "dni"}
                label="USERS_DOCUMENT"
                value={isCUIT ? data.cuit : data.dni}
                error={errors.document}
                preType="select"
                preName="document"
                preValue={data.document}
                preOptions="USERS_DOCUMENTS"
                preWidth="80"
                preWithNone
                onChange={onChange}
                isRequired
                shrinkLabel
            />
            <InputField
                isHidden={isArgentine}
                name="taxID"
                label="USERS_TAX_ID"
                value={data.taxID}
                error={errors.taxID}
                onChange={onChange}
                isRequired={isRequired}
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.object.isRequired,
        errors     : PropTypes.object.isRequired,
        countries  : PropTypes.array.isRequired,
        onChange   : PropTypes.func.isRequired,
        isRequired : PropTypes.bool,
    }
}

export default FiscalField;
