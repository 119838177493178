import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";

// Components
import CampaignSend         from "./CampaignSend";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchCampaign, pauseCampaign, deleteCampaign,
} from "Actions/Admin/Campaign/CampaignActions";



/**
 * The Campaign Details
 */
class CampaignDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { campaignID, fetchCampaign } = this.props;
        fetchCampaign(campaignID, true);
        this.endAction();
    }

    /**
     * Handles the Pause Submit
     * @returns {Promise}
     */
    pauseElem = async () => {
        const { campaignID, pauseCampaign, fetchCampaign } = this.props;
        this.endAction();
        await pauseCampaign(campaignID);
        fetchCampaign(campaignID, true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { campaignID, deleteCampaign } = this.props;
        this.endAction();
        await deleteCampaign(campaignID);
        Href.goto("CAMPAIGNS");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, campaignID } = this.props;
        const { action                                     } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="CAMPAIGNS_ERROR_EXISTS"
            >
                <DetailList message="CAMPAIGNS_DETAILS_TITLE">
                    <DetailItem
                        icon="name"
                        message={elem.subject}
                        tooltip="CAMPAIGNS_ITS_SUBJECT"
                    />
                    <DetailItem
                        icon="type"
                        message={elem.templateName}
                        tooltip="CAMPAIGNS_ITS_SUBJECT"
                    />
                    <DetailItem
                        icon="user"
                        message={elem.sendToText}
                        tooltip="CAMPAIGNS_QUANTITY"
                    />
                    <DetailItem
                        icon="status"
                        message={elem.stateName}
                        className={elem.stateClass}
                        tooltip="CAMPAIGNS_STATUS"
                    />
                    <DetailItem
                        icon="time"
                        message={elem.sentDateTime}
                        tooltip={elem.wasSent ? "GENERAL_SENT_AT" : "GENERAL_SEND_AT"}
                        prefix={elem.wasSent ? "GENERAL_SENT_AT" : "GENERAL_SEND_AT"}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.createdDateTime}
                        tooltip="GENERAL_CREATED"
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"   message="CAMPAIGNS_EDIT_TITLE"   isHidden={!elem.canEdit} />
                    <DetailAction action="CANCEL" message="CAMPAIGNS_PAUSE_TITLE"  isHidden={!elem.canPause} />
                    <DetailAction action="DELETE" message="CAMPAIGNS_DELETE_TITLE" isHidden={!elem.canDelete} />
                    <DetailAction action="SEND"   message="CAMPAIGNS_RESEND_TITLE" />
                </DetailActionList>
            </Details>

            <CampaignSend
                open={action.isEdit || action.isSend}
                elemID={campaignID}
                isCopy={action.isSend}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isCancel}
                icon="cancel"
                title="CAMPAIGNS_PAUSE_TITLE"
                message="CAMPAIGNS_PAUSE_TEXT"
                content={elem.subject}
                onSubmit={this.pauseElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="CAMPAIGNS_DELETE_TITLE"
                message="CAMPAIGNS_DELETE_TEXT"
                content={elem.subject}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCampaign  : PropTypes.func.isRequired,
        pauseCampaign  : PropTypes.func.isRequired,
        deleteCampaign : PropTypes.func.isRequired,
        charging       : PropTypes.bool.isRequired,
        error          : PropTypes.bool.isRequired,
        canEdit        : PropTypes.bool.isRequired,
        elem           : PropTypes.object.isRequired,
        campaignID     : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.campaign.charging,
            error    : state.campaign.error,
            canEdit  : state.campaign.canEdit,
            elem     : state.campaign.elem,
        };
    }
}

export default connect(CampaignDetails.mapStateToProps, {
    fetchCampaign, pauseCampaign, deleteCampaign,
})(CampaignDetails);
