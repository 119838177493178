import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { loginAs }          from "Actions/Core/AuthActions";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";

// Dialogs
import CoachEdit            from "./CoachEdit";
import CourseCompleted      from "Components/Admin/Course/CourseCompleted";
import CoachPrices          from "Components/Admin/Coach/CoachPrices";

// Components
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchCoach, editCoachLevel, deleteCoach,
} from "Actions/Admin/User/CoachActions";



/**
 * The Coach Details
 */
class CoachDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        if (action.isLogin) {
            this.props.loginAs(this.props.credentialID);
        } else {
            this.setState({ action });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchCoach(this.props.credentialID, true);
        this.endAction();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { credentialID, deleteCoach } = this.props;
        this.endAction();
        await deleteCoach(credentialID);
        Href.goto("COACHES");
    }

    /**
     * Handles the Level Submit
     * @returns {Promise}
     */
    editLevel = async () => {
        const { credentialID, editCoachLevel } = this.props;
        this.endAction();
        await editCoachLevel(credentialID);
        Href.goto("STUDENTS", credentialID);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, credentialID } = this.props;
        const { action                                       } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="COACHES_ERROR_EXISTS"
            >
                <DetailList message="COACHES_DETAILS_TITLE">
                    <DetailItem icon="name"         message={elem.credentialName}     tooltip="GENERAL_NAME"             />
                    <DetailItem icon="email"        message={elem.email}              tooltip="GENERAL_EMAIL"            isEmail />
                    <DetailItem icon="phone"        message={elem.phoneString}        tooltip="USERS_CELLPHONE"          isWhatsApp />
                    <DetailItem icon="country"      message={elem.nationalityName}    tooltip="USERS_NATIONALITY"        withTip />
                    <DetailItem icon="dni"          message={elem.dni}                tooltip="USERS_DNI"                withTip />
                    <DetailItem icon="cuit"         message={elem.cuit}               tooltip="USERS_CUIT"               withTip />
                    <DetailItem icon="taxID"        message={elem.taxID}              tooltip="USERS_TAX_ID"             withTip />
                    <DetailItem icon="ocupation"    message={elem.ocupation}          tooltip="USERS_OCUPATION"          />
                    <DetailItem icon="schedule"     message={elem.schedule}           tooltip="USERS_SCHEDULE"           />
                    <DetailItem icon="address"      message={elem.addressString}      tooltip="GENERAL_ADRRESS"          />
                    <DetailItem icon="gender"       message={elem.genderName}         tooltip="USERS_GENDER"             />
                    <DetailItem icon="conversation" message={elem.canConverseString}  tooltip="USERS_CAN_CONVERSE_SHORT" withTip showAlways />
                    <DetailItem icon="conversation" message={elem.totalConversations} tooltip="CONVERSATIONS_NAME"       withTip showAlways />
                    <DetailItem icon="billing"      message={elem.afipLongName}       tooltip="USERS_AFIP_INSCRIPTION"   />
                    <DetailItem icon="strech"       message={elem.schoolStaffString}  tooltip="USERS_SCHOOL_STAFF"       withTip showAlways />
                    <DetailItem icon="subscription" message={elem.subscriptionName}   tooltip="GENERAL_SUBSCRIPTION"     className={elem.subscriptionClass} />
                    <DetailItem icon="status"       message={elem.statusName}         tooltip="GENERAL_STATUS"           className={elem.statusClass} />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"      message="COACHES_EDIT_TITLE"     />
                    <DetailAction action="COMPLETED" message="COURSES_COMPLETED_NAME" />
                    <DetailAction action="PRICES"    message="COACH_PRICES_NAME"      />
                    <DetailAction action="ACCESS"    message="COACHES_ACCESS_TITLE"   />
                    <DetailAction action="LOGIN"     message="COACHES_LOGIN_AS"       />
                    <DetailAction action="DELETE"    message="COACHES_DELETE_TITLE"   />
                </DetailActionList>
            </Details>

            <CoachEdit
                open={action.isEdit}
                elemID={credentialID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CourseCompleted
                open={action.isCompleted}
                elemID={credentialID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <CoachPrices
                open={action.isPrices}
                elemID={credentialID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isAccess}
                icon="access"
                title="COACHES_ACCESS_TITLE"
                message="COACHES_ACCESS_TEXT"
                content={elem.credentialName}
                onSubmit={this.editLevel}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="COACHES_DELETE_TITLE"
                message="COACHES_DELETE_TEXT"
                content={elem.credentialName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCoach     : PropTypes.func.isRequired,
        editCoachLevel : PropTypes.func.isRequired,
        deleteCoach    : PropTypes.func.isRequired,
        loginAs        : PropTypes.func.isRequired,
        charging       : PropTypes.bool.isRequired,
        error          : PropTypes.bool.isRequired,
        canEdit        : PropTypes.bool.isRequired,
        elem           : PropTypes.object.isRequired,
        credentialID   : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.coach.charging,
            error    : state.coach.error,
            canEdit  : state.coach.canEdit,
            elem     : state.coach.elem,
        };
    }
}

export default connect(CoachDetails.mapStateToProps, {
    fetchCoach, editCoachLevel, deleteCoach, loginAs,
})(CoachDetails);
