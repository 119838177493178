import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramMaterial }  from "Utils/API";

// Types
import {
    PROGRAM_MATERIAL, PROGRAM_MATERIAL_LOADING,
    PROGRAM_MATERIAL_LIST, PROGRAM_MATERIAL_ELEM, PROGRAM_MATERIAL_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program Material List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterials(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_MATERIAL_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramMaterial.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_MATERIAL_LIST, data });
        dispatch(setSection(PROGRAM_MATERIAL));
    };
}

/**
 * Fetches a single Program Material
 * @param {Number} programMaterialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterial(programMaterialID) {
    return async (dispatch) => {
        const data = await ProgramMaterial.getOne({ programMaterialID });
        dispatch({ type : PROGRAM_MATERIAL_ELEM, data });
    };
}

/**
 * Fetches the Program Material Create data
 * @param {Number} programID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterialCreate(programID) {
    return async (dispatch) => {
        const data = await ProgramMaterial.getCreateData({ programID });
        dispatch({ type : PROGRAM_MATERIAL_CREATE, data });
    };
}



/**
 * Edits/Creates a Program Material
 * @param {Object} data
 * @returns {() => Void}
 */
export function editMaterial(data) {
    return () => ProgramMaterial.edit(data);
}

/**
 * Deletes a Program Material
 * @param {Number} programMaterialID
 * @returns {() => Void}
 */
export function deleteMaterial(programMaterialID) {
    return () => ProgramMaterial.delete({ programMaterialID });
}
