import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchMeetingEdit, fetchMeetingCreate, editMeeting,
} from "Actions/Admin/Strech/MeetingActions";



/**
 * The Meeting Edit Dialog
 */
class MeetingEdit extends React.Component {
    // The Initial Data
    initialData = {
        meetingID      : 0,
        groupID        : 0,
        mode           : 0,
        streamLink     : "",
        streamPassword : "",
        locationID     : 0,
        roomID         : 0,
        customLocation : "",
        timeDate       : "",
        timeHour       : "",
        observations   : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, groupID, coachID, fetchMeetingEdit, fetchMeetingCreate } = this.props;

        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchMeetingEdit(elemID);
                loading = true;
            } else {
                fetchMeetingCreate(groupID, coachID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, groupID };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { editMeeting, onSubmit } = this.props;
        const { data, loading         } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await editMeeting(data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, groupID, groups, locations, rooms } = this.props;
        const { data, loading, errors                                    } = this.state;

        const isPresential = Common.isPresentialMode(data.mode);
        const isVirtual    = Common.isVirtualMode(data.mode);
        const isCustom     = Common.isCustomLocation(data.locationID);

        return <EditDialog
            open={open}
            icon="meeting"
            title={!!elemID ? "MEETINGS_EDIT_TITLE" : "MEETINGS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!groupID}
                type="select"
                name="groupID"
                label="MEETINGS_GROUP"
                value={data.groupID}
                error={errors.groupID}
                onChange={this.handleChange}
                options={groups}
                withNone
                isRequired
            />
            <InputField
                type="select"
                name="mode"
                label="MEETINGS_MODE"
                value={data.mode}
                error={errors.mode}
                onChange={this.handleChange}
                options="MODES"
                withNone
                isRequired
            />
            <Columns>
                <InputField
                    isHidden={!isVirtual}
                    name="streamLink"
                    label="GENERAL_STREAM_LINK"
                    value={data.streamLink}
                    error={errors.streamLink}
                    onChange={this.handleChange}
                />
                <InputField
                    isHidden={!isVirtual}
                    name="streamPassword"
                    label="GENERAL_STREAM_PASSWORD"
                    value={data.streamPassword}
                    error={errors.streamPassword}
                    onChange={this.handleChange}
                />


                <InputField
                    isHidden={!isPresential}
                    type="select"
                    name="locationID"
                    label="MEETINGS_LOCATION"
                    value={data.locationID}
                    error={errors.locationID}
                    onChange={this.handleChange}
                    options={locations}
                    withNone
                    withCustom
                    isRequired
                />
                <InputField
                    isHidden={!isPresential || isCustom}
                    type="select"
                    name="roomID"
                    label="MEETINGS_ROOM"
                    value={data.roomID}
                    error={errors.roomID}
                    onChange={this.handleChange}
                    options={data.locationID > 0 ? rooms[data.locationID] || [] : []}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={!isPresential || !isCustom}
                    name="customLocation"
                    label="MEETINGS_LOCATION_CUSTOM"
                    value={data.customLocation}
                    error={errors.customLocation}
                    onChange={this.handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="timeDate"
                    label="MEETINGS_DATE"
                    value={data.timeDate}
                    error={errors.timeDate}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="timeHour"
                    label="MEETINGS_HOUR"
                    value={data.timeHour}
                    error={errors.timeHour}
                    onChange={this.handleChange}
                    isRequired
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                helperText="MEETINGS_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeetingEdit   : PropTypes.func.isRequired,
        fetchMeetingCreate : PropTypes.func.isRequired,
        editMeeting        : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        groups             : PropTypes.array.isRequired,
        locations          : PropTypes.array.isRequired,
        rooms              : PropTypes.object.isRequired,
        elem               : PropTypes.object.isRequired,
        elemID             : PropTypes.number,
        groupID            : PropTypes.number,
        coachID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.meeting.edition,
            groups    : state.meeting.groups,
            locations : state.meeting.locations,
            rooms     : state.meeting.rooms,
            elem      : state.meeting.elem,
        };
    }
}

export default connect(MeetingEdit.mapStateToProps, {
    fetchMeetingEdit, fetchMeetingCreate, editMeeting,
})(MeetingEdit);
