import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Program }          from "Utils/API";

// Types
import {
    PROGRAM, PROGRAM_LOADING, PROGRAM_CHARGING,
    PROGRAM_LIST, PROGRAM_ELEM, PROGRAM_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrograms(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "COURSE":
            params.courseID = elemID;
            data = await Program.getAllForCourse(params);
            break;
        default:
            data = await Program.getAll(params);
        }
        data.sort = params;
        dispatch({ type : PROGRAM_LIST, data });
        dispatch(setSection(PROGRAM));
    };
}

/**
 * Fetches a single Program
 * @param {Number}   programID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProgram(programID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PROGRAM_CHARGING });
        }
        const data = await Program.getOne({ programID });
        dispatch({ type : PROGRAM_ELEM, data });
    };
}

/**
 * Fetches the Program Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProgramCreate() {
    return async (dispatch) => {
        const data = await Program.getCreateData();
        dispatch({ type : PROGRAM_CREATE, data });
    };
}



/**
 * Edits/Creates a Program
 * @param {Object} data
 * @returns {() => Void}
 */
export function editProgram(data) {
    return () => Program.edit(data);
}

/**
 * Deletes a Program
 * @param {Number} programID
 * @returns {() => Void}
 */
export function deleteProgram(programID) {
    return () => Program.delete({ programID });
}
