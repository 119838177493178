import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchLocation }    from "Actions/Admin/Location/LocationActions";

// Sections
import RoomList             from "Components/Admin/Location/Room/RoomList";
import ScheduleList         from "Components/Admin/Location/Schedule/ScheduleList";
import StrechList           from "Components/Admin/Strech/Strech/StrechList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";
import MeetingList          from "Components/Admin/Strech/Meeting/MeetingList";

// Components
import LocationDetails      from "./LocationDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Location Page
 */
class LocationPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchLocation(this.props.params.locationID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.locationID !== prevProps.params.locationID) {
            this.props.fetchLocation(this.props.params.locationID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="LOCATIONS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="ROOMS_NAME"         url="ROOMS"         icon="room"         />
                <NavigationItem message="SCHEDULES_NAME"     url="SCHEDULES"     icon="schedule"     />
                <NavigationItem message="STRECHES_NAME"      url="STRECHES"      icon="strech"       isHidden={!elem.forStreches}      />
                <NavigationItem message="CONVERSATIONS_NAME" url="CONVERSATIONS" icon="conversation" isHidden={!elem.forConversations} />
                <NavigationItem message="MEETINGS_NAME"      url="MEETINGS"      icon="meeting"      isHidden={!elem.forMeetings}      />
            </SecondaryNav>

            <SwitchRoute type="LOCATION" path={path} baseUrl={url} withDetails>
                <AdminRoute url="ROOMS"         component={RoomList}         />
                <AdminRoute url="SCHEDULES"     component={ScheduleList}     />
                <AdminRoute url="STRECHES"      component={StrechList}       />
                <AdminRoute url="CONVERSATIONS" component={ConversationList} />
                <AdminRoute url="MEETINGS"      component={MeetingList}      />
            </SwitchRoute>

            <LocationDetails locationID={params.locationID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLocation : PropTypes.func.isRequired,
        elem          : PropTypes.object.isRequired,
        from          : PropTypes.string.isRequired,
        params        : PropTypes.object.isRequired,
        match         : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.location.elem,
        };
    }
}

export default connect(LocationPage.mapStateToProps, {
    fetchLocation,
})(LocationPage);
