import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Sections
import SectionList          from "Components/Admin/Ticket/Help/SectionList";
import QuestionList         from "Components/Admin/Ticket/Help/QuestionList";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";



/**
 * The Support Config Page
 */
class SupportConfigPage extends React.Component {
    // The Current State
    state = {
        filter : "sections",
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.setState({ filter : elemID });
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { section, question, route } = this.props;
        const { action, elemID, filter   } = this.state;

        const isSections  = filter === "sections";
        const isQuestions = filter === "questions";
        const data        = isSections ? section : question;

        return <Main>
            <Header message="HELP_NAME" icon="help" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <TabList selected={filter} onAction={this.startAction}>
                    <TabItem message="SECTIONS_NAME"  value="sections"  />
                    <TabItem message="QUESTIONS_NAME" value="questions" />
                </TabList>
                {isSections && <SectionList
                    startAction={this.startAction}
                    endAction={this.endAction}
                    action={action}
                    elemID={elemID}
                    forSupport
                />}
                {isQuestions && <QuestionList
                    startAction={this.startAction}
                    endAction={this.endAction}
                    action={action}
                    elemID={elemID}
                    forSupport
                />}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        section  : PropTypes.object.isRequired,
        question : PropTypes.object.isRequired,
        params   : PropTypes.object.isRequired,
        route    : PropTypes.string.isRequired,
        elemID   : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            section  : state.helpSection,
            question : state.helpQuestion,
        };
    }
}

export default connect(SupportConfigPage.mapStateToProps)(SupportConfigPage);
