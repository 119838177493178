import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import AvatarList           from "dashboard/dist/Components/AvatarList";
import AvatarItem           from "dashboard/dist/Components/AvatarItem";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchLeadEdit, assignLead,
} from "Actions/Admin/Lead/LeadActions";



/**
 * The Lead Assign Dialog
 */
class LeadAssign extends React.Component {
    // The Initial Data
    initialData = {
        leadID       : 0,
        assignedUser : 0,
        reason       : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchLeadEdit } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchLeadEdit(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = Utils.extend(this.initialData, elem);
            data.reason = "";
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
     handleChange = (name, value) => {
        this.setState({
            data : { ...this.state.data, [name] : value },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.assignLead(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, credentials } = this.props;
        const { data, loading              } = this.state;

        return <EditDialog
            open={open}
            icon="assign"
            title="LEADS_ASSIGN_TITLE"
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            withSpacing={false}
            isNarrow
        >
            <AvatarList>
                {credentials.map((elem) => <AvatarItem
                    key={elem.credentialID}
                    isSelected={data.assignedUser === elem.credentialID}
                    name={elem.credentialName}
                    email={elem.email}
                    avatar={elem.avatar}
                    onClick={() => this.handleChange("assignedUser", elem.credentialID)}
                />)}
            </AvatarList>
            <div className="spacing">
                <InputField
                    name="reason"
                    label="LEADS_ASSIGNED_REASON"
                    onChange={this.handleChange}
                />
            </div>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLeadEdit : PropTypes.func.isRequired,
        assignLead    : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        credentials   : PropTypes.array.isRequired,
        elem          : PropTypes.object.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition     : state.lead.edition,
            credentials : state.lead.credentials,
            elem        : state.lead.elem,
        };
    }
}

export default connect(LeadAssign.mapStateToProps, {
    fetchLeadEdit, assignLead,
})(LeadAssign);
