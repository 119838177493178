import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import ListContent          from "dashboard/dist/Components/ListContent";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchPresentism, togglePresentism,
} from "Actions/Admin/Strech/PresentismActions";



/**
 * The Presentism Edit Dialog
 */
class PresentismEdit extends React.Component {
    // The Current State
    state = {
        loading : false,
        sending : false,
        update  : false,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, strechID, strechClassID, fetchPresentism } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchPresentism(strechID, strechClassID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }

    /**
     * Handles the Toggle
     * @param {Object} elem
     * @returns {Promise}
     */
    handleToggle = async (elem) => {
        const { strechID, strechClassID, togglePresentism } = this.props;
        const { sending                                   } = this.state;

        if (!sending) {
            this.setState({ sending : true });
            try {
                elem.attended = !elem.attended;
                await togglePresentism(strechID, strechClassID, elem.credentialID, elem.attended ? 1 : 0);
                this.setState({ sending : false, update : true });
            } catch {
                this.setState({ sending : false });
            }
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
        this.setState({ update : false });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, elem, students } = this.props;
        const { loading              } = this.state;

        return <ViewDialog
            open={open}
            icon="presentism"
            title="PRESENTISM_EDIT_TITLE"
            onClose={this.handleClose}
            isLoading={loading}
        >
            <InfoList>
                <InfoItem label="CLASSES_SINGULAR" message={elem.name}      />
                <InfoItem label="GENERAL_DAY"      message={elem.date}      />
                <InfoItem label="COACHES_SINGULAR" message={elem.coachName} />
            </InfoList>

            <List withSpacing>
                {students.map((elem) => <ListItem key={elem.credentialID}>
                    <ListContent>{elem.credentialName}</ListContent>
                    <InputField
                        type="toggle"
                        name="attended"
                        label={elem.attended ? "PRESENTISM_ASSISTED" : "PRESENTISM_DIDNT_ASSISTED"}
                        value={!!elem.attended}
                        onChange={() => this.handleToggle(elem)}
                    />
                </ListItem>)}
            </List>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPresentism  : PropTypes.func.isRequired,
        togglePresentism : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        canEdit          : PropTypes.bool.isRequired,
        elem             : PropTypes.object.isRequired,
        students         : PropTypes.array.isRequired,
        strechID         : PropTypes.number,
        strechClassID    : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.presentism.edition,
            canEdit  : state.presentism.canEdit,
            elem     : state.presentism.elem,
            students : state.presentism.students,
        };
    }
}

export default connect(PresentismEdit.mapStateToProps, {
    fetchPresentism, togglePresentism,
})(PresentismEdit);
