import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGroup }       from "Actions/Admin/Strech/GroupActions";

// Sections
import MemberList           from "Components/Admin/Strech/Group/MemberList";
import MeetingList          from "Components/Admin/Strech/Meeting/MeetingList";

// Components
import GroupDetails         from "./GroupDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import UserRoute            from "Components/Utils/Route/UserRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Group Page
 */
class GroupPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchGroup(this.props.params.groupID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.groupID !== prevProps.params.groupID) {
            this.props.fetchGroup(this.props.params.groupID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="GROUPS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="STUDENTS_NAME" url="STUDENTS" icon="student" />
                <NavigationItem message="MEETINGS_NAME" url="MEETINGS" icon="meeting" />
            </SecondaryNav>

            <SwitchRoute type="GROUP" path={path} baseUrl={url} withDetails>
                <UserRoute url="STUDENTS" component={MemberList}  />
                <UserRoute url="MEETINGS" component={MeetingList} />
            </SwitchRoute>

            <GroupDetails groupID={params.groupID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroup : PropTypes.func.isRequired,
        elem       : PropTypes.object.isRequired,
        from       : PropTypes.string.isRequired,
        params     : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
        route      : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.strechGroup.elem,
        };
    }
}

export default connect(GroupPage.mapStateToProps, {
    fetchGroup,
})(GroupPage);
