import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Html                 from "dashboard/dist/Components/Html";
import Alert                from "dashboard/dist/Components/Alert";
import Button               from "dashboard/dist/Components/Button";
import HyperLink            from "dashboard/dist/Components/HyperLink";



/**
 * The Auth Form Component
 */
class AuthForm extends React.Component {
    /**
     * Handles the Submit
     * @param {React.FormEvent<HTMLFormElement>} e
     * @returns {Void}
     */
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { email, title, help, error, link, onEmail, onClick, onSubmit, isLoading, children } = this.props;

        const hasEmail  = Boolean(email);
        const hasHelp   = Boolean(help);
        const hasLink   = Boolean(link && onClick);
        const hasButton = Boolean(onSubmit);

        return <section className="auth-container">
            <form className="auth-content auth-form" onSubmit={this.handleSubmit}>
                <h2 className="auth-title">{NLS.get(title)}</h2>
                {hasEmail && <h3 onClick={onEmail}>{email}</h3>}
                {hasHelp  && <Html variant="p">{NLS.get(help)}</Html>}
                <Alert variant="error" message={error} />

                {children}

                <div className={hasLink ? "auth-footer" : "auth-footer-btn"}>
                    {hasLink && <div className="auth-link">
                        <HyperLink
                            variant="primary"
                            message={link}
                            onClick={onClick}
                            useBase
                        />
                    </div>}
                    {hasButton && <Button
                        variant="primary"
                        onClick={this.handleSubmit}
                        isDisabled={isLoading}
                        message="GENERAL_NEXT"
                    />}
                </div>
            </form>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        onSubmit  : PropTypes.func,
        title     : PropTypes.string,
        email     : PropTypes.string,
        help      : PropTypes.string,
        error     : PropTypes.string,
        link      : PropTypes.string,
        onEmail   : PropTypes.func,
        onClick   : PropTypes.func,
        isLoading : PropTypes.bool,
        children  : PropTypes.any,
    }
}

export default AuthForm;
