import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchChat }        from "Actions/Admin/Strech/ChatActions";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Strech Chat View Dialog
 */
class ChatView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchChat } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchChat(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading             } = this.state;

        return <ViewDialog
            open={open}
            icon="chat"
            title={elem.name || "CHATS_VIEW_TITLE"}
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField label="CHATS_DESCRIPTION" value={elem.description}    />
            <ViewField label="CHATS_COACH"       value={elem.credentialName} />
            <ViewField label="CHATS_DATE"        value={elem.timeString}     />
            {elem.isVirtual && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={elem.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={elem.streamPassword} />
            </>}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchChat : PropTypes.func.isRequired,
        open      : PropTypes.bool.isRequired,
        onClose   : PropTypes.func.isRequired,
        edition   : PropTypes.number.isRequired,
        elem      : PropTypes.object.isRequired,
        elemID    : PropTypes.number,
        strechID  : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.strechChat.edition,
            elem    : state.strechChat.elem,
        };
    }
}

export default connect(ChatView.mapStateToProps, {
    fetchChat,
})(ChatView);
