import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";

// Actions
import {
    fetchError, resolveError
} from "Actions/Admin/Setup/ErrorLogActions";



/**
 * The Error Log View Dialog
 */
class ErrorLogView extends React.Component {
    // The Current State
    state = {
        loading : false,
        update  : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchError } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchError(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }

    /**
     * Prints a Code
     * @returns {Promise}
     */
    resolveError = async () => {
        const { elemID, resolveError, fetchError } = this.props;
        const response = await resolveError(elemID);
        if (response.success) {
            fetchError(elemID);
            this.setState({ update : true });
        }
    }

    /**
     * Handles the Dialog Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open } = this.props;
        const { loading    } = this.state;

        return <ViewDialog
            open={open}
            icon="error"
            title="ERRORS_LOG_VIEW_TITLE"
            secondary={!elem.isResolved ? "ERRORS_LOG_RESOLVE" : ""}
            onSecondary={this.resolveError}
            onClose={this.handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns>
                <ViewField label="GENERAL_TIME"        value={elem.updatedDate}  />
                <ViewField label="ERRORS_LOG_SINGULAR" value={elem.errorCode}    />
                <ViewField label="ERRORS_LOG_AMOUNT"   value={elem.amount}       />
                <ViewField label="GENERAL_STATUS"      value={elem.resolvedText} />
            </Columns>
            <ViewField label="GENERAL_DESCRIPTION" value={elem.description} />
            <ViewField label="GENERAL_FILE"        value={elem.fileLine}    />
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchError   : PropTypes.func.isRequired,
        resolveError : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        elem         : PropTypes.object.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.errorLog.edition,
            elem    : state.errorLog.elem,
        };
    }
}

export default connect(ErrorLogView.mapStateToProps, {
    fetchError, resolveError,
})(ErrorLogView);
