import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";

// Actions
import {
    fetchCampaignContent,
} from "Actions/Admin/Campaign/CampaignActions";

// Styles
import "Styles/Components/App/Campaign.css";



/**
 * The Campaign Content Page
 */
class ContentPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCampaignContent(this.props.params.campaignID);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, route, elem, content } = this.props;

        return <Main>
            <Header message="CAMPAIGNS_CONTENT_TITLE" icon="media" route={route} />
            <Content isLoading={charging}>
                {!!content ? <iframe
                    className="campaign-content"
                    title={elem.subject}
                    src={"data:text/html," + encodeURIComponent(content)}
                /> : <NoneAvailable message="CAMPAIGNS_CONTENT_NONE" />}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCampaignContent : PropTypes.func.isRequired,
        charging             : PropTypes.bool.isRequired,
        elem                 : PropTypes.object.isRequired,
        content              : PropTypes.string.isRequired,
        params               : PropTypes.object.isRequired,
        route                : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.campaign.charging,
            elem     : state.campaign.elem,
            content  : state.campaign.content,
        };
    }
}

export default connect(ContentPage.mapStateToProps, {
    fetchCampaignContent,
})(ContentPage);
