import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";

// Components
import FilterDialog         from "dashboard/dist/Components/FilterDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchStudentsFilter,
} from "Actions/Admin/User/StudentActions";



/**
 * The Student Filter Dialog
 */
class StudentFilter extends React.Component {
    // The Initial Data
    initialData = {
        courseID     : 0,
        gender       : 0,
        countryID    : 0,
        provinceID   : 0,
        subscription : "",
        status       : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, filters, edition, fetchStudentsFilter } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = {};
            // Load new data
            fetchStudentsFilter();
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData, ...filters };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data = { ...this.state.data, [name] : value };
        this.setState({ data });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onSubmit, onClose, courses, countries, provinces } = this.props;
        const { loading, data                                          } = this.state;

        const Arrayextraoptions = [];
        Arrayextraoptions.push ({key: -1, value: "GENERAL_NONE"});

        return <FilterDialog
            open={open}
            title="STUDENTS_FILTER_TITLE"
            initialData={this.initialData}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="courseID"
                label="COURSES_COMPLETED_SINGULAR"
                value={data.courseID}
                onChange={this.handleChange}
                options={courses}
                extraOptions={Arrayextraoptions}
                noneText="GENERAL_ANY"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="gender"
                label="USERS_GENDER"
                value={data.gender}
                onChange={this.handleChange}
                options="USERS_GENDERS"
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
            <Columns>
                <InputField
                    type="select"
                    name="countryID"
                    label="USERS_COUNTRY"
                    value={data.countryID}
                    onChange={this.handleChange}
                    options={countries}
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="provinceID"
                    label="PROVINCES_SINGULAR"
                    value={data.provinceID}
                    onChange={this.handleChange}
                    options={data.countryID ? provinces[data.countryID] || [] : []}
                    noneText="GENERAL_ALL_FEM"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="subscription"
                    label="GENERAL_SUBSCRIPTION"
                    value={data.subscription}
                    onChange={this.handleChange}
                    options="SUBSCRIPTIONS"
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    onChange={this.handleChange}
                    options={Status.getSelect("STUDENT")}
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
            </Columns>
        </FilterDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudentsFilter : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        onClose             : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        courses             : PropTypes.array.isRequired,
        countries           : PropTypes.array.isRequired,
        provinces           : PropTypes.object.isRequired,
        filters             : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            edition   : state.student.edition,
            courses   : state.student.courses,
            countries : state.student.countries,
            provinces : state.student.provinces,
        };
    }
}

export default connect(StudentFilter.mapStateToProps, {
    fetchStudentsFilter,
})(StudentFilter);
