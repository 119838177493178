import { setSection }       from "Actions/Core/CoreActions";
import { Profile }          from "Utils/API";

// Types
import {
    PROFILE, PROFILE_LOADING, PROFILE_ELEM, PROFILE_UPLOAD,
} from "Utils/Types";




/**
 * Fetches the current Credential's Profile
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchProfile(withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PROFILE_LOADING });
        }
        const data = await Profile.get();
        dispatch({ type : PROFILE_ELEM, data });
        dispatch(setSection(PROFILE));
    };
}



/**
 * Edits the Credential's Profile
 * @param {Object} data
 * @returns {() => Void}
 */
export function editProfile(data) {
    return () => Profile.edit(data);
}

/**
 * Uploads the Credential's Avatar
 * @param {Object} image
 * @returns {() => Void}
 */
export function uploadProfile(image) {
    return async (dispatch) => {
        await Profile.upload({ image });
        dispatch({ type : PROFILE_UPLOAD });
    };
}

/**
 * Resends the Credential's Confirmation Email
 * @returns {() => Void}
 */
export function sendConfirmation() {
    return () => Profile.sendConfirmation();
}
