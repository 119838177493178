import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";
import TicketUse            from "Utils/Entity/TicketUse";
import Common               from "Utils/Common";

// Dialogs
import TicketView           from "./TicketView";
import TicketEdit           from "./TicketEdit";
import TicketAssign         from "./TicketAssign";
import AssignmentEdit       from "Components/Admin/Strech/Assignment/AssignmentEdit";
import GroupChange          from "Components/Admin/Strech/Group/GroupChange";
import CoachPrices          from "Components/Admin/Coach/CoachPrices";

// Components
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchTickets, deleteTicket,
    openTicket, closeTicket,
    muteTicket, unmuteTicket,
} from "Actions/Admin/Ticket/TicketActions";



/**
 * The Ticket Content
 */
class TicketContent extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Reload the Data
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            this.fetch();
        }
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data, filter, forSupport, fetchTickets } = this.props;
        fetchTickets(type, elemID, params || data.sort, filter, forSupport, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isMute) {
            this.muteElem(elemID);
        } else if (action.isUnmute) {
            this.unmuteElem(elemID);
        } else if (action.isUser) {
            const user = Utils.getValue(this.props.data.list, "ticketID", elemID);
            Href.goto(Common.getAccessBaseUrl(user, "user"), user.userID);
        } else {
            this.props.startAction(action, elemID);
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.props.endAction();
        if (update) {
            this.fetch(null, false);
        }
    }



    /**
     * Returns true if it can edit the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEdit(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "ticketID", elemID, "canEdit");
    }

    /**
     * Returns true if it can use the Ticket
     * @param {Number} elemID
     * @param {Number} use
     * @returns {Boolean}
     */
    canUse(elemID, use) {
        const { list } = this.props.data;
        const ticket = Utils.getValue(list, "ticketID", elemID);
        return ticket.canEdit && Number(ticket.ticketTypeUse) === use;
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { selectedID, deleteTicket, forSupport } = this.props;
        const response = await deleteTicket(selectedID, forSupport);
        this.endAction(response.success);
    }



    /**
     * Returns true if it can open the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canOpen(elemID) {
        const { canOpen, list } = this.props.data;
        return canOpen && Utils.getValue(list, "ticketID", elemID, "canOpen");
    }

    /**
     * Returns true if it can close the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canClose(elemID) {
        const { canClose, list } = this.props.data;
        return canClose && Utils.getValue(list, "ticketID", elemID, "canClose");
    }

    /**
     * Handles the Open Submit
     * @returns {Promise}
     */
    openElem = async () => {
        const { selectedID, openTicket, forSupport } = this.props;
        if (this.canOpen(selectedID)) {
            const response = await openTicket(selectedID, forSupport);
            this.endAction(response.success);
        }
    }

    /**
     * Handles the Close Submit
     * @returns {Promise}
     */
    closeElem = async () => {
        const { selectedID, closeTicket, forSupport } = this.props;
        if (this.canClose(selectedID)) {
            const response = await closeTicket(selectedID, forSupport);
            this.endAction(response.success);
        }
    }



    /**
     * Returns true if it can mute the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canMute(elemID) {
        const { list, canAssign } = this.props.data;
        const isMute = Utils.getValue(list, "ticketID", elemID, "isMute");
        return canAssign && !isMute;
    }

    /**
     * Handles the Mute Submit
     * @param {Number} elemID
     * @returns {Promise}
     */
    muteElem = async (elemID) => {
        const { muteTicket, forSupport } = this.props;
        if (this.canMute(elemID)) {
            const response = await muteTicket(elemID, forSupport);
            this.endAction(response.success);
        }
    }

    /**
     * Returns true if it can unmute the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canUnmute(elemID) {
        const { list, canAssign } = this.props.data;
        const isMute = Utils.getValue(list, "ticketID", elemID, "isMute");
        return canAssign && isMute;
    }

    /**
     * Handles the Unmute Submit
     * @param {Number} elemID
     * @returns {Promise}
     */
    unmuteElem = async (elemID) => {
        const { unmuteTicket, forSupport } = this.props;
        if (!this.canMute(elemID)) {
            const response = await unmuteTicket(elemID, forSupport);
            this.endAction(response.success);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, type, params, action, selectedID, forSupport } = this.props;
        const { canEdit, canAssign, list, total, sort, loading     } = data;

        const forTickets = !forSupport && type === "ADMIN";
        const prefix     = forSupport ? "SUPPORT_" : "TICKETS_";
        const elem       = Utils.getValue(list, "ticketID", selectedID);

        return <>
            <Table
                fetch={this.fetch}
                sort={sort}
                none={`${prefix}NONE_AVAILABLE`}
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="ticketTypeName" message="GENERAL_TYPE"       isTitle />
                    <TableHeader field="userFirstName"  message="USERS_SINGULAR"     isHidden={!forSupport} />
                    <TableHeader field="userFirstName"  message="STUDENTS_SINGULAR"  isHidden={!forTickets} />
                    <TableHeader field="userFirstName"  message="COACHES_SINGULAR"   isHidden={!forTickets} />
                    <TableHeader field="adminFirstName" message="TICKETS_ASSIGNED"   />
                    <TableHeader field="lastReply"      message="GENERAL_LAST_REPLY" maxWidth="180" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.ticketID} elemID={elem.ticketID}>
                        <TableCell message={elem.ticketTypeName}  />
                        <TableCell message={elem.userName}        />
                        <TableCell message={elem.studentName}     />
                        <TableCell message={elem.coachName}       />
                        <TableCell message={elem.adminName}       />
                        <TableCell message={elem.lastReplyString} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={this.startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message={`${prefix}VIEW_TITLE`}   />
                    <TableAction action="COACH"  message="INSC_ASSIGNS_EDIT_TITLE" hide={(elemID) => !this.canUse(elemID, TicketUse.CHANGE_COACH)}  />
                    <TableAction action="GROUP"  message="GROUPS_CHANGE_TITLE"     hide={(elemID) => !this.canUse(elemID, TicketUse.CHANGE_GROUP)}  />
                    <TableAction action="PRICES" message="COACH_PRICES_NAME"       hide={(elemID) => !this.canUse(elemID, TicketUse.CHANGE_PRICES)} />
                    <TableAction action="EDIT"   message={`${prefix}EDIT_TITLE`}   hide={(elemID) => !this.canEdit(elemID)}   />
                    <TableAction action="DELETE" message={`${prefix}DELETE_TITLE`} hide={(elemID) => !this.canEdit(elemID)}   />
                    <TableAction action="ASSIGN" message={`${prefix}ASSIGN_TITLE`} isHidden={!canAssign} />
                    <TableAction action="OPEN"   message={`${prefix}OPEN_TITLE`}   hide={(elemID) => !this.canOpen(elemID)}   />
                    <TableAction action="CLOSE"  message={`${prefix}CLOSE_TITLE`}  hide={(elemID) => !this.canClose(elemID)}  />
                    <TableAction action="MUTE"   message={`${prefix}MUTE_TITLE`}   hide={(elemID) => !this.canMute(elemID)}   />
                    <TableAction action="UNMUTE" message={`${prefix}UNMUTE_TITLE`} hide={(elemID) => !this.canUnmute(elemID)} />
                    <TableAction action="USER"   message="USERS_VIEW_TITLE"        isHidden={!forTickets} />
                </TableActionList>
            </Table>

            <TicketView
                forSupport={forSupport}
                open={action.isView}
                elemID={selectedID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <TicketEdit
                forSupport={forSupport}
                open={action.isCE}
                elemID={selectedID}
                studentID={params.studentID}
                coachID={params.coachID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <TicketAssign
                forSupport={forSupport}
                open={action.isAssign}
                elemID={selectedID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />

            <ConfirmDialog
                open={action.isOpen}
                icon="status"
                title={`${prefix}OPEN_TITLE`}
                message={`${prefix}OPEN_TEXT`}
                content={elem.ticketTypeName}
                onSubmit={this.openElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isClose}
                icon="status"
                title={`${prefix}CLOSE_TITLE`}
                message={`${prefix}CLOSE_TEXT`}
                content={elem.ticketTypeName}
                onSubmit={this.closeElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title={`${prefix}DELETE_TITLE`}
                message={`${prefix}DELETE_TEXT`}
                content={elem.ticketTypeName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />

            <AssignmentEdit
                open={action.isCoach}
                strechID={elem.strechID}
                credentialID={elem.userID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <GroupChange
                open={action.isGroup}
                strechID={elem.strechID}
                credentialID={elem.userID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <CoachPrices
                open={action.isPrices}
                elemID={elem.userID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTickets : PropTypes.func.isRequired,
        deleteTicket : PropTypes.func.isRequired,
        openTicket   : PropTypes.func.isRequired,
        closeTicket  : PropTypes.func.isRequired,
        muteTicket   : PropTypes.func.isRequired,
        unmuteTicket : PropTypes.func.isRequired,
        startAction  : PropTypes.func.isRequired,
        endAction    : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        type         : PropTypes.string.isRequired,
        params       : PropTypes.object.isRequired,
        filter       : PropTypes.number.isRequired,
        elemID       : PropTypes.number,
        action       : PropTypes.object,
        selectedID   : PropTypes.number,
        forSupport   : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        forSupport : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.ticket,
        };
    }
}

export default connect(TicketContent.mapStateToProps, {
    fetchTickets, deleteTicket,
    openTicket, closeTicket,
    muteTicket, unmuteTicket,
})(TicketContent);
