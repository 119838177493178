import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchHome }        from "Actions/Student/HomeActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";

// Dialogs
import StrechView           from "./Views/StrechView";
import ClassView            from "./Views/ClassView";
import ChatView             from "./Views/ChatView";
import MeetingView          from "./Views/MeetingView";
import ConversationView     from "./Views/ConversationView";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import HyperLink            from "dashboard/dist/Components/HyperLink";
import MenuLink             from "dashboard/dist/Components/MenuLink";
import Icon                 from "dashboard/dist/Components/Icon";

// Styles
import "Styles/Components/App/Home.css";



/**
 * The Home Page
 */
class HomePage extends React.Component {
    // The Current State
    state = {
        dialog : "",
        elem   : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchHome();
    }

    /**
     * Opens a Dialog
     * @param {Object} elem
     * @returns {Void}
     */
    openDialog = (elem) => {
        this.setState({ dialog : elem.type, elem });
    }

    /**
     * Closes a Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ dialog : "", elem : {} });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, advice, activities, notAnswered, route } = this.props;
        const { dialog, elem                                    } = this.state;

        const hasToAnswer   = notAnswered.length > 0;
        const hasActivities = activities.length > 0;
        const showAdvice    = Boolean(advice && advice.imageUrl);

        return <Main>
            <Header message="HOME_NAME" icon="home" route={route} />
            <Content className="home" isLoading={loading}>
                <div className="home-content">
                    {hasToAnswer && <HyperLink
                        href={NLS.url("COURSES_MINE", notAnswered[0], "POLL")}
                        variant="none"
                        className="home-complete"
                        message="HOME_ANSWER_STRECH"
                    />}

                    {hasActivities && <div className="home-activities">
                        <h3 className="home-title">{NLS.get("HOME_ACTIVITIES")}</h3>
                        {activities.map((elem) => <MenuLink
                            key={elem.key}
                            className="home-activity"
                            variant="light"
                            html={`<i>${elem.date}</i> <span>${elem.text}</span>`}
                            onClick={() => this.openDialog(elem)}
                        />)}
                    </div>}
                    {!hasActivities && <>
                        <div className="welcome-info" onClick={() => Href.goto("ENROLL")}>
                            <Icon icon="inscription" />
                            <p>{NLS.get("WELCOME_ENROLL_INFO")}</p>
                        </div>
                        <div className="welcome-info" onClick={() => Href.goto("TICKETS")}>
                            <Icon icon="ticket" />
                            <p>{NLS.get("WELCOME_TICKET_INFO")}</p>
                        </div>
                    </>}
                </div>

                {showAdvice && <section>
                    <div className="home-advice">
                        <img src={advice.imageUrl} alt={advice.name} />
                    </div>
                </section>}
            </Content>

            <StrechView
                open={dialog === "strech"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ClassView
                open={dialog === "class"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ChatView
                open={dialog === "chat"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <MeetingView
                open={dialog === "meeting"}
                elem={elem}
                onClose={this.closeDialog}
            />
            <ConversationView
                open={dialog === "conversation"}
                elem={elem}
                onClose={this.closeDialog}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchHome   : PropTypes.func.isRequired,
        loading     : PropTypes.bool.isRequired,
        advice      : PropTypes.object,
        activities  : PropTypes.array.isRequired,
        notAnswered : PropTypes.array.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading     : state.home.loading,
            advice      : state.home.advice,
            activities  : state.home.activities,
            notAnswered : state.home.notAnswered,
        };
    }
}

export default connect(HomePage.mapStateToProps, {
    fetchHome,
})(HomePage);
