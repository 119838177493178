import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import StatusData           from "Utils/Status";
import Common               from "Utils/Common";

// Types
import {
    CONVERSATION_LOADING,
    CONVERSATION_LIST, CONVERSATION_ELEM,
    CONVERSATION_ASSIGN, CONVERSATION_REQUEST,
    CONVERSATION_CREATE, CONVERSATION_STUDENTS,
    CONVERSATION_EDIT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    edition      : 0,
    canAssign    : false,
    canCreate    : false,
    canEdit      : false,
    canRequest   : false,
    canProgram   : false,
    canDelete    : false,
    canImport    : false,
    canExport    : false,

    list         : [],
    total        : 0,

    elem         : {},
    questions    : [],

    streches     : [],
    specialities : {},
    speciality   : [],
    students     : [],
    locations    : [],
    rooms        : {},
    prices       : [],

    sort         : {
        filter   : StatusData.SCHEDULED,
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.createdString   = DateTime.formatString(elem.createdTime);
    elem.cancelledDate   = DateTime.formatString(elem.cancelledTime);
    elem.reportDate      = DateTime.formatString(elem.reportTime);
    elem.timeDateTime    = DateTime.formatDate(elem.time, "dashesTime");
    elem.timeString      = DateTime.formatString(elem.time);
    elem.timeDate        = elem.time ? DateTime.formatDate(elem.time, "dashesReverse") : "";
    elem.timeHour        = elem.time ? DateTime.formatDate(elem.time, "time")          : "";
    elem.theTime         = elem.time ? elem.timeString : elem.createdString;
    elem.coachString     = elem.coachName || NLS.get("GENERAL_NOBODY");
    elem.modeName        = Common.getModeName(elem.mode);
    elem.statusName      = Status.getFemName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.description     = NLS.format("CONVERSATIONS_DESCRIPTION", elem.userName, elem.coachName, elem.timeDateTime);

    const cancelledMessage = elem.cancelledNotice ? "CONVERSATIONS_CANCELLED_WITH" : "CONVERSATIONS_CANCELLED_WITHOUT";
    elem.cancelledString   = NLS.format(cancelledMessage, elem.cancelledDate, elem.cancelledReason);
    elem.reportString      = elem.reportNumber ? NLS.format("CONVERSATIONS_REPORT_TEXT", elem.reportNumber, elem.reportDate) : "";

    if (elem.isPresential) {
        elem.locationString = Common.combineLocation(elem);
    }
    if (elem.fromStudent) {
        elem.requestString = NLS.format("CONVERSATIONS_REQUEST_STUDENT", elem.studentName);
    } else {
        elem.requestString = NLS.format("CONVERSATIONS_REQUEST_COACH", elem.coacheeName);
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, CONVERSATION_LIST, CONVERSATION_ELEM, CONVERSATION_EDIT, CONVERSATION_CREATE, CONVERSATION_STUDENTS, CONVERSATION_REQUEST, CONVERSATION_ASSIGN)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case CONVERSATION_LOADING:
        return {
            ...state,
            loading     : true,
        };

    case CONVERSATION_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            canAssign    : action.data.canAssign,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canRequest   : action.data.canRequest,
            canProgram   : action.data.canProgram,
            canDelete    : action.data.canDelete,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            list         : parseList(action.data.list),
            total        : action.data.total,
            sort         : action.data.sort,
        };

    case CONVERSATION_ELEM:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            canDelete    : action.data.canDelete,
            elem         : parseElem(action.data.elem),
            questions    : Common.parseQuestions(action.data.questions, action.data.elem),
        };

    case CONVERSATION_ASSIGN:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            speciality   : action.data.speciality,
        };

    case CONVERSATION_REQUEST:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            streches     : action.data.streches,
            specialities : action.data.specialities,
        };

    case CONVERSATION_CREATE:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            streches     : action.data.streches,
            specialities : action.data.specialities,
            locations    : action.data.locations,
            rooms        : action.data.rooms,
        };

    case CONVERSATION_STUDENTS:
        return {
            ...state,
            students     : action.data.students,
        };

    case CONVERSATION_EDIT:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            locations    : action.data.locations,
            rooms        : action.data.rooms,
        };

    default:
        return state;
    }
};

export default reducer;
