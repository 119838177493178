import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Course }           from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import CourseEdit           from "./CourseEdit";
import CourseUrl            from "./CourseUrl";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchCourses, deleteCourse,
} from "Actions/Admin/Course/CourseActions";



/**
 * The Course List
 */
class CourseList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        this.props.fetchCourses(params || this.props.data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        await this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @param {Boolean} edit
     * @returns {Promise}
     */
    endAction = async (edit) => {
        await this.startAction(Action.get(), 0);
        if (edit) {
            this.startAction(Action.get("EDIT"), this.state.elemID);
        }
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(false);
        this.fetch(null, false);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction(false);
        await this.props.deleteCourse(elemID);
        this.fetch(null, false);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "courseID", elemID, "name");

        return <Main>
            <Header message="COURSES_NAME" icon="course" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="COURSES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="name"            message="GENERAL_NAME"        bigMobile />
                        <TableHeader field="url"             message="GENERAL_URL"         />
                        <TableHeader field="correlativeName" message="COURSES_CORRELATIVE" />
                        <TableHeader field="position"        message="GENERAL_POSITION"    maxWidth="80" align="center" hideMobile />
                        <TableHeader field="createdTime"     message="GENERAL_CREATED"     maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.courseID} elemID={elem.courseID}>
                            <TableCell message={elem.name}            circle={elem.statusColor} />
                            <TableCell message={elem.url}             />
                            <TableCell message={elem.correlativeName} />
                            <TableCell message={elem.position}        />
                            <TableCell message={elem.createdDate}     />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="COURSES_VIEW_TITLE"     route={route} />
                        <TableAction action="URL"    message="COURSES_VIEW_URL_TITLE" />
                        <TableAction action="EDIT"   message="COURSES_EDIT_TITLE"     />
                        <TableAction action="DELETE" message="COURSES_DELETE_TITLE"   />
                    </TableActionList>
                </Table>
            </Content>

            <CourseEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CourseUrl
                open={action.isUrl}
                elemID={elemID}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="COURSES_DELETE_TITLE"
                message="COURSES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <Downloader
                download={action.isExport}
                source={Course.export()}
                onLoad={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCourses : PropTypes.func.isRequired,
        deleteCourse : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.course,
        };
    }
}

export default connect(CourseList.mapStateToProps, {
    fetchCourses, deleteCourse,
})(CourseList);
