import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachProfesion }    from "Utils/API";

// Types
import {
    COACH_PROFESION, COACH_PROFESION_LOADING,
    COACH_PROFESION_LIST, COACH_PROFESION_ELEM,
} from "Utils/Types";



/**
 * Fetches the Coach PROFESION List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProfesions(params = {}) {
    return async (dispatch) => {
        dispatch({ type : COACH_PROFESION_LOADING });
        Params.unset(params);
        const data = await CoachProfesion.getAll(params);
        data.sort = params;
        dispatch({ type : COACH_PROFESION_LIST, data });
        dispatch(setSection(COACH_PROFESION));
    };
}

/**
 * Fetches a single Coach PROFESION
 * @param {Number} coachPROFESIONID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProfesion(coachProfesionID) {
    return async (dispatch) => {
        const data = await CoachProfesion.getOne({ coachProfesionID });
        dispatch({ type : COACH_PROFESION_ELEM, data });
    };
}



/**
 * Edits a Coach PROFESION
 * @param {Object} data
 * @returns {() => Void}
 */
export function editProfesion(data) {
    return () => CoachProfesion.edit(data);
}

/**
 * Deletes a Coach Proesion
 * @param {Number} coachProfesionID
 * @returns {() => Void}
 */
export function deleteProfesion(coachProfesionID) {
    return () => CoachProfesion.delete({ coachProfesionID });
}
