import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { EmailTemplate }    from "Utils/API";

// Types
import {
    EMAIL_TEMPLATE, EMAIL_TEMPLATE_LOADING,
    EMAIL_TEMPLATE_LIST, EMAIL_TEMPLATE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Email Template List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTemplates(params = {}) {
    return async (dispatch) => {
        dispatch({ type : EMAIL_TEMPLATE_LOADING });
        Params.unset(params);
        const data = await EmailTemplate.getAll(params);
        data.sort = params;
        dispatch({ type : EMAIL_TEMPLATE_LIST, data });
        dispatch(setSection(EMAIL_TEMPLATE));
    };
}

/**
 * Fetches a single Email Template
 * @param {String} templateCode
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTemplate(templateCode) {
    return async (dispatch) => {
        const data = await EmailTemplate.getOne({ templateCode });
        dispatch({ type : EMAIL_TEMPLATE_ELEM, data });
    };
}



/**
 * Edits an Email Template
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTemplate(data) {
    return () => EmailTemplate.edit(data);
}
