import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ErrorLogView         from "./ErrorLogView";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Filter               from "dashboard/dist/Components/Filter";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchErrorLog, resolveError,
} from "Actions/Admin/Setup/ErrorLogActions";



/**
 * The Error Log List
 */
class ErrorLogList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { data, fetchErrorLog } = this.props;
        fetchErrorLog(data.filter, params || data.sort, withLoader);
    }

    /**
     * Fetch the content
     * @param {Object} params
     * @returns {Promise}
     */
    filter = async (params) => {
        try {
            const { data, fetchErrorLog } = this.props;
            await fetchErrorLog(params, { ...data.sort, page : 0 });
        } catch (errors) {
            throw errors;
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        if (action.isResolve) {
            const response = await this.props.resolveError(elemID);
            if (response.success) {
                this.fetch(null, false);
            }
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(null, false);
        }
    }

    /**
     * Returns true if it can't resolve the Error
     * @param {Number} elemID
     * @returns {Boolean}
     */
    cantResolve = (elemID) => {
        const { canEdit, list } = this.props.data;
        const isResolved = Utils.getValue(list, "logID", elemID, "isResolved");
        return !(canEdit && !isResolved);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                              } = this.state;
        const { data, route                                 } = this.props;
        const { canEdit, list, total, sort, filter, loading } = data;

        return <Main>
            <Header message="ERRORS_LOG_LIST" icon="error" route={route} />
            <Content>
                <Filter onFilter={this.filter} values={filter} hasSearch labelInside />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="ERRORS_LOG_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="updatedTime" message="GENERAL_TIME"        />
                        <TableHeader field="error"       message="ERRORS_LOG_SINGULAR" />
                        <TableHeader field="description" message="GENERAL_DESCRIPTION" grow="2" />
                        <TableHeader field="file"        message="GENERAL_FILE"        grow="2" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                            <TableCell message={elem.updatedDate} circle={elem.resolvedColor} />
                            <TableCell message={elem.errorCode}   />
                            <TableCell message={elem.description} />
                            <TableCell message={elem.fileLine}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"    message="ERRORS_LOG_VIEW_TITLE" />
                        <TableAction action="RESOLVE" message="ERRORS_LOG_RESOLVE"    hide={this.cantResolve} />
                    </TableActionList>
                </Table>
            </Content>

            <ErrorLogView
                open={action.isVCE}
                elemID={elemID}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchErrorLog : PropTypes.func.isRequired,
        resolveError  : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        params        : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.errorLog,
        };
    }
}

export default connect(ErrorLogList.mapStateToProps, {
    fetchErrorLog, resolveError,
})(ErrorLogList);
