import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import DialogAction         from "dashboard/dist/Components/DialogAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import ViewField            from "dashboard/dist/Components/ViewField";

// Actions
import {
    fetchConversation, unrequestConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation View Dialog
 */
class ConversationView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        loading : false,
        update  : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchConversation } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchConversation(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        if (update !== undefined) {
            this.props.fetchConversation(this.props.elemID);
            this.setState({ action : Action.get(), update });
        } else {
            this.setState({ action : Action.get() });
        }
    }

    /**
     * Edits the Conversation
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Unrequests a Conversation
     * @returns {Promise}
     */
    unrequestElem = async () => {
        const { elemID, unrequestConversation, onClose } = this.props;
        await unrequestConversation(elemID);
        this.endAction(false);
        onClose(true);
    }

    /**
     * Handles de Dialog Closing
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, strechID } = this.props;
        const { action, loading      } = this.state;

        return <>
            <Dialog open={open} onClose={this.handleClose} isLoading={loading}>
                <DialogHeader message="CONVERSATIONS_VIEW_TITLE" icon="conversation" />
                <DialogBody withSpacing>
                    <ViewField
                        label="COACHES_SINGULAR"
                        value={elem.coachString}
                    />
                    <ViewField
                        isHidden={!!strechID || !elem.strechID}
                        label="CONVERSATIONS_COURSE"
                        value={elem.strechTitle}
                    />
                    <ViewField label="GENERAL_MODE" value={elem.modeName} />
                    {elem.isPresential && <ViewField
                        label="LOCATIONS_SINGULAR"
                        value={elem.locationString}
                    />}
                    {elem.hasStream && <>
                        <ViewField label="GENERAL_STREAM_LINK"     value={elem.streamLink}     />
                        <ViewField label="GENERAL_STREAM_PASSWORD" value={elem.streamPassword} />
                    </>}
                    <ViewField
                        label="GENERAL_DATE"
                        value={elem.timeString}
                    />
                    <ViewField
                        isHidden={!elem.isCancelled}
                        label="GENERAL_STATUS"
                        value={elem.cancelledString}
                    />
                </DialogBody>
                <DialogFooter onAction={this.startAction} cancel="GENERAL_CLOSE">
                    <DialogAction action="UNREQUEST" isHidden={!elem.canUnrequest} />
                </DialogFooter>
            </Dialog>

            <ConfirmDialog
                open={action.isUnrequest}
                icon="cancel"
                title="CONVERSATIONS_UNREQUEST_TITLE"
                message="CONVERSATIONS_UNREQUEST_TEXT"
                onSubmit={this.unrequestElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversation     : PropTypes.func.isRequired,
        unrequestConversation : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
        strechID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.conversation.edition,
            elem    : state.conversation.elem,
        };
    }
}

export default connect(ConversationView.mapStateToProps, {
    fetchConversation, unrequestConversation,
})(ConversationView);
