import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechGuide }      from "Utils/API";

// Types
import {
    STRECH_GUIDE, STRECH_GUIDE_LOADING,
    STRECH_GUIDE_LIST, STRECH_GUIDE_ELEM,
    STRECH_GUIDE_EDIT, STRECH_GUIDE_CREATE,
    STRECH_GUIDE_STUDENTS,
} from "Utils/Types";



/**
 * Fetches the Guide List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuides(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_GUIDE_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechGuide.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_GUIDE_LIST, data });
        dispatch(setSection(STRECH_GUIDE));
    };
}

/**
 * Fetches a single Guide
 * @param {Number} strechGuideID
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuide(strechGuideID, credentialID) {
    return async (dispatch) => {
        const data = await StrechGuide.getOne({ strechGuideID, credentialID });
        dispatch({ type : STRECH_GUIDE_ELEM, data });
    };
}

/**
 * Fetches the Guide Edit data
 * @param {Number} strechGuideID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuideEdit(strechGuideID) {
    return async (dispatch) => {
        const data = await StrechGuide.getEditData({ strechGuideID });
        dispatch({ type : STRECH_GUIDE_EDIT, data });
    };
}

/**
 * Fetches the Guide Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuideCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechGuide.getCreateData({ strechID });
        dispatch({ type : STRECH_GUIDE_CREATE, data });
    };
}

/**
 * Fetches the Guide Students data
 * @param {Number} strechGuideID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuideStudents(strechGuideID) {
    return async (dispatch) => {
        const data = await StrechGuide.getStudentData({ strechGuideID });
        dispatch({ type : STRECH_GUIDE_STUDENTS, data });
    };
}



/**
 * Edits/Creates a Guide
 * @param {Object} data
 * @returns {() => Void}
 */
export function editGuide(data) {
    return () => StrechGuide.edit(data);
}

/**
 * Deletes a Guide
 * @param {Number} strechGuideID
 * @returns {() => Void}
 */
export function deleteGuide(strechGuideID) {
    return () => StrechGuide.delete({ strechGuideID });
}

/**
 * Imports Guides
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function importGuides(strechID) {
    return () => StrechGuide.import({ strechID });
}



/**
 * Saves a Guide Answer
 * @param {Object} data
 * @returns {() => Void}
 */
export function saveGuideAnswer(data) {
    return () => StrechGuide.saveAnswer(data);
}

/**
 * Sends a Guide Answers
 * @param {Number} strechGuideID
 * @returns {() => Void}
 */
export function sendGuideAnswers(strechGuideID) {
    return () => StrechGuide.sendAnswers({ strechGuideID });
}



/**
 * Saves a Guide Feedback
 * @param {Object} data
 * @returns {() => Void}
 */
export function saveGuideFeedback(data) {
    return () => StrechGuide.saveFeedback(data);
}

/**
 * Sends a Guide Feedback
 * @param {Object} data
 * @returns {() => Void}
 */
export function sendGuideFeedback(data) {
    return () => StrechGuide.sendFeedback(data);
}
