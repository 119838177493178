import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STUDENT_LOADING, STUDENT_CHARGING,
    STUDENT_LIST, STUDENT_FILTER,
    STUDENT_ELEM, STUDENT_COACH, STUDENT_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    charging    : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    canFilter   : false,
    list        : [],
    total       : 0,
    elem        : {},
    inscription : {},
    idds        : [],
    countries   : [],
    provinces   : {},
    courses     : [],
    filters     : {},
    sort        : {
        orderBy  : "firstName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.loginDate         = DateTime.formatIf(elem.lastLogin, "dashes");
    elem.loginDateTime     = DateTime.formatIf(elem.lastLogin, "dashesTime");
    elem.birthDateTime     = DateTime.formatIf(elem.birthDate, "dashes");
    elem.phoneString       = Common.combinePhone(elem);
    elem.addressString     = Common.combineAddress(elem);
    elem.genderName        = Common.getGenderName(elem.gender);
    elem.conversationsText = NLS.format("GENERAL_X_OF_Y", elem.usedConversations, elem.totalConversations);
    elem.subscriptionName  = Common.getSubscriptionName(elem.subscription);
    elem.subscriptionClass = Common.getSubscriptionClass(elem.subscription);
    elem.statusName        = Status.getName(elem.status);
    elem.statusColor       = Status.getColor(elem.status);
    elem.statusClass       = Status.getTextClass(elem.status);
    return elem;
}

/**
 * Parses an Inscription
 * @param {Object} elem
 * @returns {Object}
 */
function parseInscription(elem) {
    elem.modesText = "";
    if (elem.modes && Array.isArray(elem.modes)) {
        elem.modes      = elem.modes.sort();
        elem.modesTexts = elem.modes.map((index) => NLS.get("MODES", index));
        elem.modesText  = NLS.join(elem.modesTexts, true);
    }
    elem.timesText = "";
    if (elem.times && Array.isArray(elem.times)) {
        elem.times      = elem.times.sort();
        elem.timesTexts = elem.times.map((index) => NLS.get("TIMES", index));
        elem.timesText  = NLS.join(elem.timesTexts, true);
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STUDENT_LIST, STUDENT_FILTER, STUDENT_ELEM, STUDENT_COACH, STUDENT_CREATE)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case STUDENT_LOADING:
        return {
            ...state,
            loading   : true,
        };
    case STUDENT_CHARGING:
        return {
            ...state,
            charging  : true,
        };

    case STUDENT_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            canFilter   : action.data.canFilter,
            list        : parseList(action.data.list),
            total       : action.data.total,
            filters     : action.data.filters,
            sort        : action.data.sort,
        };

    case STUDENT_FILTER:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            courses     : action.data.courses,
            countries   : action.data.countries,
            provinces   : action.data.provinces,
        };

    case STUDENT_ELEM:
        return {
            ...state,
            charging    : false,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            elem        : parseElem(action.data.elem),
            idds        : action.data.idds,
            countries   : action.data.countries,
            provinces   : action.data.provinces,
        };

    case STUDENT_COACH:
        return {
            ...state,
            charging    : false,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            elem        : parseElem(action.data.elem),
            inscription : parseInscription(action.data.inscription),
        };

    case STUDENT_CREATE:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            idds        : action.data.idds,
            countries   : action.data.countries,
            provinces   : action.data.provinces,
        };

    default:
        return state;
    }
};

export default reducer;
