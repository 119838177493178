import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Sections
import WelcomePage          from "Components/Student/Strech/WelcomePage";
import ClassPage            from "Components/Student/Strech/ClassPage";
import ConversationList     from "Components/Student/Conversation/ConversationList";
import MeetingList          from "Components/Student/Meeting/MeetingList";
import GroupPage            from "Components/Student/Strech/GroupPage";
import CertificatePage      from "Components/Student/Strech/CertificatePage";
import AnswerCreate         from "Components/Admin/Strech/Poll/AnswerCreate";

// Components
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import UserRoute            from "Components/Utils/Route/UserRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Strech Page
 */
class StrechPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchStrech(this.props.params.strechID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.strechID !== prevProps.params.strechID) {
            this.props.fetchStrech(this.props.params.strechID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, classes, params, match, route } = this.props;
        const { path                                } = match;

        const baseUrl           = NLS.baseUrl("COURSES_MINE", params.strechID);
        const showConversations = elem.hasStarted && elem.hasConversations && elem.conversations > 0;
        const showGroup         = elem.hasStarted && elem.hasGroups && elem.hasAssignedGroup;
        const canAnswer         = elem.hasEnded && elem.hasQuestions && !elem.hasAnswered;
        const showCertificate   = elem.hasCertificate && elem.hasEnded && elem.hasAnswered;

        return <>
            <SecondaryNav
                message={elem.title}
                fallback={elem.typeName || "COURSES_SINGULAR"}
                href={NLS.baseUrl("COURSES_MINE")}
                path={route}
                baseUrl={baseUrl}
            >
                <NavigationList>
                    <NavigationItem message="WELCOME_NAME"       url="WELCOME"       icon="home"         />
                    <NavigationItem message="CONVERSATIONS_MINE" url="CONVERSATIONS" icon="conversation" isHidden={!showConversations} />
                    <NavigationItem message="GROUPS_MINE"        url="GROUP_MINE"    icon="group"        isHidden={!showGroup} />
                    <NavigationItem message="MEETINGS_MINE"      url="MEETINGS"      icon="meeting"      isHidden={!showGroup} />
                    <NavigationItem message="POLL_NAME"          url="POLL"          icon="question"     isHidden={!canAnswer} />
                    <NavigationItem message="CERTIFICATE_NAME"   url="CERTIFICATE"   icon="certificate"  isHidden={!showCertificate} />
                </NavigationList>
                {elem.hasClasses && <NavigationList message="CLASSES_NAME">
                    {classes.map((elem, index) => <NavigationItem
                        key={elem.strechClassID}
                        message={`${index + 1}. ${elem.name}`}
                        href={NLS.url(baseUrl, "CLASSES", elem.strechClassID)}
                        isDisabled={!elem.hasStarted}
                        icon="class"
                        usePrefix
                    />)}
                </NavigationList>}
            </SecondaryNav>

            <SwitchRoute type="STRECH" path={path} baseUrl={baseUrl}>
                <UserRoute url="WELCOME"          component={WelcomePage}      />
                <UserRoute url="CLASS_MINE_GUIDE" component={ClassPage}        />
                <UserRoute url="CLASS_MINE"       component={ClassPage}        />
                <UserRoute url="CONVERSATIONS"    component={ConversationList} />
                <UserRoute url="GROUP_MINE"       component={GroupPage}        />
                <UserRoute url="MEETINGS"         component={MeetingList}      />
                <UserRoute url="POLL"             component={AnswerCreate}     />
                <UserRoute url="CERTIFICATE"      component={CertificatePage}  />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        elem        : PropTypes.object.isRequired,
        classes     : PropTypes.array.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem    : state.strech.elem,
            classes : state.strech.classes,
        };
    }
}

export default connect(StrechPage.mapStateToProps, {
    fetchStrech,
})(StrechPage);
