import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchConversationEdit, programConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation Program Dialog
 */
class ConversationProgram extends React.Component {
    // The Initial Data
    initialData = {
        conversationID : 0,
        mode           : 0,
        streamLink     : "",
        streamPassword : "",
        locationID     : 0,
        roomID         : 0,
        customLocation : "",
        timeDate       : "",
        timeHour       : "",
        observations   : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchConversationEdit } = this.props;

        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchConversationEdit(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { programConversation, onSubmit } = this.props;
        const { data, loading                 } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await programConversation(data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, locations, rooms } = this.props;
        const { data, loading, errors           } = this.state;

        const isPresential = Common.isPresentialMode(data.mode);
        const isVirtual    = Common.isVirtualMode(data.mode);
        const isCustom     = Common.isCustomLocation(data.locationID);

        return <EditDialog
            open={open}
            icon="conversation"
            title="CONVERSATIONS_PROGRAM_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="mode"
                label="CONVERSATIONS_MODE"
                value={data.mode}
                error={errors.mode}
                onChange={this.handleChange}
                options="MODES"
                withNone
                isRequired
            />
            <Columns>
                <InputField
                    isHidden={!isVirtual}
                    name="streamLink"
                    label="GENERAL_STREAM_LINK"
                    value={data.streamLink}
                    error={errors.streamLink}
                    onChange={this.handleChange}
                />
                <InputField
                    isHidden={!isVirtual}
                    name="streamPassword"
                    label="GENERAL_STREAM_PASSWORD"
                    value={data.streamPassword}
                    error={errors.streamPassword}
                    onChange={this.handleChange}
                />

                <InputField
                    isHidden={!isPresential}
                    type="select"
                    name="locationID"
                    label="CONVERSATIONS_LOCATION"
                    value={data.locationID}
                    error={errors.locationID}
                    onChange={this.handleChange}
                    options={locations}
                    withNone
                    withCustom
                    isRequired
                />
                <InputField
                    isHidden={!isPresential || isCustom}
                    type="select"
                    name="roomID"
                    label="CONVERSATIONS_ROOM"
                    value={data.roomID}
                    error={errors.roomID}
                    onChange={this.handleChange}
                    options={data.locationID > 0 ? rooms[data.locationID] || [] : []}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={!isPresential || !isCustom}
                    name="customLocation"
                    label="CONVERSATIONS_LOCATION_CUSTOM"
                    value={data.customLocation}
                    error={errors.customLocation}
                    onChange={this.handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="timeDate"
                    label="CONVERSATIONS_DATE"
                    value={data.timeDate}
                    error={errors.timeDate}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="timeHour"
                    label="CONVERSATIONS_HOUR"
                    value={data.timeHour}
                    error={errors.timeHour}
                    onChange={this.handleChange}
                    isRequired
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                helperText="CONVERSATIONS_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversationEdit : PropTypes.func.isRequired,
        programConversation   : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        onSubmit              : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        locations             : PropTypes.array.isRequired,
        rooms                 : PropTypes.object.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.conversation.edition,
            locations : state.conversation.locations,
            rooms     : state.conversation.rooms,
            elem      : state.conversation.elem,
        };
    }
}

export default connect(ConversationProgram.mapStateToProps, {
    fetchConversationEdit, programConversation,
})(ConversationProgram);
