import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechAssignment } from "Utils/API";

// Types
import {
    STRECH_ASSIGNMENT, STRECH_ASSIGNMENT_LOADING,
    STRECH_ASSIGNMENT_LIST, STRECH_ASSIGNMENT_ELEM,
} from "Utils/Types";



/**
 * Fetches the Assignments List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssignments(type = "", elemID = 0, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STRECH_ASSIGNMENT_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechAssignment.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_ASSIGNMENT_LIST, data });
        dispatch(setSection(STRECH_ASSIGNMENT));
    };
}

/**
 * Fetches a single Assignments
 * @param {Number}  inscriptionID
 * @param {Number=} strechID
 * @param {Number=} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssignment(inscriptionID, strechID = 0, credentialID = 0) {
    return async (dispatch) => {
        const data = await StrechAssignment.getOne({ inscriptionID, strechID, credentialID });
        dispatch({ type : STRECH_ASSIGNMENT_ELEM, data });
    };
}



/**
 * Edits the Assignments
 * @param {Object} data
 * @returns {() => Void}
 */
export function editAssignment(data) {
    return () => StrechAssignment.edit(data);
}
