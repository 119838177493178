import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";

// Sections
import ScheduleList         from "Components/Admin/Strech/Schedule/ScheduleList";
import CoachList            from "Components/Admin/Strech/Coach/CoachList";
import ChatList             from "Components/Admin/Strech/Chat/ChatList";
import ClassList            from "Components/Admin/Strech/Class/ClassList";
import MaterialList         from "Components/Admin/Strech/Material/MaterialList";
import GuideList            from "Components/Admin/Strech/Guide/GuideList";
import StudentList          from "Components/Coach/Student/StudentList";
import GroupList            from "Components/Admin/Strech/Group/GroupList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";

// Components
import StrechDetails        from "Components/Admin/Strech/Strech/StrechDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import CoachRoute           from "Components/Utils/Route/CoachRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Strech Page
 */
class StrechPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchStrech(this.props.params.strechID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.strechID !== prevProps.params.strechID) {
            this.props.fetchStrech(this.props.params.strechID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="STRECHES_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="COACHES_NAME"       url="COACHES"       icon="coach"        />
                <NavigationItem message="SCHEDULES_NAME"     url="SCHEDULES"     icon="schedule"     isHidden={!elem.hasClasses} />
                <NavigationItem message="CHATS_NAME"         url="CHATS"         icon="chat"         isHidden={!elem.hasChats} />
                <NavigationItem message="CLASSES_NAME"       url="CLASSES"       icon="class"        isHidden={!elem.hasClasses} />
                <NavigationItem message="MATERIALS_NAME"     url="MATERIALS"     icon="material"     isHidden={!elem.hasMaterials} />
                <NavigationItem message="GUIDES_NAME"        url="GUIDES"        icon="guide"        isHidden={!elem.hasGuides} usePrefix />
                <NavigationItem message="STUDENTS_NAME"      url="STUDENTS"      icon="student"      isHidden={!elem.hasConversations} />
                <NavigationItem message="GROUPS_NAME"        url="GROUPS"        icon="group"        isHidden={!elem.hasGroups} />
                <NavigationItem message="CONVERSATIONS_NAME" url="CONVERSATIONS" icon="conversation" isHidden={!elem.hasConversations} />
            </SecondaryNav>

            <SwitchRoute type="STRECH" path={path} baseUrl={url} withDetails>
                <CoachRoute url="COACHES"       component={CoachList}        />
                <CoachRoute url="SCHEDULES"     component={ScheduleList}     />
                <CoachRoute url="CHATS"         component={ChatList}         />
                <CoachRoute url="CLASSES"       component={ClassList}        />
                <CoachRoute url="MATERIALS"     component={MaterialList}     />
                <CoachRoute url="GUIDE_STUDENT" component={GuideList}        />
                <CoachRoute url="GUIDES"        component={GuideList}        />
                <CoachRoute url="STUDENTS"      component={StudentList}      />
                <CoachRoute url="GROUPS"        component={GroupList}        />
                <CoachRoute url="CONVERSATIONS" component={ConversationList} />
            </SwitchRoute>

            <StrechDetails strechID={params.strechID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        elem        : PropTypes.object.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.strech.elem,
        };
    }
}

export default connect(StrechPage.mapStateToProps, {
    fetchStrech,
})(StrechPage);
