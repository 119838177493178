import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ClassContent         from "./ClassContent";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Strech Class Page
 */
class ClassPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { strech, params, fetchStrech } = this.props;
        if (strech.strechID !== params.strechID) {
            fetchStrech(params.strechID);
        }
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { params, fetchStrech } = this.props;
        if (params.strechID !== prevProps.params.strechID) {
            fetchStrech(params.strechID);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isStudent, strech, classes, from, params, match, route } = this.props;

        return <>
            <SecondaryNav
                message={strech.name}
                fallback="CLASSES_NAME"
                href={from}
                path={route}
                baseUrl={match.url}
            >
                {classes.map((elem, index) => <NavigationItem
                    key={elem.strechClassID}
                    message={`${index + 1}. ${elem.name}`}
                    href={NLS.url(from, "CLASSES", elem.strechClassID)}
                    isDisabled={isStudent && !elem.hasStarted}
                    icon="class"
                />)}
            </SecondaryNav>

            <ClassContent elemID={params.classID} route={route} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        strech      : PropTypes.object.isRequired,
        classes     : PropTypes.array.isRequired,
        isStudent   : PropTypes.bool.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            strech    : state.strech.elem,
            classes   : state.strech.classes,
            isStudent : state.auth.isStudent,
        };
    }
}

export default connect(ClassPage.mapStateToProps, {
    fetchStrech,
})(ClassPage);
