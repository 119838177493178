import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGroup }       from "Actions/Admin/Strech/GroupActions";
import { fetchMembers }     from "Actions/Admin/Strech/MemberActions";
import { GROUP_MEMBER }     from "Utils/Types";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";

// Components
import GroupEdit            from "./GroupEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Group Details
 */
class GroupDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        if (action.isCoach) {
            Href.goto("COACHES", this.props.elem.credentialID);
        } else {
            this.setState({ action });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { groupID, section, members, fetchGroup, fetchMembers } = this.props;
        fetchGroup(groupID, true);
        this.endAction();

        if (section === GROUP_MEMBER) {
            fetchMembers(groupID, members);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, groupID } = this.props;
        const { action                                  } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="GROUPS_ERROR_EXISTS"
            >
                <DetailList message="GROUPS_DETAILS_TITLE">
                    <DetailItem
                        icon="name"
                        message={elem.name}
                        tooltip="GROUPS_ITS_NAME"
                    />
                    <DetailItem
                        icon="coach"
                        message={elem.credentialName}
                        tooltip="COACHES_SINGULAR"
                    />
                    <DetailItem
                        icon="amount"
                        message={elem.totalMembers}
                        tooltip="GROUPS_MEMBERS"
                        prefix="GENERAL_AMOUNT"
                    />
                    <DetailItem
                        icon="status"
                        message={elem.statusName}
                        tooltip="GENERAL_STATUS"
                        className={elem.statusClass}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.createdDateTime}
                        tooltip="GENERAL_CREATED"
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"  message="GROUPS_EDIT_TITLE"  />
                    <DetailAction action="COACH" message="COACHES_VIEW_TITLE" />
                </DetailActionList>
            </Details>

            <GroupEdit
                open={action.isEdit}
                elemID={groupID}
                strechID={elem.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroup   : PropTypes.func.isRequired,
        fetchMembers : PropTypes.func.isRequired,
        section      : PropTypes.string.isRequired,
        charging     : PropTypes.bool.isRequired,
        error        : PropTypes.bool.isRequired,
        canEdit      : PropTypes.bool.isRequired,
        elem         : PropTypes.object.isRequired,
        groupID      : PropTypes.number.isRequired,
        members      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            section  : state.core.section,
            charging : state.strechGroup.charging,
            error    : state.strechGroup.error,
            canEdit  : state.strechGroup.canEdit,
            elem     : state.strechGroup.elem,
            members  : state.groupMember.sort,
        };
    }
}

export default connect(GroupDetails.mapStateToProps, {
    fetchGroup, fetchMembers,
})(GroupDetails);
