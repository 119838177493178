import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    COACH_LOADING, COACH_CHARGING,
    COACH_LIST, COACH_FILTER,
    COACH_ELEM, COACH_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    charging     : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    canFilter    : false,
    list         : [],
    total        : 0,
    elem         : {},
    idds         : [],
    countries    : [],
    provinces    : {},
    courses      : [],
    categories   : [],
    specialities : [],
    filters      : {},
    sort         : {
        orderBy  : "firstName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.loginDate         = DateTime.formatIf(elem.lastLogin, "dashes");
    elem.loginDateTime     = DateTime.formatIf(elem.lastLogin, "dashesTime");
    elem.phoneString       = Common.combinePhone(elem);
    elem.addressString     = Common.combineAddress(elem);
    elem.genderName        = Common.getGenderName(elem.gender);
    elem.afipShortName     = NLS.get("USERS_AFIP_SHORT",   elem.afipInscription);
    elem.afipLongName      = NLS.get("USERS_AFIP_OPTIONS", elem.afipInscription);
    elem.schoolStaffString = Utils.toYesNo(elem.schoolStaff);
    elem.canConverseString = Utils.toYesNo(elem.canConverse);
    elem.subscriptionName  = Common.getSubscriptionName(elem.subscription);
    elem.subscriptionClass = Common.getSubscriptionClass(elem.subscription);
    elem.statusName        = Status.getName(elem.status);
    elem.statusColor       = Status.getColor(elem.status);
    elem.trafficlightColor = 'white';
    if ( elem.trafficlight === 1 ){
        elem.trafficlightColor = 'green';
    }else if ( elem.trafficlight === 2 ) {
        elem.trafficlightColor = 'yellow';
    }else if ( elem.trafficlight === 3 ) {
        elem.trafficlightColor = 'red';
    }
    elem.statusClass       = Status.getTextClass(elem.status);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, COACH_LIST, COACH_FILTER, COACH_ELEM, COACH_CREATE)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case COACH_LOADING:
        return {
            ...state,
            loading      : true,
        };
    case COACH_CHARGING:
        return {
            ...state,
            charging     : true,
        };

    case COACH_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            canFilter    : action.data.canFilter,
            list         : parseList(action.data.list),
            total        : action.data.total,
            filters      : action.data.filters,
            sort         : action.data.sort,
        };

    case COACH_FILTER:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            courses      : action.data.courses,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
            categories   : action.data.categories,
            specialities : action.data.specialities,
        };

    case COACH_ELEM:
        return {
            ...state,
            charging     : false,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            idds         : action.data.idds,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
        };

    case COACH_CREATE:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            idds         : action.data.idds,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
        };

    default:
        return state;
    }
};

export default reducer;
