import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    COACH_INVOICE_LOADING,
    COACH_INVOICE_LIST, COACH_INVOICE_ELEM, COACH_INVOICE_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading       : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    canFilter     : false,
    canInvoice    : false,
    list          : [],
    total         : 0,
    stats         : {},
    elem          : {},
    items         : [],
    totalPrice    : 0,
    conversations : [],
    filters       : {},
    sort          : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.statusName      = Status.is("PAID", elem.status) && !elem.amountCents ? NLS.get("COACH_INVOICES_NOT_APPLY") : Status.getFemName(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusString    = elem.isRejected ? NLS.format("COACH_INVOICES_STATUS", elem.statusName, elem.reason) : elem.statusName;
    elem.strechString    = elem.strechName || NLS.get("COACH_INVOICES_EXTRA");
    return elem;
}

/**
 * Parses the Elem Items
 * @param {Object} list
 * @returns {Object}
 */
function parseItems(list) {
    Utils.parseList(list, (elem) => {
        elem.time     = elem.conversationTime || elem.meetingTime;
        elem.dateTime = DateTime.formatDate(elem.time, "dashesTime");

        if (elem.conversationID && elem.number > 0) {
            elem.text = NLS.format("COACH_INVOICES_CONVERSATION1", elem.number, elem.userName, elem.dateTime);
        } else if (elem.conversationID) {
            elem.text = NLS.format("COACH_INVOICES_CONVERSATION2", elem.userName, elem.dateTime);
        } else if (elem.meetingID) {
            elem.text = NLS.format("COACH_INVOICES_MEETING", elem.groupName, elem.dateTime);
        } else {
            elem.text = NLS.get("COACH_INVOICES_STRECH");
        }
    });
    list.sort((a, b) => a.time - b.time);
    return list;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, COACH_INVOICE_LIST, COACH_INVOICE_ELEM, COACH_INVOICE_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case COACH_INVOICE_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case COACH_INVOICE_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            canFilter : action.data.canFilter,
            list      : parseList(action.data.list),
            total     : action.data.total,
            stats     : action.data.stats,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case COACH_INVOICE_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            items     : parseItems(action.data.items),
        };

    case COACH_INVOICE_CREATE:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canInvoice    : action.data.canInvoice,
            totalPrice    : action.data.total,
            conversations : parseItems(action.data.conversations),
        };

    default:
        return state;
    }
};

export default reducer;
