import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { saveData }         from "Actions/Core/AuthActions";
import Access               from "dashboard/dist/Core/Access";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Auth Data Step
 */
class DataStep extends React.Component {
    // The Initial Data
    initialData = {
        firstName       : "",
        lastName        : "",
        nationality     : 0,
        document        : 1,
        dni             : "",
        cuit            : "",
        taxID           : "",
        countryID       : 0,
        provinceID      : 0,
        idd             : 0,
        cellphone       : "",
        gender          : 0,
        afipInscription : 0,
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        syncAll : true,
        syncIDD : true,
    }

    /**
     * Set the User data
     * @returns {Void}
     */
    componentDidMount() {
        const { elem } = this.props;
        let data    = null;
        let syncAll = true;
        let syncIDD = true;

        if (!Utils.isEmpty(elem)) {
            data    = Utils.extend(this.initialData, elem);
            syncIDD = !elem.countryID && !elem.idd;
            syncAll = syncIDD;
        }
        if (data) {
            this.setState({ data, loading : false, errors : {}, syncIDD, syncAll });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const newState = Common.handleUserChange(this.state, name, value);
        this.setState(newState);
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { email, password, saveData, onSubmit } = this.props;
        const { data, loading                       } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await saveData({ ...data, email, password });
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, elem, countries, provinces, idds } = this.props;
        const { data, loading, errors                   } = this.state;

        const nationality = Utils.getValue(countries, "key", data.nationality, "code");
        const isArgentine = Boolean(nationality && nationality === "AR");
        const isCUIT      = Number(data.document) === 2;
        const isCoach     = Access.is("COACH", elem.level);

        return <AuthForm
            onSubmit={this.handleSubmit}
            title={title}
            help="AUTH_INSCRIPTION_HELP"
            error={errors.form}
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                name="firstName"
                icon="name"
                label="USERS_FIRST_NAME"
                autoComplete="given-name"
                value={data.firstName}
                error={errors.firstName}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />
            <IconField
                className="auth-field"
                name="lastName"
                icon="name"
                label="USERS_LAST_NAME"
                autoComplete="family-name"
                value={data.lastName}
                error={errors.lastName}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />

            <IconField
                className="auth-field"
                type="select"
                name="nationality"
                icon="country"
                label="USERS_NATIONALITY"
                value={data.nationality}
                error={errors.nationality}
                onChange={this.handleChange}
                options={countries}
                isRequired
                withNone
                smallMargin
            />
            <IconField
                isHidden={!isArgentine}
                className="auth-field"
                icon="document"
                type="number"
                name={isCUIT ? "cuit" : "dni"}
                label={isCUIT ? "USERS_CUIT" : "USERS_DNI"}
                value={isCUIT ? data.cuit : data.dni}
                error={errors.document}
                preType="select"
                preLabel="USERS_DOCUMENT"
                preName="document"
                preValue={data.document}
                preOptions="USERS_DOCUMENTS"
                preWidth="120"
                onChange={this.handleChange}
                isRequired
                preWithNone
                smallMargin
            />
            <IconField
                isHidden={isArgentine}
                className="auth-field"
                name="taxID"
                icon="id"
                label="USERS_TAX_ID"
                value={data.taxID}
                error={errors.taxID}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />

            <IconField
                className="auth-field"
                type="select"
                name="countryID"
                icon="country"
                label="USERS_COUNTRY"
                value={data.countryID}
                error={errors.countryID}
                onChange={this.handleChange}
                options={countries}
                isRequired
                withNone
                smallMargin
            />
            <IconField
                className="auth-field"
                type="select"
                name="provinceID"
                icon="province"
                label="PROVINCES_SINGULAR"
                value={data.provinceID}
                error={errors.provinceID}
                onChange={this.handleChange}
                options={data.countryID ? provinces[data.countryID] || [] : []}
                isRequired
                withNone
                smallMargin
            />

            <IconField
                className="auth-field"
                type="tel"
                name="cellphone"
                icon="phone"
                label="USERS_CELLPHONE"
                autoComplete="tel"
                value={data.cellphone}
                error={errors.cellphone}
                preType="select"
                preLabel="USERS_IDD"
                preName="idd"
                preValue={data.idd}
                preOptions={idds}
                preWidth="120"
                onChange={this.handleChange}
                isRequired
                withNone
                preWithNone
                smallMargin
            />
            <IconField
                className="auth-field"
                type="select"
                name="gender"
                icon="gender"
                label="USERS_GENDER"
                value={data.gender}
                error={errors.gender}
                onChange={this.handleChange}
                options="USERS_GENDERS"
                isRequired
                withNone
                smallMargin
            />
            <IconField
                isHidden={!isCoach}
                className="auth-field"
                type="select"
                name="afipInscription"
                icon="billing"
                label="USERS_AFIP_INSCRIPTION"
                value={data.afipInscription}
                error={errors.afipInscription}
                onChange={this.handleChange}
                options="USERS_AFIP_MINE"
                isRequired
                withNone
                smallMargin
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        saveData  : PropTypes.func.isRequired,
        countries : PropTypes.array.isRequired,
        provinces : PropTypes.object.isRequired,
        idds      : PropTypes.array.isRequired,
        elem      : PropTypes.object.isRequired,
        onSubmit  : PropTypes.func.isRequired,
        title     : PropTypes.string.isRequired,
        email     : PropTypes.string.isRequired,
        password  : PropTypes.string,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            countries : state.auth.countries,
            provinces : state.auth.provinces,
            idds      : state.auth.idds,
            elem      : state.auth.elem,
        };
    }
}

export default connect(DataStep.mapStateToProps, {
    saveData,
})(DataStep);
