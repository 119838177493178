import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Html                 from "dashboard/dist/Components/Html";
import Button               from "dashboard/dist/Components/Button";



/**
 * The Enroll List
 */
class EnrollList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { list, startAction, className } = this.props;

        return <div className={`enroll-container ${className}`}>
            {list.map((elem) => <div key={elem.strechID}>
                <section className="enroll-content">
                    <h3>{elem.title}</h3>
                    {elem.isPresential && <p className="enroll-location">
                        <b>{elem.locationName}</b><br />
                        {elem.locationAddress} - {elem.localityName} - {elem.provinceName} - {elem.countryName}
                    </p>}
                    {elem.isVirtual && <p className="enroll-location">
                        <b>{NLS.get("STRECHES_MODE_VIRTUAL")}</b>
                    </p>}
                    <Html variant="p">{elem.scheduleString}</Html>
                    <Html variant="p">{elem.includesString}</Html>
                    <Html variant="p">{elem.chatsString}</Html>
                    <Html variant="p">{elem.coachesString}</Html>
                    <Html variant="p">{elem.courseDates}</Html>
                    <Html variant="p">{elem.meetingDates}</Html>
                    <Html variant="p">{elem.inscriptionDates}</Html>
                    <Html variant="p">{elem.pricesString}</Html>
                    <Html variant="h4">{elem.availableString}</Html>
                </section>
                <footer className="enroll-footer">
                    {elem.canEnrollChat && <Button
                        variant="outlined"
                        message="ENROLL_CHAT_TITLE"
                        onClick={() => startAction("CENROLL", elem)}
                    />}
                    {elem.canEnroll && <Button
                        variant="outlined"
                        message={elem.buttonString}
                        onClick={() => startAction("SENROLL", elem)}
                    />}
                    {elem.canCancel && <Button
                        variant="outlined"
                        message="ENROLL_CANCEL_TITLE"
                        onClick={() => startAction("CANCEL", elem)}
                    />}
                </footer>
            </div>)}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        list        : PropTypes.array.isRequired,
        startAction : PropTypes.func.isRequired,
        className   : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default EnrollList;
