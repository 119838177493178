import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Search }           from "Utils/API";
import MediaType            from "dashboard/dist/Core/MediaType";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";

// Actions
import {
    fetchCampaign, fetchCampaignSend, sendCampaign,
} from "Actions/Admin/Campaign/CampaignActions";



/**
 * The Campaign Send Dialog
 */
class CampaignSend extends React.Component {
    // The Initial Data
    initialData = {
        campaignID     : 0,
        sendWhen       : 0,
        sendTimeDate   : "",
        sendTimeHour   : "",
        subject        : "",
        templateID     : 0,
        message        : "",
        image          : "",
        button         : "",
        link           : "",
        users          : "",
        credentialName : "",
    }

    // The Current State
    state = {
        loading  : false,
        data     : { ...this.initialData },
        errors   : {},
        userList : [],
        sendTo   : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, elemUsers, users, fetchCampaign, fetchCampaignSend } = this.props;
        let loading  = false;
        let data     = null;
        let userList = [];
        let sendTo   = {};

        // Dialog Opens
        if (open && !prevProps.open) {
            data  = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchCampaign(elemID);
                loading = true;
            } else {
                fetchCampaignSend();
                loading = true;
            }
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data     = Utils.extend(this.initialData, elem);
                userList = elemUsers;
                for (const user of elemUsers) {
                    sendTo[user.credentialID] = user.email;
                }
            } else {
                data = { ...this.initialData };
                if (users) {
                    userList = users;
                    for (const user of users) {
                        if (Common.isSubscribed(user.subscription)) {
                            sendTo[user.credentialID] = user.email;
                        }
                    }
                }
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {}, userList, sendTo });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the User Search
     * @param {String} id
     * @param {Number} credentialID
     * @param {String} name
     * @param {String} credentialName
     * @param {Object} data
     * @returns {Void}
     */
    handleSearch = (id, credentialID, name, credentialName, data) => {
        const { userList, sendTo } = this.state;
        if (!sendTo[credentialID]) {
            const { email, subscription } = data;
            sendTo[credentialID] = data.email;
            userList.push({ credentialID, credentialName, email, subscription });
            this.setState({ userList, sendTo });
        }
    }

    /**
     * Handles the User Add/Remove
     * @param {Number} credentialID
     * @param {String} email
     * @returns {Void}
     */
    handleUser = (credentialID, email) => {
        const sendTo = this.state.sendTo;
        if (sendTo[credentialID]) {
            delete sendTo[credentialID];
        } else {
            sendTo[credentialID] = email;
        }
        this.setState({ sendTo });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { sendCampaign, onSubmit, isCopy } = this.props;
        const { data, loading, sendTo       } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                if (isCopy) {
                    data.campaignID = 0;
                }
                data.users = JSON.stringify(sendTo);
                await sendCampaign(data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Renders the Users checkboxes
     * @returns {Object[]}
     */
    renderUsers() {
        const { userList, sendTo, errors } = this.state;

        const result = [];
        for (const elem of userList) {
            const isSubscribed = Common.isSubscribed(elem.subscription);
            const label        = elem.credentialName + (!isSubscribed ? NLS.format("CAMPAIGNS_NOT_SUBSCRIBED", Common.getSubscriptionName(elem.subscription)) : "");
            const value        = sendTo[elem.credentialID] ? 1 : 0;

            result.push(<InputField
                key={elem.credentialID}
                type="checkbox"
                name="users[]"
                label={label}
                value={value}
                error={errors[`users${elem.credentialID}`]}
                onChange={() => this.handleUser(elem.credentialID, elem.email)}
                isDisabled={!isSubscribed}
                noMargin
            />);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, templates, templateID, onClose, elemID, isCopy } = this.props;
        const { data, loading, errors, userList                      } = this.state;

        const title    = isCopy ? "CAMPAIGNS_RESEND_TITLE" : (elemID ? "CAMPAIGNS_EDIT_TITLE" : "CAMPAIGNS_SEND_TITLE");
        const isCustom = Boolean(data.templateID && Number(data.templateID) === templateID);
        const hasUsers = Boolean(userList && userList.length);

        return <EditDialog
            open={open}
            icon="campaign"
            title={title}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="sendWhen"
                label="CAMPAIGNS_SEND_WHEN"
                value={data.sendWhen}
                error={errors.sendWhen}
                onChange={this.handleChange}
                options="CAMPAIGNS_SEND_WHEN_OPTIONS"
                withNone
                isRequired
            />
            <Columns isHidden={Number(data.sendWhen) !== 5}>
                <InputField
                    type="date"
                    name="sendTimeDate"
                    label="CAMPAIGNS_SEND_DATE"
                    value={data.sendTimeDate}
                    error={errors.sendTimeDate}
                    onChange={this.handleChange}
                    isRequired
                    shrinkLabel
                />
                <InputField
                    type="time"
                    name="sendTimeHour"
                    label="CAMPAIGNS_SEND_HOUR"
                    value={data.sendTimeHour}
                    error={errors.sendTimeHour}
                    onChange={this.handleChange}
                    isRequired
                    shrinkLabel
                />
            </Columns>
            <InputField
                name="subject"
                label="CAMPAIGNS_ITS_SUBJECT"
                value={data.subject}
                error={errors.subject}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="templateID"
                label="CAMPAIGNS_ITS_TEMPLATE"
                value={data.templateID}
                error={errors.templateID}
                onChange={this.handleChange}
                options={templates}
                withNone
                isRequired
            />
            <InputField
                isHidden={!isCustom}
                type="textarea"
                name="message"
                label="CAMPAIGNS_MESSAGE"
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
            />
            <MediaField
                isHidden={!isCustom}
                name="image"
                label="CAMPAIGNS_IMAGE"
                mesdiaType={MediaType.IMAGE}
                mediaPath="campanas"
                value={data.image}
                error={errors.image}
                onChange={this.handleChange}
            />
            <InputField
                isHidden={!isCustom}
                name="button"
                label="CAMPAIGNS_BUTTON"
                value={data.button}
                error={errors.button}
                onChange={this.handleChange}
            />
            <InputField
                isHidden={!isCustom}
                name="link"
                label="CAMPAIGNS_LINK"
                helperText="CAMPAIGNS_LINK_HELP"
                value={data.link}
                error={errors.link}
                onChange={this.handleChange}
            />
            <DialogMessage noSpace>
                <h3>{NLS.get("CAMPAIGNS_USERS")}</h3>
                <InputField
                    name="credentialName"
                    label="USERS_SEARCH_TITLE"
                    suggestID="credentialID"
                    suggestParams={{ justUsers : true }}
                    suggestFetch={Search.searchUser}
                    suggestNone="USERS_NONE_AVAILABLE"
                    value={data.credentialName}
                    error={errors.users}
                    onChange={this.handleChange}
                    onSuggest={this.handleSearch}
                    keepSuggestions
                />
                {hasUsers && <section className="two-columns">
                    {this.renderUsers()}
                </section>}
            </DialogMessage>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCampaign     : PropTypes.func.isRequired,
        fetchCampaignSend : PropTypes.func.isRequired,
        sendCampaign      : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        templates         : PropTypes.array.isRequired,
        templateID        : PropTypes.number.isRequired,
        elem              : PropTypes.object.isRequired,
        elemUsers         : PropTypes.array.isRequired,
        elemID            : PropTypes.number,
        users             : PropTypes.array,
        isCopy            : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition    : state.campaign.edition,
            templates  : state.campaign.templates,
            templateID : state.campaign.templateID,
            elem       : state.campaign.elem,
            elemUsers  : state.campaign.users,
        };
    }
}

export default connect(CampaignSend.mapStateToProps, {
    fetchCampaign, fetchCampaignSend, sendCampaign,
})(CampaignSend);
