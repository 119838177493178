// @ts-nocheck
import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Access               from "Utils/Access";



/**
 * Returns the Type Name
 * @param {Number} type
 * @returns {String}
 */
function getTypeName(type) {
    return type ? NLS.get("TYPES", type) : "";
}

/**
 * Returns the Type Button
 * @param {Number} type
 * @returns {String}
 */
function getTypeButton(type) {
    return type ? NLS.get("TYPES_BUTTONS", type) : "GENERAL_VIEW";
}



/**
 * Returns the Mode Name
 * @param {Number} mode
 * @returns {String}
 */
function getModeName(mode) {
    return NLS.get("MODES", mode);
}

/**
 * Returns true if the Mode is Presential
 * @param {Number} mode
 * @returns {Boolean}
 */
function isPresentialMode(mode) {
    return Number(mode) === 1;
}

/**
 * Returns true if the Mode is Presential
 * @param {Number} mode
 * @returns {Boolean}
 */
function isVirtualMode(mode) {
    return Number(mode) === 2;
}

/**
 * Returns true if the Location is Custom
 * @param {Number} locationID
 * @returns {Boolean}
 */
function isCustomLocation(locationID) {
    return Number(locationID) === -1;
}



/**
 * Returns true if the given status is Subscribed
 * @param {Number} subscription
 * @returns {Boolean}
 */
function isSubscribed(subscription) {
    return Number(subscription) === 1;
}

/**
 * Returns the Subscription Name
 * @param {Number} subscription
 * @returns {String}
 */
function getSubscriptionName(subscription) {
    return NLS.get("SUBSCRIPTIONS", subscription);
}

/**
 * Returns the Subscription Class
 * @param {Number} subscription
 * @returns {String}
 */
 function getSubscriptionClass(subscription) {
    return Utils.getTextClass("SUBSCRIPTIONS_COLORS", subscription);
}



/**
 * Returns the Strech Dates data
 * @param {Object} elem
 * @return {Object}
 */
function getStrechDates(elem) {
    const fromDay  = DateTime.create(elem.fromTime);
    const toDay    = DateTime.create(elem.toTime);
    const date     = fromDay.toString("dashes");
    const toDate   = toDay.toString("dashes");
    let   fromTime = fromDay.toString("time");
    let   toTime   = toDay.toString("time");
    let   timeText = NLS.format("GENERAL_DATE_TIME", date, fromTime, toTime);

    if (date !== toDate) {
        fromTime = fromDay.toString("dashesHour");
        toTime   = toDay.toString("dashesHour");
        timeText = NLS.format("GENERAL_DATE_PERIOD", fromTime, toTime);
    }

    const homeText = NLS.format("HOME_STRECH", elem.title, fromTime, toTime);
    const calText  = NLS.format("CALENDAR_STRECH", elem.title, fromTime, toTime);

    return { fromDay, toDay, date, calText, homeText, timeText };
}

/**
 * Returns the Class Name depending if is deleted or not
 * @param {Object} elem
 * @returns {String}
 */
 function getClassName(elem) {
    if (elem.classID < 0 || elem.strechClassID < 0) {
        return NLS.get("CLASSES_WELCOME");
    }
    if (elem.classIsDeleted || (elem.classID === 0 || elem.strechClassID === 0)) {
        return NLS.get("CLASSES_NONE");
    }
    return elem.className;
}

/**
 * Returns the Class Dates data
 * @param {Object} elem
 * @return {Object}
 */
function getClassDates(elem) {
    const fromDay  = DateTime.fromString(elem.timeDate, elem.startHour, true);
    const toDay    = DateTime.fromString(elem.timeDate, elem.endHour, true);
    const date     = fromDay.toString("dashes");
    const fromTime = fromDay.toString("time");
    const toTime   = toDay.toString("time");

    const homeText = NLS.format("HOME_CLASS", elem.strechTitle, elem.name, fromTime, toTime);
    const calText  = NLS.format("CALENDAR_CLASS", elem.strechTitle, elem.name, fromTime, toTime);
    const timeText = NLS.format("GENERAL_DATE_TIME", date, fromTime, toTime);

    return { fromDay, toDay, date, calText, homeText, timeText };
}

/**
 * Returns the Chat Dates data
 * @param {Object} elem
 * @return {Object}
 */
function getChatDates(elem) {
    const fromDay  = DateTime.fromString(elem.timeDate, elem.fromHour, true);
    const toDay    = DateTime.fromString(elem.timeDate, elem.fromHour, true);
    const date     = fromDay.toString("dashes");
    const fromTime = fromDay.toString("time");
    const toTime   = toDay.toString("time");

    const homeText = NLS.format("HOME_CHAT", elem.strechTitle, elem.name, fromTime, toTime);
    const calText  = NLS.format("CALENDAR_CHAT", elem.strechTitle, elem.name, fromTime, toTime);
    const timeText = NLS.format("GENERAL_DATE_TIME", date, fromTime, toTime);

    return { fromDay, toDay, date, calText, homeText, timeText };
}

/**
 * Returns the Material Type
 * @param {Number} type
 * @returns {String}
 */
function getMaterialType(type) {
    return NLS.get("MATERIALS_TYPES", type);
}

/**
 * Returns the Gender Name
 * @param {Number} gender
 * @returns {String}
 */
function getGenderName(gender) {
    return NLS.get("USERS_GENDERS", gender);
}

/**
 * Returns the base url depending on the Access
 * @param {Object}  elem
 * @param {String=} prefix
 * @returns {String}
 */
function getAccessBaseUrl(elem, prefix) {
    let access = Access.STUDENT;
    if (prefix) {
        access = Number(elem[`${prefix}Access`] || elem[`${prefix}Level`]);
    } else {
        access = Number(elem.access || elem.level);
    }
    return access === Access.STUDENT ? "STUDENTS" : "COACHES";
}

/**
 * Combines an Address form an Element into a String
 * @param {Object}   elem
 * @param {Boolean=} withPlus
 * @returns {String}
 */
function combinePhone(elem, withPlus = false) {
    const cellphone = elem.credentialCellphone || elem.cellphone;
    const iddRoot   = elem.credentialIddRoot   || elem.iddRoot;
    let result      = "";
    if (cellphone) {
        if (iddRoot) {
            if (withPlus) {
                result += "+";
            }
            result += iddRoot;
        }
        result += cellphone;
    }
    return result;
}

/**
 * Combines an Address form an Element into a String
 * @param {Object} elem
 * @returns {String}
 */
function combineAddress(elem) {
    const result = [];
    if (elem.address) {
        result.push(elem.address);
    }
    if (elem.provinceName) {
        result.push(elem.provinceName);
    }
    if (elem.countryName) {
        result.push(elem.countryName);
    }
    return result.join(" - ");
}

/**
 * Combines a Location into a String
 * @param {Object} elem
 * @returns {String}
 */
function combineLocation(elem) {
    if (elem.customLocation) {
        return elem.customLocation;
    }
    if (elem.locationID) {
        return `${elem.locationName} - ${elem.roomName}`;
    }
    return "";
}

/**
 * Combines a Price into a String
 * @param {Object} elem
 * @returns {String}
 */
function combinePrice(elem) {
    if (elem.strechPriceID === -2) {
        return NLS.get("PRICES_FREE");
    }
    const dues  = elem.priceDues  || elem.customDues;
    const price = elem.pricePrice || elem.customPrice;
    return price ? NLS.pluralize("PRICES_NAME", dues, price) : "";
}

/**
 * Combines the sendTo emails into a String
 * @param {Object} elem
 * @returns {String}
 */
function combineSendTo(elem) {
    let parts = [ "" ];
    if (elem.sendTo) {
        try {
            parts = JSON.parse(String(elem.sendTo));
            if (!Array.isArray(parts)) {
                parts = [ parts ];
            }
        } catch(e) {
            parts = [ "" ];
        }
    }
    return parts.join(" - ");
}



/**
 * Parses the Materials
 * @param {Array.<Object>} materials
 * @returns {Object}
 */
function parseMaterials(materials) {
    const data   = {};
    const result = [];

    for (const elem of materials) {
        if (!data[elem.matCategoryID]) {
            data[elem.matCategoryID] = {
                key  : elem.matCategoryID,
                name : elem.matCategoryName,
                list : [],
            };
        }
        data[elem.matCategoryID].list.push(elem);
    }
    for (const elem of Object.values(data)) {
        result.push(elem);
    }
    return result;
}

/**
 * Parses the Schedules
 * @param {Array.<Object>} schedules
 * @param {Boolean=}       useTimezone
 * @returns {Object}
 */
function parseSchedules(schedules, useTimezone = true) {
    const dateTime = DateTime.create();
    const data     = {};
    const list     = [];
    const types    = [];

    for (const elem of schedules) {
        const frequency = elem.frequency;
        const date      = dateTime.toWeekStart(elem.weekDay).toString("dashes");
        const fromDay   = DateTime.fromString(date, elem.fromHour, useTimezone);
        const fromHour  = fromDay.toString("time");
        const toHour    = DateTime.formatDateTime(date, elem.toHour, "time", useTimezone);
        const id        = `${fromHour}-${toHour}`;

        if (!data[frequency]) {
            data[frequency] = { frequency, schedules : {} };
        }
        if (!data[frequency].schedules[id]) {
            data[frequency].schedules[id] = { fromHour, toHour, days : [] };
        }
        data[frequency].schedules[id].days.push(fromDay.getDayName());
        types.push(getScheduleType(elem.toHour));
    }

    const elems = Object.values(data).sort((a, b) => a.frequency - b.frequency);
    for (const elem of elems) {
        for (const schedule of Object.values(elem.schedules)) {
            const days = NLS.join(schedule.days);
            if (elem.frequency === 1) {
                list.push(NLS.format("GENERAL_DATE_TIME_ALL", days, schedule.fromHour, schedule.toHour));
            } else {
                list.push(NLS.format("GENERAL_DATE_TIME_WEEK", days, schedule.fromHour, schedule.toHour, elem.frequency));
            }
        }
    }

    return { list, types };
}

/**
 * Generates the Schedule Types
 * @param {string} hour
 * @returns {Object}
 */
function getScheduleType(hour) {
    const hourParts = hour.split(":");
    const hourTime  = Number(hourParts[0]) * 100 + Number(hourParts[1]);

    if (hourTime <= 1200) {
        return 1;
    }
    if (hourTime <= 1600) {
        return 2;
    }
    return 3;
}

/**
 * Parses the Questions
 * @param {Object[]} questions
 * @param {Object=}  elem
 * @returns {Object}
 */
function parseQuestions(questions, elem) {
    return Utils.parseList(questions, (question) => {
        question.select = [];
        if (question.type === "select" || question.type === "radio") {
            question.select = Utils.stringsToSelect(question.optionsParts);
        }

        if (elem && elem.isReported) {
            const answer = elem.reportAnswers[question.id];
            if (question.type === "select" || question.type === "radio") {
                question.answer = question.optionsParts[answer];
            } else {
                question.answer = answer.split("\n");
            }
        }
    });
}

/**
 * Parses the Conversations Limit
 * @param {Number} conversations
 * @returns {Object}
 */
function parseConvLimit(conversations) {
    switch (conversations) {
    case -1:
        return NLS.get("GENERAL_ALL_FEM");
    case 0:
        return NLS.get("GENERAL_NONE_FEM");
    default:
        return conversations;
    }
}

/**
 * Returns a Conversation Limit Select
 * @param {Number} total
 * @return {Object[]}
 */
function getConvLimitSelect(total) {
    const result = [
        { key : -1, value : "GENERAL_ALL_FEM"  },
        { key : 0,  value : "GENERAL_NONE_FEM" },
    ];
    for (let i = 1; i < total; i++) {
        result.push({ key : i, value : String(i) });
    }
    return result;
}

/**
 * Handles the User Change
 * @param {Object} state
 * @param {String} name
 * @param {*}      value
 * @returns {Object}
 */
function handleUserChange(state, name, value) {
    const data   = { ...state.data,   [name] : value };
    const errors = { ...state.errors, [name] : ""    };
    let { syncAll, syncIDD } = state;

    if (name === "nationality" && syncAll) {
        data["countryID"]   = value;
        data["idd"]         = value;
        errors["countryID"] = "";
        errors["idd"]       = "";
    }
    if (name === "countryID" && syncIDD) {
        data["idd"]   = value;
        errors["idd"] = "";
        syncAll       = false;
    }
    if (name === "idd") {
        syncAll = false;
        syncIDD = false;
    }
    return { data, errors, syncAll, syncIDD };
}



/**
 * Parses the Enroll Error
 * @param {String}            strechName
 * @param {Boolean}           toChat
 * @param {(String|String[])} error
 * @returns {String}
 */
function parseEnrollError(strechName, toChat, error) {
    if (!error) {
        return "";
    }
    let errTxt = "";
    if (Array.isArray(error)) {
        errTxt = NLS.format(error[0], error[1]);
    } else {
        errTxt = NLS.get(error);
    }
    errTxt = errTxt[0].toLocaleLowerCase() + errTxt.substr(1);
    return NLS.format(toChat ? "ENROLL_ERROR_GENERAL_CHAT" : "ENROLL_ERROR_GENERAL", strechName, errTxt);
}

/**
 * Filters the Streches
 * @param {Object[]} streches
 * @param {Object}   filters
 * @param {String=}  course
 * @param {Number=}  strechID
 * @returns {Object[]}
 */
function filterStreches(streches, filters, course, strechID) {
    const courseID     = Number(filters.courseID);
    const strechMode   = Number(filters.strechMode);
    const scheduleType = Number(filters.scheduleType);
    const strechFor    = Number(filters.strechFor);
    const countryID    = Number(filters.countryID);
    const provinceID   = Number(filters.provinceID);
    const localityID   = Number(filters.localityID);
    const result       = [];

    for (const strech of streches) {
        let addToList = true;

        // From the Url
        if (course && strech.courseUrl !== course) {
            addToList = false;
        }
        if (strechID && strech.strechID !== strechID) {
            addToList = false;
        }

        // From the Filters
        if (courseID && strech.courseID !== courseID) {
            addToList = false;
        }
        if (strechMode && strech.mode !== strechMode) {
            addToList = false;
        }
        if (scheduleType && !strech.scheduleTypes.includes(scheduleType)) {
            addToList = false;
        }
        if (!course && !strechID && strechFor && ((strechFor === 1 && strech.onlyCoaches) || (strechFor === 2 && !strech.onlyCoaches))) {
            addToList = false;
        }

        // Filter by location
        if (isPresentialMode(strechMode)) {
            if (countryID && strech.countryID !== countryID) {
                addToList = false;
            }
            if (provinceID && strech.provinceID !== provinceID) {
                addToList = false;
            }
            if (localityID && strech.localityID !== localityID) {
                addToList = false;
            }
        }

        if (addToList) {
            result.push(strech);
        }
    }
    return result;
}




// The public API
const API = {
    getTypeName,
    getTypeButton,

    getModeName,
    isPresentialMode,
    isVirtualMode,
    isCustomLocation,

    isSubscribed,
    getSubscriptionName,
    getSubscriptionClass,

    getStrechDates,
    getClassName,
    getClassDates,
    getChatDates,
    getMaterialType,
    getGenderName,
    getAccessBaseUrl,
    combinePhone,
    combineAddress,
    combineLocation,
    combinePrice,
    combineSendTo,

    parseMaterials,
    parseSchedules,
    getScheduleType,
    parseQuestions,
    parseConvLimit,
    getConvLimitSelect,
    handleUserChange,

    parseEnrollError,
    filterStreches,
};

export default API;
