import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechGroup }      from "Utils/API";

// Types
import {
    STRECH_GROUP, STRECH_GROUP_LOADING, STRECH_GROUP_CHARGING,
    STRECH_GROUP_LIST, STRECH_GROUP_ELEM, STRECH_GROUP_CREATE,
    STRECH_GROUP_CHANGE, STRECH_GROUP_STUDENTS,
} from "Utils/Types";



/**
 * Fetches the Group List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchGroups(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_GROUP_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechGroup.getAllForStrech(params);
            break;
        case "COACH":
            params.coachID = elemID;
            data = await StrechGroup.getAllForCoach(params);
            break;
        case "USER":
            data = await StrechGroup.getAllAsCoach(params);
            break;
        default:
            data = await StrechGroup.getAll(params);
        }
        data.sort = params;
        dispatch({ type : STRECH_GROUP_LIST, data });
        dispatch(setSection(STRECH_GROUP));
    };
}

/**
 * Fetches a single Group
 * @param {Number}   groupID
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchGroup(groupID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STRECH_GROUP_CHARGING });
        }
        const data = await StrechGroup.getOne({ groupID });
        dispatch({ type : STRECH_GROUP_ELEM, data });
    };
}

/**
 * Fetches the Group Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGroupCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechGroup.getCreateData({ strechID });
        dispatch({ type : STRECH_GROUP_CREATE, data });
    };
}

/**
 * Fetches the Group Change data
 * @param {Number} strechID
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGroupChange(strechID, credentialID) {
    return async (dispatch) => {
        const data = await StrechGroup.getChangeData({ strechID, credentialID });
        dispatch({ type : STRECH_GROUP_CHANGE, data });
    };
}

/**
 * Fetches the Students
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStudents(strechID) {
    return async (dispatch) => {
        const data = await StrechGroup.getStudents({ strechID });
        dispatch({ type : STRECH_GROUP_STUDENTS, data });
    };
}



/**
 * Edits/Creates a Group
 * @param {Object} data
 * @returns {() => Void}
 */
export function editGroup(data) {
    return () => StrechGroup.edit(data);
}

/**
 * Assigns Students to Groups
 * @param {Object} data
 * @returns {() => Void}
 */
export function assignToGroups(data) {
    return () => StrechGroup.assign(data);
}

/**
 * Changes the Group of a Student
 * @param {Object} data
 * @returns {() => Void}
 */
export function changeGroups(data) {
    return () => StrechGroup.change(data);
}

/**
 * Deletes a Group
 * @param {Number} groupID
 * @returns {() => Void}
 */
export function deleteGroup(groupID) {
    return () => StrechGroup.delete({ groupID });
}
