import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchConversation, reportConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation Report Dialog
 */
class ConversationReport extends React.Component {
    // The Initial Data
    initialData = {
        answers : {},
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchConversation } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchConversation(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { answers : {} };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        let answers   = this.state.data.answers;
        answers[name] = value

        this.setState({
            data : { ...this.state.data, answers },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, reportConversation, onSubmit } = this.props;
        const { data, loading                      } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const elemID = elem.conversationID;
                const report = JSON.stringify(data.answers);
                await reportConversation(elemID, report);
                this.setState({ loading : false });
                onSubmit(Status.getID("REPORTED"));
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, questions, open, onClose } = this.props;
        const { data, loading, errors          } = this.state;

        return <EditDialog
            open={open}
            icon="complete"
            title="CONVERSATIONS_REPORT_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            withSpacing={false}
        >
            <InfoList>
                <InfoItem label="STUDENTS_SINGULAR"     message={elem.studentName} />
                <InfoItem label="CONVERSATIONS_COACHEE" message={elem.coacheeName} />
                <InfoItem label="COACHES_SINGULAR"      message={elem.coachString} />
                <InfoItem label="GENERAL_DATE"          message={elem.timeString}  />
            </InfoList>

            <AccordionList className="spacing">
                {questions.map((elem, index) => <AccordionItem
                    key={index}
                    number={index + 1}
                    message={elem.question}
                    icon={data.answers[elem.id] ? "checkedbox" : "checkbox"}
                >
                    <InputField
                        type={elem.type}
                        name={String(elem.id)}
                        label="ANSWERS_MINE"
                        value={data.answers[elem.id]}
                        onChange={this.handleChange}
                        options={elem.select}
                        withNone
                    />
                </AccordionItem>)}
            </AccordionList>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversation  : PropTypes.func.isRequired,
        reportConversation : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        onClose            : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        canEdit            : PropTypes.bool.isRequired,
        elem               : PropTypes.object.isRequired,
        questions          : PropTypes.array.isRequired,
        elemID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.conversation.edition,
            canEdit   : state.conversation.canEdit,
            elem      : state.conversation.elem,
            questions : state.conversation.questions,
        };
    }
}

export default connect(ConversationReport.mapStateToProps, {
    fetchConversation, reportConversation,
})(ConversationReport);
