import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import ChatView             from "./ChatView";
import ChatEdit             from "./ChatEdit";
import ChatPresentism       from "./ChatPresentism";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchChats, deleteChat, importChats,
} from "Actions/Admin/Strech/ChatActions";



/**
 * The Strech Chat List
 */
class ChatList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchChats(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch();
            this.props.fetchStrech(this.props.elemID);
        }
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteChat(this.state.elemID);
        this.endAction(true);
    }

    /**
     * Handles the Import Submit
     * @returns {Promise}
     */
    importElem = async () => {
        const strechID = this.props.params.strechID;
        await this.props.importChats(strechID);
        this.endAction(true);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                           } = this.state;
        const { data, strech, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading      } = data;

        const elem     = Utils.getValue(list, "strechChatID", elemID);
        const elemName = elem.name || "";

        return <Main withDetails={withDetails}>
            <Header message="CHATS_NAME" icon="class" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <StrechBar step={StrechStep.CHATS} />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CHATS_NONE_AVAILABLE"
                    hasAlert={StrechStep.hasHelp(strech, StrechStep.CHATS)}
                >
                    <TableHead>
                        <TableHeader field="name"      message="GENERAL_NAME"       hideCircle={!canEdit} grow="1.5" bigMobile />
                        <TableHeader field="firstName" message="COACHES_SINGULAR"   bigMobile />
                        <TableHeader field="time"      message="GENERAL_DAY"        maxWidth="90" />
                        <TableHeader field="fromHour"  message="SCHEDULES_SINGULAR" maxWidth="90" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechChatID} elemID={elem.strechChatID}>
                            <TableCell message={elem.name}           circle={elem.statusColor} />
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.date}           />
                            <TableCell message={elem.scheduleTime}   />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="CHATS_VIEW_TITLE"   />
                        <TableAction action="EDIT"   message="CHATS_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="CHATS_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <ChatView
                open={Boolean(!canEdit && action.isView)}
                elemID={elemID}
                strechID={params.strechID}
                onClose={this.endAction}
            />
            <ChatPresentism
                open={Boolean(canEdit && elem.isActive && action.isView)}
                elemID={elemID}
                strechID={params.strechID}
                onClose={this.endAction}
            />
            <ChatEdit
                open={Boolean(canEdit && ((!elem.isActive && action.isView) || action.isCE))}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={Boolean(canEdit && action.isDelete)}
                title="CHATS_DELETE_TITLE"
                message="CHATS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={canEdit && action.isImport}
                icon="import"
                title="CHATS_IMPORT_TITLE"
                message="CHATS_IMPORT_TEXT"
                onSubmit={this.importElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        fetchChats  : PropTypes.func.isRequired,
        deleteChat  : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        strech      : PropTypes.object.isRequired,
        type        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
        elemID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.strechChat,
            strech : state.strech.elem,
        };
    }
}

export default connect(ChatList.mapStateToProps, {
    fetchStrech, fetchChats, deleteChat, importChats,
})(ChatList);
