import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Course }           from "Utils/API";

// Types
import {
    COURSE, COURSE_LOADING, COURSE_CHARGING,
    COURSE_LIST, COURSE_ELEM, COURSE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Course List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCourses(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COURSE_LOADING });
        }
        Params.unset(params);
        const data = await Course.getAll(params);
        data.sort = params;
        dispatch({ type : COURSE_LIST, data });
        dispatch(setSection(COURSE));
    };
}

/**
 * Fetches a single Course
 * @param {Number}   courseID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCourse(courseID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COURSE_CHARGING });
        }
        const data = await Course.getOne({ courseID });
        dispatch({ type : COURSE_ELEM, data });
    };
}

/**
 * Fetches the Course Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCourseCreate() {
    return async (dispatch) => {
        const data = await Course.getCreateData();
        dispatch({ type : COURSE_CREATE, data });
    };
}



/**
 * Edits/Creates a Course
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCourse(data) {
    return () => Course.edit(data);
}

/**
 * Deletes a Course
 * @param {Number} courseID
 * @returns {() => Void}
 */
export function deleteCourse(courseID) {
    return () => Course.delete({ courseID });
}
