import React                from "react";
import PropTypes            from "prop-types";

// Sections
import CourseEdit           from "Components/Admin/Course/Course/CourseEdit";
import ProgramEdit          from "Components/Admin/Program/Program/ProgramEdit";
import StrechEdit           from "Components/Admin/Strech/Strech/StrechEdit";
import ConversationEdit     from "Components/Admin/Conversation/ConversationEdit";
import CoachEdit            from "Components/Admin/User/Coach/CoachEdit";
import StudentEdit          from "Components/Admin/User/Student/StudentEdit";
import LocationEdit         from "Components/Admin/Location/Location/LocationEdit";

// Components
import CreateDrawer         from "dashboard/dist/Components/CreateDrawer";
import DrawerItem           from "dashboard/dist/Components/DrawerItem";

// Images
import Logo                 from "Styles/Images/Icon.png";



/**
 * A New Drawer
 */
class NewDrawer extends React.Component {
    // The Current State
    state = {
        dialog : "",
    }

    /**
     * Srarts an Action
     * @param {String} dialog
     * @returns {Void}
     */
    startAction = (dialog) => {
        this.setState({ dialog });
        this.props.onClose();
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction("");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isHidden, open, onClose } = this.props;
        const { dialog                  } = this.state;

        if (isHidden) {
            return <React.Fragment />;
        }
        return <>
            <CreateDrawer
                open={open}
                logo={Logo}
                logoWidth={40}
                onClose={onClose}
                onAction={this.startAction}
            >
                <DrawerItem type="course"       message="COURSES_SINGULAR"       />
                <DrawerItem type="program"      message="PROGRAMS_SINGULAR"      />
                <DrawerItem type="strech"       message="STRECHES_SINGULAR"      />
                <DrawerItem type="conversation" message="CONVERSATIONS_SINGULAR" />
                <DrawerItem type="student"      message="STUDENTS_SINGULAR"      />
                <DrawerItem type="coach"        message="COACHES_SINGULAR"       />
                <DrawerItem type="location"     message="LOCATIONS_SINGULAR"     />
            </CreateDrawer>

            <CourseEdit
                open={dialog === "course"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ProgramEdit
                open={dialog === "program"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <StrechEdit
                open={dialog === "strech"}
                isEdit={false}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ConversationEdit
                open={dialog === "conversation"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <CoachEdit
                open={dialog === "coach"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <StudentEdit
                open={dialog === "student"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <LocationEdit
                open={dialog === "location"}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHidden : PropTypes.bool.isRequired,
        open     : PropTypes.bool.isRequired,
        onClose  : PropTypes.func.isRequired,
    }
}

export default NewDrawer;
