import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Status               from "dashboard/dist/Core/Status";
import Graph                from "Utils/Graph";
import GraphType            from "Utils/Entity/GraphType";

// Partials
import ReportFilters        from "../Partials/ReportFilters"
import ReportGraph          from "../Partials/ReportGraph";
import ReportTable          from "../Partials/ReportTable";

// Components
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import InputField           from "dashboard/dist/Components/InputField";
import Downloader           from "dashboard/dist/Components/Downloader";

// APIs
import {
    ConversationReport, Coach, Search,
} from "Utils/API";

// Actions
import {
    fetchConversationData, fetchConversationGraph, fetchConversationEmails,
} from "Actions/Admin/Report/ConversationActions";

// Styles
import "Styles/Components/App/Report.css";



/**
 * The Conversation Report Page
 */
class ConversationPage extends React.Component {
    // The Initial Data
    initialFilters = {
        graphType    : GraphType.COURSES,
        status       : 0,
        programType  : 0,
        mode         : 0,
        specialityID : 0,
        courseID     : 0,
        programID    : 0,
        strechID     : 0,
        coachID      : 0,
        coachName    : "",
        coacheeID    : 0,
        coacheeName  : "",
        period       : 0,
        fromDate     : "",
        toDate       : "",
    }

    // The Current State
    state = {
        filters : { ...this.initialFilters },
        action  : Action.get(),
        elemID  : 0,
        users   : [],
        graph   : 0,
    }



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.data.loaded) {
            this.props.fetchConversationData();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const filters = GraphType.handleFilter(this.state, name, value);
        if (GraphType.shouldFetchFilters(name)) {
            this.props.fetchConversationData(filters, false);
        }
        this.setState({ filters });
    }

    /**
     * Handles the Report Filter
     * @returns {Promise}
     */
    handleFilter = async () => {
        const { graph, filters } = this.state;
        await this.props.fetchConversationGraph(filters);
        this.setState({ graph : graph + 1 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        let users = [];
        if (action.isCampaign) {
            users = await this.props.fetchConversationEmails({ ...this.state.filters, elemID });
        }
        this.setState({ action, elemID, users });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, data                           } = this.props;
        const { filters, action, elemID, users, graph } = this.state;

        const hideFilter = GraphType.getHideFilters(filters.graphType);

        return <Main>
            <Header message="REPORTS_CONVERSATIONS" icon="conversation" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <ReportFilters
                    type="CONVERSATIONS"
                    data={data}
                    filters={filters}
                    onChange={this.handleChange}
                    onFilter={this.handleFilter}
                >
                    <InputField
                        type="select"
                        name="status"
                        label="REPORTS_CONVERSATION_STATUS"
                        value={filters.status}
                        onChange={this.handleChange}
                        options={Status.getFemSelect("CONVERSATION")}
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.specialities}
                        type="select"
                        name="specialityID"
                        label="SPECIALITIES_SINGULAR"
                        value={filters.specialityID}
                        onChange={this.handleChange}
                        options={data.specialities}
                        noneText="GENERAL_ALL_FEM"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />

                    <InputField
                        isHidden={hideFilter.coaches}
                        name="coachName"
                        label="COACHES_SINGULAR"
                        suggestID="coachID"
                        suggestFetch={Coach.search}
                        suggestNone="COACHES_NONE_AVAILABLE"
                        value={filters.coachName}
                        onChange={this.handleChange}
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.coachees}
                        name="coacheeName"
                        label="CONVERSATIONS_COACHEE"
                        suggestID="coacheeID"
                        suggestParams={{ justUsers : true }}
                        suggestFetch={Search.searchUser}
                        suggestNone="USERS_NONE_AVAILABLE"
                        value={filters.coacheeName}
                        onChange={this.handleChange}
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                </ReportFilters>

                <ReportGraph
                    data={data}
                    graph={graph}
                    dataType={Graph.DATA_AMOUNT}
                />
                <ReportTable
                    data={data}
                    startAction={this.startAction}
                />
            </Content>

            <Downloader
                download={action.isExport}
                source={ConversationReport.export(filters)}
                onLoad={this.endAction}
            />
            <Downloader
                download={action.isDownload}
                source={ConversationReport.download({ ...filters, elemID })}
                onLoad={this.endAction}
            />
            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversationData   : PropTypes.func.isRequired,
        fetchConversationGraph  : PropTypes.func.isRequired,
        fetchConversationEmails : PropTypes.func.isRequired,
        data                    : PropTypes.object.isRequired,
        route                   : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.conversationReport,
        };
    }
}

export default connect(ConversationPage.mapStateToProps, {
    fetchConversationData, fetchConversationGraph, fetchConversationEmails,
})(ConversationPage);
