import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { enrollToStrech }   from "Actions/Student/EnrollActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Enroll Strech Dialog
 */
class EnrollStrech extends React.Component {
    // The Initial Data
    initialData = {
        modes : [],
        times : [],
    }

    // The Current State
    state = {
        data    : Utils.clone(this.initialData),
        loading : false,
        errors  : {},
    }



    /**
     * Resets the State
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({ data : Utils.clone(this.initialData), errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, enrollToStrech, onSubmit } = this.props;
        const { data, loading                  } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await enrollToStrech(elem.strechID, data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, elem, onClose } = this.props;
        const { data, errors        } = this.state;

        const text = elem.isFree ? "ENROLL_TEXT_FREE" : "ENROLL_TEXT";

        return <EditDialog
            open={open}
            icon="inscription"
            title="ENROLL_NAME"
            error={errors.form}
            primary="ENROLL_NAME"
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isNarrow
        >
            <DialogMessage
                variant="h3"
                message={NLS.format(text, elem.title)}
                noSpace={!elem.canConverse}
                centered
            />
            <DialogMessage
                isHidden={!elem.canConverse}
                variant="p"
                message="ENROLL_TEXT_OPTIONS"
                smallSpace
                centered
            />
            <InputField
                isHidden={!elem.canConverse}
                type="multiple"
                name="modes"
                label="GENERAL_MODES"
                value={data.modes}
                error={errors.modes}
                onChange={this.handleChange}
                options="MODES"
                isRequired
            />
            <InputField
                isHidden={!elem.canConverse}
                type="multiple"
                name="times"
                label="SCHEDULES_NAME"
                value={data.times}
                error={errors.times}
                onChange={this.handleChange}
                options="TIMES"
                isRequired
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        enrollToStrech : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        onClose        : PropTypes.func.isRequired,
        onSubmit       : PropTypes.func.isRequired,
        elem           : PropTypes.object,
    }
}

export default connect(null, {
    enrollToStrech,
})(EnrollStrech);
