import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Location }         from "Utils/API";

// Types
import {
    LOCATION, LOCATION_LOADING, LOCATION_CHARGING,
    LOCATION_LIST, LOCATION_ELEM, LOCATION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Location List
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchLocations(params = {}) {
    return async (dispatch) => {
        dispatch({ type : LOCATION_LOADING });
        Params.unset(params);
        const data = await Location.getAll(params);
        data.sort = params;
        dispatch({ type : LOCATION_LIST, data });
        dispatch(setSection(LOCATION));
    };
}

/**
 * Fetches a single Location
 * @param {Number}   locationID
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchLocation(locationID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : LOCATION_CHARGING });
        }
        const data = await Location.getOne({ locationID });
        dispatch({ type : LOCATION_ELEM, data });
    };
}

/**
 * Fetches the Location Create data
 * @returns {(dispatch) => Void}
 */
export function fetchLocationCreate() {
    return async (dispatch) => {
        const data = await Location.getCreateData();
        dispatch({ type : LOCATION_CREATE, data });
    };
}



/**
 * Edits/Creates a Location
 * @param {Object} data
 * @returns {() => Void}
 */
export function editLocation(data) {
    return () => Location.edit(data);
}

/**
 * Deletes a Location
 * @param {Number} locationID
 * @returns {() => Void}
 */
export function deleteLocation(locationID) {
    return () => Location.delete({ locationID });
}
