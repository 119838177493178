import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import InvoiceFilter        from "./InvoiceFilter";
import InvoiceCreate        from "./InvoiceCreate";
import InvoiceView          from "./InvoiceView";
import InvoiceSend          from "./InvoiceSend";
import InvoiceAttach        from "./InvoiceAttach";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import StatList             from "dashboard/dist/Components/StatList";
import StatItem             from "dashboard/dist/Components/StatItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchInvoices, exportInvoices, approveInvoice, rejectInvoice, paidInvoice,
} from "Actions/Admin/Coach/InvoiceActions";



/**
 * The Coach Invoice List
 */
class InvoiceList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
        if (this.props.params.invoiceID) {
            this.startAction(Action.get("VIEW"), this.props.params.invoiceID);
        }
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Object=}  filters
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, filters, withLoader) => {
        const { type, elemID, data, fetchInvoices } = this.props;
        fetchInvoices(type, elemID, params || data.sort, filters || data.filters, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        const invoice = Utils.getValue(this.props.data.list, "invoiceID", elemID);
        if (action.isCoach) {
            Href.goto("COACHES", invoice.credentialID, "INVOICES");
        } else if (action.isStrech) {
            Href.goto("STRECHES", invoice.strechID);
        } else if (action.isDownload) {
            window.open(invoice.fileUrl);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @param {Object=}  params
     * @returns {Void}
     */
    endAction = (update, params = null) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(params, null, false);
        }
    }

    /**
     * Handles the Filter Submit
     * @param {Object} newFilters
     * @returns {Promise}
     */
    filterElem = async (newFilters) => {
        const { filters, sort } = this.props.data;
        this.endAction();
        this.fetch({ ...sort, page : 0 }, { ...filters, ...newFilters }, false);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Approve Submit
     * @returns {Promise}
     */
    approveElem = async () => {
        const elemID = this.state.elemID;
        await this.props.approveInvoice(elemID);
        this.endAction(true);
    }

    /**
     * Handles the Reject Submit
     * @returns {Promise}
     */
    rejectElem = async (reason) => {
        const elemID = this.state.elemID;
        await this.props.rejectInvoice(elemID, reason);
        this.endAction(true);
    }

    /**
     * Handles the Paid Submit
     * @returns {Promise}
     */
    paidElem = async () => {
        const elemID = this.state.elemID;
        await this.props.paidInvoice(elemID);
        this.endAction(true);
    }



    /**
     * Returns true if it can Download the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canDownload(elemID) {
        const { list } = this.props.data;
        const elem = Utils.getValue(list, "invoiceID", elemID);
        return Boolean(elem.file);
    }

    /**
     * Returns true if it can Send the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canSend(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "invoiceID", elemID).canSend;
    }

    /**
     * Returns true if it can Attach the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canAttach(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "invoiceID", elemID).canAttach;
    }

    /**
     * Returns true if it can Approve the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canApprove(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "invoiceID", elemID).canApprove;
    }

    /**
     * Returns true if it can Reject the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canReject(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "invoiceID", elemID).canReject;
    }

    /**
     * Returns true if it can Paid the Invoice
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canPaid(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "invoiceID", elemID).canPaid;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                                      } = this.state;
        const { data, strech, params, type, route, withDetails      } = this.props;
        const { canEdit, list, total, stats, sort, filters, loading } = data;

        const forStrech        = type === "STRECH";
        const forCoach         = type === "COACH" || type === "USER";
        const hasConversations = (forStrech && strech.hasConversations) || !forStrech
        const hasMeetings      = (forStrech && strech.hasGroups) || !forStrech

        return <Main withDetails={withDetails}>
            <Header message="COACH_INVOICES_NAME" icon="invoice" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <StatList twoLines>
                    <StatItem message="GENERAL_TOTAL"      value={stats.total}              isPrice />
                    <StatItem message="CONVERSATIONS_NAME" value={stats.totalConversations} isPrice isHidden={!hasConversations} />
                    <StatItem message="MEETINGS_NAME"      value={stats.totalMeetings}      isPrice isHidden={!hasMeetings} />
                    <StatItem message="GENERAL_FEE"        value={stats.totalFees}          isPrice />
                </StatList>
                <Table fetch={this.fetch} sort={sort} none="COACH_INVOICES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="createdTime"    message="GENERAL_CREATED_FEM" maxWidth="160" />
                        <TableHeader field="coachFirstName" message="COACHES_SINGULAR"    isHidden={forCoach} />
                        <TableHeader field="strechName"     message="STRECHES_SINGULAR"   isHidden={forStrech} grow="2" />
                        <TableHeader field="amount"         message="GENERAL_TOTAL"       maxWidth="100" align="center" />
                        <TableHeader field="status"         message="GENERAL_STATUS"      maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.invoiceID} elemID={elem.invoiceID}>
                            <TableCell message={elem.createdDateTime} />
                            <TableCell message={elem.coachName}       />
                            <TableCell message={elem.strechString}    />
                            <TableCell message={`$ ${elem.amount}`}   />
                            <TableCell message={elem.statusName}      className={elem.statusClass} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"     message="COACH_INVOICES_VIEW_TITLE"     />
                        <TableAction action="DOWNLOAD" message="COACH_INVOICES_DOWNLOAD_TITLE" hide={(elemID) => !this.canDownload(elemID)} />
                        <TableAction action="COACH"    message="COACHES_VIEW_TITLE"            isHidden={forCoach  || !canEdit} />
                        <TableAction action="STRECH"   message="STRECHES_VIEW_TITLE"           isHidden={forStrech || !canEdit} />
                        <TableAction action="SEND"     message="COACH_INVOICES_SEND_TITLE"     hide={(elemID) => !this.canSend(elemID)}    />
                        <TableAction action="ATTACH"   message="COACH_INVOICES_ATTACH_TITLE"   hide={(elemID) => !this.canAttach(elemID)}  />
                        <TableAction action="APPROVE"  message="COACH_INVOICES_APPROVE_TITLE"  hide={(elemID) => !this.canApprove(elemID)} />
                        <TableAction action="REJECT"   message="COACH_INVOICES_REJECT_TITLE"   hide={(elemID) => !this.canReject(elemID)}  />
                        <TableAction action="PAID"     message="COACH_INVOICES_PAID_TITLE"     hide={(elemID) => !this.canPaid(elemID)}    />
                    </TableActionList>
                </Table>
            </Content>

            <InvoiceFilter
                open={action.isFilter}
                filters={filters}
                withStrech={!forStrech}
                withCoach={!forCoach}
                onSubmit={this.filterElem}
                onClose={this.endAction}
            />
            <InvoiceCreate
                open={action.isCreate}
                coachID={params.coachID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <InvoiceView
                open={action.isView}
                elemID={elemID}
                onClose={this.endAction}
            />
            <InvoiceSend
                open={action.isSend}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <InvoiceAttach
                open={action.isAttach}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />

            <ConfirmDialog
                icon="check"
                open={action.isApprove}
                title="COACH_INVOICES_APPROVE_TITLE"
                message="COACH_INVOICES_APPROVE_TEXT"
                onSubmit={this.approveElem}
                onClose={this.endAction}
            />
            <PromptDialog
                icon="cancel"
                open={action.isReject}
                title="COACH_INVOICES_REJECT_TITLE"
                message="COACH_INVOICES_REJECT_TEXT"
                inputLabel="COACH_INVOICES_REJECT_REASON"
                onSubmit={this.rejectElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                icon="check"
                open={action.isPaid}
                title="COACH_INVOICES_PAID_TITLE"
                message="COACH_INVOICES_PAID_TEXT"
                onSubmit={this.paidElem}
                onClose={this.endAction}
            />

            <Downloader
                download={action.isExport}
                source={exportInvoices(type, this.props.elemID, filters)}
                onLoad={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInvoices  : PropTypes.func.isRequired,
        approveInvoice : PropTypes.func.isRequired,
        rejectInvoice  : PropTypes.func.isRequired,
        paidInvoice    : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        strech         : PropTypes.object.isRequired,
        type           : PropTypes.string.isRequired,
        route          : PropTypes.string.isRequired,
        withDetails    : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.coachInvoice,
            strech : state.strech.elem,
        };
    }
}

export default connect(InvoiceList.mapStateToProps, {
    fetchInvoices, approveInvoice, rejectInvoice, paidInvoice,
})(InvoiceList);
