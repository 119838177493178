import Utils                from "dashboard/dist/Utils/Utils";
import GraphType            from "Utils/Entity/GraphType";

// Types
import {
    INVOICE_REPORT_LOADING,
    INVOICE_REPORT_DATA, INVOICE_REPORT_GRAPH,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    loaded      : false,
    fetched     : false,
    error       : false,
    canExport   : true,
    edition     : 0,
    courses     : [],
    programs    : [],
    streches    : [],
    report      : [],
    total       : 0,
    totalAmount : 0,
    totalPrice  : 0,
};



/**
 * Parses the Streches
 * @param {Object[]} report
 * @param {Number}   graphType
 * @returns {Object[]}
 */
function parseReport(report, graphType) {
    return Utils.parseList(report, (elem) => {
        elem.name = GraphType.getReportName(graphType, elem.name);
    });
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, INVOICE_REPORT_DATA, INVOICE_REPORT_GRAPH)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case INVOICE_REPORT_LOADING:
        return {
            ...state,
            loading     : true,
        };

    case INVOICE_REPORT_DATA:
        return {
            ...state,
            loading     : false,
            error       : false,
            loaded      : true,
            courses     : action.data.courses,
            programs    : action.data.programs,
            streches    : action.data.streches,
        };

    case INVOICE_REPORT_GRAPH:
        return {
            ...state,
            loading     : false,
            error       : false,
            fetched     : true,
            report      : parseReport(action.data.report, action.data.graphType),
            total       : action.data.report.length,
            totalAmount : action.data.totalAmount,
            totalPrice  : action.data.totalPrice,
        };

        default:
        return state;
    }
};

export default reducer;
