const data = {
    NONE                    : "",

    // Auth Urls
    AUTH                    : "inicio",
    AUTH_CODE               : "inicio/:code",
    INSCRIPTION             : "inscripcion",
    INSC_NONE_STRECH        : "inscripcion/tramo/:strechID/:code",
    INSC_NONE_CHAT          : "inscripcion/charla/:chatID/:code",
    INSC_COURSE             : "inscripcion/:course",
    INSC_COURSE_STRECH      : "inscripcion/:course/tramo/:strechID/:code",
    INSC_COURSE_CHAT        : "inscripcion/:course/charla/:chatID/:code",
    INSC_STRECH_ONLY        : "inscripcion/curso",
    INSC_STRECH             : "inscripcion/curso/:strechID",
    INSC_STRECH_ACCESS      : "inscripcion/curso/:strechID/ingresar",
    INSC_STRECH_STRECH      : "inscripcion/curso/:strechID/:code",
    INSC_STRECH_CHAT        : "inscripcion/curso/:strechID/charla/:chatID/:code",
    ASSIGNME                : "asignarme",
    ASSIGNME_STRECH         : "asignarme/:strechID",
    ASSIGNME_CODE           : "asignarme/:strechID/:code",


    // User Urls
    WELCOME                 : "inicio",
    HOME                    : "inicio",
    ENROLL                  : "inscribirme",
    CALENDAR                : "calendario",

    COURSES_MINE            : "mis-cursos",
    COURSE_MINE             : "mis-cursos/:strechID",
    COURSE_MINE_CLASS       : "mis-cursos/:strechID/clases/:classID",
    CLASS_MINE              : "clases/:classID",
    CLASS_MINE_GUIDE        : "clases/:classID/guia/:guideID",


    // Admin Urls
    DASHBOARD               : "inicio",
    DASHBOARD_SIGNUPS       : "inicio/registros",
    DASHBOARD_INSCRIPTIONS  : "inicio/inscripciones",
    SUPPORT                 : "soporte",


    // Course Urls
    COURSES                 : "cursos",
    COURSE                  : "cursos/:courseID",
    COURSE_GUIDE            : "cursos/:courseID/guias/:guideID",
    COURSE_PROGRAM          : "cursos/:courseID/programas/:programID",
    COURSE_PROGRAM_STRECH   : "cursos/:courseID/programas/:programID/tramos/:strechID",
    COURSE_PROGRAM_CLASS    : "cursos/:courseID/programas/:programID/tramos/:strechID/clases/:classID",
    COURSE_PROGRAM_GROUP    : "cursos/:courseID/programas/:programID/tramos/:strechID/grupos/:groupID",
    COURSE_STRECH           : "cursos/:courseID/tramos/:strechID",
    COURSE_STRECH_CLASS     : "cursos/:courseID/tramos/:strechID/clases/:classID",
    COURSE_STRECH_GROUP     : "cursos/:courseID/tramos/:strechID/grupos/:groupID",


    // Program Urls
    PROGRAMS                : "programas",
    PROGRAM                 : "programas/:programID",
    PROGRAM_STRECH          : "programas/:programID/tramos/:strechID",
    PROGRAM_STRECH_CLASS    : "programas/:programID/tramos/:strechID/clases/:classID",
    PROGRAM_STRECH_GROUP    : "programas/:programID/tramos/:strechID/grupos/:groupID",

    PRICES                  : "precios",
    CLASSES                 : "clases",
    VIDEOS                  : "videos",
    POLL                    : "encuesta",
    POLLS                   : "encuestas",


    // Strech Urls
    STRECHES                : "tramos",
    STRECH                  : "tramos/:strechID",
    STRECH_CLASS            : "tramos/:strechID/clases/:classID",
    STRECH_GROUP            : "tramos/:strechID/grupos/:groupID",

    CREATING                : "creando",
    SCHEDULES               : "horarios",
    CHATS                   : "charlas",
    INSCRIPTIONS            : "inscripciones",
    REQUESTS                : "solicitudes",
    SPECIALITIES            : "especialidades",
    ASSIGNMENTS             : "asignaciones",
    PRESENTISM              : "presentismo",
    CERTIFICATE             : "certificado",


    // Conversation Urls
    CONVERSATIONS           : "conversaciones",
    CONVERSATIONS_MINE      : "mis-conversaciones",


    // Group Urls
    GROUPS                  : "grupos",
    GROUP                   : "grupos/:groupID",
    GROUP_MINE              : "grupo",

    MEETINGS                : "reuniones",


    // Billing Urls
    INVOICES                : "facturas",
    INVOICES_INVOICE        : "facturas/:invoiceID",


    // Campaign Urls
    CAMPAIGNS               : "campañas",
    CAMPAIGNS_CAMPAIGN      : "campañas/:campaignID",

    USERS                   : "usuarios",
    CONTENT                 : "contenido",


    // Report Urls
    REPORTS                 : "reportes",


    // Tickets Urls
    TICKETS                 : "consultas",
    TICKETS_TICKET          : "consultas/:ticketID",
    TICKETS_CREATE          : "consultas/crear",

    HELP                    : "ayuda",


    // Lead Urls
    LEADS                   : "leads",
    LEADS_LEAD              : "leads/:leadID",


    // Guide Urls
    GUIDES                  : "guias",
    GUIDE                   : "guias/:guideID",
    GUIDE_STUDENT           : "guias/:guideID/:studentID",

    SECTIONS                : "secciones",
    QUESTIONS               : "preguntas",


    // Material Urls
    MATERIALS               : "materiales",
    CATEGORIES              : "categorias",
    LIBRARY                 : "biblioteca",


    // Student Urls
    STUDENTS                : "alumnos",
    STUDENT                 : "alumnos/:studentID",
    STUDENT_STRECH          : "alumnos/:studentID/tramos/:strechID",
    STUDENT_STRECH_CLASS    : "alumnos/:studentID/tramos/:strechID/clases/:classID",
    STUDENT_STRECH_GROUP    : "alumnos/:studentID/tramos/:strechID/grupos/:groupID",


    // Coach Urls
    COACHES                 : "coaches",
    COACH                   : "coaches/:coachID",
    COACH_GROUP             : "coaches/:coachID/grupos/:groupID",
    COACH_STRECH            : "coaches/:coachID/tramos/:strechID",
    COACH_STRECH_CLASS      : "coaches/:coachID/tramos/:strechID/clases/:classID",
    COACH_STRECH_GROUP      : "coaches/:coachID/tramos/:strechID/grupos/:groupID",


    // Location Urls
    LOCATION_SINGLE         : "configuracion/ubicacion/lugares",
    LOCATION                : "configuracion/ubicacion/lugares/:locationID",
    LOCATION_STRECH         : "configuracion/ubicacion/lugares/:locationID/tramos/:strechID",
    LOCATION_STRECH_CLASS   : "configuracion/ubicacion/lugares/:locationID/tramos/:strechID/clases/:classID",
    LOCATION_STRECH_GROUP   : "configuracion/ubicacion/lugares/:locationID/tramos/:strechID/grupos/:groupID",

    ROOMS                   : "salones",


    // Settings Urls
    SETTINGS                : "configuracion",
    SETTINGS_GENERAL        : "general",

    ADMINS                  : "general/admins",
    INTERESTS               : "general/intereses",
    GROUPINTERESTS          : "general/grupointereses",
    STAFF                   : "general/quienes-somos",
    NEWS                    : "general/novedades",
    ADVICES                 : "general/consejos",

    SETTINGS_STRECHES       : "tramos",
    PAYMENT_METHODS         : "tramos/pagos",
    MATERIAL_CATEGORIES     : "tramos/categorias",
    CANCEL_REASONS          : "tramos/motivos",

    SETTINGS_COACHES        : "coaches",
    COACHES_SPECIALITIES    : "coaches/especialidades",
    COACHES_CATEGORIES      : "coaches/categorias",
    COACHES_QUESTIONS       : "coaches/preguntas",
    COACHES_MATERIALS       : "coaches/materiales",
    COACHES_PROFESION       : "coaches/profesions",

    SETTINGS_TICKETS        : "consultas",
    TICKETS_HELP            : "consultas/ayuda",
    TICKETS_TYPES           : "consultas/tipos",

    SETTINGS_SUPPORT        : "soporte",
    SUPPORT_HELP            : "soporte/ayuda",
    SUPPORT_TYPES           : "soporte/tipos",

    SETTINGS_LEADS          : "leads",
    LEADS_ORIGINS           : "leads/origenes",
    LEADS_ASSIGNMENTS       : "leads/asignaciones",
    LEADS_REASONS           : "leads/motivos",

    SETTINGS_LOCATIONS      : "ubicacion",
    LOCATIONS               : "ubicacion/lugares",
    COUNTRIES               : "ubicacion/paises",
    PROVINCES               : "ubicacion/provincias",
    LOCALITIES              : "ubicacion/localidades",

    SETTINGS_EMAILS         : "emails",
    EMAIL_TEMPLATES         : "emails/plantillas",
    EMAIL_QUEUE             : "emails/queue",
    EMAIL_WHITE_LIST        : "emails/emails",

    SETTINGS_EXTRAS         : "extras",
    MEDIA                   : "extras/archivos",
    ACTIONS                 : "extras/acciones",
    ERRORS                  : "extras/errores",


    // Profile Urls
    PROFILE                 : "perfil",
    PROFILE_SETTINGS        : "perfil/configuracion",
};

export default data;
