import React                from "react";
import PropTypes            from "prop-types";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Conversation View Dialog
 */
class ConversationView extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { type, data          } = elem;

        const values = data || {};

        return <ViewDialog
            open={open}
            icon={type}
            title="CONVERSATIONS_VIEW_TITLE"
            onClose={onClose}
            withSpacing
        >
            <ViewField
                label="COACHES_SINGULAR"
                value={values.coachName}
                isHidden={values.asCoach}
            />
            <ViewField
                label="CONVERSATIONS_COACHEE"
                value={values.userName}
                isHidden={!values.asCoach}
            />
            <ViewField
                label="CONVERSATIONS_COURSE"
                value={values.strechTitle}
                isHidden={values.asCoach}
            />
            <ViewField
                label="CONVERSATIONS_STRECH"
                value={values.strechName}
                isHidden={!values.asCoach}
            />
            <ViewField
                label="SPECIALITIES_SHORT"
                value={values.specialityName}
            />
            <ViewField
                label="GENERAL_MODE"
                value={values.modeName}
            />

            {values.isPresential && <ViewField
                label="LOCATIONS_SINGULAR"
                value={values.locationString}
            />}
            {values.hasStream && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={values.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={values.streamPassword} />
            </>}
            <ViewField label="GENERAL_DATE" value={values.timeString} />
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elem    : PropTypes.object.isRequired,
    }
}

export default ConversationView;
