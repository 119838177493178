import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProgram }     from "Actions/Admin/Program/ProgramActions";

// Sections
import PriceList            from "Components/Admin/Program/Price/PriceList";
import ChatList             from "Components/Admin/Program/Chat/ChatList";
import ClassList            from "Components/Admin/Program/Class/ClassList";
import MaterialList         from "Components/Admin/Program/Material/MaterialList";
import GuideList            from "Components/Admin/Program/Guide/GuideList";
import SpecialityList       from "Components/Admin/Program/Speciality/SpecialityList";
import QuestionList         from "Components/Admin/Program/Question/QuestionList";
import StrechList           from "Components/Admin/Strech/Strech/StrechList";

// Components
import ProgramDetails       from "./ProgramDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Program Page
 */
class ProgramPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchProgram(this.props.params.programID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.programID !== prevProps.params.programID) {
            this.props.fetchProgram(this.props.params.programID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="PROGRAMS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="PRICES_NAME"        url="PRICES"       icon="price"      isHidden={elem.isFree} />
                <NavigationItem message="CHATS_NAME"         url="CHATS"        icon="chat"       isHidden={!elem.hasChats} />
                <NavigationItem message="CLASSES_NAME"       url="CLASSES"      icon="class"      isHidden={!elem.hasClasses} />
                <NavigationItem message="MATERIALS_NAME"     url="MATERIALS"    icon="material"   isHidden={!elem.hasMaterials} />
                <NavigationItem message="GUIDES_NAME"        url="GUIDES"       icon="guide"      isHidden={!elem.hasGuides} />
                <NavigationItem message="SPECIALITIES_TITLE" url="SPECIALITIES" icon="speciality" isHidden={!elem.hasConversations} />
                <NavigationItem message="POLL_NAME"          url="QUESTIONS"    icon="question"   isHidden={!elem.hasPoll} />
                <NavigationItem message="STRECHES_NAME"      url="STRECHES"     icon="strech"     />
            </SecondaryNav>

            <SwitchRoute type="PROGRAM" path={path} baseUrl={url} withDetails>
                <AdminRoute url="PRICES"       component={PriceList}      isHidden={elem.isFree} />
                <AdminRoute url="CHATS"        component={ChatList}       isHidden={!elem.hasChats} />
                <AdminRoute url="CLASSES"      component={ClassList}      isHidden={!elem.hasClasses} />
                <AdminRoute url="MATERIALS"    component={MaterialList}   isHidden={!elem.hasMaterials} />
                <AdminRoute url="GUIDES"       component={GuideList}      isHidden={!elem.hasGuides} />
                <AdminRoute url="SPECIALITIES" component={SpecialityList} isHidden={!elem.hasConversations} />
                <AdminRoute url="QUESTIONS"    component={QuestionList}   isHidden={!elem.hasPoll} />
                <AdminRoute url="STRECHES"     component={StrechList}     />
            </SwitchRoute>

            <ProgramDetails programID={params.programID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProgram : PropTypes.func.isRequired,
        elem         : PropTypes.object.isRequired,
        from         : PropTypes.string.isRequired,
        params       : PropTypes.object.isRequired,
        match        : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.program.elem,
        };
    }
}

export default connect(ProgramPage.mapStateToProps, {
    fetchProgram,
})(ProgramPage);
