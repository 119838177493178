import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { resetPassword }    from "Actions/Core/AuthActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Auth Reset Password Step
 */
class ResetPassStep extends React.Component {
    initialData = {
        code            : "",
        password        : "",
        confirmPassword : "",
    };

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }

    /**
     * Sets the Initial Data
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.code) {
            this.handleChange("code", this.props.code);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { resetPassword, onSubmit } = this.props;
        const { data, loading           } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await resetPassword(data);
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Email
     * @returns {Promise}
     */
    handleLink = async () => {
        const { email, code, match, onSubmit } = this.props;
        await this.setState({ data : { ...this.initialData } });
        if (code) {
            const url = match.url.replace(`/${code}`, "");
            Href.goto(url);
        } else {
            onSubmit({ step : "email", email });
        }
    }

    /**
     * Handles the Email
     * @returns {Promise}
     */
    handleEmail = async () => {
        const { email, onSubmit } = this.props;
        await this.setState({ data : { ...this.initialData } });
        onSubmit({ step : "email", email });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, email          } = this.props;
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title={title}
            email={email}
            help={NLS.format("AUTH_RESET_HELP", email)}
            error={errors.form}
            link="AUTH_GO_BACK"
            onClick={this.handleLink}
            onEmail={this.handleEmail}
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                name="code"
                icon="password"
                label="GENERAL_CODE"
                value={data.code}
                error={errors.code}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />
            <IconField
                className="auth-field"
                type="password"
                name="password"
                icon="password"
                label="USERS_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.password}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />
            <IconField
                className="auth-field"
                type="password"
                name="confirmPassword"
                icon="password"
                label="USERS_PASSWORD_CONFIRM"
                autoComplete="new-password"
                value={data.confirmPassword}
                error={errors.confirmPassword}
                onChange={this.handleChange}
                isRequired
                smallMargin
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        resetPassword : PropTypes.func.isRequired,
        match         : PropTypes.object.isRequired,
        title         : PropTypes.string,
        code          : PropTypes.string,
        email         : PropTypes.string,
        onSubmit      : PropTypes.func,
    }
}

export default connect(null, {
    resetPassword,
})(ResetPassStep);
