import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchWelcome }     from "Actions/Admin/Strech/StrechActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ClassDetails         from "Components/Admin/Strech/Class/ClassDetails";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import PDFViewer            from "dashboard/dist/Components/PDFViewer";
import Button               from "dashboard/dist/Components/Button";
import Html                 from "dashboard/dist/Components/Html";

// Styles
import "Styles/Components/App/Strech.css";



/**
 * The Strech Welcome Page
 */
class WelcomePage extends React.Component {
    // The Current State
    state = {
        loading     : true,
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchWelcome(this.props.params.strechID);
    }



    /**
     * Handles the PDF Load
     * @param {Number} totalPages
     * @returns {Void}
     */
    handleLoad = (totalPages) => {
        this.setState({ totalPages, loading : false });
    }

    /**
     * Handles the Prev Page button
     * @returns {Void}
     */
    handlePrevPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage : currentPage - 1 });
        }
    }

    /**
     * Handles the Next Page button
     * @returns {Void}
     */
    handleNextPage = () => {
        const { currentPage, totalPages } = this.state;
        if (currentPage < totalPages) {
            this.setState({ currentPage : currentPage + 1 });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            charging, error, route, strech, coachName,
            materials, conversations, coaches, schedules
        } = this.props;
        const { loading, currentPage, totalPages } = this.state;

        const showAssignment    = strech.hasConversations && strech.conversations > 0;
        const showConversations = strech.hasStarted && showAssignment;
        const columns           = showAssignment ? 3 : 2;
        const hasMaterials      = !error && materials.length > 0;
        const showAside         = Boolean(hasMaterials || strech.streamLink);
        const prevDisabled      = currentPage === 1;
        const nextDisabled      = currentPage >= totalPages;
        const showNavigation    = !loading && totalPages > 1;

        return <>
            <Main withDetails={hasMaterials}>
                <Header message="WELCOME_NAME" icon="home" route={route} />
                <Content isLoading={charging}>
                    {error ? <NoneAvailable message="COURSES_ERROR_EXISTS" /> : <>
                        <section className={`strech-header strech-header-${columns}`}>
                            <div className="strech-info">
                                <h3>{NLS.pluralize("STRECHES_COACHES", coaches.length)}</h3>
                                {coaches.map((elem, index) => <p key={index}>{elem.credentialName}</p>)}
                            </div>
                            <div className="strech-info">
                                <h3>{NLS.get("SCHEDULES_SINGULAR")}</h3>
                                {strech.hasClasses ?
                                    schedules.map((elem, index) => <p key={index}>{elem}</p>) :
                                    <p>{strech.timeText}</p>
                                }
                            </div>
                            {showAssignment && <div className="strech-info">
                                <h3>{NLS.get("STRECHES_COACH")}</h3>
                                <p><b>{coachName}</b></p>
                                {showConversations && <Html variant="p">
                                    {NLS.pluralize("STRECHES_CONVERSATIONS", conversations)}
                                </Html>}
                            </div>}
                        </section>

                        <section className="strech-guide">
                            {strech.guideIsPDF && <PDFViewer
                                source={strech.guideFileUrl}
                                currentPage={currentPage}
                                onLoad={this.handleLoad}
                            />}
                            {strech.guideIsImage && <img
                                alt={strech.name}
                                src={strech.guideFileUrl}
                                style={{ maxWidth : "100%" }}
                            />}
                        </section>

                        {showNavigation && <div className="strech-pages">
                            <Button
                                className="right-space-big"
                                message="GENERAL_PREV"
                                variant="primary"
                                icon="prev"
                                onClick={this.handlePrevPage}
                                isDisabled={prevDisabled}
                            />
                            <p>{Utils.getPageText(currentPage, totalPages)}</p>
                            <Button
                                className="left-space-big"
                                message="GENERAL_NEXT"
                                variant="primary"
                                afterIcon="next"
                                onClick={this.handleNextPage}
                                isDisabled={nextDisabled}
                            />
                        </div>}
                    </>}
                </Content>
            </Main>

            {showAside && <ClassDetails
                isLoading={charging}
                elem={strech}
                materials={materials}
            />}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchWelcome  : PropTypes.func.isRequired,
        charging      : PropTypes.bool.isRequired,
        error         : PropTypes.bool.isRequired,
        strech        : PropTypes.object.isRequired,
        coachName     : PropTypes.string.isRequired,
        conversations : PropTypes.number.isRequired,
        coaches       : PropTypes.array.isRequired,
        schedules     : PropTypes.array.isRequired,
        materials     : PropTypes.array.isRequired,
        params        : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging      : state.strech.charging,
            error         : state.strech.error,
            strech        : state.strech.elem,
            coachName     : state.strech.coachName,
            conversations : state.strech.conversations,
            coaches       : state.strech.coaches,
            schedules     : state.strech.schedules,
            materials     : state.strech.materials,
        };
    }
}

export default connect(WelcomePage.mapStateToProps, {
    fetchWelcome,
})(WelcomePage);
