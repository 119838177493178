import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Containers
import AdminContainer       from "Components/Admin/AdminContainer";
import ProfileContainer     from "Components/Profile/ProfileContainer";
import ReportContainer      from "Components/Admin/Report/ReportContainer";
import SetupContainer       from "Components/Admin/Setup/SetupContainer";

// Sections
import CoursePage           from "Components/Admin/Course/Course/CoursePage";
import ProgramPage          from "Components/Admin/Program/Program/ProgramPage";
import StrechPage           from "Components/Admin/Strech/Strech/StrechPage";
import ClassPage            from "Components/Admin/Strech/Class/ClassPage";
import GroupPage            from "Components/Admin/Strech/Group/GroupPage";
import CampaignPage         from "Components/Admin/Campaign/CampaignPage";
import GuidePage            from "Components/Admin/Guide/Guide/GuidePage";
import LocationPage         from "Components/Admin/Location/Location/LocationPage";
import StudentPage          from "Components/Admin/User/Student/StudentPage";
import CoachPage            from "Components/Admin/User/Coach/CoachPage";

// Components
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Admin Router
 */
class AdminRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isAdmin) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <AdminRoute url="PROFILE"               component={ProfileContainer} />
            <AdminRoute url="COURSE_GUIDE"          component={GuidePage}        />
            <AdminRoute url="COURSE_PROGRAM_CLASS"  component={ClassPage}        />
            <AdminRoute url="COURSE_PROGRAM_GROUP"  component={GroupPage}        />
            <AdminRoute url="COURSE_PROGRAM_STRECH" component={StrechPage}       />
            <AdminRoute url="COURSE_PROGRAM"        component={ProgramPage}      />
            <AdminRoute url="COURSE_STRECH_CLASS"   component={ClassPage}        />
            <AdminRoute url="COURSE_STRECH_GROUP"   component={GroupPage}        />
            <AdminRoute url="COURSE_STRECH"         component={StrechPage}       />
            <AdminRoute url="COURSE"                component={CoursePage}       />
            <AdminRoute url="PROGRAM_STRECH_CLASS"  component={ClassPage}        />
            <AdminRoute url="PROGRAM_STRECH_GROUP"  component={GroupPage}        />
            <AdminRoute url="PROGRAM_STRECH"        component={StrechPage}       />
            <AdminRoute url="PROGRAM"               component={ProgramPage}      />
            <AdminRoute url="STRECH_CLASS"          component={ClassPage}        />
            <AdminRoute url="STRECH_GROUP"          component={GroupPage}        />
            <AdminRoute url="STRECH"                component={StrechPage}       />
            <AdminRoute url="CAMPAIGNS_CAMPAIGN"    component={CampaignPage}     />
            <AdminRoute url="GUIDE"                 component={GuidePage}        />
            <AdminRoute url="LOCATION_STRECH_CLASS" component={ClassPage}        />
            <AdminRoute url="LOCATION_STRECH_GROUP" component={GroupPage}        />
            <AdminRoute url="LOCATION_STRECH"       component={StrechPage}       />
            <AdminRoute url="LOCATION"              component={LocationPage}     />
            <AdminRoute url="STUDENT_STRECH_CLASS"  component={ClassPage}        />
            <AdminRoute url="STUDENT_STRECH_GROUP"  component={GroupPage}        />
            <AdminRoute url="STUDENT_STRECH"        component={StrechPage}       />
            <AdminRoute url="STUDENT"               component={StudentPage}      />
            <AdminRoute url="COACH_GROUP"           component={GroupPage}        />
            <AdminRoute url="COACH_STRECH_CLASS"    component={ClassPage}        />
            <AdminRoute url="COACH_STRECH_GROUP"    component={GroupPage}        />
            <AdminRoute url="COACH_STRECH"          component={StrechPage}       />
            <AdminRoute url="COACH"                 component={CoachPage}        />

            <AdminRoute url="REPORTS"               component={ReportContainer}  />
            <AdminRoute url="SETTINGS"              component={SetupContainer}   />
            <AdminRoute url="NONE"                  component={AdminContainer}   />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAdmin : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAdmin : state.auth.isAdmin,
        };
    }
}

export default connect(AdminRouter.mapStateToProps)(AdminRouter);
