import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchMembers }     from "Actions/Admin/Strech/MemberActions";
import Action               from "dashboard/dist/Core/Action";
import TicketUse            from "Utils/Entity/TicketUse";

// Dialogs
import TicketEdit           from "Components/Admin/Ticket/Ticket/TicketEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";



/**
 * The Group Page
 */
class GroupPage extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchMembers(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, route, params, credentialID } = this.props;
        const { list, total, sort, loading        } = data;
        const { action                            } = this.state;

        return <Main>
            <Header message="GROUPS_MINE" icon="group" route={route}>
                <ActionList onAction={this.startAction}>
                    <ActionItem action="REQUEST" message="GROUPS_CHANGE_TITLE" />
                </ActionList>
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="STUDENTS_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="firstName" message="GENERAL_NAME"      />
                        <TableHeader field="email"     message="GENERAL_EMAIL"     />
                        <TableHeader field="phone"     message="GENERAL_CELLPHONE" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.email}          />
                            <TableCell message={elem.phoneString}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                </Table>
            </Content>

            <TicketEdit
                open={action.isRequest}
                title="GROUPS_CHANGE_REQUEST"
                use={TicketUse.CHANGE_GROUP}
                studentID={credentialID}
                strechID={params.strechID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMembers : PropTypes.func.isRequired,
        credentialID : PropTypes.number.isRequired,
        data         : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
        type         : PropTypes.string.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data         : state.groupMember,
            credentialID : state.auth.credentialID,
        };
    }
}

export default connect(GroupPage.mapStateToProps, {
    fetchMembers,
})(GroupPage);
