import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Dialogs
import TicketEdit           from "./TicketEdit";
import TicketAssign         from "./TicketAssign";
import TicketMessage        from "./TicketMessage";

// Components
import IconLink             from "dashboard/dist/Components/IconLink";
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import InfoAction           from "dashboard/dist/Components/InfoAction";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchTicket, openTicket, closeTicket,
} from "Actions/Admin/Ticket/TicketActions";
import {
    addMessage, editMessage, deleteMessage,
} from "Actions/Admin/Ticket/TicketMessageActions";

// Styles
import "Styles/Components/App/Tickets.css";



/**
 * The Ticket View Dialog
 */
class TicketView extends React.Component {
    // The Initial Data
    initialData = {
        ticketID : 0,
        message  : "",
    }

    // The Current State
    state = {
        action  : Action.get(),
        message : {},
        loading : false,
        data    : { ...this.initialData },
        sending : false,
        update  : false,
        errors  : {},
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchTicket } = this.props;

        // View Opens
        if (open && !prevProps.open) {
            fetchTicket(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({
                data    : { ticketID : elem.ticketID, message : "" },
                loading : false,
            });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Promise}
     */
    addMessage = async (e) => {
        e.preventDefault();
        const { elemID, addMessage, fetchTicket } = this.props;
        const { data, sending                   } = this.state;
        if (sending) {
            return;
        }

        this.setState({ sending : true, errors : {} });
        try {
            await addMessage(data);
            await fetchTicket(elemID);
            this.setState({ update : true, sending : false, data : { ...data, message : "" } });
        } catch (errors) {
            this.setState({ sending : false, errors });
        }
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Object=} message
     * @returns {Void}
     */
    startAction = (action, message) => {
        this.setState({ action, message });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        if (update !== undefined) {
            const { elemID, fetchTicket } = this.props;
            fetchTicket(elemID);
            this.setState({ action : Action.get(), update });
        } else {
            this.setState({ action : Action.get() });
        }
    }

    /**
     * Edits the Ticket
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Open Submit
     * @returns {Promise}
     */
    openElem = async () => {
        const { ticket, elemID, openTicket } = this.props;
        if (ticket.canOpen) {
            await openTicket(elemID);
            this.endAction(true);
        }
    }

    /**
     * Handles the Close Submit
     * @returns {Promise}
     */
    closeElem = async () => {
        const { ticket, elemID, closeTicket } = this.props;
        if (ticket.canClose) {
            await closeTicket(elemID);
            this.endAction(true);
        }
    }



    /**
     * Handles the Message Edit Submit
     * @param {String} message
     * @returns {Promise}
     */
    editMessage = async (message) => {
        if (this.state.message) {
            const { elem, editMessage } = this.props;
            await editMessage(elem.ticketID, this.state.message.messageID, message);
            this.endAction(true);
        }
    }

    /**
     * Handles the Message Delete Submit
     * @returns {Promise}
     */
    deleteMessage = async () => {
        if (this.state.message) {
            const { elem, deleteMessage } = this.props;
            await deleteMessage(elem.ticketID, this.state.message.messageID);
            this.endAction(true);
        }
    }

    /**
     * Handles the Close Dialog
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { forSupport, open, elem, ticket                    } = this.props;
        const { action, message, loading, sending, data, errors   } = this.state;
        const { messages, canModify, canOpen, canClose, canAssign } = ticket;

        const prefix     = forSupport ? "SUPPORT_" : "TICKETS_";
        const forMessage = Boolean(message && message.messageID);

        return <>
            <ViewDialog
                open={open}
                icon="ticket"
                title={`${prefix}VIEW_TITLE`}
                cancel="GENERAL_CANCEL"
                onClose={this.handleClose}
                isLoading={loading}
            >
                <InfoList className="ticket-info" onAction={this.startAction}>
                    <InfoItem label="GENERAL_STATUS"            message={elem.statusName}     />
                    <InfoItem label={`${prefix}TYPES_SINGULAR`} message={elem.ticketTypeName} />
                    <InfoItem label="TICKETS_ASSIGNED"          message={elem.adminName}      />
                    <InfoItem label="COURSES_SINGULAR"          message={elem.strechTitle}    isHidden={canModify} />
                    <InfoAction action="EDIT"   isHidden={!canModify} />
                    <InfoAction action="ASSIGN" isHidden={canModify || !canAssign} />
                    <InfoAction action="OPEN"   isHidden={!canOpen}  />
                    <InfoAction action="CLOSE"  isHidden={!canClose} />
                </InfoList>

                <div className="ticket-content">
                    {canModify && <h4>{elem.strechName}</h4>}

                    {!messages.length ? <NoneAvailable
                        variant="dialog"
                        message="TICKETS_NONE_MESSAGES"
                    /> : <div className="ticket-messages">
                        {messages.map((msg, index) => <TicketMessage
                            key={msg.messageID}
                            elem={msg}
                            image={index === 0 ? elem.imageUrl : ""}
                            startAction={this.startAction}
                        />)}
                    </div>}

                    <div className="ticket-reply">
                        <InputField
                            className="ticket-input"
                            type="textarea"
                            name="message"
                            placeholder="TICKETS_WRITE_MESSAGE"
                            value={data.message}
                            error={errors.message}
                            onChange={this.handleChange}
                            isDisabled={sending}
                            noMargin
                        />
                        <div>
                            <IconLink
                                className="ticket-link"
                                variant="light"
                                icon="send"
                                onClick={this.addMessage}
                                onTouchEnd={this.addMessage}
                            />
                        </div>
                    </div>
                </div>
            </ViewDialog>

            <TicketEdit
                forSupport={forSupport}
                open={!forMessage && action.isCE}
                elemID={elem.ticketID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <TicketAssign
                forSupport={forSupport}
                open={action.isAssign}
                elemID={elem.ticketID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />

            <ConfirmDialog
                open={action.isOpen}
                icon="status"
                title={`${prefix}OPEN_TITLE`}
                message={`${prefix}OPEN_TEXT`}
                content={elem.ticketTypeName}
                onSubmit={this.openElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isClose}
                icon="status"
                title={`${prefix}CLOSE_TITLE`}
                message={`${prefix}CLOSE_TEXT`}
                content={elem.ticketTypeName}
                onSubmit={this.closeElem}
                onClose={this.endAction}
            />

            <PromptDialog
                open={forMessage && action.isEdit}
                icon="edit"
                title="TICKETS_EDIT_MESSAGE"
                inputType="textarea"
                placeholder="TICKETS_WRITE_MESSAGE"
                initialValue={forMessage ? message.message : ""}
                onSubmit={this.editMessage}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={forMessage && action.isDelete}
                title="TICKETS_DELETE_MESSAGE"
                message="TICKETS_DELETE_MESSAGE_TEXT"
                content={forMessage ? message.message : ""}
                onSubmit={this.deleteMessage}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicket   : PropTypes.func.isRequired,
        openTicket    : PropTypes.func.isRequired,
        closeTicket   : PropTypes.func.isRequired,
        addMessage    : PropTypes.func.isRequired,
        editMessage   : PropTypes.func.isRequired,
        deleteMessage : PropTypes.func.isRequired,
        forSupport    : PropTypes.bool.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        ticket        : PropTypes.object.isRequired,
        edition       : PropTypes.number.isRequired,
        elem          : PropTypes.object.isRequired,
        elemID        : PropTypes.number,
        clientID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            ticket  : state.ticket,
            edition : state.ticket.edition,
            elem    : state.ticket.elem,
        };
    }
}

export default connect(TicketView.mapStateToProps, {
    fetchTicket, openTicket, closeTicket,
    addMessage, editMessage, deleteMessage,
})(TicketView);
