import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchMaterial, fetchMaterialCreate, editMaterial,
} from "Actions/Admin/Material/MaterialActions";



/**
 * The Material Edit Dialog
 */
class MaterialEdit extends React.Component {
    // The Initial Data
    initialData = {
        materialID    : 0,
        courseID      : 0,
        matCategoryID : 0,
        name          : "",
        type          : 0,
        file          : "",
        position      : 0,
        status        : Status.getID("ACTIVE"),
        observations  : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, courseID, position, fetchMaterial, fetchMaterialCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchMaterial(elemID);
                loading = true;
           // Load edit data
            } else if (courseID) {
                fetchMaterialCreate(courseID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, courseID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editMaterial(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { courseID, elemID, open, onClose, courses, categories } = this.props;
        const { data, loading, errors                                } = this.state;

        return <EditDialog
            open={open}
            icon="material"
            title={!!elemID ? "MATERIALS_EDIT_TITLE" : "MATERIALS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!courseID}
                type="select"
                name="courseID"
                label="GUIDES_COURSE"
                value={data.courseID}
                error={errors.courseID}
                onChange={this.handleChange}
                options={courses}
                withNone
            />
            <InputField
                name="name"
                label="MATERIALS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="matCategoryID"
                label="MAT_CATEGORIES_SINGULAR"
                value={data.matCategoryID}
                error={errors.matCategoryID}
                onChange={this.handleChange}
                options={categories}
                withNone
                isRequired
            />
            <InputField
                type="select"
                name="type"
                label="MATERIALS_TYPE"
                value={data.type}
                error={errors.type}
                onChange={this.handleChange}
                options="MATERIALS_TYPES"
                withNone
                isRequired
            />
            <MediaField
                isHidden={Number(data.type) !== 1}
                name="file"
                label="MATERIALS_FILE"
                mediaPath="materiales"
                value={data.file}
                error={errors.file}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                isHidden={Number(data.type) !== 2}
                name="file"
                label="MATERIALS_VIDEO"
                value={data.file}
                error={errors.file}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                isHidden={Number(data.type) !== 3}
                name="file"
                label="MATERIALS_LINK"
                value={data.file}
                error={errors.file}
                onChange={this.handleChange}
                isRequired
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMaterial       : PropTypes.func.isRequired,
        fetchMaterialCreate : PropTypes.func.isRequired,
        editMaterial        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        position            : PropTypes.number.isRequired,
        courses             : PropTypes.array.isRequired,
        categories          : PropTypes.array.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
        courseID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition    : state.material.edition,
            position   : state.material.position,
            courses    : state.material.courses,
            categories : state.material.categories,
            elem       : state.material.elem,
        };
    }
}

export default connect(MaterialEdit.mapStateToProps, {
    fetchMaterial, fetchMaterialCreate, editMaterial,
})(MaterialEdit);
