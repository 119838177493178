import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchTemplates }   from "Actions/Admin/Setup/EmailTemplateActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import EmailTemplateEdit    from "./EmailTemplateEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";



/**
 * The Email Template List
 */
class EmailTemplateList extends React.Component {
    // The Current State
    state = {
        action   : Action.get(),
        elemCode : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        this.props.fetchTemplates(params || this.props.data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {String} elemCode
     * @returns {Void}
     */
    startAction = (action, elemCode) => {
        this.setState({ action, elemCode });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), "");
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemCode                    } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        return <Main>
            <Header message="EMAIL_TEMPLATES_NAME" icon="email" route={route} />
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="EMAIL_TEMPLATES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="description" message="GENERAL_DESCRIPTION" grow="1.5" isTitle />
                        <TableHeader field="subject"     message="GENERAL_SUBJECT"     grow="1.5" />
                        <TableHeader field="sendTo"      message="GENERAL_EMAIL"       />
                        <TableHeader field="position"    message="GENERAL_POSITION"    maxWidth="80" align="center" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.templateCode} elemID={elem.templateCode}>
                            <TableCell message={elem.description}  />
                            <TableCell message={elem.subject}      />
                            <TableCell message={elem.sendToString} />
                            <TableCell message={elem.position}     />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT" message="EMAIL_TEMPLATES_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <EmailTemplateEdit
                open={action.isVCE}
                elemCode={elemCode}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTemplates : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.emailTemplate,
        };
    }
}

export default connect(EmailTemplateList.mapStateToProps, {
    fetchTemplates,
})(EmailTemplateList);
