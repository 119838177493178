import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Student, Coach }   from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Access               from "dashboard/dist/Core/Access";
import Status               from "dashboard/dist/Core/Status";

// Dialogs
import StudentEdit          from "Components/Admin/User/Student/StudentEdit";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchLeadCreate, createLead,
} from "Actions/Admin/Lead/LeadActions";



/**
 * The Lead Create Dialog
 */
class LeadCreate extends React.Component {
    // The Initial Data
    initialData = {
        access         : Access.getID("STUDENT"),
        credentialID   : 0,
        credentialName : "",
        originID       : 0,
        interestID     : 0,
        description    : "",
        assignedUser   : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        action  : Action.get(),
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, fetchLeadCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchLeadCreate();
            data    = { ...this.initialData };
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.createLead(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Starts an Action
     * @returns {Void}
     */
    startAction = () => {
        this.setState({ action : Action.get("CREATE") });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get() });
    }

    /**
     * Handles the Create Student Submit
     * @param {Object} data
     * @returns {Void}
     */
    createStudent = (data) => {
        this.endAction();
        if (data) {
            this.handleChange("credentialID",   data.credentialID);
            this.handleChange("credentialName", data.credentialName);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, origins, interests, admins } = this.props;
        const { data, loading, errors, action             } = this.state;

        return <>
            <EditDialog
                open={open}
                icon="lead"
                title="LEADS_CREATE_TITLE"
                error={errors.form}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                isLoading={loading}
            >
                <Columns>
                    <InputField
                        type="select"
                        name="access"
                        label="GENERAL_TYPE"
                        value={data.access}
                        error={errors.access}
                        onChange={this.handleChange}
                        options={Access.getSelect("USER")}
                        isRequired
                    />
                    <InputField
                        isHidden={!Access.is("STUDENT", data.access)}
                        name="credentialName"
                        label="STUDENTS_SINGULAR"
                        suggestID="credentialID"
                        suggestFetch={Student.search}
                        suggestNone="STUDENTS_NONE_AVAILABLE"
                        value={data.credentialName}
                        error={errors.credentialID}
                        onChange={this.handleChange}
                        button="GENERAL_CREATE"
                        onClick={this.startAction}
                        isRequired
                    />
                    <InputField
                        isHidden={!Access.is("COACH", data.access)}
                        name="credentialName"
                        label="COACHES_SINGULAR"
                        suggestID="credentialID"
                        suggestFetch={Coach.search}
                        suggestNone="COACHES_NONE_AVAILABLE"
                        value={data.credentialName}
                        error={errors.credentialID}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <InputField
                        type="select"
                        name="originID"
                        label="LEADS_ORIGIN"
                        value={data.originID}
                        error={errors.originID}
                        onChange={this.handleChange}
                        options={origins}
                        withNone
                        isRequired
                    />
                    <InputField
                        type="select"
                        name="interestID"
                        label="LEADS_INTEREST"
                        value={data.interestID}
                        error={errors.interestID}
                        onChange={this.handleChange}
                        options={interests}
                        withNone
                        isRequired
                    />
                </Columns>
                <InputField
                    type="textarea"
                    name="description"
                    label="LEADS_ITS_DESCRIPTION"
                    value={data.description}
                    error={errors.description}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="assignedUser"
                    label="LEADS_ASSIGNED_USER"
                    value={data.assignedUser}
                    error={errors.assignedUser}
                    onChange={this.handleChange}
                    options={admins}
                    extraOptions="LEADS_ASSIGNED_EXTRAS"
                    withNone
                />
            </EditDialog>

            <StudentEdit
                open={action.isCreate}
                status={Status.getID("POTENTIAL")}
                onSubmit={this.createStudent}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLeadCreate : PropTypes.func.isRequired,
        createLead      : PropTypes.func.isRequired,
        open            : PropTypes.bool.isRequired,
        onClose         : PropTypes.func.isRequired,
        onSubmit        : PropTypes.func.isRequired,
        edition         : PropTypes.number.isRequired,
        origins         : PropTypes.array.isRequired,
        interests       : PropTypes.array.isRequired,
        admins          : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.lead.edition,
            origins   : state.lead.origins,
            interests : state.lead.interests,
            admins    : state.lead.admins,
        };
    }
}

export default connect(LeadCreate.mapStateToProps, {
    fetchLeadCreate, createLead,
})(LeadCreate);
