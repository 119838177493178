import Ajax                         from "dashboard/dist/Core/Ajax";



// Auth Actions
export const Auth = {
    "getAssignData"       : (data) => Ajax.get("/auth/getAssignData",                           data),
    "validateEmail"       : (data) => Ajax.post("/auth/validateEmail",                          data),
    "validatePassword"    : (data) => Ajax.post("/auth/validatePassword",                       data, true, false),
    "requestReset"        : (data) => Ajax.post("/auth/requestReset",                           data),
    "resetPassword"       : (data) => Ajax.post("/auth/resetPassword",                          data),
    "saveData"            : (data) => Ajax.post("/auth/saveData",                               data),
    "saveCourses"         : (data) => Ajax.post("/auth/saveCourses",                            data),
    "saveAssign"          : (data) => Ajax.post("/auth/saveAssign",                             data),
    "loginAs"             : (data) => Ajax.post("/auth/loginAs",                                data),
    "logout"              : (data) => Ajax.post("/auth/logout",                                 data, true, false),
    "logoutAs"            : (data) => Ajax.post("/auth/logoutAs",                               data),
};
export const Profile = {
    "get"                 : (data) => Ajax.get("/profile/get",                                  data),
    "edit"                : (data) => Ajax.post("/profile/edit",                                data),
    "upload"              : (data) => Ajax.post("/profile/upload",                              data),
};



// Core Actions
export const Search = {
    "search"              : (data) => Ajax.get("/search/search",                                data),
    "searchUser"          : (data) => Ajax.get("/search/searchUser",                            data),
};
export const Dashboard = {
    "getAll"              : (data) => Ajax.get("/dashboard/getAll",                             data),
};
export const Media = {
    "getAll"              : (data) => Ajax.get("/media/getAll",                                 data),
    "create"              : (data) => Ajax.post("/media/create",                                data),
    "upload"              : (data) => Ajax.post("/media/upload",                                data),
    "rename"              : (data) => Ajax.post("/media/rename",                                data),
    "move"                : (data) => Ajax.post("/media/move",                                  data),
    "delete"              : (data) => Ajax.post("/media/delete",                                data),
};



// Course Actions
export const Course = {
    "getAll"              : (data) => Ajax.get("/course/getAll",                                data),
    "getOne"              : (data) => Ajax.get("/course/getOne",                                data),
    "getCreateData"       : (data) => Ajax.get("/course/getCreateData",                         data),
    "search"              : (data) => Ajax.get("/course/search",                                data),
    "export"              : (data) => Ajax.route("/course/export",                              data),
    "edit"                : (data) => Ajax.post("/course/edit",                                 data),
    "delete"              : (data) => Ajax.post("/course/delete",                               data),
};
export const CourseCompleted = {
    "getAllForCredential" : (data) => Ajax.get("/course/completed/getAllForCredential",         data),
    "edit"                : (data) => Ajax.post("/course/completed/edit",                       data),
};



// Program Actions
export const Program = {
    "getAll"              : (data) => Ajax.get("/program/getAll",                               data),
    "getAllForCourse"     : (data) => Ajax.get("/program/getAllForCourse",                      data),
    "getOne"              : (data) => Ajax.get("/program/getOne",                               data),
    "getCreateData"       : (data) => Ajax.get("/program/getCreateData",                        data),
    "search"              : (data) => Ajax.get("/program/search",                               data),
    "export"              : (data) => Ajax.route("/program/export",                             data),
    "edit"                : (data) => Ajax.post("/program/edit",                                data),
    "delete"              : (data) => Ajax.post("/program/delete",                              data),
};
export const ProgramPrice = {
    "getAllForProgram"    : (data) => Ajax.get("/program/price/getAllForProgram",               data),
    "getOne"              : (data) => Ajax.get("/program/price/getOne",                         data),
    "edit"                : (data) => Ajax.post("/program/price/edit",                          data),
    "delete"              : (data) => Ajax.post("/program/price/delete",                        data),
};
export const ProgramChat = {
    "getAllForProgram"    : (data) => Ajax.get("/program/chat/getAllForProgram",                data),
    "getOne"              : (data) => Ajax.get("/program/chat/getOne",                          data),
    "getCreateData"       : (data) => Ajax.get("/program/chat/getCreateData",                   data),
    "edit"                : (data) => Ajax.post("/program/chat/edit",                           data),
    "delete"              : (data) => Ajax.post("/program/chat/delete",                         data),
};
export const ProgramClass = {
    "getAllForProgram"    : (data) => Ajax.get("/program/class/getAllForProgram",               data),
    "getOne"              : (data) => Ajax.get("/program/class/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/program/class/getCreateData",                  data),
    "edit"                : (data) => Ajax.post("/program/class/edit",                          data),
    "delete"              : (data) => Ajax.post("/program/class/delete",                        data),
};
export const ProgramMaterial = {
    "getAllForProgram"    : (data) => Ajax.get("/program/material/getAllForProgram",            data),
    "getOne"              : (data) => Ajax.get("/program/material/getOne",                      data),
    "getCreateData"       : (data) => Ajax.get("/program/material/getCreateData",               data),
    "edit"                : (data) => Ajax.post("/program/material/edit",                       data),
    "delete"              : (data) => Ajax.post("/program/material/delete",                     data),
};
export const ProgramGuide = {
    "getAllForProgram"    : (data) => Ajax.get("/program/guide/getAllForProgram",               data),
    "getOne"              : (data) => Ajax.get("/program/guide/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/program/guide/getCreateData",                  data),
    "edit"                : (data) => Ajax.post("/program/guide/edit",                          data),
    "delete"              : (data) => Ajax.post("/program/guide/delete",                        data),
};
export const ProgramSpeciality = {
    "getAllForProgram"    : (data) => Ajax.get("/program/speciality/getAllForProgram",          data),
    "getOne"              : (data) => Ajax.get("/program/speciality/getOne",                    data),
    "getCreateData"       : (data) => Ajax.get("/program/speciality/getCreateData",             data),
    "edit"                : (data) => Ajax.post("/program/speciality/edit",                     data),
    "delete"              : (data) => Ajax.post("/program/speciality/delete",                   data),
};
export const ProgramQuestion = {
    "getAllForProgram"    : (data) => Ajax.get("/program/question/getAllForProgram",            data),
    "getOne"              : (data) => Ajax.get("/program/question/getOne",                      data),
    "getCreateData"       : (data) => Ajax.get("/program/question/getCreateData",               data),
    "edit"                : (data) => Ajax.post("/program/question/edit",                       data),
    "delete"              : (data) => Ajax.post("/program/question/delete",                     data),
};



// Strech Actions
export const Strech = {
    "getAll"              : (data) => Ajax.get("/strech/getAll",                                data),
    "getAllForCourse"     : (data) => Ajax.get("/strech/getAllForCourse",                       data),
    "getAllForProgram"    : (data) => Ajax.get("/strech/getAllForProgram",                      data),
    "getAllForLocation"   : (data) => Ajax.get("/strech/getAllForLocation",                     data),
    "getAllForStudent"    : (data) => Ajax.get("/strech/getAllForStudent",                      data),
    "getAllForCoach"      : (data) => Ajax.get("/strech/getAllForCoach",                        data),
    "getAllAsCoach"       : (data) => Ajax.get("/strech/getAllAsCoach",                         data),
    "getAllAsStudent"     : (data) => Ajax.get("/strech/getAllAsStudent",                       data),
    "getOne"              : (data) => Ajax.get("/strech/getOne",                                data),
    "getWelcome"          : (data) => Ajax.get("/strech/getWelcome",                            data),
    "getCertificate"      : (data) => Ajax.route("/strech/getCertificate",                      data),
    "getEditData"         : (data) => Ajax.get("/strech/getEditData",                           data),
    "getCreateData"       : (data) => Ajax.get("/strech/getCreateData",                         data),
    "search"              : (data) => Ajax.get("/strech/search",                                data),
    "export"              : (data) => Ajax.route("/strech/export",                              data),
    "edit"                : (data) => Ajax.post("/strech/edit",                                 data),
    "activate"            : (data) => Ajax.post("/strech/activate",                             data),
    "cancel"              : (data) => Ajax.post("/strech/cancel",                               data),
    "delete"              : (data) => Ajax.post("/strech/delete",                               data),
};
export const StrechPrice = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/price/getAllForStrech",                 data),
    "getOne"              : (data) => Ajax.get("/strech/price/getOne",                          data),
    "getCreateData"       : (data) => Ajax.get("/strech/price/getCreateData",                   data),
    "edit"                : (data) => Ajax.post("/strech/price/edit",                           data),
    "delete"              : (data) => Ajax.post("/strech/price/delete",                         data),
    "import"              : (data) => Ajax.post("/strech/price/import",                         data),
};
export const StrechSchedule = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/schedule/getAllForStrech",              data),
    "getOne"              : (data) => Ajax.get("/strech/schedule/getOne",                       data),
    "edit"                : (data) => Ajax.post("/strech/schedule/edit",                        data),
    "delete"              : (data) => Ajax.post("/strech/schedule/delete",                      data),
};
export const StrechCoach = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/coach/getAllForStrech",                 data),
    "getEmails"           : (data) => Ajax.get("/strech/coach/getEmails",                       data),
    "getOne"              : (data) => Ajax.get("/strech/coach/getOne",                          data),
    "getCreateData"       : (data) => Ajax.get("/strech/coach/getCreateData",                   data),
    "edit"                : (data) => Ajax.post("/strech/coach/edit",                           data),
    "delete"              : (data) => Ajax.post("/strech/coach/delete",                         data),
};
export const StrechChat = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/chat/getAllForStrech",                  data),
    "getOne"              : (data) => Ajax.get("/strech/chat/getOne",                           data),
    "getEditData"         : (data) => Ajax.get("/strech/chat/getEditData",                      data),
    "getCreateData"       : (data) => Ajax.get("/strech/chat/getCreateData",                    data),
    "edit"                : (data) => Ajax.post("/strech/chat/edit",                            data),
    "delete"              : (data) => Ajax.post("/strech/chat/delete",                          data),
    "import"              : (data) => Ajax.post("/strech/chat/import",                          data),
};
export const StrechClass = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/class/getAllForStrech",                 data),
    "getOne"              : (data) => Ajax.get("/strech/class/getOne",                          data),
    "getEditData"         : (data) => Ajax.get("/strech/class/getEditData",                     data),
    "getCreateData"       : (data) => Ajax.get("/strech/class/getCreateData",                   data),
    "edit"                : (data) => Ajax.post("/strech/class/edit",                           data),
    "editDate"            : (data) => Ajax.post("/strech/class/editDate",                       data),
    "editFeedback"        : (data) => Ajax.post("/strech/class/editFeedback",                   data),
    "delete"              : (data) => Ajax.post("/strech/class/delete",                         data),
    "import"              : (data) => Ajax.post("/strech/class/import",                         data),
};
export const StrechMaterial = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/material/getAllForStrech",              data),
    "getOne"              : (data) => Ajax.get("/strech/material/getOne",                       data),
    "getEditData"         : (data) => Ajax.get("/strech/material/getEditData",                  data),
    "getCreateData"       : (data) => Ajax.get("/strech/material/getCreateData",                data),
    "edit"                : (data) => Ajax.post("/strech/material/edit",                        data),
    "delete"              : (data) => Ajax.post("/strech/material/delete",                      data),
    "import"              : (data) => Ajax.post("/strech/material/import",                      data),
};
export const StrechGuide = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/guide/getAllForStrech",                 data),
    "getOne"              : (data) => Ajax.get("/strech/guide/getOne",                          data),
    "getEditData"         : (data) => Ajax.get("/strech/guide/getEditData",                     data),
    "getCreateData"       : (data) => Ajax.get("/strech/guide/getCreateData",                   data),
    "getStudentData"      : (data) => Ajax.get("/strech/guide/getStudentData",                  data),
    "edit"                : (data) => Ajax.post("/strech/guide/edit",                           data),
    "delete"              : (data) => Ajax.post("/strech/guide/delete",                         data),
    "import"              : (data) => Ajax.post("/strech/guide/import",                         data),
    "saveAnswer"          : (data) => Ajax.post("/strech/guide/saveAnswer",                     data),
    "sendAnswers"         : (data) => Ajax.post("/strech/guide/sendAnswers",                    data),
    "saveFeedback"        : (data) => Ajax.post("/strech/guide/saveFeedback",                   data),
    "sendFeedback"        : (data) => Ajax.post("/strech/guide/sendFeedback",                   data),
};
export const StrechSpeciality = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/speciality/getAllForStrech",            data),
    "getOne"              : (data) => Ajax.get("/strech/speciality/getOne",                     data),
    "getCreateData"       : (data) => Ajax.get("/strech/speciality/getCreateData",              data),
    "edit"                : (data) => Ajax.post("/strech/speciality/edit",                      data),
    "delete"              : (data) => Ajax.post("/strech/speciality/delete",                    data),
    "import"              : (data) => Ajax.post("/strech/speciality/import",                    data),
};
export const StrechQuestion = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/question/getAllForStrech",              data),
    "getOne"              : (data) => Ajax.get("/strech/question/getOne",                       data),
    "getCreateData"       : (data) => Ajax.get("/strech/question/getCreateData",                data),
    "edit"                : (data) => Ajax.post("/strech/question/edit",                        data),
    "delete"              : (data) => Ajax.post("/strech/question/delete",                      data),
    "import"              : (data) => Ajax.post("/strech/question/import",                      data),
};
export const StrechAnswer = {
    "getStudents"         : (data) => Ajax.get("/strech/answer/getStudents",                    data),
    "getAnswers"          : (data) => Ajax.get("/strech/answer/getAnswers",                     data),
    "getQuestions"        : (data) => Ajax.get("/strech/answer/getQuestions",                   data),
    "export"              : (data) => Ajax.route("/strech/answer/export",                       data),
    "answer"              : (data) => Ajax.post("/strech/answer/answer",                        data),
};
export const Inscription = {
    "getAllRequests"      : (data) => Ajax.get("/strech/inscription/getAllRequests",            data),
    "getAllForStrech"     : (data) => Ajax.get("/strech/inscription/getAllForStrech",           data),
    "getEmails"           : (data) => Ajax.get("/strech/inscription/getEmails",                 data),
    "getOne"              : (data) => Ajax.get("/strech/inscription/getOne",                    data),
    "getCreateData"       : (data) => Ajax.get("/strech/inscription/getCreateData",             data),
    "edit"                : (data) => Ajax.post("/strech/inscription/edit",                     data),
    "cancel"              : (data) => Ajax.post("/strech/inscription/cancel",                   data),
};
export const InscriptionChat = {
    "getAllForChat"       : (data) => Ajax.get("/strech/inscription/chat/getAllForChat",        data),
    "create"              : (data) => Ajax.post("/strech/inscription/chat/create",              data),
    "togglePresentism"    : (data) => Ajax.post("/strech/inscription/chat/togglePresentism",    data),
};
export const InscriptionReason = {
    "getAll"              : (data) => Ajax.get("/strech/inscription/reason/getAll",             data),
    "getOne"              : (data) => Ajax.get("/strech/inscription/reason/getOne",             data),
    "getCreateData"       : (data) => Ajax.get("/strech/inscription/reason/getCreateData",      data),
    "edit"                : (data) => Ajax.post("/strech/inscription/reason/edit",              data),
    "delete"              : (data) => Ajax.post("/strech/inscription/reason/delete",            data),
};
export const StrechAssignment = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/assignment/getAllForStrech",            data),
    "getOne"              : (data) => Ajax.get("/strech/assignment/getOne",                     data),
    "edit"                : (data) => Ajax.post("/strech/assignment/edit",                      data),
};
export const StrechPresentism = {
    "getAllForStrech"     : (data) => Ajax.get("/strech/presentism/getAllForStrech",            data),
    "getOne"              : (data) => Ajax.get("/strech/presentism/getOne",                     data),
    "toggle"              : (data) => Ajax.post("/strech/presentism/toggle",                    data),
};
export const StrechGroup = {
    "getAll"              : (data) => Ajax.get("/strech/group/getAll",                          data),
    "getAllForStrech"     : (data) => Ajax.get("/strech/group/getAllForStrech",                 data),
    "getAllForCoach"      : (data) => Ajax.get("/strech/group/getAllForCoach",                  data),
    "getAllAsCoach"       : (data) => Ajax.get("/strech/group/getAllAsCoach",                   data),
    "getOne"              : (data) => Ajax.get("/strech/group/getOne",                          data),
    "getCreateData"       : (data) => Ajax.get("/strech/group/getCreateData",                   data),
    "getChangeData"       : (data) => Ajax.get("/strech/group/getChangeData",                   data),
    "getStudents"         : (data) => Ajax.get("/strech/group/getStudents",                     data),
    "getMembers"          : (data) => Ajax.get("/strech/group/getMembers",                      data),
    "edit"                : (data) => Ajax.post("/strech/group/edit",                           data),
    "assign"              : (data) => Ajax.post("/strech/group/assign",                         data),
    "change"              : (data) => Ajax.post("/strech/group/change",                         data),
    "delete"              : (data) => Ajax.post("/strech/group/delete",                         data),
};
export const Meeting = {
    "getAll"              : (data) => Ajax.get("/strech/meeting/getAll",                        data),
    "getAllForGroup"      : (data) => Ajax.get("/strech/meeting/getAllForGroup",                data),
    "getAllForCoach"      : (data) => Ajax.get("/strech/meeting/getAllForCoach",                data),
    "getAllForLocation"   : (data) => Ajax.get("/strech/meeting/getAllForLocation",             data),
    "getAllAsCoach"       : (data) => Ajax.get("/strech/meeting/getAllAsCoach",                 data),
    "getAllAsStudent"     : (data) => Ajax.get("/strech/meeting/getAllAsStudent",               data),
    "getOne"              : (data) => Ajax.get("/strech/meeting/getOne",                        data),
    "getEditData"         : (data) => Ajax.get("/strech/meeting/getEditData",                   data),
    "getCreateData"       : (data) => Ajax.get("/strech/meeting/getCreateData",                 data),
    "edit"                : (data) => Ajax.post("/strech/meeting/edit",                         data),
    "report"              : (data) => Ajax.post("/strech/meeting/report",                       data),
    "cancel"              : (data) => Ajax.post("/strech/meeting/cancel",                       data),
    "delete"              : (data) => Ajax.post("/strech/meeting/delete",                       data),
};



// Conversations Actions
export const Conversation = {
    "getAll"              : (data) => Ajax.get("/conversation/getAll",                          data),
    "getAllForStudent"    : (data) => Ajax.get("/conversation/getAllForStudent",                data),
    "getAllForCoach"      : (data) => Ajax.get("/conversation/getAllForCoach",                  data),
    "getAllForStrech"     : (data) => Ajax.get("/conversation/getAllForStrech",                 data),
    "getAllForLocation"   : (data) => Ajax.get("/conversation/getAllForLocation",               data),
    "getAllAsCoach"       : (data) => Ajax.get("/conversation/getAllAsCoach",                   data),
    "getAllAsStudent"     : (data) => Ajax.get("/conversation/getAllAsStudent",                 data),
    "getOne"              : (data) => Ajax.get("/conversation/getOne",                          data),
    "getEditData"         : (data) => Ajax.get("/conversation/getEditData",                     data),
    "getCreateData"       : (data) => Ajax.get("/conversation/getCreateData",                   data),
    "getStudentsData"     : (data) => Ajax.get("/conversation/getStudentsData",                 data),
    "getRequestData"      : (data) => Ajax.get("/conversation/getRequestData",                  data),
    "getAssignData"       : (data) => Ajax.get("/conversation/getAssignData",                   data),
    "assign"              : (data) => Ajax.post("/conversation/assign",                         data),
    "request"             : (data) => Ajax.post("/conversation/request",                        data),
    "unrequest"           : (data) => Ajax.post("/conversation/unrequest",                      data),
    "create"              : (data) => Ajax.post("/conversation/create",                         data),
    "program"             : (data) => Ajax.post("/conversation/program",                        data),
    "edit"                : (data) => Ajax.post("/conversation/edit",                           data),
    "report"              : (data) => Ajax.post("/conversation/report",                         data),
    "cancel"              : (data) => Ajax.post("/conversation/cancel",                         data),
    "delete"              : (data) => Ajax.post("/conversation/delete",                         data),
};



// Campaign Actions
export const Campaign = {
    "getAll"              : (data) => Ajax.get("/campaign/getAll",                              data),
    "getOne"              : (data) => Ajax.get("/campaign/getOne",                              data),
    "getSendData"         : (data) => Ajax.get("/campaign/getSendData",                         data),
    "getContent"          : (data) => Ajax.get("/campaign/getContent",                          data),
    "getUsers"            : (data) => Ajax.get("/campaign/getUsers",                            data),
    "send"                : (data) => Ajax.post("/campaign/send",                               data),
    "pause"               : (data) => Ajax.post("/campaign/pause",                              data),
    "delete"              : (data) => Ajax.post("/campaign/delete",                             data),
};



// Report Actions
export const InscriptionReport = {
    "getData"             : (data) => Ajax.get("/report/inscription/getData",                   data),
    "getGraph"            : (data) => Ajax.get("/report/inscription/getGraph",                  data),
    "getEmails"           : (data) => Ajax.get("/report/inscription/getEmails",                 data),
    "export"              : (data) => Ajax.route("/report/inscription/export",                  data),
    "download"            : (data) => Ajax.route("/report/inscription/download",                data),
};
export const ConversationReport = {
    "getData"             : (data) => Ajax.get("/report/conversation/getData",                  data),
    "getGraph"            : (data) => Ajax.get("/report/conversation/getGraph",                 data),
    "getEmails"           : (data) => Ajax.get("/report/conversation/getEmails",                data),
    "export"              : (data) => Ajax.route("/report/conversation/export",                 data),
    "download"            : (data) => Ajax.route("/report/conversation/download",               data),
};
export const MeetingReport = {
    "getData"             : (data) => Ajax.get("/report/meeting/getData",                       data),
    "getGraph"            : (data) => Ajax.get("/report/meeting/getGraph",                      data),
    "getEmails"           : (data) => Ajax.get("/report/meeting/getEmails",                     data),
    "export"              : (data) => Ajax.route("/report/meeting/export",                      data),
    "download"            : (data) => Ajax.route("/report/meeting/download",                    data),
};
export const GuideReport = {
    "getData"             : (data) => Ajax.get("/report/guide/getData",                         data),
    "getGraph"            : (data) => Ajax.get("/report/guide/getGraph",                        data),
    "getEmails"           : (data) => Ajax.get("/report/guide/getEmails",                       data),
    "export"              : (data) => Ajax.route("/report/guide/export",                        data),
    "download"            : (data) => Ajax.route("/report/guide/download",                      data),
};
export const PollReport = {
    "getData"             : (data) => Ajax.get("/report/poll/getData",                          data),
    "getGraph"            : (data) => Ajax.get("/report/poll/getGraph",                         data),
    "getEmails"           : (data) => Ajax.get("/report/poll/getEmails",                        data),
    "export"              : (data) => Ajax.route("/report/poll/export",                         data),
    "download"            : (data) => Ajax.route("/report/poll/download",                       data),
};
export const InvoiceReport = {
    "getData"             : (data) => Ajax.get("/report/invoice/getData",                       data),
    "getGraph"            : (data) => Ajax.get("/report/invoice/getGraph",                      data),
    "getEmails"           : (data) => Ajax.get("/report/invoice/getEmails",                     data),
    "export"              : (data) => Ajax.route("/report/invoice/export",                      data),
    "download"            : (data) => Ajax.route("/report/invoice/download",                    data),
};



// Ticket Actions
export const Ticket = {
    "getAll"              : (data) => Ajax.get("/ticket/getAll",                                data),
    "getAllForStudent"    : (data) => Ajax.get("/ticket/getAllForStudent",                      data),
    "getAllForCoach"      : (data) => Ajax.get("/ticket/getAllForCoach",                        data),
    "getAllAsUser"        : (data) => Ajax.get("/ticket/getAllAsUser",                          data),
    "getOne"              : (data) => Ajax.get("/ticket/getOne",                                data),
    "getCreateData"       : (data) => Ajax.get("/ticket/getCreateData",                         data),
    "getAssignData"       : (data) => Ajax.get("/ticket/getAssignData",                         data),
    "create"              : (data) => Ajax.post("/ticket/create",                               data),
    "edit"                : (data) => Ajax.post("/ticket/edit",                                 data),
    "delete"              : (data) => Ajax.post("/ticket/delete",                               data),
    "assign"              : (data) => Ajax.post("/ticket/assign",                               data),
    "open"                : (data) => Ajax.post("/ticket/open",                                 data),
    "close"               : (data) => Ajax.post("/ticket/close",                                data),
    "mute"                : (data) => Ajax.post("/ticket/mute",                                 data),
    "unmute"              : (data) => Ajax.post("/ticket/unmute",                               data),
};
export const TicketMessage = {
    "add"                 : (data) => Ajax.post("/ticket/message/add",                          data),
    "edit"                : (data) => Ajax.post("/ticket/message/edit",                         data),
    "delete"              : (data) => Ajax.post("/ticket/message/delete",                       data),
};
export const TicketType = {
    "getAll"              : (data) => Ajax.get("/ticket/type/getAll",                           data),
    "getOne"              : (data) => Ajax.get("/ticket/type/getOne",                           data),
    "getCreateData"       : (data) => Ajax.get("/ticket/type/getCreateData",                    data),
    "edit"                : (data) => Ajax.post("/ticket/type/edit",                            data),
    "delete"              : (data) => Ajax.post("/ticket/type/delete",                          data),
};
export const HelpSection = {
    "getAll"              : (data) => Ajax.get("/ticket/section/getAll",                        data),
    "getOne"              : (data) => Ajax.get("/ticket/section/getOne",                        data),
    "getCreateData"       : (data) => Ajax.get("/ticket/section/getCreateData",                 data),
    "edit"                : (data) => Ajax.post("/ticket/section/edit",                         data),
    "delete"              : (data) => Ajax.post("/ticket/section/delete",                       data),
};
export const HelpQuestion = {
    "getAll"              : (data) => Ajax.get("/ticket/question/getAll",                       data),
    "getOne"              : (data) => Ajax.get("/ticket/question/getOne",                       data),
    "getCreateData"       : (data) => Ajax.get("/ticket/question/getCreateData",                data),
    "getHelp"             : (data) => Ajax.get("/ticket/question/getHelp",                      data),
    "edit"                : (data) => Ajax.post("/ticket/question/edit",                        data),
    "delete"              : (data) => Ajax.post("/ticket/question/delete",                      data),
};



// Lead Actions
export const Lead = {
    "getAll"              : (data) => Ajax.get("/lead/getAll",                                  data),
    "getAllForStudent"    : (data) => Ajax.get("/lead/getAllForStudent",                        data),
    "getFilterData"       : (data) => Ajax.get("/lead/getFilterData",                           data),
    "searchContact"       : (data) => Ajax.get("/lead/searchContact",                           data),
    "getEmails"           : (data) => Ajax.get("/lead/getEmails",                               data),
    "export"              : (data) => Ajax.route("/lead/export",                                data),
    "getOne"              : (data) => Ajax.get("/lead/getOne",                                  data),
    "getCreateData"       : (data) => Ajax.get("/lead/getCreateData",                           data),
    "getEditData"         : (data) => Ajax.get("/lead/getEditData",                             data),
    "getCloseData"        : (data) => Ajax.get("/lead/getCloseData",                            data),
    "create"              : (data) => Ajax.post("/lead/create",                                 data),
    "edit"                : (data) => Ajax.post("/lead/edit",                                   data),
    "sendMessage"         : (data) => Ajax.post("/lead/sendMessage",                            data),
    "assign"              : (data) => Ajax.post("/lead/assign",                                 data),
    "close"               : (data) => Ajax.post("/lead/close",                                  data),
    "open"                : (data) => Ajax.post("/lead/open",                                   data),
    "delete"              : (data) => Ajax.post("/lead/delete",                                 data),
};
export const Origin = {
    "getAll"              : (data) => Ajax.get("/lead/origin/getAll",                           data),
    "getOne"              : (data) => Ajax.get("/lead/origin/getOne",                           data),
    "getCreateData"       : (data) => Ajax.get("/lead/origin/getCreateData",                    data),
    "edit"                : (data) => Ajax.post("/lead/origin/edit",                            data),
    "delete"              : (data) => Ajax.post("/lead/origin/delete",                          data),
};
export const Assignment = {
    "getAll"              : (data) => Ajax.get("/lead/assignment/getAll",                       data),
    "getOne"              : (data) => Ajax.get("/lead/assignment/getOne",                       data),
    "getCreateData"       : (data) => Ajax.get("/lead/assignment/getCreateData",                data),
    "edit"                : (data) => Ajax.post("/lead/assignment/edit",                        data),
    "delete"              : (data) => Ajax.post("/lead/assignment/delete",                      data),
};
export const CloseReason = {
    "getAll"              : (data) => Ajax.get("/lead/reason/getAll",                           data),
    "getOne"              : (data) => Ajax.get("/lead/reason/getOne",                           data),
    "getCreateData"       : (data) => Ajax.get("/lead/reason/getCreateData",                    data),
    "edit"                : (data) => Ajax.post("/lead/reason/edit",                            data),
    "delete"              : (data) => Ajax.post("/lead/reason/delete",                          data),
};



// Student Actions
export const Student = {
    "getAll"              : (data) => Ajax.get("/student/getAll",                               data),
    "getAllSignups"       : (data) => Ajax.get("/student/getAllSignups",                        data),
    "getAllForCoach"      : (data) => Ajax.get("/student/getAllForCoach",                       data),
    "getAllAsCoach"       : (data) => Ajax.get("/student/getAllAsCoach",                        data),
    "getFilterData"       : (data) => Ajax.get("/student/getFilterData",                        data),
    "getEmails"           : (data) => Ajax.get("/student/getEmails",                            data),
    "getOne"              : (data) => Ajax.get("/student/getOne",                               data),
    "getOneAsCoach"       : (data) => Ajax.get("/student/getOneAsCoach",                        data),
    "getCreateData"       : (data) => Ajax.get("/student/getCreateData",                        data),
    "search"              : (data) => Ajax.post("/student/search",                              data),
    "export"              : (data) => Ajax.route("/student/export",                             data),
    "edit"                : (data) => Ajax.post("/student/edit",                                data),
    "editLevel"           : (data) => Ajax.post("/student/editLevel",                           data),
    "delete"              : (data) => Ajax.post("/student/delete",                              data),
};
export const Home = {
    "getHome"             : (data) => Ajax.get("/student/dashboard/getHome",                    data),
    "getWelcome"          : (data) => Ajax.get("/student/dashboard/getWelcome",                 data),
    "getCalendar"         : (data) => Ajax.get("/student/dashboard/getCalendar",                data),
};
export const Enroll = {
    "getData"             : (data) => Ajax.get("/student/enroll/getData",                       data),
    "validateOptions"     : (data) => Ajax.post("/student/enroll/validateOptions",              data),
    "enrollToChat"        : (data) => Ajax.post("/student/enroll/enrollToChat",                 data),
    "enrollToStrech"      : (data) => Ajax.post("/student/enroll/enrollToStrech",               data, true, false),
    "cancel"              : (data) => Ajax.post("/student/enroll/cancel",                       data),
};



// Coach Actions
export const Coach = {
    "getAll"              : (data) => Ajax.get("/coach/getAll",                                 data),
    "getFilterData"       : (data) => Ajax.get("/coach/getFilterData",                          data),
    "getEmails"           : (data) => Ajax.get("/coach/getEmails",                              data),
    "getOne"              : (data) => Ajax.get("/coach/getOne",                                 data),
    "getCreateData"       : (data) => Ajax.get("/coach/getCreateData",                          data),
    "search"              : (data) => Ajax.post("/coach/search",                                data),
    "export"              : (data) => Ajax.route("/coach/export",                               data),
    "edit"                : (data) => Ajax.post("/coach/edit",                                  data),
    "editLevel"           : (data) => Ajax.post("/coach/editLevel",                             data),
    "delete"              : (data) => Ajax.post("/coach/delete",                                data),
};
export const CoachPrices = {
    "getAllForCredential" : (data) => Ajax.get("/coach/prices/getAllForCredential",             data),
    "edit"                : (data) => Ajax.post("/coach/prices/edit",                           data),
};
export const CoachSpeciality = {
    "getAll"              : (data) => Ajax.get("/coach/speciality/getAll",                      data),
    "getOne"              : (data) => Ajax.get("/coach/speciality/getOne",                      data),
    "getCreateData"       : (data) => Ajax.get("/coach/speciality/getCreateData",               data),
    "edit"                : (data) => Ajax.post("/coach/speciality/edit",                       data),
    "delete"              : (data) => Ajax.post("/coach/speciality/delete",                     data),
};
export const CoachCategory = {
    "getAll"              : (data) => Ajax.get("/coach/category/getAll",                        data),
    "getOne"              : (data) => Ajax.get("/coach/category/getOne",                        data),
    "edit"                : (data) => Ajax.post("/coach/category/edit",                         data),
    "delete"              : (data) => Ajax.post("/coach/category/delete",                       data),
};
export const CoachQuestion = {
    "getAll"              : (data) => Ajax.get("/coach/question/getAll",                        data),
    "getOne"              : (data) => Ajax.get("/coach/question/getOne",                        data),
    "getCreateData"       : (data) => Ajax.get("/coach/question/getCreateData",                 data),
    "edit"                : (data) => Ajax.post("/coach/question/edit",                         data),
    "delete"              : (data) => Ajax.post("/coach/question/delete",                       data),
};
export const CoachInvoice = {
    "getAll"              : (data) => Ajax.get("/coach/invoice/getAll",                         data),
    "getAllForStrech"     : (data) => Ajax.get("/coach/invoice/getAllForStrech",                data),
    "getAllForCoach"      : (data) => Ajax.get("/coach/invoice/getAllForCoach",                 data),
    "getAllAsCoach"       : (data) => Ajax.get("/coach/invoice/getAllAsCoach",                  data),
    "getOne"              : (data) => Ajax.get("/coach/invoice/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/coach/invoice/getCreateData",                  data),
    "export"              : (data) => Ajax.route("/coach/invoice/export",                       data),
    "create"              : (data) => Ajax.post("/coach/invoice/create",                        data),
    "send"                : (data) => Ajax.post("/coach/invoice/send",                          data),
    "attach"              : (data) => Ajax.post("/coach/invoice/attach",                        data),
    "markApproved"        : (data) => Ajax.post("/coach/invoice/markApproved",                  data),
    "markRejected"        : (data) => Ajax.post("/coach/invoice/markRejected",                  data),
    "markPaid"            : (data) => Ajax.post("/coach/invoice/markPaid",                      data),
};
export const CoachMaterial = {
    "getAll"              : (data) => Ajax.get("/coach/material/getAll",                        data),
    "getAllAsCoach"       : (data) => Ajax.get("/coach/material/getAllAsCoach",                 data),
    "getOne"              : (data) => Ajax.get("/coach/material/getOne",                        data),
    "getCreateData"       : (data) => Ajax.get("/coach/material/getCreateData",                 data),
    "edit"                : (data) => Ajax.post("/coach/material/edit",                         data),
    "delete"              : (data) => Ajax.post("/coach/material/delete",                       data),
};
export const CoachProfesion = {
    "getAll"              : (data) => Ajax.get("/coach/profesion/getAll",                        data),
    "getOne"              : (data) => Ajax.get("/coach/profesion/getOne",                        data),
    "edit"                : (data) => Ajax.post("/coach/profesion/edit",                         data),
    "delete"              : (data) => Ajax.post("/coach/profesion/delete",                       data),
};



// Materials Actions
export const Material = {
    "getAll"              : (data) => Ajax.get("/material/getAll",                              data),
    "getAllForCourse"     : (data) => Ajax.get("/material/getAllForCourse",                     data),
    "getOne"              : (data) => Ajax.get("/material/getOne",                              data),
    "getCreateData"       : (data) => Ajax.get("/material/getCreateData",                       data),
    "edit"                : (data) => Ajax.post("/material/edit",                               data),
    "delete"              : (data) => Ajax.post("/material/delete",                             data),
};
export const MatCategory = {
    "getAll"              : (data) => Ajax.get("/material/category/getAll",                     data),
    "getOne"              : (data) => Ajax.get("/material/category/getOne",                     data),
    "getCreateData"       : (data) => Ajax.get("/material/category/getCreateData",              data),
    "edit"                : (data) => Ajax.post("/material/category/edit",                      data),
    "delete"              : (data) => Ajax.post("/material/category/delete",                    data),
};



// Guides Actions
export const Guide = {
    "getAll"              : (data) => Ajax.get("/guide/getAll",                                 data),
    "getAllForCourse"     : (data) => Ajax.get("/guide/getAllForCourse",                        data),
    "getOne"              : (data) => Ajax.get("/guide/getOne",                                 data),
    "getCreateData"       : (data) => Ajax.get("/guide/getCreateData",                          data),
    "edit"                : (data) => Ajax.post("/guide/edit",                                  data),
    "delete"              : (data) => Ajax.post("/guide/delete",                                data),
};
export const GuideSection = {
    "getAllForGuide"      : (data) => Ajax.get("/guide/section/getAllForGuide",                 data),
    "getOne"              : (data) => Ajax.get("/guide/section/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/guide/section/getCreateData",                  data),
    "edit"                : (data) => Ajax.post("/guide/section/edit",                          data),
    "delete"              : (data) => Ajax.post("/guide/section/delete",                        data),
};
export const GuideQuestion = {
    "getAllForGuide"      : (data) => Ajax.get("/guide/question/getAllForGuide",                data),
    "getOne"              : (data) => Ajax.get("/guide/question/getOne",                        data),
    "getCreateData"       : (data) => Ajax.get("/guide/question/getCreateData",                 data),
    "edit"                : (data) => Ajax.post("/guide/question/edit",                         data),
    "delete"              : (data) => Ajax.post("/guide/question/delete",                       data),
};



// Locations Actions
export const Location = {
    "getAll"              : (data) => Ajax.get("/location/getAll",                              data),
    "getCreateData"       : (data) => Ajax.get("/location/getCreateData",                       data),
    "getOne"              : (data) => Ajax.get("/location/getOne",                              data),
    "export"              : (data) => Ajax.route("/location/export",                            data),
    "edit"                : (data) => Ajax.post("/location/edit",                               data),
    "delete"              : (data) => Ajax.post("/location/delete",                             data),
};
export const LocRoom = {
    "getAllForLocation"   : (data) => Ajax.get("/location/room/getAllForLocation",              data),
    "getOne"              : (data) => Ajax.get("/location/room/getOne",                         data),
    "edit"                : (data) => Ajax.post("/location/room/edit",                          data),
    "delete"              : (data) => Ajax.post("/location/room/delete",                        data),
};
export const LocSchedule = {
    "getAllForLocation"   : (data) => Ajax.get("/location/schedule/getAllForLocation",          data),
    "getOne"              : (data) => Ajax.get("/location/schedule/getOne",                     data),
    "edit"                : (data) => Ajax.post("/location/schedule/edit",                      data),
    "delete"              : (data) => Ajax.post("/location/schedule/delete",                    data),
};



// Setup Actions
export const Admin = {
    "getAll"              : (data) => Ajax.get("/admin/getAll",                                 data),
    "getOne"              : (data) => Ajax.get("/admin/getOne",                                 data),
    "getCreateData"       : (data) => Ajax.get("/admin/getCreateData",                          data),
    "edit"                : (data) => Ajax.post("/admin/edit",                                  data),
    "editLevel"           : (data) => Ajax.post("/admin/editLevel",                             data),
    "delete"              : (data) => Ajax.post("/admin/delete",                                data),
};
export const GroupInterest = {
    "getAll"              : (data) => Ajax.get("/groupinterest/getAll",                         data),
    "getOne"              : (data) => Ajax.get("/groupinterest/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/groupinterest/getCreateData",                  data),
    "edit"                : (data) => Ajax.post("/groupinterest/edit",                          data),
    "delete"              : (data) => Ajax.post("/groupinterest/delete",                        data),
};
export const Interest = {
    "getAll"              : (data) => Ajax.get("/interest/getAll",                              data),
    "getOne"              : (data) => Ajax.get("/interest/getOne",                              data),
    "getCreateData"       : (data) => Ajax.get("/interest/getCreateData",                       data),
    "edit"                : (data) => Ajax.post("/interest/edit",                               data),
    "delete"              : (data) => Ajax.post("/interest/delete",                             data),
};
export const Staff = {
    "getAll"              : (data) => Ajax.get("/staff/getAll",                                 data),
    "getOne"              : (data) => Ajax.get("/staff/getOne",                                 data),
    "getCreateData"       : (data) => Ajax.get("/staff/getCreateData",                          data),
    "edit"                : (data) => Ajax.post("/staff/edit",                                  data),
    "delete"              : (data) => Ajax.post("/staff/delete",                                data),
};
export const News = {
    "getAll"              : (data) => Ajax.get("/news/getAll",                                  data),
    "getOne"              : (data) => Ajax.get("/news/getOne",                                  data),
    "edit"                : (data) => Ajax.post("/news/edit",                                   data),
    "delete"              : (data) => Ajax.post("/news/delete",                                 data),
};
export const Advice = {
    "getAll"              : (data) => Ajax.get("/advice/getAll",                                data),
    "getOne"              : (data) => Ajax.get("/advice/getOne",                                data),
    "edit"                : (data) => Ajax.post("/advice/edit",                                 data),
    "delete"              : (data) => Ajax.post("/advice/delete",                               data),
};
export const PaymentMethod = {
    "getAll"              : (data) => Ajax.get("/paymentMethod/getAll",                         data),
    "getOne"              : (data) => Ajax.get("/paymentMethod/getOne",                         data),
    "getCreateData"       : (data) => Ajax.get("/paymentMethod/getCreateData",                  data),
    "edit"                : (data) => Ajax.post("/paymentMethod/edit",                          data),
    "delete"              : (data) => Ajax.post("/paymentMethod/delete",                        data),
};
export const Country = {
    "getAll"              : (data) => Ajax.get("/country/getAll",                               data),
    "getOne"              : (data) => Ajax.get("/country/getOne",                               data),
    "edit"                : (data) => Ajax.post("/country/edit",                                data),
    "delete"              : (data) => Ajax.post("/country/delete",                              data),
};
export const Province = {
    "getAll"              : (data) => Ajax.get("/province/getAll",                              data),
    "getOne"              : (data) => Ajax.get("/province/getOne",                              data),
    "getCreateData"       : (data) => Ajax.get("/province/getCreateData",                       data),
    "edit"                : (data) => Ajax.post("/province/edit",                               data),
    "delete"              : (data) => Ajax.post("/province/delete",                             data),
};
export const Locality = {
    "getAll"              : (data) => Ajax.get("/locality/getAll",                              data),
    "getOne"              : (data) => Ajax.get("/locality/getOne",                              data),
    "getCreateData"       : (data) => Ajax.get("/locality/getCreateData",                       data),
    "search"              : (data) => Ajax.post("/locality/search",                             data),
    "edit"                : (data) => Ajax.post("/locality/edit",                               data),
    "delete"              : (data) => Ajax.post("/locality/delete",                             data),
};
export const EmailTemplate = {
    "getAll"              : (data) => Ajax.get("/emailTemplate/getAll",                         data),
    "getOne"              : (data) => Ajax.get("/emailTemplate/getOne",                         data),
    "edit"                : (data) => Ajax.post("/emailTemplate/edit",                          data),
};
export const EmailQueue = {
    "getAll"              : (data) => Ajax.get("/emailQueue/getAll",                            data),
    "getOne"              : (data) => Ajax.get("/emailQueue/getOne",                            data),
};
export const EmailWhiteList = {
    "getAll"              : (data) => Ajax.get("/emailWhiteList/getAll",                        data),
    "getOne"              : (data) => Ajax.get("/emailWhiteList/getOne",                        data),
    "edit"                : (data) => Ajax.post("/emailWhiteList/edit",                         data),
    "remove"              : (data) => Ajax.post("/emailWhiteList/remove",                       data),
};
export const ActionLog = {
    "filter"              : (data) => Ajax.get("/actionLog/filter",                             data),
    "filterForClient"     : (data) => Ajax.get("/actionLog/filterForClient",                    data),
    "filterForManager"    : (data) => Ajax.get("/actionLog/filterForManager",                   data),
    "filterForUser"       : (data) => Ajax.get("/actionLog/filterForUser",                      data),
};
export const ErrorLog = {
    "filter"              : (data) => Ajax.get("/errorLog/filter",                              data),
    "getOne"              : (data) => Ajax.get("/errorLog/getOne",                              data),
    "markResolved"        : (data) => Ajax.post("/errorLog/markResolved",                       data),
};
