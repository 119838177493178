import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Staff }            from "Utils/API";

// Types
import {
    STAFF, STAFF_LOADING,
    STAFF_LIST, STAFF_ELEM, STAFF_CREATE,
} from "Utils/Types";



/**
 * Fetches the Staffs List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStaffs(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STAFF_LOADING });
        }
        Params.unset(params);
        const data = await Staff.getAll(params);
        data.sort = params;
        dispatch({ type : STAFF_LIST, data });
        dispatch(setSection(STAFF));
    };
}

/**
 * Fetches a single Staff
 * @param {Number} staffID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStaff(staffID) {
    return async (dispatch) => {
        const data = await Staff.getOne({ staffID });
        dispatch({ type : STAFF_ELEM, data });
    };
}

/**
 * Fetches the Staff Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStaffCreate() {
    return async (dispatch) => {
        const data = await Staff.getCreateData();
        dispatch({ type : STAFF_CREATE, data });
    };
}



/**
 * Edits a Staff
 * @param {Object} data
 * @returns {() => Void}
 */
export function editStaff(data) {
    return () => Staff.edit(data);
}

/**
 * Deletes a Staff
 * @param {Number} staffID
 * @returns {() => Void}
 */
export function deleteStaff(staffID) {
    return () => Staff.delete({ staffID });
}
