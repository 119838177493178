import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGuide }       from "Actions/Admin/Guide/GuideActions";

// Sections
import SectionList          from "Components/Admin/Guide/Section/SectionList";
import QuestionList         from "Components/Admin/Guide/Question/QuestionList";

// Components
import GuideDetails         from "./GuideDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Guide Page
 */
class GuidePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchGuide(this.props.params.guideID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.guideID !== prevProps.params.guideID) {
            this.props.fetchGuide(this.props.params.guideID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="GUIDES_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="SECTIONS_NAME"  url="SECTIONS"  icon="section"  />
                <NavigationItem message="QUESTIONS_NAME" url="QUESTIONS" icon="question" />
            </SecondaryNav>

            <SwitchRoute type="GUIDE" path={path} baseUrl={url} withDetails>
                <AdminRoute url="SECTIONS"  component={SectionList}  />
                <AdminRoute url="QUESTIONS" component={QuestionList} />
            </SwitchRoute>

            <GuideDetails guideID={params.guideID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuide : PropTypes.func.isRequired,
        elem       : PropTypes.object.isRequired,
        from       : PropTypes.string.isRequired,
        params     : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
        route      : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.guide.elem,
        };
    }
}

export default connect(GuidePage.mapStateToProps, {
    fetchGuide,
})(GuidePage);
