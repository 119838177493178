import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Coach }            from "Utils/API";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchClassEdit, fetchClassCreate, editClass,
} from "Actions/Admin/Strech/ClassActions";



/**
 * The Strech Class Edit Dialog
 */
class ClassEdit extends React.Component {
    // The Initial Data
    initialData = {
        strechClassID : 0,
        strechID      : 0,
        scheduleID    : 0,
        credentialID  : 0,
        coachID       : 0,
        coachName     : "",
        name          : "",
        description   : "",
        videos        : "[\"\"]",
        timeDate      : "",
        fromHour      : "",
        toHour        : "",
        status        : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, strechID, coaches, fetchClassEdit, fetchClassCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchClassEdit(elemID);
                loading = true;
            // Load edit data
            } else if (strechID) {
                fetchClassCreate(strechID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
                const name = Utils.getValue(coaches, "key", elem.credentialID, "value");
                if (!name) {
                    data.coachID   = elem.credentialID;
                    data.coachName = elem.credentialName;
                }
            } else {
                data = { ...this.initialData, strechID };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        data.videos  = JSON.parse(data.videos);
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editClass(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, schedules, coaches } = this.props;
        const { data, loading, errors                     } = this.state;

        return <EditDialog
            open={open}
            icon="class"
            title={!!elemID ? "CLASSES_EDIT_TITLE" : "CLASSES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="CLASSES_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="textarea"
                name="description"
                label="CLASSES_DESCRIPTION"
                value={data.description}
                error={errors.description}
                onChange={this.handleChange}
            />
            <InputField
                type="fields"
                name="videos"
                label="CLASSES_VIDEOS"
                fieldButton="CLASSES_ADD_VIDEO"
                value={data.videos}
                error={errors.videos}
                onChange={this.handleChange}
                isSmall
            />
            <Columns>
                <InputField
                    type="select"
                    name="credentialID"
                    label="CLASSES_COACH"
                    value={data.credentialID}
                    error={errors.credentialID}
                    onChange={this.handleChange}
                    options={coaches}
                    withNone
                />
                <InputField
                    name="coachName"
                    label="CLASSES_COACH_SEARCH"
                    suggestID="coachID"
                    suggestFetch={Coach.search}
                    suggestNone="COACHES_NONE_AVAILABLE"
                    value={data.coachName}
                    error={errors.coachID}
                    onChange={this.handleChange}
                />

                <InputField
                    type="date"
                    name="timeDate"
                    label="CLASSES_DATE"
                    value={data.timeDate}
                    error={errors.timeDate}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="scheduleID"
                    label="CLASSES_SCHEDULE"
                    options={schedules}
                    value={data.scheduleID}
                    error={errors.scheduleID}
                    onChange={this.handleChange}
                    withNone
                    withCustom
                    isRequired
                />
                <InputField
                    isHidden={Number(data.scheduleID) !== -1}
                    type="time"
                    name="fromHour"
                    label="GENERAL_FROM_HOUR"
                    value={data.fromHour}
                    error={errors.fromHour}
                    onChange={this.handleChange}
                />
                <InputField
                    isHidden={Number(data.scheduleID) !== -1}
                    type="time"
                    name="toHour"
                    label="GENERAL_TO_HOUR"
                    value={data.toHour}
                    error={errors.toHour}
                    onChange={this.handleChange}
                />
            </Columns>
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getFemSelect("STRECH")}
                withNone
                isRequired
                shrinkLabel
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClassEdit   : PropTypes.func.isRequired,
        fetchClassCreate : PropTypes.func.isRequired,
        editClass        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        schedules        : PropTypes.array.isRequired,
        coaches          : PropTypes.array.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
        strechID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.strechClass.edition,
            schedules : state.strechClass.schedules,
            coaches   : state.strechClass.coaches,
            elem      : state.strechClass.elem,
        };
    }
}

export default connect(ClassEdit.mapStateToProps, {
    fetchClassEdit, fetchClassCreate, editClass,
})(ClassEdit);
