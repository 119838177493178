import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Lead }             from "Utils/API";

// Types
import {
    LEAD, LEAD_LOADING, LEAD_CHARGING,
    LEAD_LIST, LEAD_FILTER,
    LEAD_ELEM, LEAD_CREATE, LEAD_EDIT, LEAD_CLOSE,
} from "Utils/Types";



/**
 * Fetches the Leads List
 * @param {Object=}  params
 * @param {Object=}  filters
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLeads(elemID, params = {}, filters = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : LEAD_LOADING });
        }
        Params.unset(params);
        params.studentID = elemID;
        const data = await Lead.getAllForStudent({ ...params, ...filters });

        data.filters = filters;
        data.sort    = params;
        dispatch({ type : LEAD_LIST, data });
        dispatch(setSection(LEAD));
    };
}

/**
 * Fetches the Leads Filter data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLeadsFilter() {
    return async (dispatch) => {
        const data = await Lead.getFilterData();
        dispatch({ type : LEAD_FILTER, data });
    };
}

/**
 * Fetches the Lead Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchLeadsEmails(params = {}) {
    return async () => {
        return await Lead.getEmails(params);
    };
}



/**
 * Fetches a single Lead
 * @param {Number}   leadID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLead(leadID, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : LEAD_CHARGING });
        }
        const data = await Lead.getOne({ leadID });
        dispatch({ type : LEAD_ELEM, data });
    };
}

/**
 * Fetches the Lead Create Data
 * @returns {(dispatch: Function) => Void}
 */
 export function fetchLeadCreate() {
    return async (dispatch) => {
        const data = await Lead.getCreateData();
        dispatch({ type : LEAD_CREATE, data });
    };
}

/**
 * Fetches a Lead Edit Data
 * @param {Number} leadID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLeadEdit(leadID) {
    return async (dispatch) => {
        const data = await Lead.getEditData({ leadID });
        dispatch({ type : LEAD_EDIT, data });
    };
}

/**
 * Fetches the Lead Close Data
 * @param {Number} leadID
 * @returns {(dispatch: Function) => Void}
 */
 export function fetchLeadClose(leadID) {
    return async (dispatch) => {
        const data = await Lead.getCloseData({ leadID });
        dispatch({ type : LEAD_CLOSE, data });
    };
}



/**
 * Creates a Lead
 * @param {Object} data
 * @returns {() => Void}
 */
export function createLead(data) {
    return () => Lead.create(data);
}

/**
 * Edits a Lead
 * @param {Object} data
 * @returns {() => Void}
 */
export function editLead(data) {
    return () => Lead.edit(data);
}

/**
 * Sends the Lead a Message
 * @param {Number} leadID
 * @param {String} message
 * @returns {() => Void}
 */
export function sendMessage(leadID, message) {
    return () => Lead.sendMessage({ leadID, message });
}

/**
 * Assigns a Lead
 * @param {Object} data
 * @returns {() => Void}
 */
export function assignLead(data) {
    return () => Lead.assign(data);
}

/**
 * Closes a Lead
 * @param {Object} data
 * @returns {() => Void}
 */
export function closeLead(data) {
    return () => Lead.close(data);
}

/**
 * Opens a Lead
 * @param {Number} leadID
 * @returns {() => Void}
 */
export function openLead(leadID) {
    return () => Lead.open({ leadID });
}

/**
 * Deletes a Lead
 * @param {Number} leadID
 * @returns {() => Void}
 */
export function deleteLead(leadID) {
    return () => Lead.delete({ leadID });
}
