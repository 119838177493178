import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    CALENDAR_LOADING,
    CALENDAR_LIST, CALENDAR_DAY,
} from "Utils/Types";



// The initial State
const initialState = {
    loading    : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,

    currentDay : DateTime.create(),
    events     : {},
};



/**
 * Parses the Events
 * @param {Object} data
 * @returns {Object}
 */
function parseEvents(data) {
    const result   = {};
    const addEvent = (type, day, id, time, text, data) => {
        const index = day.toDayStart().time;
        if (!result[index]) {
            result[index] = [];
        }
        result[index].push({ type, id, time, text, data });
    }

    for (const elem of data.streches) {
        const dates     = Common.getStrechDates(elem);
        elem.timeString = dates.timeText;
        addEvent("strech", dates.fromDay, elem.strechID, dates.date, dates.calText, elem);
    }

    for (const elem of data.classes) {
        const dates     = Common.getClassDates(elem);
        elem.timeString = dates.timeText;
        addEvent("class", dates.fromDay, elem.classID, elem.time, dates.calText, elem);
    }

    for (const elem of data.chats) {
        const dates     = Common.getChatDates(elem);
        elem.timeString = dates.timeText;
        addEvent("chat", dates.fromDay, elem.chatID, elem.time, dates.calText, elem);
    }

    for (const elem of data.meetings) {
        const day  = DateTime.create(elem.time);
        const hour = DateTime.formatDate(elem.time, "time");
        const text = NLS.format("CALENDAR_MEETING", elem.groupName, hour);

        elem.timeString = DateTime.formatString(elem.time);
        addEvent("meeting", day, elem.meetingID, elem.time, text, elem);
    }

    for (const elem of data.conversations) {
        const day     = DateTime.create(elem.time);
        const hour    = DateTime.formatDate(elem.time, "time");
        const coachee = elem.asCoach ? elem.userName : elem.coachName;
        const text    = NLS.format("CALENDAR_CONVERSATION", coachee, hour);

        elem.timeString = DateTime.formatString(elem.time);
        addEvent("conversation", day, elem.conversationID, elem.time, text, elem);
    }

    for (const elem of Object.values(result)) {
        elem.sort((a, b) => b.time - a.time);
    }

    return result;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, CALENDAR_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case CALENDAR_LOADING:
        return {
            ...state,
            loading : true,
        };

    case CALENDAR_LIST:
        return {
            ...state,
            loading : false,
            error   : false,
            events  : parseEvents(action.data),
        };

    case CALENDAR_DAY:
        return {
            ...state,
            currentDay : action.currentDay,
        };

    default:
        return state;
    }
};

export default reducer;
