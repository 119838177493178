import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { saveAssign }       from "Actions/Core/AuthActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Assign Step
 */
class AssignStep extends React.Component {
    // The Initial Data
    initialData = {
        modes : [],
        times : [],
    }

    // The Current State
    state = {
        data    : Utils.clone(this.initialData),
        loading : false,
        errors  : {},
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { strech, saveAssign, onSubmit } = this.props;
        const { data, loading                } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await saveAssign(strech.strechID, data);
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strech                } = this.props;
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title="ASSIGNME_TITLE"
            help={NLS.format("ASSIGNME_HELP2", strech.name || "")}
            error={errors.form}
            isLoading={loading}
        >
            <InputField
                className="auth-multiple"
                type="multiple"
                name="modes"
                label="GENERAL_MODES"
                value={data.modes}
                error={errors.modes}
                onChange={this.handleChange}
                options="MODES"
                labelInside
                smallMargin
                errorBackground
                isRequired
            />
            <InputField
                className="auth-multiple"
                type="multiple"
                name="times"
                label="SCHEDULES_NAME"
                value={data.times}
                error={errors.times}
                onChange={this.handleChange}
                options="TIMES"
                labelInside
                smallMargin
                errorBackground
                isRequired
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        saveAssign : PropTypes.func.isRequired,
        strech     : PropTypes.object.isRequired,
        onSubmit   : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            strech : state.auth.strech,
        };
    }
}

export default connect(AssignStep.mapStateToProps, {
    saveAssign,
})(AssignStep);
