import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Access               from "dashboard/dist/Core/Access";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import StudentEdit          from "Components/Admin/User/Student/StudentEdit";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// APIs
import {
    Strech, Student, Coach,
} from "Utils/API";

// Actions
import {
    fetchInscription, fetchInscriptionCreate, editInscription,
} from "Actions/Admin/Strech/InscriptionActions";



/**
 * The Inscription Edit Dialog
 */
class InscriptionEdit extends React.Component {
    // The Initial Data
    initialData = {
        access          : Access.getID("STUDENT"),
        inscriptionID   : 0,
        strechID        : 0,
        strechName      : "",
        credentialID    : 0,
        credentialName  : "",
        strechPriceID   : 0,
        customDues      : "",
        customPrice     : "",
        paymentMethodID : 0,
        conversations   : -1,
        modes           : [],
        times           : [],
        observations    : "",
        activate        : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : Utils.clone(this.initialData),
        errors  : {},
        action  : Action.get(),
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const {
            open, edition, elemID, elem, isActivate, strechID, credentialID,
            fetchInscription, fetchInscriptionCreate,
        } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = Utils.clone(this.initialData);
            // Load new data
            if (elemID) {
                fetchInscription(elemID);
                loading = true;
            // Load edit data
            } else if (strechID) {
                fetchInscriptionCreate(strechID);
                loading = true;
            // Set the data
            } else {
                data.credentialID = credentialID;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            const initial = Utils.clone(this.initialData);
            if (elemID) {
                data = Utils.extend(initial, elem);
            } else if (strechID) {
                data = { ...initial, strechID };
            } else {
                data = { ...initial };
            }
            data.activate = isActivate ? 1 : 0;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
        if (name === "strechID") {
            this.props.fetchInscriptionCreate(value);
        }
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editInscription(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Starts an Action
     * @returns {Void}
     */
    startAction = () => {
        this.setState({ action : Action.get("CREATE") });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get() });
    }

    /**
     * Handles the Create Submit
     * @param {Object} data
     * @returns {Void}
     */
    createElem = (data) => {
        this.endAction();
        if (data) {
            this.handleChange("credentialID",   data.credentialID);
            this.handleChange("credentialName", data.credentialName);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            elem, elemID, open, isActivate, onClose,
            prices, payments, isFree, hasConversations, totalConv, strechID, credentialID,
        } = this.props;
        const { data, loading, errors, action } = this.state;

        const showUser        = !elemID && !credentialID;
        const customPrice     = !isFree && Number(data.strechPriceID) === -1;
        const showMethod      = !isFree && Number(data.strechPriceID) !== -2;
        const conversations   = Common.getConvLimitSelect(totalConv);
        const showPreferences = hasConversations && Number(data.conversations) !== 0;

        let title      = "INSCRIPTIONS_CREATE_TITLE";
        let isRequired = true;
        if (isActivate) {
            title = "INSCRIPTIONS_ACTIVATE_TITLE";
            isRequired = true;
        } else if(elemID) {
            title      = "INSCRIPTIONS_EDIT_TITLE";
            isRequired = Status.is("ACTIVE", elem.status);
        }

        return <>
            <EditDialog
                open={open}
                icon="inscription"
                title={title}
                error={errors.form}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                isLoading={loading}
            >
                <InputField
                    isHidden={!!strechID}
                    name="strechName"
                    label="STRECHES_SINGULAR"
                    suggestID="strechID"
                    suggestFetch={Strech.search}
                    suggestNone="STRECHES_NONE_AVAILABLE"
                    value={data.strechName}
                    error={errors.strechID}
                    onChange={this.handleChange}
                    isRequired
                />
                <Columns>
                    <InputField
                        isHidden={!showUser}
                        type="select"
                        name="access"
                        label="GENERAL_TYPE"
                        value={data.access}
                        error={errors.access}
                        onChange={this.handleChange}
                        options={Access.getSelect("USER")}
                        isRequired
                    />
                    <InputField
                        isHidden={!showUser || !Access.is("STUDENT", data.access)}
                        name="credentialName"
                        label="STUDENTS_SINGULAR"
                        suggestID="credentialID"
                        suggestFetch={Student.search}
                        suggestNone="STUDENTS_NONE_AVAILABLE"
                        value={data.credentialName}
                        error={errors.credentialID}
                        onChange={this.handleChange}
                        button="GENERAL_CREATE"
                        onClick={this.startAction}
                        isRequired
                    />
                    <InputField
                        isHidden={!showUser || !Access.is("COACH", data.access)}
                        name="credentialName"
                        label="STUDENTS_SINGULAR"
                        suggestID="credentialID"
                        suggestFetch={Coach.search}
                        suggestNone="COACHES_NONE_AVAILABLE"
                        value={data.credentialName}
                        error={errors.credentialID}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <InputField
                        isHidden={isFree}
                        className="columns-double"
                        type="select"
                        name="strechPriceID"
                        label="INSCRIPTIONS_PRICE"
                        value={data.strechPriceID}
                        error={errors.strechPriceID}
                        onChange={this.handleChange}
                        options={prices}
                        extraOptions="INSCRIPTIONS_PRICE_EXTRAS"
                        isRequired={isRequired}
                        withNone
                    />
                    <InputField
                        isHidden={!customPrice}
                        type="number"
                        name="customDues"
                        label="PRICES_DUES"
                        value={data.customDues}
                        error={errors.customDues}
                        onChange={this.handleChange}
                        isRequired={isRequired}
                    />
                    <InputField
                        isHidden={!customPrice}
                        type="number"
                        name="customPrice"
                        label="PRICES_PRICE"
                        value={data.customPrice}
                        error={errors.customPrice}
                        onChange={this.handleChange}
                        isRequired={isRequired}
                    />
                    <InputField
                        isHidden={!showMethod}
                        type="select"
                        name="paymentMethodID"
                        label="PAYMENT_METHODS_SINGULAR"
                        value={data.paymentMethodID}
                        error={errors.paymentMethodID}
                        onChange={this.handleChange}
                        options={payments}
                        isRequired={isRequired}
                        withNone
                    />

                    <InputField
                        isHidden={!hasConversations}
                        className={!showMethod ? "columns-double" : ""}
                        type="select"
                        name="conversations"
                        label="INSCRIPTIONS_CONVERSATIONS"
                        value={data.conversations}
                        error={errors.conversations}
                        onChange={this.handleChange}
                        options={conversations}
                        isRequired={isRequired}
                        shrinkLabel
                    />
                    <InputField
                        isHidden={!showPreferences}
                        type="multiple"
                        name="modes"
                        label="GENERAL_MODES"
                        value={data.modes}
                        error={errors.modes}
                        onChange={this.handleChange}
                        options="MODES"
                        isRequired={isRequired}
                    />
                    <InputField
                        isHidden={!showPreferences}
                        type="multiple"
                        name="times"
                        label="SCHEDULES_NAME"
                        value={data.times}
                        error={errors.times}
                        onChange={this.handleChange}
                        options="TIMES"
                        isRequired={isRequired}
                    />
                </Columns>

                <InputField
                    type="textarea"
                    name="observations"
                    label="GENERAL_OBSERVATIONS"
                    value={data.observations}
                    onChange={this.handleChange}
                />
            </EditDialog>

            <StudentEdit
                open={action.isCreate}
                onSubmit={this.createElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInscription       : PropTypes.func.isRequired,
        fetchInscriptionCreate : PropTypes.func.isRequired,
        editInscription        : PropTypes.func.isRequired,
        open                   : PropTypes.bool.isRequired,
        onClose                : PropTypes.func.isRequired,
        onSubmit               : PropTypes.func.isRequired,
        edition                : PropTypes.number.isRequired,
        prices                 : PropTypes.array.isRequired,
        payments               : PropTypes.array.isRequired,
        isFree                 : PropTypes.bool.isRequired,
        hasConversations       : PropTypes.bool.isRequired,
        totalConv              : PropTypes.number.isRequired,
        elem                   : PropTypes.object.isRequired,
        elemID                 : PropTypes.number,
        strechID               : PropTypes.number,
        credentialID           : PropTypes.number,
        isActivate             : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition          : state.inscription.edition,
            prices           : state.inscription.prices,
            payments         : state.inscription.payments,
            isFree           : state.inscription.isFree,
            hasConversations : state.inscription.hasConversations,
            totalConv        : state.inscription.totalConv,
            elem             : state.inscription.elem,
        };
    }
}

export default connect(InscriptionEdit.mapStateToProps, {
    fetchInscription, fetchInscriptionCreate, editInscription,
})(InscriptionEdit);
