import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramChat }      from "Utils/API";

// Types
import {
    PROGRAM_CHAT, PROGRAM_CHAT_LOADING,
    PROGRAM_CHAT_LIST, PROGRAM_CHAT_ELEM, PROGRAM_CHAT_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program Chat List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchChats(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_CHAT_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramChat.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_CHAT_LIST, data });
        dispatch(setSection(PROGRAM_CHAT));
    };
}

/**
 * Fetches a single Program Chat
 * @param {Number} chatID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchChat(chatID) {
    return async (dispatch) => {
        const data = await ProgramChat.getOne({ chatID });
        dispatch({ type : PROGRAM_CHAT_ELEM, data });
    };
}

/**
 * Fetches the Program Chat Create data
 * @param {Number} programID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchChatCreate(programID) {
    return async (dispatch) => {
        const data = await ProgramChat.getCreateData({ programID });
        dispatch({ type : PROGRAM_CHAT_CREATE, data });
    };
}



/**
 * Edits/Creates a Program Chat
 * @param {Object} data
 * @returns {() => Void}
 */
export function editChat(data) {
    return () => ProgramChat.edit(data);
}

/**
 * Deletes a Program Chat
 * @param {Number} chatID
 * @returns {() => Void}
 */
export function deleteChat(chatID) {
    return () => ProgramChat.delete({ chatID });
}
