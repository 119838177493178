import { setSection }        from "Actions/Core/CoreActions";
import { MeetingReport }     from "Utils/API";

// Types
import {
    MEETING_REPORT, MEETING_REPORT_LOADING,
    MEETING_REPORT_DATA, MEETING_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Meeting Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchMeetingData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : MEETING_REPORT_LOADING });
        }
        const data = await MeetingReport.getData(params);
        dispatch({ type : MEETING_REPORT_DATA, data });
        dispatch(setSection(MEETING_REPORT));
    };
}

/**
 * Fetches the Meeting Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchMeetingGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : MEETING_REPORT_LOADING });
        }
        const data = await MeetingReport.getGraph(params);
        dispatch({ type : MEETING_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Meeting Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchMeetingEmails(params = {}) {
    return async () => {
        return await MeetingReport.getEmails(params);
    };
}
