import Utils                from "dashboard/dist/Utils/Utils";
import GraphType            from "Utils/Entity/GraphType";

// Types
import {
    INSCRIPTION_REPORT_LOADING,
    INSCRIPTION_REPORT_DATA, INSCRIPTION_REPORT_GRAPH,
} from "Utils/Types";



// The initial State
const initialState = {
    loading        : false,
    loaded         : false,
    fetched        : false,
    error          : false,
    canExport      : true,
    edition        : 0,
    paymentMethods : [],
    courses        : [],
    programs       : [],
    streches       : [],
    dues           : [],
    conversations  : [],
    facilitators   : [],
    report         : [],
    total          : 0,
    totalAmount    : 0,
    totalPrice     : 0,
};



/**
 * Parses the Conversations Select
 * @param {Array} conversations
 * @returns {Array}
 */
function parseConversations(conversations) {
    return Utils.parseList(conversations, (elem) => {
        elem.key   += 100;
        elem.value += "%";
    });
}

/**
 * Parses the Report Data
 * @param {Object[]} report
 * @param {Number}   graphType
 * @returns {Object[]}
 */
function parseReport(report, graphType) {
    return Utils.parseList(report, (elem) => {
        elem.name = GraphType.getReportName(graphType, elem.name);
    });
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, INSCRIPTION_REPORT_DATA, INSCRIPTION_REPORT_GRAPH)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case INSCRIPTION_REPORT_LOADING:
        return {
            ...state,
            loading        : true,
        };

    case INSCRIPTION_REPORT_DATA:
        return {
            ...state,
            loading        : false,
            error          : false,
            loaded         : true,
            paymentMethods : action.data.paymentMethods,
            courses        : action.data.courses,
            programs       : action.data.programs,
            streches       : action.data.streches,
            dues           : action.data.dues,
            conversations  : parseConversations(action.data.conversations),
            facilitators   : action.data.facilitators,
        };

    case INSCRIPTION_REPORT_GRAPH:
        return {
            ...state,
            loading        : false,
            error          : false,
            fetched        : true,
            report         : parseReport(action.data.report, action.data.graphType),
            total          : action.data.report.length,
            totalAmount    : action.data.totalAmount,
            totalPrice     : action.data.totalPrice,
        };

        default:
        return state;
    }
};

export default reducer;
