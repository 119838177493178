import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "dashboard/dist/Core/Href";
import Common               from "Utils/Common";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Strech View Dialog
 */
class StrechView extends React.Component {
    /**
     * Handles the View Link
     * @return {Void}
     */
    handleView = () => {
        const { data } = this.props.elem;
        const baseUrl = data.asCoach ? "STRECHES" : "COURSES_MINE";
        Href.goto(baseUrl, data.strechID);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { type, data          } = elem;

        const values = data || {};
        const button = Common.getTypeButton(values.programType);

        return <ViewDialog
            open={open}
            icon={type}
            title={values.title || "GENERAL_VIEW"}
            onClose={onClose}
            secondary={button}
            onSecondary={this.handleView}
            withSpacing
        >
            <ViewField label="GENERAL_NAME"  value={values.title} />
            <ViewField label="CLASSES_COACH" value={values.credentialName} />
            <ViewField label="GENERAL_DATE"  value={values.timeString} />
            {values.isVirtual && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={values.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={values.streamPassword} />
            </>}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elem    : PropTypes.object.isRequired,
    }
}

export default StrechView;
