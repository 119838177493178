import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import QuestionEdit         from "./QuestionEdit";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchQuestions, deleteQuestion, importQuestions,
} from "Actions/Admin/Strech/QuestionActions";



/**
 * The Strech Question List
 */
class QuestionList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { strechID, data } = this.props;
        this.props.fetchQuestions(strechID, params || data.sort);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { strechID, endAction, fetchStrech } = this.props;
        endAction();
        this.fetch();
        fetchStrech(strechID);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { elemID, deleteQuestion } = this.props;
        await deleteQuestion(elemID);
        this.editElem();
    }

    /**
     * Handles the Import Submit
     * @returns {Promise}
     */
    importElem = async () => {
        const { strechID, importQuestions } = this.props;
        await importQuestions(strechID);
        this.editElem();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, data, strech, params, startAction, endAction, hasTabs } = this.props;
        const { canEdit, list, total, sort, loading                                   } = data;

        const elemName = Utils.getValue(list, "strechQuestionID", elemID, "question");

        return <>
            <StrechBar step={StrechStep.QUESTIONS} />
            <Table
                fetch={this.fetch}
                sort={sort}
                none="QUESTIONS_NONE_AVAILABLE"
                hasAlert={StrechStep.hasHelp(strech, StrechStep.QUESTIONS)}
                isLoading={loading}
                hasTabs={hasTabs}
            >
                <TableHead>
                    <TableHeader field="question"   message="QUESTIONS_SINGULAR"  bigMobile />
                    <TableHeader field="type"       message="GENERAL_TYPE"        maxWidth="80" />
                    <TableHeader field="isRequired" message="QUESTIONS_REQUIRED"  maxWidth="80" align="center" />
                    <TableHeader field="position"   message="GENERAL_POSITION"    maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.strechQuestionID} elemID={elem.strechQuestionID}>
                        <TableCell message={elem.question}     />
                        <TableCell message={elem.typeName}     />
                        <TableCell message={elem.requiredName} />
                        <TableCell message={elem.position}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="QUESTIONS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="QUESTIONS_DELETE_TITLE" />
                </TableActionList>
            </Table>

            <QuestionEdit
                open={action.isVCE}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="QUESTIONS_DELETE_TITLE"
                message="QUESTIONS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={endAction}
            />
            <ConfirmDialog
                open={action.isImport}
                icon="import"
                title="QUESTIONS_IMPORT_TITLE"
                message="QUESTIONS_IMPORT_TEXT"
                onSubmit={this.importElem}
                onClose={endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech     : PropTypes.func.isRequired,
        fetchQuestions  : PropTypes.func.isRequired,
        deleteQuestion  : PropTypes.func.isRequired,
        importQuestions : PropTypes.func.isRequired,
        startAction     : PropTypes.func.isRequired,
        endAction       : PropTypes.func.isRequired,
        hasTabs         : PropTypes.bool,
        data            : PropTypes.object.isRequired,
        strech          : PropTypes.object.isRequired,
        params          : PropTypes.object.isRequired,
        action          : PropTypes.object,
        strechID        : PropTypes.number,
        elemID          : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.strechQuestion,
            strech : state.strech.elem,
        };
    }
}

export default connect(QuestionList.mapStateToProps, {
    fetchStrech, fetchQuestions, deleteQuestion, importQuestions,
})(QuestionList);
